/* eslint-disable newline-per-chained-call */
import { Button, createStyles, Theme, withStyles, WithStyles, CircularProgress } from '@material-ui/core';
import { Formik, Form } from 'formik';
import { equals } from 'ramda';
import { useEffect, useState, Fragment } from 'react';
import { useSelector } from 'react-redux';
import * as Yup from 'yup';

import { getBusinessUnitsIdsSelectOptions } from '~features/businessUnits/utils';

import { selectors, boundActions } from '~store';
import {
  Progress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormTextField,
  FormSelect,
  FormDatePicker,
} from '~ui-kit';
import { debounce, getTodayDate, getYesterdayDate, hexIdValidator, todayDateHelper } from '~utils';

const documentTypeOptions = ['CSV', 'PDF', 'XLS'];

const alarmTypeOptions = [
  { label: 'All Alarms', value: 'ALL' },
  { label: 'Unrestored only', value: 'UNRESTORED' },
  { label: 'Unacknowledged only', value: 'UNACKNOWLEDGE' }
];

const validationSchema = Yup.object().shape({
  businessUnitId: Yup.number(),
  subscriberId: hexIdValidator('Subscriber ID should be a HEX decimal between 0x0001 and 0xFFFF'),
  fromDate: Yup.string().required('From Date is a required field'),
  alarmType: Yup.string(),
  documentType: Yup.string(),
});

const styles = ({ spacing, palette }: Theme) =>
  createStyles({
    content: {
      paddingBottom: spacing(0),
    },
    submitBtn: {
      maxHeight: '40px',
    },
    loader: {
      marginLeft: spacing(1),
    },
  });

interface ReportExportProps extends WithStyles<typeof styles> {
  open: boolean;
  onCancel?: () => void;
}

export const ReportExport = withStyles(styles)(({ classes, open, onCancel }: ReportExportProps) => {
  const businessUnitIds = useSelector(selectors.businessUnits.getBusinessUnitIds, equals);
  const businessUnitsOptions = getBusinessUnitsIdsSelectOptions(businessUnitIds);
  const reportFetchProgress = useSelector(selectors.reportExport.reportFetchProgress);
  const reportPreFetchCount = useSelector(selectors.reportExport.reportPreFetchCount);
  const reportPreFetchIsPending = useSelector(selectors.reportExport.reportPreFetchIsPending);
  const [isReady, toggleIsReady] = useState(Boolean(businessUnitsOptions.length));

  const reportCount = reportPreFetchIsPending ? (
    <span className={classes.loader}>
      <CircularProgress color="inherit" size={20} variant="indeterminate" />
    </span>
  ) : (
    `(${reportPreFetchCount})`
  );

  useEffect(() => {
    toggleIsReady(Boolean(businessUnitsOptions.length));
  }, [businessUnitsOptions.length]);

  return (
    <Dialog open={open} maxWidth="xs" closeAfterTransition>
      {isReady && (
        <Fragment>
          <DialogTitle title="Export" />

          <Formik
            initialValues={
              {
                subscriberId: '',
                fromDate: getYesterdayDate(),
                toDate: getTodayDate(),
                alarmType: alarmTypeOptions[0]?.value,
                reportType: documentTypeOptions[0],
                businessUnitId: businessUnitsOptions[0]?.value,
              } as AES.ReportExport.SearchForm
            }
            validationSchema={validationSchema}
            onSubmit={values => {
              boundActions.reportExport.reportFetch({
                reportType: values.reportType as AES.ReportExport.reportType,
                businessUnitId: values.businessUnitId,
                fromDate: todayDateHelper(values.fromDate),
                toDate: todayDateHelper(values.toDate),
                alarmType: values.alarmType,
                unitId: values.subscriberId,
              });
              onCancel?.();
            }}
          >
            {({ values, isValidating, isValid }) => {
              useEffect(() => {
                debounce(() => {
                  if (!isValidating && isValid) {
                    boundActions.reportExport.reportPreFetch({
                      ...values,
                    });
                  }
                })();
              }, [
                values.fromDate,
                values.toDate,
                values.businessUnitId,
                values.subscriberId,
                values.alarmType,
                isValidating,
                isValid,
              ]);

              return (
                <Form>
                  <DialogContent classes={{ root: classes.content }}>
                    <FormSelect label="Business Unit" name="businessUnitId" options={businessUnitsOptions} />
                    <FormTextField label="Subscriber ID" name="subscriberId" cleanable />
                    <FormDatePicker disableFuture format="yyyy/MM/dd" label="From" name="fromDate" />
                    <FormDatePicker disableFuture format="yyyy/MM/dd" label="To" name="toDate" />
                    <FormSelect label="Alarms Type" name="alarmType" options={alarmTypeOptions} />
                    <FormSelect label="Document Type" name="reportType" options={documentTypeOptions} />
                  </DialogContent>

                  <DialogActions>
                    {!reportFetchProgress.completed && <Progress {...reportFetchProgress} />}

                    <Button variant="outlined" color="primary" onClick={() => onCancel?.()}>
                      Cancel
                    </Button>

                    <Button
                      disabled={!(reportPreFetchIsPending || Boolean(reportPreFetchCount))}
                      classes={{ root: classes.submitBtn }}
                      variant="contained"
                      color="primary"
                      type="submit"
                    >
                      Download {reportCount}
                    </Button>
                  </DialogActions>
                </Form>
              );
            }}
          </Formik>
        </Fragment>
      )}
    </Dialog>
  );
});
