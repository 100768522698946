import { Theme } from '@material-ui/core';
import { isNil } from 'ramda';

import { FACPColor } from '~models/subscriber';

import { matchStyles } from '~utils';

export const COLOR_WHITE = '#FFFFFF';
export const COLOR_TRANSPARENT = 'transparent';
export const COLOR_BLACK = '#000000';
export const COLOR_BOX_SHADOW = '0px 0px 10px rgba(0, 0, 0, 0.1)';

export const DARK = {
  logo: '#e5e5e5',
  primary: {
    main: '#3BAEDA',
    dark: '#2D98C1',
    light: '#61CCF4',
  },
  warning: {
    main: '#F49D0E',
  },
  error: {
    main: '#EB5757',
  },
  success: {
    main: '#8CC051',
  },
  background: {
    main: '#161D30',
  },
  blueColor: {
    main: '#26324C',
    dark: '#1C263C',
    light: '#28384E',
  },
  greyColor: {
    main: '#8292AB',
    dark: '#536179',
    light: '#B8CAE3',
  },
  whiteColor: {
    main: COLOR_WHITE,
  },
  blackColor: {
    main: COLOR_BLACK,
  },
  transparentColor: {
    main: COLOR_TRANSPARENT,
  },
};

export const LIGHT = {
  logo: '#016fae',
  primary: {
    main: '#449CEA',
    dark: '#3D8BD1',
    light: '#4AAAFF',
  },
  warning: {
    main: '#F6BE0F',
  },
  error: {
    main: '#EF3D39',
  },
  success: {
    main: '#7DD753',
  },
  background: {
    main: '#F5F6F8',
  },
  blueColor: {
    main: '#475F7D',
  },
  greyColor: {
    main: '#C2CFE0',
    dark: '#90A0B7',
    light: '#FAFAFC',
  },
  whiteColor: {
    main: COLOR_WHITE,
  },
  blackColor: {
    main: COLOR_BLACK,
  },
  transparentColor: {
    main: COLOR_TRANSPARENT,
  },
};

export const colors = {
  WHITE: COLOR_WHITE,
  TRANSPARENT: COLOR_TRANSPARENT,
  BOX_SHADOW: COLOR_BOX_SHADOW,
  BLACK: COLOR_BLACK,
};

export const statusColors = (theme: Theme, propName = 'color') => {
  const getStyles = matchStyles(theme.palette.type);

  return {
    '&.success': {
      [propName]: theme.palette.success.main,
    },
    '&.warning': {
      [propName]: theme.palette.warning.main,
    },
    '&.error': {
      [propName]: theme.palette.error.main,
    },
    '&.self-monitoring': {
      [propName]: theme.palette.primary.dark,
    },
    '&.default': {
      [propName]: getStyles(theme.palette.greyColor.main, theme.palette.greyColor.dark),
    },
  };
};

export const highContrastColors = (theme: Theme) => {
  const getStyles = matchStyles(theme.palette.type);

  return {
    '&.high-contrast': {
      color: theme.palette.blackColor.main,

      '& h6': {
        color: theme.palette.blackColor.main,
      },

      '& span': {
        color: theme.palette.blackColor.main,
      },

      '& a': {
        color: theme.palette.blackColor.main,
      },

      '& i': {
        background: theme.palette.blackColor.main,
      },

      '& button': {
        backgroundColor: `${theme.palette.blackColor.main} !important`,
        border: 'thin solid',
        borderColor: `${theme.palette.whiteColor.main} !important`,

        '& span': {
          color: theme.palette.whiteColor.main,
        },

        '&.Mui-disabled': {
          backgroundColor: `${theme.palette.greyColor.dark} !important`,
        },
      },

      '&.warning': {
        backgroundColor: theme.palette.warning.main,
      },
      '&.error': {
        backgroundColor: theme.palette.error.main,
      },
      '&.default': {
        backgroundColor: getStyles(theme.palette.greyColor.main, theme.palette.greyColor.dark),
      },

      '&.facp-blue': {
        backgroundColor: theme.palette.primary.main,
      },
      '&.facp-red': {
        backgroundColor: theme.palette.error.main,
      },
      '&.facp-yellow': {
        backgroundColor: theme.palette.warning.main,
      },
      '&.facp-green': {
        backgroundColor: theme.palette.success.main,
      },
    },
  };
};

export const getFACPColor = (theme, value: FACPColor | null) => {
  if (isNil(value)) {
    return theme.palette.primary.main;
  }

  switch (value) {
    case FACPColor.Blue:
      return theme.palette.primary.main;

    case FACPColor.Red:
      return theme.palette.error.main;

    case FACPColor.Yellow:
      return theme.palette.warning.main;

    case FACPColor.Green:
      return theme.palette.success.main;
  }
};
