import * as querystring from 'querystring';

import { is } from 'ramda';

import { PageFilters, PageSorting, Pagination, PaginationRequestPayload, PaginationResponse, PageRange } from '~models';

export const extractPaginationValues = ({
  totalPages,
  totalElements,
  number,
  size,
}: PaginationResponse): Pagination => ({
  totalElements,
  totalPages,
  number,
  size,
});

export const getInitialRequestParams = (pagination: Pagination, size = pagination.size): PaginationRequestPayload => ({
  pagination: {
    ...pagination,
    size,
  },
  initial: true,
});

export const mapFilters = (filters: PageFilters = {}) =>
  Object.keys(filters).reduce((acc, key) => {
    if (Array.isArray(filters[key])) {
      if (filters[key].length) {
        acc[key] = filters[key].join(',');
      }
    }

    if (filters[key] || is(Number, filters[key]) || is(Boolean, filters[key])) {
      acc[key] = filters[key];
    }

    return acc;
  }, {});

export const mapSorting = (sorting: PageSorting['sorting'] = null) => {
  if (!sorting) {
    return {};
  }

  return { sort: [sorting.value, sorting.order, sorting?.optional].join(',') };
};

export const getPaginationQueryParams = (
  {
    pagination: { number, size },
    initial,
    filters,
    sorting,
    from,
    to,
  }: PaginationRequestPayload & PageFilters & PageSorting & PageRange,
  params = {}
): string => {
  const values = {
    page: initial ? number : number + 1,
    size,
    ...mapFilters(filters),
    ...mapSorting(sorting),
    ...params,
  };

  if (from && to) {
    return querystring.stringify({
      ...values,
      from,
      to,
    });
  }

  return querystring.stringify(values);
};

export const isNoLicense = (errorStatus: number): boolean => errorStatus === 402;

export const pageSizeOption = [
  { value: 20, label: '20' },
  { value: 50, label: '50' },
  { value: 100, label: '100' },
  { value: 200, label: '200' },
  { value: 500, label: '500' },
];

export const getPageSizeOption = (totalNumber: number) => {
  if (!totalNumber) {
    return pageSizeOption;
  }

  if (totalNumber < 50) {
    return pageSizeOption.slice(0, 2);
  }

  if (totalNumber < 100) {
    return pageSizeOption.slice(0, 3);
  }

  if (totalNumber < 200) {
    return pageSizeOption.pop();
  }

  return pageSizeOption;
};

export const setLocalStorageItem = (key: string, value?: string) => {
  if (!value) {
    return null;
  }

  return localStorage.setItem(key, value);
};

const getAndSetLocalPageSize = (key: string, value?: string) => {
  if (value) {
    setLocalStorageItem(key, value);
  }

  return localStorage.getItem(key);
};

export const userBasedPageSize = (userId?: number | null, unitType?: string, size?: string): number | null => {
  if (!userId || !unitType) {
    return null;
  }

  return getAndSetLocalPageSize(`pagination${unitType}PageSize${userId}`, size) as unknown as number;
};

export const getLocalStorageItem = (key: string) => JSON.parse(localStorage.getItem(key) as string);

export const removeLocalStorageItem = (key: string) => localStorage.removeItem(key);

