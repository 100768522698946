import { Typography } from '@material-ui/core';
import { assoc, all, propOr, map } from 'ramda';
import * as yup from 'yup';

import { AlarmAutomation, AlarmAutomationSocket } from '~models/alarmAutomation';

import { alarmAutomationValidators } from '~utils';

export type AlarmAutomationFormValues = {
  portNumber?: number;
  alarmAutomation: Partial<AlarmAutomation>;
};

export const initialValues: AlarmAutomationFormValues = {
  portNumber: 0,
  alarmAutomation: {
    id: 0,
    isServer: true,
    businessUnits: [],
    sockets: [
      {
        id: 0,
        ipAddress: '',
        portNumber: 0,
        number: 0,
        isHeartbeatEnabled: false,
      },
    ],
  },
};

export const validationSchema = yup.object().shape({
  portNumber: alarmAutomationValidators.portNumber,
  alarmAutomation: yup.object().shape({
    sockets: yup.array().of(
      yup.object().shape({
        ipAddress: alarmAutomationValidators.ipAddress,
        portNumber: alarmAutomationValidators.portNumber,
      })
    ),
  }),
});

export const getAlarmAutomationFormValues = (alarmAutomation?: AlarmAutomation): AlarmAutomationFormValues => {
  if (!alarmAutomation) {
    return initialValues;
  }

  return {
    portNumber: alarmAutomation.sockets[0].portNumber,
    alarmAutomation,
  };
};

export const helpers = {
  addNewSocket: (alarmAutomation: AlarmAutomation): AlarmAutomation => {
    const { sockets } = alarmAutomation;

    return {
      ...alarmAutomation,
      sockets: [
        ...sockets,
        {
          ipAddress: '',
          portNumber: sockets[0]?.portNumber || 0,
          isHeartbeatEnabled: false,
          number: sockets.length,
        },
      ],
    };
  },

  removeSocket: (alarmAutomation: AlarmAutomation, index: number): AlarmAutomation =>
    assoc(
      'sockets',
      alarmAutomation.sockets.filter((s, i) => i !== index),
      alarmAutomation
    ),

  allSocketsAreValid: (alarmAutomation: AlarmAutomation): boolean =>
    all<AlarmAutomationSocket>(socket => Boolean(socket.ipAddress), propOr([], 'sockets', alarmAutomation)),

  updateSocketsWithPortNumber: (
    alarmAutomation: Partial<AlarmAutomation>,
    portNumber: number
  ): Partial<AlarmAutomation>['sockets'] =>
    map(assoc('portNumber', portNumber), propOr([], 'sockets', alarmAutomation)),
};

export const getInfoDialogContent = (): { title: string; content } => {
  const title = 'Alarm Automation';

  const content = (
    <Typography variant="body2">
      Instance can have up to 4 AA IP addresses. If primary AA is down, all traffic will be redirected to the secondary,
      and so on.
    </Typography>
  );

  return { title, content };
};
