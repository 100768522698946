import { Theme } from '@material-ui/core';
import { createStyles } from '@material-ui/styles';

export const styles = ({ spacing }: Theme) =>
  createStyles({
    'qr-code': {
      display: 'flex',
      justifyContent: 'center',
      marginBottom: spacing(1),

      '& img': {
        width: spacing(20),
        height: spacing(20),
      }
    },

    secret: {
      fontWeight: 'bold',
    },

    buttons: {
      justifyContent: 'space-between',
    },

    code: {
      marginTop: spacing(1),
    }
  });
