import { Grid, WithStyles, withStyles } from '@material-ui/core';
import { useFormikContext, FormikContextType } from 'formik';
import { equals } from 'ramda';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';

import { AlarmAutomation } from '~models/alarmAutomation';
import { getAAHeartbeatOptions } from '~pages/Settings/AlarmAutomation';

import { styles } from './AlarmAutomationForm.styles';
import { helpers, AlarmAutomationFormValues } from './config';

import { selectors } from '~store';
import { Required, FormTextField as TextField, DeleteButton, AddMoreButton, FormSelect, SelectOption } from '~ui-kit';
import { getFormErrors } from '~utils';

type AlarmAutomationFormProps = WithStyles<typeof styles> & {
  showing?: boolean;
  alarmAutomation?: AlarmAutomation;
  onSubmit?: (values: AlarmAutomationFormValues) => void;
};

export const AlarmAutomationForm = withStyles(styles)(({ showing = false, classes }: AlarmAutomationFormProps) => {
  const error = useSelector(selectors.alarmAutomation.getAlarmAutomationError, equals);
  const portRanges = useSelector(selectors.app.getAllowedPortRanges, equals);
  const { values, setErrors, setFieldValue }: FormikContextType<AlarmAutomationFormValues> = useFormikContext();

  useEffect(() => {
    if (values.alarmAutomation) {
      setFieldValue(
        'alarmAutomation.sockets',
        helpers.updateSocketsWithPortNumber(values.alarmAutomation, values.portNumber as number)
      );
    }
  }, [values.portNumber]);

  useEffect(() => {
    setErrors(
      getFormErrors(error, {
        alarmAutomation: 'tcpServer',
      })
    );
  }, [error]);

  if (!values?.alarmAutomation?.sockets) {
    return null;
  }

  return (
    <Grid container spacing={2} classes={{ root: classes.root }}>
      <Grid item xs={12}>
        <TextField
          cleanable
          name="portNumber"
          label={<Required>Port number</Required>}
          type="number"
          showing={showing}
          helperText={portRanges.alarmAutomation}
        />
      </Grid>

      {values.alarmAutomation.sockets.map((field, i) => {
        const ipAddressLabel = i === 0 ? 'Primary IP address' : `IP Address ${i + 1}`;
        const heartbeatLabel = i === 0 ? 'Primary Heartbeat' : `Heartbeat ${i + 1}`;
        const ipAddress = `alarmAutomation.sockets[${i}].ipAddress`;
        const isHeartbeatEnabled = `alarmAutomation.sockets[${i}].isHeartbeatEnabled`;

        return (
          <Grid item xs={12} key={i}>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <TextField
                  cleanable
                  name={ipAddress}
                  showing={showing}
                  label={<Required>{ipAddressLabel}</Required>}
                />
              </Grid>

              <Grid item xs={i !== 0 ? 4 : 6}>
                <FormSelect
                  name={isHeartbeatEnabled}
                  label={heartbeatLabel}
                  showing={showing}
                  options={getAAHeartbeatOptions() as unknown as SelectOption[]}
                />
              </Grid>

              {!showing && i !== 0 ? (
                <DeleteButton
                  classes={{ root: classes.deleteButton }}
                  onClick={() =>
                    setFieldValue('alarmAutomation', helpers.removeSocket(values.alarmAutomation as AlarmAutomation, i))
                  }
                />
              ) : null}
            </Grid>
          </Grid>
        );
      })}

      {!showing && values.alarmAutomation && values.alarmAutomation.sockets.length < 4 ? (
        <Grid item xs={12} md={6}>
          <AddMoreButton
            color="primary"
            variant="text"
            classes={{ root: classes.addButton }}
            onClick={() => {
              setFieldValue('alarmAutomation', helpers.addNewSocket(values.alarmAutomation as AlarmAutomation));
            }}
            disabled={!helpers.allSocketsAreValid(values.alarmAutomation as AlarmAutomation)}
          >
            Add IP Address
          </AddMoreButton>
        </Grid>
      ) : null}
    </Grid>
  );
});
