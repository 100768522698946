import { Typography } from '@material-ui/core';
import { WithStyles, withStyles } from '@material-ui/styles';
import { PropsWithChildren, ReactNode } from 'react';

import { styles } from './FormGroup.styles';

export type FormGroupProps = PropsWithChildren<WithStyles<typeof styles>> & {
  title?: string;
  icon?: ReactNode;
};

export const FormGroup = withStyles(styles)(({ title, icon, children, classes }: FormGroupProps) => (
  <div className={classes.root}>
    {title && (
      <Typography variant="h4" color="primary" className={classes.title}>
        {title}
        {icon}
      </Typography>
    )}
    <div className={classes.inner}>{children}</div>
  </div>
));
