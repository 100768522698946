import { VariantType } from 'notistack';
import { assoc } from 'ramda';

import { BusinessUnit, BusinessUnitId, BusinessUnitStatuses, ImportCSVUnitsStats } from '~models';
import { CardStatus, SelectOption } from '~ui-kit';
import { intToHex } from '~utils';

export const getBusinessUnitsIdsSelectOptions = (ids: BusinessUnitId[] = []): SelectOption[] =>
  ids.map(({ id, name }) => ({ value: id, label: name }));

export const getBusinessUnitsIdsSelectOptionsWithUnits = (ids: BusinessUnitId[] = []): SelectOption[] =>
  ids.map(({ id, name }) => ({ value: id, label: name, units: [] }));

export const getReceiverNumberOptions = () =>
  Array(15)
    .fill(null)
    .map((v, i) => ({ value: i + 1, label: intToHex(i + 1, 1) } as SelectOption));

export const getBusinessUnitMeta = (
  unit?: BusinessUnit | null | undefined,
  isSelfMonitoring?: boolean
): { status: { value: CardStatus; title: string } } => {
  const status = { value: 'warning' as CardStatus, title: 'N/A' };

  if (!unit) {
    return { status };
  }

  if (isSelfMonitoring) {
    status.value = 'self-monitoring';
    status.title = 'Self Monitoring';

    return { status };
  }
  switch (unit.status) {
    case 'UP': {
      status.value = 'success';
      status.title = 'Up';
      break;
    }

    case 'DOWN': {
      status.value = 'error';
      status.title = 'Down';
      break;
    }

    default:
      break;
  }

  return { status };
};

export const updateBusinessUnitsWithStatuses = (
  units: BusinessUnit[],
  statuses: BusinessUnitStatuses
): BusinessUnit[] => units.map(unit => assoc('status', statuses[unit.id] || unit.status, unit));

export const getImportCSVUnitsNotificationConfig = ({
  total,
  faults,
  success,
}: ImportCSVUnitsStats): { variant: VariantType; message: string } => {
  if (total === faults) {
    return { message: `Failed to import ${total} units`, variant: 'error' };
  }

  if (faults === 0) {
    return { variant: 'success', message: `Successfully imported ${success} units` };
  }

  return {
    variant: 'warning',
    message: `Imported ${success} units. \n Failed to import ${faults}. \n Total ${total} units`,
  };
};
