import { Button, Grid } from '@material-ui/core';
import { WithStyles, withStyles } from '@material-ui/styles';
import { equals } from 'ramda';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';

import { styles } from './AssignedDealersDialog.styles';

import { PageLoader } from '~components';
import { appDetailRoutes } from '~constants';
import { AssignedDealer, Subscriber, IPLink } from '~models';
import { selectors, boundActions } from '~store';
import { Dialog, DialogContent, DialogActions, DialogTitle, DataPlaceholder, NavLink } from '~ui-kit';

type AssignedDealersDialogProps = {
  open: boolean;
  type: 'subscribers' | 'hybrids' | 'ipLinks';
  data: Partial<Subscriber> | Partial<IPLink>;
  onConfirm: () => void;
  backTo?: string;
} & WithStyles<typeof styles>;

export const AssignedDealersDialog = withStyles(styles)(({
  open = false,
  type,
  data,
  onConfirm,
  backTo,
  classes
}: AssignedDealersDialogProps) => {
  const dealers = useSelector(
    selectors[type].getDealers(data.id as number, data.businessUnitId as number), equals
  );
  // @ts-ignore
  const { details: { dealers: isLoading } } = useSelector(selectors[type].getLoaders, equals);

  useEffect(() => {
    if (open) {
      boundActions[type].fetchAssignedDealersInit(
        { id: data.id as number, businessUnitId: data.businessUnitId as number }
      );
    }
  }, [open]);

  return (
    <Dialog open={open} maxWidth="xs" fullWidth>
      <DialogTitle title="Dealers" />

      <DialogContent>
        {isLoading && <PageLoader fullscreen />}

        {dealers?.length
          ? <Grid className={classes.list}>
            {dealers?.map((dealer: AssignedDealer) => <NavLink
              className={classes.item}
              to={appDetailRoutes.dealerDetails(dealer.id, backTo)}
              key={dealer.id}
            >
              {dealer.name}
            </NavLink>)}
          </Grid>
          : <DataPlaceholder />}
      </DialogContent>

      <DialogActions>
        <Button variant="contained" color="primary" onClick={onConfirm}>
            OK
        </Button>
      </DialogActions>
    </Dialog>
  );
}
);
