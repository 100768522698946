/* eslint-disable newline-per-chained-call */
import { Grid, Typography } from '@material-ui/core';
import { WithStyles, withStyles } from '@material-ui/styles';
import { Formik, Form } from 'formik';
import { equals } from 'ramda';
import { Fragment, useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { DialogContent } from '~ui-kit/Dialog';
import { getFormErrors } from '~utils/forms';

import { SelectMigratedBUButtons } from '../SelectMigratedBU/Buttons';
import { getBusinessUnitsSelectOptions } from '../SelectMigratedBU/config';

import { getInitialValues, validationSchema, getBUCheckedValues, dbMessages } from './config';
import { styles } from './MultipleBUMigration.styles';

import { PageLoader } from '~components';
import { FullHeightPage } from '~hocs';
import { boundActions, selectors } from '~store';
import { FormSelect, FormCheckbox, Divider, Required, FormError, FormSwitchField } from '~ui-kit';

const FormWrapper = FullHeightPage(Form);

type MultipleBUMigrationProps = WithStyles<typeof styles>;

export const MultipleBUMigration = withStyles(styles)(({ classes }: MultipleBUMigrationProps) => {
  const {
    status,
    withWarnings,
    error,
    migratedBUList,
    loading: { migration },
  } = useSelector(selectors.app.getDatabaseMigrationState, equals);
  const isDatabaseMigrationNotStarted = useSelector(selectors.app.isDatabaseMigrationNotStarted);
  const isDatabaseMigrationInProgress = useSelector(selectors.app.isDatabaseMigrationInProgress);
  const isDatabaseMigrationFinished = useSelector(selectors.app.isDatabaseMigrationFinished);
  const isBUMigrated = useSelector(selectors.app.isBUMigrated);
  const isOnlyOrphanBusinessUnit = useSelector(selectors.businessUnits.isOnlyOrphanBusinessUnit);
  const isLoading = migration || isDatabaseMigrationInProgress;
  const showMigratedBUList = isDatabaseMigrationNotStarted && !isLoading;
  const [skipTechOptions, setSkipTechOptions] = useState<boolean>(true);
  const [displayMsg, setDisplayMsg] = useState<string>(dbMessages.complete);

  const onSubmit = useCallback(
    ({ businessUnitNames, defaultBusinessUnitName }) => {
      const checkedBUs = getBUCheckedValues(businessUnitNames);
      const defaultBU = checkedBUs[defaultBusinessUnitName];

      if (checkedBUs.length && defaultBU) {
        boundActions.app.databaseMigrationInit({
          businessUnitNames: checkedBUs,
          defaultBusinessUnitName: defaultBU,
          skipConfiguration: true,
          skipTechOptions,
        });
      }
    },
    [isOnlyOrphanBusinessUnit, skipTechOptions]
  );

  const onNext = useCallback(() => {
    boundActions.app.setDatabaseMigrationWithWarnings(false);
    boundActions.app.setDatabaseMigrationStatus('FINISHED');
    setDisplayMsg('');
  }, []);

  const onCancel = useCallback(() => {
    if (isBUMigrated) {
      boundActions.app.cleanDatabaseArchiveInit();
      window.location.reload();
      return;
    }

    boundActions.app.setAppDialog({
      dialog: 'selectMigratedBU',
      value: false,
    });
  }, [isBUMigrated]);

  useEffect(() => {
    if (status === 'FAILED') {
      setDisplayMsg(dbMessages.failed);
      return;
    }

    if (withWarnings) {
      setDisplayMsg(dbMessages.warnings);
    }
  }, [status, withWarnings]);

  return (
    <Formik
      initialValues={getInitialValues(migratedBUList)}
      enableReinitialize
      validationSchema={validationSchema}
      onSubmit={onSubmit}
    >
      {({ values, setFieldValue, errors, setErrors, resetForm }) => {
        const checkedBUs = getBUCheckedValues(values.businessUnitNames);

        useEffect(() => {
          setErrors(getFormErrors(error));
        }, [error]);

        useEffect(() => {
          if (checkedBUs.length) {
            setFieldValue('defaultBusinessUnitName', '0');
            return;
          }

          setFieldValue('defaultBusinessUnitName', '');
        }, [checkedBUs.length]);

        useEffect(() => {
          if (isDatabaseMigrationFinished) {
            resetForm({ values: getInitialValues(migratedBUList) });
          }
        }, [isDatabaseMigrationFinished]);

        return (
          <FormWrapper>
            <DialogContent>
              {isLoading && (
                <Fragment>
                  <PageLoader />

                  <Typography className={classes.loadingText} variant="body1">
                    Do not refresh this window until migration is complete
                  </Typography>
                </Fragment>
              )}

              {showMigratedBUList && (
                <Fragment>
                  <Grid container>
                    <Grid container className={classes.buList}>
                      {migratedBUList.map(bu => (
                        <FormCheckbox
                          key={bu}
                          classes={{ root: classes.checkboxItem }}
                          name={`businessUnitNames.${bu}`}
                          label={bu}
                        />
                      ))}
                    </Grid>

                    <Grid item xs={12}>
                      <FormSwitchField
                        name="skipTechOptions"
                        activeText="Import Tech Options"
                        text="Import Tech Options"
                        value={!skipTechOptions}
                        onChangeCapture={() => setSkipTechOptions(!skipTechOptions)}
                      />
                    </Grid>

                    <Grid item xs={12}>
                      <FormError error={error} value={errors?.businessUnitNames as string} />
                    </Grid>
                  </Grid>

                  {isOnlyOrphanBusinessUnit && (
                    <Fragment>
                      <Divider classes={{ root: classes.divider }} />

                      <Grid item xs={12} className={classes.select}>
                        <FormSelect
                          name="defaultBusinessUnitName"
                          label={<Required>Default Business unit</Required>}
                          options={getBusinessUnitsSelectOptions(checkedBUs)}
                        />
                      </Grid>
                    </Fragment>
                  )}
                </Fragment>
              )}

              {(isDatabaseMigrationFinished || status === 'FAILED') && (
                <Typography variant="body1">
                  {displayMsg}
                </Typography>
              )}

              {withWarnings &&
                  <Typography
                    variant="body1"
                    className={classes.downloadLog}
                    onClick={boundActions.app.downloadMigrationLogInit}
                  >
                    Click here to download log file
                  </Typography>
              }

              {(isDatabaseMigrationFinished && !withWarnings) && (
                <Typography className={classes.inlineSubtitle} variant="body1">
                  Do you want to migrate one more BU?
                </Typography>
              )}
            </DialogContent>

            <SelectMigratedBUButtons
              isLoading={isLoading}
              onCancel={onCancel}
              onNext={onNext}
            />
          </FormWrapper>
        );
      }}
    </Formik>
  );
});
