import { Hybrid } from './hybrid';
import { IPLink } from './ip-link';
import { NonAESUnit } from './non-aes';
import { Subscriber } from './subscriber';
import { UnitRoute, UnitRouteHop, UnitType } from './unit';

export enum ThemeType {
  DARK = 'https://www.figma.com/embed?embed_host=share&url=https%3A%2F%2Fwww.figma.com%2Fproto%2FNUmG36cCYnfW0mx95qMZFJ%2FAES_SMNR%3Fnode-id%3D2456%253A23831%26scaling%3Dmin-zoom%26page-id%3D2321%253A23436',
  LIGHT = 'https://www.figma.com/embed?embed_host=share&url=https%3A%2F%2Fwww.figma.com%2Fproto%2FNUmG36cCYnfW0mx95qMZFJ%2FAES_SMNR%3Fnode-id%3D3316%253A31052%26scaling%3Dmin-zoom%26page-id%3D3316%253A31051',
}

export enum MapLayers {
  UNITS = 'map-units',
  DUPLICATED_UNITS = 'map-duplicated--units',
  IP_LINKS = 'map-ip-links',
  HYBRIDS = 'map-hybrids',
  SELECTED_UNIT = 'map-units-selected',
  SELECTED_ROUTE_UNIT = 'map-route-units-selected',
  ROUTES = 'map-routes',
  SELECTED_ROUTE = 'map-routes-selected',
  CLUSTERS = 'clusters',
  CLUSTERS_COUNT = 'cluster-count',
}

export enum MapLayerType {
  STREET = 'street',
  SATELLITE = 'satellite',
}

export enum MapFilter {
  All = 'all',
  Faults = 'faults',
  Routes = 'routes',
  LinkLayer = 'link layer',
  Dependency = 'dependency,'
}

export type GeoIconType =
  | 'fire-ip'
  | 'fire-rf'
  | 'burglary-ip'
  | 'burglary-rf'
  | 'hybrid'
  | 'ip-link'
  | 'unknown'
  | 'non-aes'
  | 'inactive'
  | 'circle-filled-green'
  | 'circle-filled-orange'
  | 'circle-filled-red'
  | 'extender'
  | 'multiple';
  // INCC-3142 hide High Gain Antenna
  // | 'high-antenna'

export type GeoCoordinates = {
  latitude?: number;
  longitude?: number;
  altitude?: number;
  coordinateError?: string;
};

export type GeoUnit = GeoCoordinates & {
  unitType: UnitType;
  iconType: GeoIconType;
  isActive: boolean;
  hasExtender?: boolean;
};

export type GeoUnitRouteHop = UnitRouteHop & GeoCoordinates;
export type GeoUnitRoute = UnitRoute & GeoCoordinates & { position: GeoCoordinates } & { hops: GeoUnitRouteHop[] };
export type GeoSubscriber = Subscriber & GeoUnit;
export type GeoIPLink = IPLink & GeoUnit;
export type GeoHybrid = Hybrid & GeoUnit;
export type GeoNonAESUnit = NonAESUnit & GeoUnit;
export type GeoUnitModel = GeoSubscriber | GeoIPLink | GeoHybrid | GeoNonAESUnit;

export type GeoUnitSearch = {
  id: number,
  unitType: UnitType,
  longitude: number;
  latitude: number;
}

export type GeoFaultsByUnitTypeKey = 'IP Link' | 'Hybrid' | 'Burg' | 'Fire' | 'Unknown';

export type GeoFaultsByUnitType = {
  [key in GeoFaultsByUnitTypeKey]: GeoFaultByUnitType[];
}

export type GeoFaultByUnitType = {
  unitId: number;
  description: string[];
  types: string;
}

export type GeoFaultsByCode = {
  [key in string]: GeoFaultByCode[];
}

export type GeoFaultByCode = {
  cid: string;
  description: string[];
  unitId: number;
  type: string;
}

export type GeoLinkLayers = {
  [key in string]: {
    [key in string]: number [];
  }
}

export type GeoDependencyKey = 'IP Link' | 'Subscriber' | 'Hybrid';
export type DependencyTypeColor = 'GREEN' | 'ORANGE' | 'RED';

export type GeoDependency = {
  [key in GeoDependencyKey]: {
    [key in DependencyTypeColor]: GeoDependencyUnit[]
  }
}

export type GeoDependencyUnit = {
  id: number;
  type: 'Burg' | 'Fire' | null;
  reportingRoute:
  | 'Radio and Internet'
  | 'Internet Only'
  | 'Radio Only'
  | 'Internet with Radio Backup'
  | 'Radio with Internet Backup';
}
