import { Theme } from '@material-ui/core';
import { createStyles } from '@material-ui/styles';

export const styles = ({ spacing }: Theme) =>
  createStyles({
    checkbox: {
      marginBottom: 0,
    },

    field: {
      marginBottom: spacing(1),
    },
  });
