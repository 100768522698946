import { Button, Grid, Typography, WithStyles, withStyles } from '@material-ui/core';
import { equals } from 'ramda';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';

import { PageLoader } from '~components/Page';
import { CustomNote } from '~models/subscriber';
import { intToHex } from '~utils/hex';
import { getLinkByUnitType } from '~utils/index';

import { styles } from './UnitNotesDialog.styles';
import { NoteUnit, getTitle, getUnitTypePath } from './UnitNotesDialogUtil';

import { boundActions, selectors } from '~store';
import { Dialog, DialogContent, DialogActions, DialogTitle, LinkButton, DataPlaceholder } from '~ui-kit';

type UnitNotesDialogProps = WithStyles<typeof styles> & {
    open: boolean;
    unit: NoteUnit;
    onClose: () => void;
    backTo?: string;
};

export const UnitNotesDialog = withStyles(styles)(({
  open = false,
  unit,
  onClose,
  backTo,
  classes
}: UnitNotesDialogProps) => {
  if (!open) {
    return null;
  }
  const link = getLinkByUnitType(unit.unitType, unit.businessUnitId, unit.unitId, backTo);
  const { customNote: isLoading } = useSelector(selectors.alarms.getAlarmsLoaders, equals);
  const customNotes = useSelector(selectors.alarms.getCustomNotes, equals) as unknown as CustomNote[];

  useEffect(() => {
    boundActions.alarms.fetchCustomNotesInit({
      businessUnitId: unit.businessUnitId,
      id: unit.unitId,
      unitType: getUnitTypePath(unit.unitType),
    });
  }, [unit.unitId, unit.businessUnitId]);

  return (
    <Dialog fullWidth maxWidth="sm" open={open}>
      <DialogTitle title={getTitle(unit)} />
      <DialogContent>
        { isLoading ?
          <PageLoader /> :
          customNotes?.length ?
            (customNotes.map((field, i) =>
              <Grid container key={field.id}>
                <Grid item xs={12} md={4}>
                  <Typography variant="subtitle2" className={i !== 0 ? classes.noteTitleSpace : classes.noteTitle}>
                    Title
                  </Typography>
                </Grid>
                <Grid item xs={12} md={8}>
                  <Typography variant="subtitle2" className={i !== 0 ? classes.noteTitleSpace : classes.noteTitle}>
                    Text
                  </Typography>
                </Grid>
                <Grid item xs={12} md={4}>
                  {field.title}
                </Grid>
                <Grid item xs={12} md={8}>
                  {field.note}
                </Grid>
              </Grid>
            )) :
            <DataPlaceholder />
        }

      </DialogContent>
      <DialogActions>
        <Button variant="outlined" color="primary" onClick={onClose}>
        Close
        </Button>
        <LinkButton
          variant="contained"
          color="primary"
          linkTo={link}
        >
        Open {intToHex(unit.unitId)}
        </LinkButton>
      </DialogActions>
    </Dialog>
  );
});
