export interface SystemSettings {
  date: string;
  time: string;
}

export interface SystemInfoConfigurationSettings {
  isMeter: boolean;
}
export interface SystemCoordinateSettings {
  latitude: number,
  longitude: number
}

export interface SystemCheckInGraceConfiguration {
  gracePercent: number | null,
  gracePeriod: number | null,
}

export interface ServerSettings {
  serverId: string;
  receiverNumber: number;
  ipLinkPort: number;
  ipSubscriberPort: number;
  defaultBUID: number;
  orphanBUPassword?: string;
  orphanBUPasswordRepeat?: string;
}

export interface NetworkSettings {
  dns: string[];
  ip: string;
  netmask: string;
  gateway: string;
}

export interface TcpSettings {
  isIpLinksStop: boolean;
  isIpSubscribersStop: boolean;
}

export interface WebRelay {
  webRelayIp: string | null;
  ringDuration: number;
  pauseDuration: number;
}

export interface TechOptionsSettings {
  [key: string]: TechOption[];
}
export interface TechOption {
  category: string;
  description: string;
  enabled: boolean;
  masterKey: string;
}

export interface TechOptionCategoryConfig {
  value: string;
  label: string;
}

export type SubToolsSettingsDataConfiguration = {
  modeSettings: boolean;
  modelRevision: boolean;
  timingSettings: boolean;
  ttl: boolean;
  zoneConfiguration: boolean;
};

export type SubToolsSettingsExecution = {
  lastExecution: string; // date time???
  nextExecution: string; // date time???
};

export type SubToolsSettingsUnits = {
  servicedSubs: number;
  scannedSubs: number;
};

export enum SubToolsSettingsFirstDataRunInterval {
  'EVERY_24_HOURS' = 'EVERY_24_HOURS',
  'EVERY_48_HOURS' = 'EVERY_48_HOURS',
  'NEVER' = 'NEVER',
}
export enum SubToolsSettingsRefreshDataCriteria {
  'OLDER_THAN_30_DAYS' = 'OLDER_THAN_30_DAYS',
  'OLDER_THAN_60_DAYS' = 'OLDER_THAN_60_DAYS',
  'NEVER' = 'NEVER',
}

export type SubToolsSettings = {
  firstData: SubToolsSettingsDataConfiguration &
    SubToolsSettingsExecution &
    SubToolsSettingsUnits & {
      runInterval: SubToolsSettingsFirstDataRunInterval;
    };
  refreshData: SubToolsSettingsDataConfiguration &
    SubToolsSettingsExecution &
    SubToolsSettingsUnits & {
      criteria: SubToolsSettingsRefreshDataCriteria;
    };
  subToolsTimeFrom: string;
  subToolsTimeTo: string;
};

export type SubToolsExecuteSection = 'first-data' | 'refresh-data';

export type Antenna = {
  id: number;
  name: string;
};

export type FCCData = {
  callSign: string;
  expiry: string;
  frequency: number;
  granted: string;
  latitude: number;
  license: string;
  longitude: number;
};

export type InstancesInfo = {
  ipAddress: string;
  instanceNumber: number;
  healthStatus: string;
};

export type SSLRequest = {
  country: string;
  state: string;
  city: string;
  company: string;
  department: string;
  email: string;
  commonName: string;
};

export type DatabaseDump = {
  fileName: string;
  timestamp: number;
}

export type ScheduledDBDumpStatus = 'ON' | 'OFF';

export type MFAAuthenticationSettings = {
  isMfaActive: boolean;
}
