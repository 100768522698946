import { FormControlLabel, Radio as MuiRadio, RadioProps as MuiRadioProps, Typography } from '@material-ui/core';
import { withStyles, WithStyles } from '@material-ui/styles';
import clsx from 'clsx';
import { ReactNode } from 'react';

import { Icon } from '../Icon/Icon';

import { styles } from './Radio.styles';

export type RadioProps = MuiRadioProps &
  WithStyles<typeof styles> & {
    label: ReactNode;
    showing?: boolean;
  };

export const Radio = withStyles(styles)(({ classes, label, showing, ...props }: RadioProps) => {
  if (showing) {
    return <Typography variant="body1">{label}</Typography>;
  }

  return (
    <FormControlLabel
      label={label}
      value={props.value}
      classes={{ root: clsx(classes.label) }}
      control={
        <MuiRadio
          icon={<Icon icon="radio" classes={{ root: clsx(classes.icon) }} size={16} />}
          checkedIcon={<Icon icon="radio-checked" classes={{ root: clsx(classes.checkedIcon) }} size={16} />}
          classes={{ root: clsx(classes.root) }}
          disableRipple
          color="primary"
          {...props}
        />
      }
    />
  );
});
