import { Route, Switch, useRouteMatch } from 'react-router';

import { AlarmAutomationCreate } from './Create';
import { AlarmAutomationDetails } from './Details';
import { AlarmAutomationList } from './List';

import { PageProps } from '~models';

export type AlarmAutomationParams = {
  id: string;
};

export const AlarmAutomationSettingsPage = (props: PageProps) => {
  const match = useRouteMatch();

  return (
    <Switch>
      <Route exact path={match.path}>
        <AlarmAutomationList title={props.title} id={props.id} routes={props.routes} />
      </Route>

      <Route exact path={`${match.path}/create`} component={AlarmAutomationCreate} />

      <Route path={`${match.path}/:id`} component={AlarmAutomationDetails} />
    </Switch>
  );
};
