import querystring from 'querystring';

import { AnyAction, createSlice, PayloadAction, createDraftSafeSelector } from '@reduxjs/toolkit';
import { VariantType } from 'notistack';
import { is, assocPath, pathOr, prop } from 'ramda';
import { load } from 'redux-localstorage-simple';
import { ActionsObservable, combineEpics, StateObservable } from 'redux-observable';
import { merge, of } from 'rxjs';
import { ajax } from 'rxjs/ajax';
import { catchError, delay, filter, mergeMap, switchMap } from 'rxjs/operators';

import {
  actions as notificationsActions,
  selectors as notificationSelectors,
} from '../notifications/notificationsSlice';
import { actions as sharedActions } from '../sharedSlice';

import { generateHealthCheckFailedNotification, getAppInstanceInfo, getInstanceType, getLedStatus } from './appUtils';

import { api, APP_HEALTH_CHECK_INTERVAL, APP_HEALTH_CHECK_TIMEOUT } from '~constants';
import {
  AppConnectionStatus,
  AppHealth,
  AppInfo,
  AppLedPanel,
  AppDialogs,
  AppSettings,
  LedStatus,
  ResponseError,
  LedType,
  NotificationKey,
  AppInstanceHealth,
  AppDatabaseMigrationStatus,
  AppDataMigrationInfo,
  UnitSearch,
  AppTtlThresholds,
} from '~models';
import { http } from '~services';
import { getResponseError, saveFile, updateArray } from '~utils';

export interface AppState {
  loaded: boolean;
  info: AppInfo & {
    loading: boolean;
  };
  settings: AppSettings;
  ledPanel: AppLedPanel;
  connectionStatus: AppConnectionStatus;
  health: AppHealth;
  dialogs: AppDialogs;
  ttlThresholds: AppTtlThresholds | null;
  status: 'updated' | null;
  unitsSearch: {
    data: UnitSearch[];
    loading: boolean;
    error: ResponseError | null;
  };
  databaseMigration: {
    status: AppDatabaseMigrationStatus | null;
    migratedBUList: string[];
    loading: {
      uploadFile: boolean;
      migration: boolean;
      clean: boolean;
      downloadLog: boolean;
    };
    isBUMigrated: boolean;
    withWarnings: boolean;
    error: ResponseError | null;
  };
  error: ResponseError | null;
}

export const defaultState: AppState = {
  connectionStatus: null,
  loaded: false,
  info: {
    type: null,
    version: '',
    time: null,
    isAgreed: false,
    loading: false,
  },
  settings: { soundEnabled: true },
  ledPanel: {
    cpu: null,
    automation: null,
    ethernet: null,
    rf: null,
  },
  health: {
    values: [],
    error: null,
  },
  dialogs: {
    firstLoginStepper: false,
    reportingRouteDialog: false,
    uploadMigratedDB: false,
    selectMigratedBU: false,
  },
  status: null,
  unitsSearch: {
    data: [],
    loading: false,
    error: null,
  },
  databaseMigration: {
    status: null,
    migratedBUList: [],
    loading: {
      uploadFile: false,
      migration: false,
      clean: false,
      downloadLog: false,
    },
    isBUMigrated: false,
    withWarnings: false,
    error: null,
  },
  ttlThresholds: null,
  error: null,
};

const loaded = load({ states: ['app.dialogs'], disableWarnings: true }) as { app: AppState };
const initialState = assocPath(
  ['dialogs', 'firstLoginStepper'],
  pathOr(prop('firstLoginStepper', defaultState.dialogs), ['app', 'dialogs', 'firstLoginStepper'], loaded),
  defaultState
);

export const { name, actions, reducer } = createSlice({
  name: 'app',
  initialState,
  reducers: {
    appLoad(state) {
      state.loaded = true;
    },

    appRedirectInit(
      state,
      action: PayloadAction<{ timeout: number; message: string; redirectTo: string; pathname?: string }>
    ) {},

    // Health check
    healthCheckInit() {},
    healthCheckSuccess(state, { payload }: PayloadAction<AppHealth['values']>) {
      state.health.values = payload;
    },
    healthCheckFailed(state, { payload }: PayloadAction<AppHealth['error']>) {
      state.health.error = payload;
    },

    setConnectionStatus(state, { payload }: PayloadAction<AppConnectionStatus>) {
      state.connectionStatus = payload;
    },

    setAppTime(state, { payload }: PayloadAction<AppInfo['time']>) {
      state.info.time = payload;
    },

    toggleSoundState(state, { payload }: PayloadAction<boolean>) {
      state.settings.soundEnabled = payload;
    },

    // Led panel
    setLedPanelStatus(state, { payload: { led, status } }: PayloadAction<{ led: LedType; status: LedStatus }>) {
      state.ledPanel[led] = status;
    },
    fetchLedPanelStatusesInit(state) {},
    fetchLedPanelStatusesSuccess(state, { payload }: PayloadAction<AppLedPanel>) {
      state.ledPanel = payload;
    },
    fetchLedPanelStatusesFailed(state, action: PayloadAction<ResponseError>) {
      state.error = action.payload;
    },

    // System info
    fetchSystemInfoInit(state) {
      state.info.loading = true;
    },
    fetchSystemInfoSuccess(
      state,
      {
        payload: { receivers, migrationStatus, isAgreed, isMeter, ...info },
      }: PayloadAction<Partial<AppInfo> & { receivers: AppInstanceHealth[] } & AppDataMigrationInfo>
    ) {
      Object.assign(state.info, info);
      state.health.values = receivers;
      state.databaseMigration.status = migrationStatus;
      state.info.isAgreed = isAgreed as boolean;
      state.info.isMeter = isMeter as boolean;
      state.status = 'updated';
      state.info.loading = false;
    },
    fetchSystemInfoFailed(state) {
      state.info.loading = false;
    },

    // Dialogs
    setAppDialog(state, { payload: { dialog, value } }: PayloadAction<{ dialog: keyof AppDialogs; value: boolean }>) {
      state.dialogs[dialog] = value;
    },

    // ttl thresholds
    fetchTtlThresholdsInit(state) {},
    fetchTtlThresholdsSuccess(state, { payload }: PayloadAction<AppTtlThresholds>) {
      state.ttlThresholds = payload;
    },

    // Database migration
    uploadDatabaseFileInit(state, { payload: { files } }: PayloadAction<{ files: File[] }>) {
      state.databaseMigration.loading.uploadFile = true;
      state.databaseMigration.error = null;
    },
    uploadDatabaseFileSuccess(state, { payload: { businessUnits } }: PayloadAction<{ businessUnits: string[] }>) {
      state.databaseMigration.loading.uploadFile = false;
      state.databaseMigration.status = 'UPLOADED_FILE';
      state.databaseMigration.migratedBUList = businessUnits;
    },
    uploadDatabaseFileFailed(state) {
      state.databaseMigration.loading.uploadFile = false;
    },

    databaseMigrationInit(state, { payload }) {
      state.databaseMigration.loading.migration = true;
      state.databaseMigration.error = null;
      state.databaseMigration.withWarnings = false;
    },
    databaseMigrationSuccess(state) {
      state.databaseMigration.loading.migration = false;
      state.databaseMigration.isBUMigrated = true;
    },
    databaseMigrationFailed(state, action: PayloadAction<ResponseError>) {
      state.databaseMigration.loading.migration = false;
      state.databaseMigration.error = action.payload;
    },

    cleanDatabaseArchiveInit(state) {
      state.databaseMigration.loading.clean = true;
    },
    cleanDatabaseArchiveSuccess(state) {
      state.databaseMigration.loading.clean = false;
    },
    cleanDatabaseArchiveFailed(state, action: PayloadAction<ResponseError>) {
      state.databaseMigration.loading.clean = false;
      state.databaseMigration.error = action.payload;
    },

    setDatabaseMigrationStatus(state, action: PayloadAction<AppDatabaseMigrationStatus>) {
      state.databaseMigration.status = action.payload;
    },

    setDatabaseMigrationWithWarnings(state, action: PayloadAction<boolean>) {
      state.databaseMigration.withWarnings = action.payload;
    },

    resetDatabaseMigrationStatus(state) {
      state.databaseMigration.status = initialState.databaseMigration.status;
    },

    fetchUnitsSearchInit: (state, { payload }: PayloadAction<{ unitId: UnitSearch['unitId'] }>) => {
      state.unitsSearch.loading = true;
      state.unitsSearch.data = [];
    },
    fetchUnitsSearchSuccess: (state, { payload }: PayloadAction<UnitSearch>) => {
      state.unitsSearch.loading = false;
      state.unitsSearch.data = updateArray(state.unitsSearch.data, payload);
    },
    fetchUnitsSearchFailed: (state, action: PayloadAction<ResponseError>) => {
      state.unitsSearch.loading = false;
      state.unitsSearch.error = action.payload;
    },

    downloadMigrationLogInit(state) {
      state.databaseMigration.loading.downloadLog = true;
    },
    downloadMigrationLogSuccess: state => {
      state.databaseMigration.loading.downloadLog = false;
    },
    downloadMigrationLogFailed: (state, action: PayloadAction<ResponseError>) => {
      state.databaseMigration.loading.downloadLog = false;
      state.databaseMigration.error = action.payload;
    },

    resetUnitsSearch: state => {
      state.unitsSearch.data = initialState.unitsSearch.data;
    },
  },
  extraReducers: {
    [sharedActions.reset.toString()]: state => Object.assign(state, defaultState),
  },
});

const getAppState = (state: AES.RootState) => state.app;
const getDatabaseMigrationState = createDraftSafeSelector(getAppState, state => state.databaseMigration);

export const selectors = {
  getAppState,

  // App info
  getAppInfo: createDraftSafeSelector(getAppState, state => state.info),
  getInstanceType: createDraftSafeSelector(getAppState, state => state.info.type),
  getInstanceInfo: createDraftSafeSelector(getAppState, state => {
    let instanceType: string | null = null;
    let ipAddress: string | null = null;

    if (!state.info?.type || !is(Number, state.info?.instanceNumber) || !state.health.values?.length) {
      return { instanceType, ipAddress };
    }

    const {
      info: { type, instanceNumber },
      health: { values },
    } = state;
    instanceType = getInstanceType(type);
    ipAddress = getAppInstanceInfo(values, instanceNumber)?.ipAddress as string;

    return { instanceType, ipAddress };
  }),
  getAllowedPortRanges: createDraftSafeSelector(getAppState, state => {
    if (!is(Number, state.info.aaPortRangeMin)) {
      return {
        ipLink: null,
        ipSubscriber: null,
        alarmAutomation: null,
      };
    }

    const {
      aaPortRangeMin,
      aaPortRangeMax,
      ipLinkPortRangeMin,
      ipLinkPortRangeMax,
      ipSubscriberPortRangeMin,
      ipSubscriberPortRangeMax,
    } = state.info;

    return {
      ipLink: `Allowed range: ${ipLinkPortRangeMin} - ${ipLinkPortRangeMax}`,
      ipSubscriber: `Allowed range: ${ipSubscriberPortRangeMin} - ${ipSubscriberPortRangeMax}`,
      alarmAutomation: `Allowed range: ${aaPortRangeMin} - ${aaPortRangeMax}`,
    };
  }),

  isWebsocketConnected: createDraftSafeSelector(getAppState, state => state.connectionStatus === 'connected'),
  isSoundEnabled: createDraftSafeSelector(getAppState, state => state.settings.soundEnabled),
  // Led statuses
  getLedPanelStatuses: createDraftSafeSelector(
    (state: AES.RootState) => state,
    ({
      app: {
        ledPanel: { automation, cpu, rf, ethernet },
      },
      alarms: {
        data: { connectivity },
      },
    }: AES.RootState) => ({
      automation: connectivity.length ? getLedStatus(automation) : 'error',
      cpu: getLedStatus(cpu),
      rf: getLedStatus(rf),
      ethernet: getLedStatus(ethernet),
    })
  ),

  isAppLoaded: createDraftSafeSelector(getAppState, state => state.loaded),
  isAppStatusUpdated: createDraftSafeSelector(getAppState, state => state.status === 'updated'),

  // Health
  getAppHealth: createDraftSafeSelector(getAppState, state => state.health),
  isPrimary: createDraftSafeSelector(getAppState, state => state.info.type === 'PRIMARY'),
  isSecondary: createDraftSafeSelector(getAppState, state => state.info.type === 'SECONDARY'),
  getAppInstanceNumber: createDraftSafeSelector(getAppState, state => state.info?.instanceNumber),
  getAppInstances: createDraftSafeSelector(getAppState, state => state.health.values),
  getHealthError: createDraftSafeSelector(getAppState, state => state.health.error),

  getMapboxAccessToken: createDraftSafeSelector(getAppState, state => state.info?.mapboxAccessToken),
  getGeoCenter: createDraftSafeSelector(getAppState, ({ info: { latitude, longitude } }) => {
    if (longitude || latitude) {
      return { longitude, latitude };
    }

    return null;
  }),
  getInfoMeter: createDraftSafeSelector(getAppState, state => state.info?.isMeter as boolean),

  // Dialogs
  getAppDialogs: createDraftSafeSelector(getAppState, state => state.dialogs),

  // Database migration
  getDatabaseMigrationState,
  isDatabaseMigrationNotStarted: createDraftSafeSelector(
    getDatabaseMigrationState,
    state => state.status === 'NOT_STARTED'
  ),
  isDatabaseMigrationInProgress: createDraftSafeSelector(
    getDatabaseMigrationState,
    state => state.status === 'STARTED'
  ),
  isDatabaseMigrationFailed: createDraftSafeSelector(getDatabaseMigrationState, state => state.status === 'FAILED'),
  isDatabaseMigrationFinished: createDraftSafeSelector(getDatabaseMigrationState, state => state.status === 'FINISHED'),
  isBUMigrated: createDraftSafeSelector(getDatabaseMigrationState, state => state.isBUMigrated),

  getUnitsSearch: createDraftSafeSelector(getAppState, state => state.unitsSearch),
  getTtlThresholds: createDraftSafeSelector(getAppState, state => state.ttlThresholds),
  isSelfMonitoring: createDraftSafeSelector(getAppState, state => state.info.isSelfMonitoringEnabled),
  isAutomationDown: createDraftSafeSelector(getAppState, state => state.ledPanel.automation === 'ERROR'),
  isHighContrast: createDraftSafeSelector(getAppState, state => state.info.highContrast),
};

const connectionStatusEpic = (action$: ActionsObservable<AnyAction>) =>
  action$.pipe(
    filter(actions.setConnectionStatus.match),
    mergeMap(({ payload }) => {
      let variant: VariantType = 'default';
      let message = '';

      switch (payload) {
        case 'connected': {
          variant = 'success';
          message = 'Connected to the server';

          break;
        }

        case 'disconnected': {
          variant = 'error';
          message = 'Disconnected from the server';

          break;
        }

        case 'reconnecting': {
          variant = 'warning';
          message = 'Connecting to the server in 5 sec';

          break;
        }
      }

      return of(notificationsActions.enqueue({ message, options: { variant }, key: message }));
    })
  );

const healthCheckEpic = (action$: ActionsObservable<AnyAction>, state$: StateObservable<AES.RootState>) =>
  action$.pipe(
    filter(action => actions.healthCheckInit.match(action) || actions.appLoad.match(action)),
    mergeMap(() =>
      http.getJSON<AppHealth['values']>(api.system.health, {}, { timeout: APP_HEALTH_CHECK_TIMEOUT * 1000 }).pipe(
        mergeMap(values => {
          const epicActions: AnyAction[] = [actions.healthCheckSuccess(values)];
          const unhealthyInstances = values.filter(
            ({ instanceNumber, status }) =>
              instanceNumber !== state$.value.app.info.instanceNumber && status !== 'HEALTHY'
          );
          const notifications = notificationSelectors.getNotifications(state$.value);
          const unhealthyNotifications = notifications.filter(notification =>
            `${notification.key}`.match(NotificationKey.HealthCheckFailed)
          );

          if (unhealthyInstances.length) {
            const { key, message } = generateHealthCheckFailedNotification(unhealthyInstances);

            epicActions.push(
              notificationsActions.enqueue({
                message,
                key,
                options: {
                  variant: 'warning',
                  autoHideDuration: null,
                },
              })
            );
          } else if (unhealthyNotifications.length) {
            unhealthyNotifications.forEach(notification =>
              epicActions.push(notificationsActions.close(notification.key))
            );
            epicActions.push(
              notificationsActions.enqueue({
                message: 'All instances has been restored',
                options: {
                  variant: 'success',
                },
              })
            );
          }

          return merge(
            of(...epicActions),
            of(null).pipe(
              delay(APP_HEALTH_CHECK_INTERVAL * 1000),
              mergeMap(() => of(actions.healthCheckInit()))
            )
          );
        }),
        catchError(err => {
          const error = getResponseError(err);

          return merge(
            of(actions.healthCheckFailed(error)),
            of(null).pipe(
              delay(APP_HEALTH_CHECK_INTERVAL * 1000),
              mergeMap(() => of(actions.healthCheckInit()))
            )
          );
        })
      )
    )
  );

const fetchSystemInfoEpic = (action$: ActionsObservable<AnyAction>) =>
  action$.pipe(
    filter(actions.fetchSystemInfoInit.match),
    mergeMap(() =>
      http.getJSON<Partial<AppInfo> & { receivers: AppInstanceHealth[] } & AppDataMigrationInfo>(api.system.info).pipe(
        mergeMap(values => of(actions.fetchSystemInfoSuccess(values))),
        catchError(() =>
          of(null).pipe(
            delay(3000),
            mergeMap(() => of(actions.fetchSystemInfoInit()))
          )
        )
      )
    )
  );

const appRedirectEpic = (action$: ActionsObservable<AnyAction>, state$: StateObservable<AES.RootState>) =>
  action$.pipe(
    filter(actions.appRedirectInit.match),
    switchMap(({ payload }) =>
      merge(
        of(
          notificationsActions.enqueue({
            message: payload.message,
            options: { variant: 'warning', autoHideDuration: payload.timeout },
          })
        ),
        of(null).pipe(
          delay(payload.timeout as number),
          mergeMap(() => {
            const accessToken = state$.value.auth.accessToken;
            const pathname = payload.pathname || window.location.pathname;
            const url = accessToken
              ? `${payload.redirectTo}?${querystring.stringify({ accessToken, pathname })}`
              : payload.redirectTo;

            window.location.replace(`https://${url}`.replace('https://127.0.0.1', 'http://localhost:3000'));

            return of(() => {});
          })
        )
      )
    )
  );

export const fetchLedPanelStatusesEpic = (action$: ActionsObservable<AnyAction>) =>
  action$.pipe(
    filter(actions.fetchLedPanelStatusesInit.match),
    mergeMap(() =>
      http.getJSON<AppLedPanel>(api.system.ledPanel).pipe(
        mergeMap(payload => of(actions.fetchLedPanelStatusesSuccess(payload))),
        catchError(err => of(actions.fetchLedPanelStatusesFailed(getResponseError(err))))
      )
    )
  );

export const fetchTtlThresholdsEpic = (action$: ActionsObservable<AnyAction>) =>
  action$.pipe(
    filter(actions.fetchTtlThresholdsInit.match),
    mergeMap(() =>
      http.getJSON<AppTtlThresholds>(api.system.ttlThresholds).pipe(
        mergeMap(payload => of(actions.fetchTtlThresholdsSuccess(payload))),
        catchError(err => of(getResponseError(err)))
      )
    )
  );

export const uploadDatabaseFileEpic = (action$: ActionsObservable<AnyAction>, state$: StateObservable<AES.RootState>) =>
  action$.pipe(
    filter(actions.uploadDatabaseFileInit.match),
    mergeMap(({ payload: { files } }) => {
      const formData = new FormData();

      files.forEach(file => formData.append('file', file, file.name));

      return ajax({
        method: 'POST',
        url: api.system.databaseMigration.uploadFile,
        body: formData,
        headers: {
          Authorization: `Bearer ${state$.value.auth.accessToken}`,
        },
      }).pipe(
        mergeMap(payload =>
          of(
            actions.uploadDatabaseFileSuccess(payload.response),
            notificationsActions.enqueue({
              message: 'DB dump uploaded',
              options: {
                variant: 'success',
              },
            })
          )
        ),
        catchError(err =>
          of(
            actions.uploadDatabaseFileFailed(),
            notificationsActions.enqueue({ message: getResponseError(err).message, options: { variant: 'error' } })
          )
        )
      );
    })
  );

export const databaseMigrationEpic = (action$: ActionsObservable<AnyAction>) =>
  action$.pipe(
    filter(actions.databaseMigrationInit.match),
    mergeMap(({ payload }) =>
      http.post(api.system.databaseMigration.migrate, payload).pipe(
        mergeMap(() => of(actions.databaseMigrationSuccess())),
        catchError(err => of(actions.databaseMigrationFailed(getResponseError(err))))
      )
    )
  );

export const cleanDatabaseArchiveEpic = (action$: ActionsObservable<AnyAction>) =>
  action$.pipe(
    filter(actions.cleanDatabaseArchiveInit.match),
    mergeMap(() =>
      http.post(api.system.databaseMigration.clean).pipe(
        mergeMap(() => of(actions.cleanDatabaseArchiveSuccess())),
        catchError(err => of(actions.cleanDatabaseArchiveFailed(getResponseError(err))))
      )
    )
  );

export const fetchUnitsSearchEpic = (action$: ActionsObservable<AnyAction>) =>
  action$.pipe(
    filter(actions.fetchUnitsSearchInit.match),
    mergeMap(({ payload: { unitId } }) =>
      http.getJSON<UnitSearch>(api.search.searchById(unitId)).pipe(
        mergeMap(unit => of(actions.fetchUnitsSearchSuccess(unit))),
        catchError(err => of(actions.fetchUnitsSearchFailed(getResponseError(err))))
      )
    )
  );

export const downloadMigrationLogEpic = (action$: ActionsObservable<AnyAction>) =>
  action$.pipe(
    filter(actions.downloadMigrationLogInit.match),
    mergeMap(() =>
      http
        .call({
          method: 'GET',
          url: api.system.databaseMigration.downloadLog,
          responseType: 'blob' as 'json',
        })
        .pipe(
          mergeMap(res => {
            const fileName = 'db_migration.log';
            saveFile(res.response, fileName);
            return of(
              actions.downloadMigrationLogSuccess(),
              notificationsActions.enqueue({
                message: `${fileName} has been downloaded`,
                options: { variant: 'success' },
              })
            );
          }),
          catchError(err => of(actions.downloadMigrationLogFailed(getResponseError(err))))
        )
    )
  );

export const epics = combineEpics(
  connectionStatusEpic,
  healthCheckEpic,
  fetchSystemInfoEpic,
  appRedirectEpic,
  fetchLedPanelStatusesEpic,
  fetchTtlThresholdsEpic,
  uploadDatabaseFileEpic,
  databaseMigrationEpic,
  cleanDatabaseArchiveEpic,
  fetchUnitsSearchEpic,
  downloadMigrationLogEpic,
);
export const allEpics = {
  connectionStatusEpic,
  healthCheckEpic,
  fetchSystemInfoEpic,
  appRedirectEpic,
  fetchLedPanelStatusesEpic,
  fetchTtlThresholdsEpic,
  uploadDatabaseFileEpic,
  databaseMigrationEpic,
  cleanDatabaseArchiveEpic,
  fetchUnitsSearchEpic,
  downloadMigrationLogEpic,
};

declare global {
  namespace AES {
    export interface RootState {
      app: AppState;
    }
    export interface Actions {
      app: typeof actions;
    }
    export interface Selectors {
      app: typeof selectors;
    }
  }
}
