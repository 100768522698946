import * as Yup from 'yup';

import { CustomNote } from '~models/subscriber';
import { trailingSpacesValidator } from '~utils/validators';

export const getInitialValues = (values?: CustomNote) => ({
  id: values?.id || 0,
  note: values?.note || '',
  title: values?.title || '',
});

export const validationSchema = Yup.object().shape({
  note: trailingSpacesValidator().max(250, 'Text should not exceed 250 characters length')
    .required('Text is required'),
  title: trailingSpacesValidator().max(64, 'Title should not exceed 64 characters length')
    .required('Title is required'),
});
