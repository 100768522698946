import { AnyAction, createDraftSafeSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ActionsObservable, combineEpics } from 'redux-observable';
import { of } from 'rxjs';
import { catchError, filter, mergeMap } from 'rxjs/operators';

import { actions as appActions } from '../app/appSlice';
import { actions as pageActions } from '../page/pageSlice';

import { api } from '~constants';
import { LicenseDetails, LicenseType, ResponseError, AddOnLicenseType, AddOnLicenseDetails } from '~models';
import { http } from '~services';
import { getResponseError, getResponsePayload, saveFile } from '~utils';

interface LicenseState {
  data: {
    license: LicenseDetails | null;
    'self-monitoring': AddOnLicenseDetails | null;
    bacnet: AddOnLicenseDetails | null;
    'instance-3': AddOnLicenseDetails | null;
  };
  limitedDetails: {
    message: string;
    timeStamp: number;
  };
  loading: {
    license: {
      details: boolean;
      agreement: boolean;
    };
    addOnLicense: {[key in AddOnLicenseType]: boolean};
  };
  error?: ResponseError | null;
}

export const initialState: LicenseState = {
  data: {
    license: null,
    'self-monitoring': null,
    bacnet: null,
    'instance-3': null,
  },
  limitedDetails: {
    message: '',
    timeStamp: 0,
  },
  loading: {
    license: {
      details: false,
      agreement: false,
    },
    addOnLicense: {
      'self-monitoring': false,
      bacnet: false,
      'instance-3': false,
    },
  },
  error: null,
};

export const { name, actions, reducer } = createSlice({
  name: 'license',
  initialState,
  reducers: {
    // Fetch license details
    fetchLicenseDetailsInit: state => {
      state.loading.license.details = true;
    },
    fetchLicenseDetailsSuccess: (state, { payload }) => {
      state.loading.license.details = false;
      state.data.license = payload;
    },
    fetchLicenseDetailsFailed: (state, action: PayloadAction<ResponseError>) => {
      state.loading.license.details = false;
      state.error = action.payload;
    },

    // Generate request key
    generateRequestKeyInit: (state, { payload }: PayloadAction<{ licenseType: LicenseType; productKey: string }>) => {
      state.loading.license.details = true;
    },

    // Activate license key
    activateLicenseKeyInit: (state, { payload }: PayloadAction<{ licenseKey: string }>) => {
      state.loading.license.details = true;
    },

    // Limited license details
    setLimitedLicenseDetails(
      state,
      { payload: { message, timeStamp } }: PayloadAction<{ message: string; timeStamp: number }>
    ) {
      state.limitedDetails = { message, timeStamp };
    },
    clearLimitedLicenseDetails(state) {
      state.limitedDetails = initialState.limitedDetails;
    },

    // Accept license agreement
    acceptLicenseAgreementInit(state, { payload }: PayloadAction<{ person: string }>) {
      state.loading.license.agreement = true;
    },
    acceptLicenseAgreementSuccess: state => {
      state.loading.license.agreement = false;
    },
    licenseAgreementFailed: (state, action: PayloadAction<ResponseError>) => {
      state.loading.license.agreement = false;
      state.error = action.payload;
    },

    // Download license agreement
    downloadLicenseAgreementInit(state) {
      state.loading.license.agreement = true;
    },
    downloadLicenseAgreementSuccess: state => {
      state.loading.license.agreement = false;
    },

    fetchAddOnLicenseDetailsInit: (state, { payload: { type } }: PayloadAction<{ type: AddOnLicenseType }>) => {
      state.loading.addOnLicense[type] = true;
    },
    fetchAddOnLicenseDetailsSuccess: (state,
      { payload: { type, values } }: PayloadAction<{ type: AddOnLicenseType, values: AddOnLicenseDetails }>) => {
      state.loading.addOnLicense[type] = false;
      state.data[type] = values;
    },
    fetchAddOnLicenseDetailsFailed: (state,
      { payload: { type, error } }: PayloadAction<{ type: AddOnLicenseType, error: ResponseError }>) => {
      state.loading.addOnLicense[type] = false;
      state.error = error;
    },

    activateAddOnLicenseKeyInit: (state,
      { payload: { type, licenseKey, licenseType } }:
      PayloadAction<{ type: AddOnLicenseType; licenseType: string; licenseKey: string }>) => {
      state.loading.addOnLicense[type] = true;
    },

    getAddOnLicenseRequestKeyInit: (state,
      { payload: { type, licenseCode } }:
      PayloadAction<{ type: AddOnLicenseType; licenseCode: number }>) => {
      state.loading.addOnLicense[type] = true;
    },
  },
});

const getLicenseState = (state: AES.RootState) => state.license;
export const selectors = {
  getLicenseState,
  getLicenseData: createDraftSafeSelector(getLicenseState, state => state.data.license),
  getLimitedLicenseDetails: createDraftSafeSelector(getLicenseState, state => state.limitedDetails),
  getLicenseLoading: createDraftSafeSelector(getLicenseState, state => state.loading),
  getLicenseError: createDraftSafeSelector(getLicenseState, state => state.error),
  isPartialLicense: createDraftSafeSelector(
    getLicenseState,
    state => state.data.license?.currentLicenseType?.value === LicenseType.UP_TO_5000_UNITS
  ),
  isUnlimitedLicense: createDraftSafeSelector(
    getLicenseState,
    state => state.data.license?.currentLicenseType?.value === LicenseType.UNLIMITED
  ),
  getAddOnLicenseData: (type: AddOnLicenseType) => createDraftSafeSelector(getLicenseState, state => state.data[type]),
};

const fetchLicenseDetailsEpic = (action$: ActionsObservable<AnyAction>) =>
  action$.pipe(
    filter(actions.fetchLicenseDetailsInit.match),
    mergeMap(() =>
      http.getJSON<LicenseDetails>(api.license.details).pipe(
        mergeMap(res => of(actions.fetchLicenseDetailsSuccess(res))),
        catchError(err => of(actions.fetchLicenseDetailsFailed(getResponseError(err))))
      )
    )
  );

const generateRequestKeyEpic = (action$: ActionsObservable<AnyAction>) =>
  action$.pipe(
    filter(actions.generateRequestKeyInit.match),
    mergeMap(({ payload }) =>
      http.post(api.license.details, payload).pipe(
        mergeMap(res => of(actions.fetchLicenseDetailsSuccess(getResponsePayload(res)))),
        catchError(err => of(actions.fetchLicenseDetailsFailed(getResponseError(err))))
      )
    )
  );

const activateLicenseKeyEpic = (action$: ActionsObservable<AnyAction>) =>
  action$.pipe(
    filter(actions.activateLicenseKeyInit.match),
    mergeMap(({ payload }) =>
      http.post(api.license.activate, payload).pipe(
        mergeMap(res => {
          const epicActions = [
            actions.fetchLicenseDetailsSuccess(getResponsePayload(res)),
            window.location.reload(),
            pageActions.setPageNoLicense(false),
          ];

          return of(...epicActions);
        }),
        catchError(err => of(actions.fetchLicenseDetailsFailed(getResponseError(err))))
      )
    )
  );

const downloadLicenseAgreementEpic = (action$: ActionsObservable<AnyAction>) =>
  action$.pipe(
    filter(action => actions.downloadLicenseAgreementInit.match(action)),
    mergeMap(({ payload }) =>
      http
        .call({
          method: 'GET',
          url: api.license.agreement.download,
          responseType: 'blob' as 'json',
        })
        .pipe(
          mergeMap(res => {
            saveFile(res.response, 'INCC License Agreement');

            return of(actions.downloadLicenseAgreementSuccess());
          }),
          catchError(err => of(actions.licenseAgreementFailed(getResponseError(err))))
        )
    )
  );

const acceptLicenseAgreementEpic = (action$: ActionsObservable<AnyAction>) =>
  action$.pipe(
    filter(actions.acceptLicenseAgreementInit.match),
    mergeMap(({ payload: { person } }) =>
      http.post(api.license.agreement.accept, { person, isAgree: true }).pipe(
        mergeMap(() => of(appActions.fetchSystemInfoInit(), actions.acceptLicenseAgreementSuccess())),
        catchError(err => of(actions.licenseAgreementFailed(getResponseError(err))))
      )
    )
  );

const fetchAddOnLicenseDetailsEpic = (action$: ActionsObservable<AnyAction>) =>
  action$.pipe(
    filter(actions.fetchAddOnLicenseDetailsInit.match),
    mergeMap(({ payload: { type } }) =>
      http.getJSON<AddOnLicenseDetails>(api.license.addOnLicense.details(type)).pipe(
        mergeMap(res => of(actions.fetchAddOnLicenseDetailsSuccess({ type, values: res }))),
        catchError(err => of(actions.fetchAddOnLicenseDetailsFailed({ type, error: getResponseError(err) })))
      )
    )
  );

const getAddOnLicenseRequestKeyEpic = (action$: ActionsObservable<AnyAction>) =>
  action$.pipe(
    filter(actions.getAddOnLicenseRequestKeyInit.match),
    mergeMap(({ payload: { type, licenseCode } }) =>
      http.post(api.license.addOnLicense.details(type), { licenseCode }).pipe(
        mergeMap(res => of(actions.fetchAddOnLicenseDetailsSuccess({ type, values: getResponsePayload(res) }))),
        catchError(err => of(actions.fetchAddOnLicenseDetailsFailed({ type, error: getResponseError(err) })))
      )
    )
  );

const activateAddOnLicenseKeyEpic = (action$: ActionsObservable<AnyAction>) =>
  action$.pipe(
    filter(actions.activateAddOnLicenseKeyInit.match),
    mergeMap(({ payload: { type, licenseKey, licenseType } }) =>
      http.post(api.license.addOnLicense.activate(type), { licenseKey, licenseType }).pipe(
        mergeMap(res => {
          const epicActions = [
            actions.fetchAddOnLicenseDetailsSuccess({ type, values: getResponsePayload(res) }),
            window.location.reload(),
          ];

          return of(...epicActions);
        }),
        catchError(err => of(actions.fetchAddOnLicenseDetailsFailed({ type, error: getResponseError(err) })))
      )
    )
  );

export const epics = combineEpics(
  fetchLicenseDetailsEpic,
  generateRequestKeyEpic,
  activateLicenseKeyEpic,
  downloadLicenseAgreementEpic,
  acceptLicenseAgreementEpic,
  fetchAddOnLicenseDetailsEpic,
  activateAddOnLicenseKeyEpic,
  getAddOnLicenseRequestKeyEpic,
);

export const allEpics = {
  fetchLicenseDetailsEpic,
  generateRequestKeyEpic,
  activateLicenseKeyEpic,
  downloadLicenseAgreementEpic,
  acceptLicenseAgreementEpic,
  fetchAddOnLicenseDetailsEpic,
  activateAddOnLicenseKeyEpic,
  getAddOnLicenseRequestKeyEpic
};

declare global {
  namespace AES {
    export interface Actions {
      license: typeof actions;
    }
    export interface RootState {
      license: LicenseState;
    }

    export interface Selectors {
      license: typeof selectors;
    }
  }
}
