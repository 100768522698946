import { Button, Grid } from '@material-ui/core';
import { PropsWithChildren, useCallback } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

import { RoutingLink } from '~components/Routing/RoutingLink';
import { getLinkByUnitType } from '~utils/unit';

import { PageLoader, PaginatedRoutingLinkList } from '~components';
import { BusinessUnit, UnitType } from '~models';
import { selectors, boundActions } from '~store';
import { Dialog, DialogTitle, DialogActions, DialogProps } from '~ui-kit';

type PaginatedSubscribersListDialogProps = Partial<DialogProps> &
  PropsWithChildren<{
    open: boolean;
    onDialogClose: () => void;
    businessUnitId: BusinessUnit['id'];
    dialogTitle: string;
    backTo?: string;
  }>;

const GridContainer = styled(Grid)({
  paddingBottom: '14px',
});

export const PaginatedSubscribersListDialog = ({
  open,
  onDialogClose,
  businessUnitId,
  dialogTitle,
  backTo
}: PaginatedSubscribersListDialogProps) => {
  const businessUnit = useSelector(selectors.businessUnits.getBusinessUnit(businessUnitId));
  const {
    details: { healthPopup: isLoading },
  } = useSelector(selectors.businessUnits.getBusinessUnitsLoaders);

  const popUpUnits = (businessUnit?.healthPopup) as unknown as { unitId: number, unitType: UnitType }[];

  const onPaginationChange = useCallback(pagination => {
    boundActions.businessUnits.fetchBusinessUnitPopUpHealthScoreInit({
      pagination,
      businessUnitId,
    });
  }, []);

  return (
    <Dialog open={open} maxWidth="sm" PaperProps={{ style: { width: '100%' } }}>
      <DialogTitle title={dialogTitle} />

      {popUpUnits ? (
        <PaginatedRoutingLinkList
          data={popUpUnits}
          onPaginationChange={onPaginationChange}
          loading={isLoading}
          renderItem={(res, i: number) => (
            <GridContainer key={res.unitId}>
              <RoutingLink
                id={res.unitId}
                linkTo={getLinkByUnitType(res?.unitType, businessUnitId, res.unitId, backTo)}
              />
            </GridContainer>
          )}
        />
      ) : (
        <PageLoader />
      )}

      <DialogActions>
        <Button type="submit" variant="contained" color="primary" onClick={onDialogClose}>
          OK
        </Button>
      </DialogActions>
    </Dialog>
  );
};
