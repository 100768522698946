import { Button } from '@material-ui/core';

import { Dialog, DialogContent, DialogActions, DialogProps } from '~ui-kit';

type ExportSubscribersDialogProps = Partial<DialogProps> & {
  onConfirm: () => void;
  onCancel: () => void;
};

export const ExportSubscribersDialog = (
  { open = false, onConfirm, onCancel, ...props }: ExportSubscribersDialogProps) => (
  <Dialog open={open} {...props}>
    <DialogContent>
    Please don’t refresh or leave this page before export complete.
    If you have more than 100 Subscribers in the list, this action can take a long.
    Exporting more than 1000 items can fail by timeout. Proceed?
    </DialogContent>
    <DialogActions>
      <Button variant="outlined" color="primary" onClick={onCancel}>
        No
      </Button>
      <Button variant="contained" color="primary" onClick={onConfirm}>
        Yes
      </Button>
    </DialogActions>
  </Dialog>
);
