import { FACPColor } from './subscriber';
import { UnitID } from './unit';

export type AlarmType = 'FIRE' | 'MEDICAL' | 'PANIC' | 'BURGLARY' | 'SUPERVISORY' | 'OTHER' | 'UNKNOWN';

export type AlarmMeta = {
  isLoading?: boolean;
  isExpanded?: boolean;
  count?: number;
  first?: boolean;
  last: boolean;
  parent?: boolean;
};
export type AlarmCodeType = 'E' | 'P' | 'R';

export enum AlarmManualAck {
  UNACKNOWLEDGED = 1,
  ACKNOWLEDGED = 2,
}

export type Alarm = UnitID & {
  id: string;
  alarmType: AlarmType;
  alarmTypeDescription: string;
  businessUnitId: number;
  businessUnitName: string;
  code: string | null;
  cid: string | null;
  /** Created date timestamp */
  createdAt: number;
  /** Acknowledged date timestamp */
  acknowledgedAt: number;
  note: string;

  silent: boolean;
  aaMessage: string;
  zone?: string;
  meta?: AlarmMeta;
  isTest?: boolean;
  address1?: string;
  address2?: string;
  manualAck: AlarmManualAck;
  facpDescription?: string;
  facpColor: FACPColor | null;
  facpSound?: number;
};

export interface AlarmsGroup {
  id: string;
  children: Alarm[];
}

export interface ConnectivityAlarm {
  /** IP address */
  address: string;
  businessUnits: { id: number; name: string }[];
  /** Date timestamp */
  connectedAt: number;
  isServer: boolean;
  port: number;
  status: 'UP' | 'DOWN';
  alarmAutomationId: number;
  isHeartbeatEnabled: boolean;
}
