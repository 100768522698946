import { is } from 'ramda';

import { CardStatus } from '~ui-kit/Card';

import { AppInfo, AppInstanceHealth, LedStatus, NotificationKey } from '~models';
import { formatDate } from '~utils';

export const getLedStatus = (status?: LedStatus): CardStatus => {
  switch (status) {
    case 'SUCCESS':
      return 'success';
    case 'NORMAL':
      return 'warning';
    case 'ERROR':
      return 'error';
    default:
      return 'default';
  }
};

export const getAppInstanceInfo = (instances: AppInstanceHealth[], instanceNumber?: number) => {
  if (!is(Number, instanceNumber) || !instances?.length) {
    return null;
  }

  return instances.find(instance => instance.instanceNumber === instanceNumber);
};

export const getInstanceType = (type: AppInfo['type']): string => {
  switch (type) {
    case 'SECONDARY':
      return 'Secondary';

    case 'THIRD':
      return 'Third Instance';

    default:
      return 'Primary';
  }
};

export const getFirstHealthyInstance = (instances: AppInstanceHealth[], instanceNumber?: number) => {
  if (!is(Number, instanceNumber) || !instances?.length) {
    return null;
  }

  return instances
    .filter(instance => instance.instanceNumber !== instanceNumber)
    .find(instance => instance.status === 'HEALTHY');
};

export const generateHealthCheckFailedNotification = (
  unhealthyInstances: AppInstanceHealth[]
): { message: string; key: string } => {
  const messages = unhealthyInstances.map(
    ({ instanceNumber, status, ipAddress }) => `Receiver №${instanceNumber} (${ipAddress}): ${status}`
  );
  const key = `${NotificationKey.HealthCheckFailed}:${unhealthyInstances
    .map(instance => instance.instanceNumber)
    .join('|')}`;
  const message = `Something went wrong: \n ${formatDate(new Date())} \n ${messages.join('\n')}`;

  return { key, message };
};
