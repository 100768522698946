import { Theme } from '@material-ui/core';
import { createStyles } from '@material-ui/styles';

export const styles = ({ spacing }: Theme) =>
  createStyles({
    item: {
      display: 'flex',
      justifyContent: 'space-around',
      marginTop: spacing(3),
    },
  });
