import { useSnackbar } from 'notistack';
import { equals } from 'ramda';
import { useEffect, useRef, memo } from 'react';
import { useSelector } from 'react-redux';

import { Notification } from '~models';
import { selectors } from '~store';

export type NotifierProps = {
  notifications: Notification[];
  onRemove(key: Notification['key']): void;
};

export const Notifier = memo(({ onRemove }: NotifierProps) => {
  const notifications = useSelector(selectors.notifications.getNotifications, equals);
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const displayed = useRef<Notification['key'][]>([]);

  const storeDisplayed = (id: Notification['key']) => {
    displayed.current.push(id);
  };

  const removeDisplayed = (id: Notification['key']) => {
    displayed.current = displayed.current.filter(key => id !== key);
  };

  useEffect(() => {
    notifications.forEach(({ key, message, options = {}, dismissed = false }) => {
      if (dismissed) {
        closeSnackbar(key);
        return;
      }

      if (displayed.current.includes(key)) {
        return;
      }

      enqueueSnackbar(message, {
        key,
        ...options,
        onClose: (event, reason, myKey) => {
          if (options.onClose) {
            options.onClose(event, reason, myKey);
          }
        },
        onExited: (event, myKey: Notification['key']) => {
          onRemove(myKey);
          removeDisplayed(myKey);
        },
      });

      storeDisplayed(key);
    });
  }, [notifications, closeSnackbar, enqueueSnackbar]);

  return null;
});
