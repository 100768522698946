import { Button, Grid } from '@material-ui/core';
import { Form, Formik } from 'formik';

import { getInitialValues, validationSchema } from './config';

import { CustomNote } from '~models';
import { Dialog, DialogActions, DialogContent, DialogTitle, FormTextField, Required } from '~ui-kit';

type CustomNoteDialogProps = {
  open: boolean;
  note?: CustomNote;
  onSave: (values: Partial<CustomNote>) => void;
  onCancel?: () => void;
};

export const CustomNoteDialog = ({ open, note, onSave, onCancel }: CustomNoteDialogProps) => (
  <Dialog open={open} fullWidth maxWidth="sm">
    <DialogTitle title="Custom Note" />
    <Formik
      initialValues={getInitialValues(note)}
      validationSchema={validationSchema}
      enableReinitialize
      onSubmit={onSave}
    >
      {({ values, isValid }) => (
        <Form>
          <DialogContent>
            <Grid container key={values.id}>
              <Grid item xs={12} md={12}>
                <FormTextField label={<Required>Title</Required>} name="title" cleanable />
              </Grid>
              <Grid item xs={12} md={12}>
                <FormTextField label={<Required>Text</Required>} name="note" cleanable />
              </Grid>
            </Grid>
          </DialogContent>

          <DialogActions>
            <Button variant="outlined" color="primary" onClick={onCancel}>
              Cancel
            </Button>

            <Button variant="contained" type="submit" color="primary" disabled={!isValid}>
              Save
            </Button>
          </DialogActions>
        </Form>
      )}
    </Formik>
  </Dialog>
);
