import { Grid, Button, WithStyles, withStyles } from '@material-ui/core';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';

import { styles } from './MultipleRangeInput.styles';

import { FormTextField, Required } from '~ui-kit';
import { hexIdValidator, hexToInt } from '~utils';

const validationSchema = Yup.object().shape({
  valueFrom: hexIdValidator('Incorrect format').required('Field is required'),
  valueTo: hexIdValidator('Incorrect format')
    .required('Field is required')
    .test(
      'valueTo should equal or more than valueFrom',
      'Incorrect format',
      (currentValue, context) => hexToInt(context.parent.valueFrom) <= hexToInt(currentValue as string)
    ),
});

type MultipleRangeInputProps = WithStyles<typeof styles> & {
  onSubmit?: (values) => void;
  fromValue?: string;
  toValue?: string;
  title?: string;
  onClean?: () => void;
};

export const MultipleRangeInput = withStyles(styles)(
  ({ fromValue = '', toValue = '', onSubmit = () => {}, title = '', onClean, classes }: MultipleRangeInputProps) => (
    <Formik
      initialValues={{
        valueFrom: fromValue,
        valueTo: toValue,
      }}
      enableReinitialize
      validationSchema={validationSchema}
      onSubmit={onSubmit}
    >
      {({ values }) => (
        <Form className={classes.content}>
          {title && <Grid className={classes.text}>{title }</Grid>}

          <FormTextField
            name="valueFrom"
            className={classes.input}
            label={<Required>From</Required>}
            onClean={onClean}
            cleanable
          />

          <FormTextField
            name="valueTo"
            className={classes.input}
            label={<Required>To</Required>}
            onClean={onClean}
            cleanable
          />

          <Button
            className={classes.button}
            type="submit"
            size="small"
            variant="contained"
            color="primary"
            disabled={Boolean(!values.valueFrom.length || !values.valueTo.length)}
          >
            Find
          </Button>
        </Form>
      )}
    </Formik>
  )
);
