import * as querystring from 'querystring';

import { AnyAction, createSlice, PayloadAction, createDraftSafeSelector } from '@reduxjs/toolkit';
import { push } from 'connected-react-router';
import { assoc, assocPath } from 'ramda';
import { ActionsObservable, StateObservable, combineEpics } from 'redux-observable';
import { of } from 'rxjs';
import { ajax } from 'rxjs/ajax';
import { mergeMap, filter, catchError, delay } from 'rxjs/operators';

import { http } from '~services/http';

import { actions as notificationsActions } from '../notifications/notificationsSlice';
import { actions as pageActions } from '../page/pageSlice';
import { extractPaginationValues, getPaginationQueryParams, mapFilters } from '../page/pageUtils';
import { actions as sharedActions } from '../sharedSlice';

import { updateSubscriberInfo, updateSubscribersWithRoutes } from './subscribersUtils';

import { api, appRoutes } from '~constants';
import {
  BusinessUnit,
  PaginationRequestPayload,
  PaginationResponse,
  ResponseError,
  Subscriber,
  SubscriberSettings,
  SubscriberZonesConfiguration,
  UnitRoute,
  SubscriberMessage,
  PageFilters,
  PageSorting,
  ReportingRoute,
  SubscribersListSection,
  SubscriberHistory,
  SubscriberHistoryPeriod,
  AssignedDealer,
  SubscriberNotifications,
  SubscriberPeers,
  SubscriberMode,
  UnitDependent,
  Pagination,
  ControlRelay,
  FACPZones,
  ZoneNotifications,
  TestModeUnitID,
  CustomNote,
  ReverseGeoCoding,
} from '~models';
import {
  findDataByCallback,
  getResponsePayload,
  getResponseError,
  updateArray,
  updateArrayWithCallback,
  intToHex,
  saveFile,
} from '~utils';

export type SubscriberInfoSection = 'hardware' | 'zones' | 'radio-status' | 'ip-config' | 'routing-tables';
export type SubscriberSettingsSection = 'radio-packet-life' | 'timing' | 'modes' | 'rf-settings' | 'supervision';
export type SubscriberSettingsMode = 'intellitap' | 'line-cut' | 'repeater';

export interface SubscribersState {
  data: Subscriber[];
  loading: {
    list: {
      all: boolean;
      export: boolean;
    };
    details: { [key in SubscriberInfoSection]: boolean } & {
      general: boolean;
      routes: boolean;
      faults: boolean;
      update: boolean;
      messages: {
        all: boolean;
        send: boolean;
      };
      activate: boolean;
      history: {
        all: boolean;
        export: boolean;
      };
      reverseGeoCoding: boolean;
      ahjreport: boolean;
      dealers: boolean;
      notifications: boolean;
      peers: boolean;
      nct: boolean;
      inactiveDependency: boolean;
      controlRelay: boolean;
      testMode: boolean;
      dependencies: boolean;
    };
    facp: {
      list: boolean;
      update: boolean;
      delete: boolean;
      import: boolean;
      notifications: boolean;
      export: boolean;
    };
    settings: {
      sections: { [key in SubscriberSettingsSection]: boolean };
      modes: { [key in SubscriberSettingsMode]: boolean };
      request: { [key in SubscriberSettingsSection]: boolean };
      'remote-reset': boolean;
      rf: boolean;
      defaultSetting: {
        'radio-packet-life': boolean;
        timing: boolean;
      };
    };
    delete: boolean;
    zones: boolean;
    subscriberIds: boolean;
    customNote: {
      list: boolean;
      delete: boolean;
    };
  };
  status: {
    details: 'updated' | 'deleted' | null;
    messages: 'send' | null;
    facp: 'imported' | 'updated' | 'deleted' | null;
  };
  subscriberIds: unknown;
  testModeSubsIds: {
    loading: boolean;
    list: TestModeUnitID[];
    error: ResponseError | null;
  };
  errors: {
    list: ResponseError | null;
    subscriberIds: ResponseError | null;
    settings: { [key in SubscriberSettingsSection]: ResponseError | null };
    'remote-reset': ResponseError | null;
    zones: ResponseError | null;
    messages: ResponseError | null;
    details: ResponseError | null;
    peers: ResponseError | null;
    notifications: ResponseError | null;
    facp: ResponseError | null;
  };
}

export const initialState: SubscribersState = {
  data: [],
  loading: {
    list: {
      all: false,
      export: false,
    },
    details: {
      general: false,
      hardware: false,
      zones: false,
      'radio-status': false,
      'ip-config': false,
      'routing-tables': false,
      routes: false,
      faults: false,
      update: false,
      messages: {
        all: false,
        send: false,
      },
      activate: false,
      history: {
        all: false,
        export: false,
      },
      reverseGeoCoding: false,
      ahjreport: false,
      dealers: false,
      notifications: false,
      peers: false,
      nct: false,
      inactiveDependency: false,
      controlRelay: false,
      testMode: false,
      dependencies: false,
    },
    facp: {
      list: false,
      update: false,
      delete: false,
      import: false,
      notifications: false,
      export: false,
    },
    settings: {
      sections: {
        'radio-packet-life': false,
        timing: false,
        modes: false,
        'rf-settings': false,
        supervision: false,
      },
      request: {
        'radio-packet-life': false,
        timing: false,
        modes: false,
        'rf-settings': false,
        supervision: false,
      },
      modes: {
        intellitap: false,
        'line-cut': false,
        repeater: false,
      },
      'remote-reset': false,
      rf: false,
      defaultSetting: {
        'radio-packet-life': false,
        timing: false,
      },
    },
    zones: false,
    delete: false,
    subscriberIds: false,
    customNote: {
      list: false,
      delete: false,
    },
  },
  status: {
    details: null,
    messages: null,
    facp: null,
  },
  subscriberIds: [],
  testModeSubsIds: {
    loading: false,
    list: [],
    error: null,
  },
  errors: {
    list: null,
    subscriberIds: null,
    settings: { 'radio-packet-life': null, timing: null, modes: null, 'rf-settings': null, supervision: null },
    'remote-reset': null,
    zones: null,
    messages: null,
    details: null,
    peers: null,
    notifications: null,
    facp: null,
  },
};

export const { name, actions, reducer } = createSlice({
  name: 'subscribers',
  initialState,
  reducers: {
    // Fetch subscribers list
    fetchSubscribersInit: (state, payload: PayloadAction<PaginationRequestPayload & PageFilters & PageSorting>) => {
      state.loading.list.all = true;
      state.data = [];
    },
    fetchSubscribersSuccess: (
      state,
      { payload: { content, number } }: PayloadAction<PaginationResponse<Subscriber>>
    ) => {
      state.loading.list.all = false;
      state.data = content;
    },
    fetchSubscribersFailed: (state, action: PayloadAction<ResponseError>) => {
      state.loading.list.all = false;
      state.errors.list = action.payload;
    },

    // Fetch subscribers list by business unit

    fetchSubscribersByBusinessUnitInit: (
      state,
      payload: PayloadAction<
        PaginationRequestPayload & { businessUnitId: Subscriber['businessUnitId']; section?: SubscribersListSection }
      >
    ) => {
      state.status.details = null;
      state.loading.list.all = true;
      state.data = [];
    },
    fetchSubscribersByBusinessUnitSuccess: (
      state,
      { payload: { content, number } }: PayloadAction<PaginationResponse<Subscriber>>
    ) => {
      state.loading.list.all = false;
      state.data = content;
    },
    fetchSubscribersByBusinessUnitFailed: (state, action: PayloadAction<ResponseError>) => {
      state.loading.list.all = false;
      state.errors.list = action.payload;
    },

    // Fetch subscriber details

    fetchSubscriberByIdInit: (
      state,
      action: PayloadAction<{ id: Subscriber['id']; businessUnitId: Subscriber['businessUnitId']; notify?: boolean }>
    ) => {
      state.loading.details.general = true;
    },
    fetchSubscriberByIdSuccess: (state, { payload }: PayloadAction<Subscriber>) => {
      state.loading.details.general = false;
      state.data = updateArray(state.data, payload);
    },
    fetchSubscriberByIdFailed: (state, { payload }: PayloadAction<ResponseError>) => {
      state.loading.details.general = false;
      state.errors.details = payload;
    },

    // Fetch subscriber routes

    fetchSubscriberRoutesInit(
      state,
      action: PayloadAction<{ id: Subscriber['id']; businessUnitId: Subscriber['businessUnitId']; notify?: boolean }>
    ) {
      state.loading.details.routes = true;
    },
    fetchSubscriberRoutesSuccess(
      state,
      {
        payload: { id, businessUnitId, routes },
      }: PayloadAction<{ id: Subscriber['id']; businessUnitId: Subscriber['businessUnitId']; routes: UnitRoute[] }>
    ) {
      state.loading.details.routes = false;
      state.data = updateSubscribersWithRoutes(state.data, routes, businessUnitId, id);
    },
    fetchSubscriberRoutesFailed(state, { payload }: PayloadAction<ResponseError>) {
      state.loading.details.routes = false;
      state.errors.details = payload;
    },

    // Fetch subscriber partial info

    fetchSubscriberPartialInfoInit(
      state,
      {
        payload: { section },
      }: PayloadAction<{
        id: Subscriber['id'];
        businessUnitId: Subscriber['businessUnitId'];
        section: SubscriberInfoSection;
      }>
    ) {
      state.loading.details[section] = true;
    },
    fetchSubscriberPartialInfoSuccess(
      state,
      {
        payload: { id, businessUnitId, section, info },
      }: PayloadAction<{
        id: Subscriber['id'];
        businessUnitId: Subscriber['businessUnitId'];
        section: SubscriberInfoSection;
        info: Partial<Subscriber>;
      }>
    ) {
      const subscriber = findDataByCallback(
        state.data,
        sub => sub.id === id && sub.businessUnitId === businessUnitId
      ) as Subscriber;

      state.loading.details[section] = false;
      state.data = updateArrayWithCallback(
        state.data,
        assocPath(['subscriberInfo', section], info, subscriber),
        (e, u) => e.id === id && u.businessUnitId === businessUnitId
      );
    },
    fetchSubscriberPartialInfoFailed(
      state,
      {
        payload: { section, error },
      }: PayloadAction<{
        section: SubscriberInfoSection;
        error: ResponseError;
      }>
    ) {
      state.loading.details[section] = false;
    },

    // Request subscriber data

    requestSubscriberDataInit(
      state,
      {
        payload: { section },
      }: PayloadAction<{
        id: Subscriber['id'];
        businessUnitId: Subscriber['businessUnitId'];
        section: SubscriberInfoSection;
        reportingRoute?: ReportingRoute['route'];
      }>
    ) {
      state.loading.details[section] = true;
    },
    requestSubscriberDataSuccess(
      state,
      {
        payload: { section },
      }: PayloadAction<{
        section: SubscriberInfoSection;
      }>
    ) {
      state.loading.details[section] = false;
    },
    requestSubscriberDataFailed(
      state,
      {
        payload: { section },
      }: PayloadAction<{
        section: SubscriberInfoSection;
        error: ResponseError;
      }>
    ) {
      state.loading.details[section] = false;
    },

    // Fetch subscriber settings

    fetchSubscriberSettingsInit(
      state,
      {
        payload: { section },
      }: PayloadAction<{
        businessUnitId: BusinessUnit['id'];
        id: Subscriber['id'];
        section: SubscriberSettingsSection;
      }>
    ) {
      state.loading.settings.sections[section] = true;
      state.errors.settings[section] = null;
    },
    fetchSubscriberSettingsSuccess<T>(
      state,
      {
        payload: { businessUnitId, id, section, values },
      }: PayloadAction<{
        businessUnitId: BusinessUnit['id'];
        id: Subscriber['id'];
        section: SubscriberSettingsSection;
        values: T;
      }>
    ) {
      state.loading.settings.sections[section] = false;

      const subscriber = findDataByCallback(
        state.data,
        sub => sub.id === id && sub.businessUnitId === businessUnitId
      ) as Subscriber;

      state.data = updateArrayWithCallback(
        state.data,
        assocPath(['settings', section], values, subscriber),
        (e, u) => e.id === id && u.businessUnitId === businessUnitId
      );
    },
    fetchSubscriberSettingsFailed(
      state,
      {
        payload: { section, error },
      }: PayloadAction<{ section: SubscriberSettingsSection; error: ResponseError | null }>
    ) {
      state.loading.settings.sections[section] = false;
      state.errors.settings[section] = error;
    },

    // Fetch subscriber settings default

    fetchSubscriberSettingsDefaultInit(
      state,
      {
        payload: { section },
      }: PayloadAction<{
        businessUnitId: BusinessUnit['id'];
        id: Subscriber['id'];
        section: SubscriberSettingsSection;
      }>
    ) {
      state.loading.settings.defaultSetting[section] = true;
      state.errors.settings[section] = null;
    },

    fetchSubscriberSettingsDefaultSuccess<T>(
      state,
      {
        payload: { businessUnitId, id, section, values },
      }: PayloadAction<{
        businessUnitId: BusinessUnit['id'];
        id: Subscriber['id'];
        section: SubscriberSettingsSection;
        values: T;
      }>
    ) {
      state.loading.settings.defaultSetting[section] = false;
      const subscriberDefault = findDataByCallback(
        state.data,
        sub => sub.id === id && sub.businessUnitId === businessUnitId
      ) as Subscriber;
      state.data = updateArrayWithCallback(
        state.data,
        assocPath(['settings', section], values, subscriberDefault),
        (e, u) => e.id === id && u.businessUnitId === businessUnitId
      );
    },

    fetchSubscriberSettingsDefaultFailed(
      state,
      {
        payload: { section, error },
      }: PayloadAction<{ section: SubscriberSettingsSection; error: ResponseError | null }>
    ) {
      state.loading.settings.defaultSetting[section] = false;
      state.errors.settings[section] = error;
    },

    // Request subscriber settings

    requestSubscriberSettingsInit(
      state,
      {
        payload: { section },
      }: PayloadAction<{
        businessUnitId: BusinessUnit['id'];
        id: Subscriber['id'];
        section: SubscriberSettingsSection;
        reportingRoute?: ReportingRoute['route'];
      }>
    ) {
      state.loading.settings.request[section] = true;
      state.errors.settings[section] = null;
    },
    requestSubscriberSettingsSuccess(
      state,
      {
        payload: { section },
      }: PayloadAction<{
        section: SubscriberSettingsSection;
      }>
    ) {
      state.loading.settings.request[section] = false;
    },
    requestSubscriberSettingsFailed(
      state,
      { payload: { section, error } }: PayloadAction<{ section: SubscriberSettingsSection; error: ResponseError }>
    ) {
      state.loading.settings.request[section] = false;
      state.errors.settings[section] = error;
    },

    // Update Subscriber Settings

    updateSubscriberSettingsInit(
      state,
      {
        payload: { section },
      }: PayloadAction<{
        businessUnitId: BusinessUnit['id'];
        id: Subscriber['id'];
        section: SubscriberSettingsSection;
        values: Partial<
          | SubscriberSettings['timing']
          | SubscriberSettings['radio-packet-life']
          | SubscriberSettings['rf-settings']
          | SubscriberSettings['supervision']
        >;
      }>
    ) {
      state.loading.settings.sections[section] = true;
      state.errors.settings[section] = null;
    },
    updateSubscriberSettingsSuccess(
      state,
      { payload: { section } }: PayloadAction<{ section: SubscriberSettingsSection }>
    ) {
      state.loading.settings.sections[section] = false;
    },
    updateSubscriberSettingsFailed(
      state,
      { payload: { section, error } }: PayloadAction<{ section: SubscriberSettingsSection; error: ResponseError }>
    ) {
      state.loading.settings.sections[section] = false;
      state.errors.settings[section] = error;
    },

    // Update Subscriber Details Info

    updateSubscriberDetailsInfoInit(
      state,
      {
        payload,
      }: PayloadAction<{
        businessUnitId: BusinessUnit['id'];
        id: Subscriber['id'];
        values: Partial<Subscriber>;
      }>
    ) {
      state.loading.details.update = true;
      state.status.details = null;
    },
    updateSubscriberDetailsSuccess(state, { payload }: PayloadAction<Partial<Subscriber>>) {
      const subscriber = findDataByCallback(
        state.data,
        sub => sub.id === payload.id && sub.businessUnitId === payload.businessUnitId
      ) as Subscriber;

      state.loading.details.update = false;
      state.status.details = 'updated';

      state.data = updateArrayWithCallback(
        state.data,
        updateSubscriberInfo(subscriber, payload),
        (e, u) => e.id === payload.id && u.businessUnitId === payload.businessUnitId
      );
    },

    updateSubscriberDetailsFailed(
      state,
      {
        payload: { error },
      }: PayloadAction<{
        error: ResponseError;
      }>
    ) {
      state.loading.details.update = false;
      state.errors.details = error;
    },

    //Update subscriber settings mode

    updateSubscriberSettingsModeInit(
      state,
      {
        payload: { businessUnitId, id, mode, values, fieldName },
      }: PayloadAction<{
        businessUnitId: BusinessUnit['id'];
        id: Subscriber['id'];
        mode: SubscriberSettingsMode;
        values: Partial<SubscriberSettings['modes']>;
        fieldName: string;
      }>
    ) {
      state.loading.settings.modes[mode] = true;
    },
    updateSubscriberSettingsModeSuccess(
      state,
      {
        payload: { businessUnitId, id, mode, values, fieldName },
      }: PayloadAction<{
        businessUnitId: BusinessUnit['id'];
        id: Subscriber['id'];
        mode: SubscriberSettingsMode;
        values: Partial<SubscriberSettings['modes']>;
        fieldName: string;
      }>
    ) {
      state.loading.settings.modes[mode] = false;

      const subscriber = findDataByCallback(
        state.data,
        sub => sub.id === id && sub.businessUnitId === businessUnitId
      ) as Subscriber;

      state.data = updateArrayWithCallback(
        state.data,
        assocPath(['settings', 'modes', fieldName], values[fieldName], subscriber),
        (e, u) => e.id === id && u.businessUnitId === businessUnitId
      );
    },
    updateSubscriberSettingsModeFailed(
      state,
      {
        payload: { mode },
      }: PayloadAction<{
        mode: SubscriberSettingsMode;
        error: ResponseError | null;
      }>
    ) {
      state.loading.settings.modes[mode] = false;
    },

    // Reset subscriber settings

    resetSubscriberSettings(
      state,
      { payload: { id, businessUnitId } }: PayloadAction<{ id: Subscriber['id']; businessUnitId: BusinessUnit['id'] }>
    ) {
      const subscriber = findDataByCallback(
        state.data,
        sub => sub.id === id && sub.businessUnitId === businessUnitId
      ) as Subscriber;

      if (subscriber) {
        state.data = updateArrayWithCallback(
          state.data,
          assocPath(['settings'], null, subscriber),
          (e, u) => e.id === id && u.businessUnitId === businessUnitId
        );
      }
    },

    //request remote reset subscriber settings

    requestSubscriberRemoteResetInit(
      state,
      { payload }: PayloadAction<{ businessUnitId: BusinessUnit['id']; id: Subscriber['id'] }>
    ) {
      state.loading.settings['remote-reset'] = true;
      state.errors.settings['remote-reset'] = null;
    },
    requestSubscriberRemoteResetSuccess(state) {
      state.loading.settings['remote-reset'] = false;
    },
    requestSubscriberRemoteResetFailed(state, { payload: { error } }: PayloadAction<{ error: ResponseError }>) {
      state.loading.settings['remote-reset'] = false;
      state.errors.settings['remote-reset'] = error;
    },

    //update rf subscriber settings

    updateSubscriberRFInit(
      state,
      { payload }: PayloadAction<{ businessUnitId: BusinessUnit['id']; id: Subscriber['id']; path: string }>
    ) {
      state.loading.settings.rf = true;
    },
    updateSubscriberRFSuccess(state) {
      state.loading.settings.rf = false;
    },
    updateSubscriberRFFailed(state, payload) {
      state.loading.settings.rf = false;
    },

    // Fetch subscriber messages

    fetchSubscriberMessagesInit(
      state,
      { payload }: PayloadAction<{ businessUnitId: Subscriber['businessUnitId']; id: Subscriber['id'] }>
    ) {
      state.loading.details.messages.all = true;
    },
    fetchSubscriberMessagesSuccess(
      state,
      {
        payload: { messages, businessUnitId, id },
      }: PayloadAction<{
        messages: SubscriberMessage[];
        businessUnitId: Subscriber['businessUnitId'];
        id: Subscriber['id'];
      }>
    ) {
      state.loading.details.messages.all = false;

      const subscriber = findDataByCallback<Subscriber>(
        state.data,
        subscriber => subscriber.id === id && subscriber.businessUnitId === businessUnitId
      );

      if (subscriber) {
        const value = (subscriber.messages || []).concat(messages);

        state.data = updateArrayWithCallback(state.data, assoc('messages' as keyof Subscriber, value, subscriber));
      }
    },
    fetchSubscriberMessagesFailed(state, { payload }: PayloadAction<ResponseError>) {
      state.loading.details.messages.all = false;
      state.errors.messages = payload;
    },

    // Send subscriber message

    sendSubscriberMessageInit(
      state,
      {
        payload: { message },
      }: PayloadAction<{
        businessUnitId: Subscriber['businessUnitId'];
        id: Subscriber['id'];
        message: SubscriberMessage['message'];
        reportingRoute?: ReportingRoute['route'];
      }>
    ) {
      state.loading.details.messages.send = true;
      state.status.messages = null;
    },
    sendSubscriberMessageSuccess(state) {
      state.loading.details.messages.send = false;
      state.status.messages = 'send';
    },
    sendSubscriberMessageFailed(state, { payload }: PayloadAction<ResponseError>) {
      state.loading.details.messages.send = false;
      state.errors.messages = payload;
    },

    //delete subscriber

    deleteSubscriberInit(
      state,
      { payload }: PayloadAction<{ businessUnitId: BusinessUnit['id']; id: Subscriber['id']; backTo?: string }>
    ) {
      state.loading.delete = true;
    },
    deleteSubscriberSuccess(
      state,
      { payload }: PayloadAction<{ businessUnitId: BusinessUnit['id']; id: Subscriber['id'] }>
    ) {
      state.loading.delete = false;
      state.status.details = 'deleted';
    },
    deleteSubscriberFailed(state, { payload: { error } }: PayloadAction<{ error: ResponseError }>) {
      state.loading.delete = false;
    },

    // Fetch subscriber zones configuration

    fetchSubscriberZonesConfigurationInit(
      state,
      action: PayloadAction<{ businessUnitId: BusinessUnit['id']; id: Subscriber['id']; notify?: boolean }>
    ) {
      state.errors.zones = null;
      state.loading.zones = true;
    },
    fetchSubscriberZonesConfigurationSuccess(
      state,
      {
        payload: { id, businessUnitId, zones },
      }: PayloadAction<{
        businessUnitId: BusinessUnit['id'];
        id: Subscriber['id'];
        zones: SubscriberZonesConfiguration[];
      }>
    ) {
      state.loading.zones = false;

      const subscriber = findDataByCallback(
        state.data,
        sub => sub.id === id && sub.businessUnitId === businessUnitId
      ) as Subscriber;

      if (subscriber) {
        state.data = updateArrayWithCallback(
          state.data,
          updateSubscriberInfo(subscriber, { zones }),
          (e, u) => e.id === id && u.businessUnitId === businessUnitId
        );
      }
    },
    fetchSubscriberZonesConfigurationFailed(state, { payload }: PayloadAction<ResponseError>) {
      state.errors.zones = payload;
      state.loading.zones = false;
    },

    // Update subscriber zones configuration

    updateSubscriberZonesConfigurationInit(
      state,
      action: PayloadAction<{
        businessUnitId: BusinessUnit['id'];
        id: Subscriber['id'];
        zoneProgramingDtoList: unknown[];
      }>
    ) {
      state.errors.zones = null;
      state.loading.zones = true;
    },
    updateSubscriberZonesConfigurationSuccess(state) {
      state.loading.zones = false;
    },
    updateSubscriberZonesConfigurationFailed(state, { payload }: PayloadAction<ResponseError>) {
      state.loading.zones = false;
      state.errors.zones = payload;
    },

    // activate/inactivate subscriber

    activateSubscriberInit(
      state,
      action: PayloadAction<{ businessUnitId: BusinessUnit['id']; id: Subscriber['id']; status: Subscriber['status'] }>
    ) {
      state.loading.details.activate = true;
      state.status.details = initialState.status.details;
    },
    activateSubscriberSuccess(state) {
      state.loading.details.activate = false;
      state.status.details = 'updated';
    },
    activateSubscriberFailed(state, { payload }: PayloadAction<ResponseError>) {
      state.loading.details.activate = false;
      state.errors.details = payload;
    },

    // Reset subscriber messages

    resetSubscriberMessages(
      state,
      {
        payload: { businessUnitId, id },
      }: PayloadAction<{ businessUnitId: Subscriber['businessUnitId']; id: Subscriber['id'] }>
    ) {
      const subscriber = findDataByCallback<Subscriber>(
        state.data,
        subscriber => subscriber.id === id && subscriber.businessUnitId === businessUnitId
      );

      if (subscriber) {
        state.data = updateArrayWithCallback(state.data, assoc('messages' as keyof Subscriber, [], subscriber));
      }
    },

    // Fetch subscribers ids

    fetchSubscribersIdsInit: (
      state,
      payload: PayloadAction<PaginationRequestPayload & { businessUnitId: Subscriber['businessUnitId'] }>
    ) => {
      state.loading.subscriberIds = true;
      state.subscriberIds = [];
    },
    fetchSubscribersIdsSuccess: (
      state,
      { payload: { content, number } }: PayloadAction<PaginationResponse<{ id: number; unitType: string }[]>>
    ) => {
      state.loading.subscriberIds = false;
      state.subscriberIds = content;
    },
    fetchSubscribersIdsFailed: (state, { payload }: PayloadAction<ResponseError>) => {
      state.loading.subscriberIds = false;
      state.errors.subscriberIds = payload;
    },

    // Fetch subscriber history

    fetchSubscriberHistoryInit: (
      state,
      payload: PayloadAction<
        PaginationRequestPayload & {
          businessUnitId: Subscriber['businessUnitId'];
          period: SubscriberHistoryPeriod;
          id: Subscriber['id'];
        }
      >
    ) => {
      state.loading.details.history.all = true;
    },
    fetchSubscriberHistorySuccess: (
      state,
      { payload: { content, id } }: PayloadAction<{ content: SubscriberHistory[], id: Subscriber['id'] }>
    ) => {
      state.loading.details.history.all = false;
      const subscriber = findDataByCallback<Subscriber>(state.data, subscriber => subscriber.id === id);

      if (subscriber) {
        state.data = updateArrayWithCallback(
          state.data,
          assoc('eventHistory' as keyof Subscriber, content, subscriber)
        );
      }
    },
    fetchSubscriberHistoryFailed: (state, { payload }: PayloadAction<ResponseError>) => {
      state.loading.details.history.all = false;
      state.errors.details = payload;
    },

    exportSubscriberEventHistoryInit(
      state,
      { payload }: PayloadAction<{ buId: BusinessUnit['id']; id: Subscriber['id']; period: SubscriberHistoryPeriod }>
    ) {
      state.loading.details.history.export = true;
    },
    exportSubscriberEventHistorySuccess: state => {
      state.loading.details.history.export = false;
    },
    exportSubscriberEventHistoryFailed: (state, action: PayloadAction<ResponseError>) => {
      state.loading.details.history.export = false;
    },

    // ahjreport email address
    ahjreportSubscriberInit(
      state,
      action: PayloadAction<{ businessUnitId: BusinessUnit['id']; id: Subscriber['id']; emailAddress: string }>
    ) {
      state.loading.details.ahjreport = true;
    },
    ahjreportSubscriberSuccess(state) {
      state.loading.details.ahjreport = false;
    },
    ahjreportSubscriberFailed(state, { payload }: PayloadAction<ResponseError>) {
      state.loading.details.ahjreport = false;
      state.errors.details = payload;
    },

    // Fetch assigned dealers to subscriber

    fetchAssignedDealersInit: (
      state,
      action: PayloadAction<{ id: Subscriber['id']; businessUnitId: Subscriber['businessUnitId'] }>
    ) => {
      state.loading.details.dealers = true;
    },
    fetchAssignedDealersSuccess: (
      state,
      {
        payload: { dealers, id, businessUnitId },
      }: PayloadAction<{
        dealers: AssignedDealer[];
        id: Subscriber['id'];
        businessUnitId: Subscriber['businessUnitId'];
      }>
    ) => {
      const subscriber = findDataByCallback<Subscriber>(state.data, subscriber => subscriber.id === id);
      if (subscriber) {
        state.data = updateArrayWithCallback(state.data, assoc('dealers' as keyof Subscriber, dealers, subscriber));
      } else {
        state.data = [{ businessUnitId, id, dealers } as Subscriber];
      }

      state.loading.details.dealers = false;
    },
    fetchAssignedDealersFailed: (state, { payload }: PayloadAction<ResponseError>) => {
      state.loading.details.dealers = false;
      state.errors.details = payload;
    },

    // Fetch subscriber notifications

    fetchSubscriberNotificationsInit: (
      state,
      action: PayloadAction<{ id: Subscriber['id']; businessUnitId: Subscriber['businessUnitId'] }>
    ) => {
      state.loading.details.notifications = true;
    },
    fetchSubscriberNotificationsSuccess: (
      state,
      {
        payload: { notifications, id },
      }: PayloadAction<{ notifications: SubscriberNotifications; id: Subscriber['id'] }>
    ) => {
      const subscriber = findDataByCallback<Subscriber>(state.data, subscriber => subscriber.id === id);

      if (subscriber) {
        state.data = updateArrayWithCallback(
          state.data,
          assoc('notifications' as keyof Subscriber, notifications, subscriber)
        );
      }

      state.loading.details.notifications = false;
    },
    fetchSubscriberNotificationsFailed: (state, { payload }: PayloadAction<ResponseError>) => {
      state.loading.details.notifications = false;
      state.errors.notifications = payload;
    },

    // Update subscriber notifications

    updateSubscriberNotificationsInit(
      state,
      {
        payload: { businessUnitId, id, values },
      }: PayloadAction<{
        id: Subscriber['id'];
        businessUnitId: Subscriber['businessUnitId'];
        values: Partial<SubscriberNotifications>;
      }>
    ) {
      state.loading.details.notifications = true;
    },

    //delete inactive subscriber

    deleteInactiveSubscriberInit(
      state,
      {
        payload,
      }: PayloadAction<
        PaginationRequestPayload & {
          businessUnitId: BusinessUnit['id'];
          id: Subscriber['id'];
          section: SubscribersListSection;
          backTo?: string;
        }
      >
    ) {
      state.loading.delete = true;
    },
    deleteInactiveSubscriberSuccess(
      state,
      { payload }: PayloadAction<{ businessUnitId: BusinessUnit['id']; id: Subscriber['id'] }>
    ) {
      state.loading.delete = false;
      state.status.details = 'deleted';
    },
    deleteInactiveSubscriberFailed(state, { payload: { error } }: PayloadAction<{ error: ResponseError }>) {
      state.loading.delete = false;
    },

    // Fetch inactive subscriber dependent

    fetchInactiveSubscriberDependencyInit: (
      state,
      payload: PayloadAction<{
        id: Subscriber['id'];
        businessUnitId: Subscriber['businessUnitId'];
      }>
    ) => {
      state.loading.details.inactiveDependency = true;
    },

    fetchInactiveSubscriberDependencySuccess: (
      state,
      { payload: { dependency, id } }: PayloadAction<{ dependency: UnitDependent[]; id: Subscriber['id'] }>
    ) => {
      const subscriber = findDataByCallback<Subscriber>(state.data, subscriber => id === subscriber.id);

      if (subscriber) {
        state.data = updateArrayWithCallback(
          state.data,
          assoc('inactiveDependency' as keyof Subscriber, dependency, subscriber)
        );
      }

      state.loading.details.inactiveDependency = false;
    },

    fetchInactiveSubscriberDependencyFailed: state => {
      state.loading.details.inactiveDependency = false;
    },

    // Fetch subscriber Peers

    fetchSubscriberPeersInit: (
      state,
      payload: PayloadAction<{
        id: Subscriber['id'];
        businessUnitId: Subscriber['businessUnitId'];
      }>
    ) => {
      state.loading.details.peers = true;
    },
    fetchSubscriberPeersSuccess: (
      state,
      { payload: { peers, id } }: PayloadAction<{ peers: SubscriberPeers; id: Subscriber['id'] }>
    ) => {
      state.loading.details.peers = false;
      const subscriber = findDataByCallback<Subscriber>(state.data, subscriber => subscriber.id === id);

      if (subscriber) {
        state.data = updateArrayWithCallback(state.data, assoc('peers' as keyof Subscriber, peers, subscriber));
      }
    },
    fetchSubscriberPeersFailed: (state, action: PayloadAction<ResponseError>) => {
      state.loading.details.peers = false;
      state.errors.peers = action.payload;
    },

    nctModeSubscriberInit(
      state,
      action: PayloadAction<{
        businessUnitId: BusinessUnit['id'];
        id: Subscriber['id'];
        mode: SubscriberMode;
        pagination?: Pagination;
        fromBU?: boolean;
      }>
    ) {
      state.loading.details.nct = true;
    },
    nctModeSubscriberSuccess(state, { payload }: PayloadAction<{ mode: SubscriberMode }>) {
      state.loading.details.nct = false;
    },
    nctModeSubscriberFailed(state, { payload }: PayloadAction<ResponseError>) {
      state.loading.details.nct = false;
      state.errors.details = payload;
    },

    fetchSubscriberControlRelayInit: (
      state,
      action: PayloadAction<{ id: Subscriber['id']; businessUnitId: Subscriber['businessUnitId'] }>
    ) => {
      state.loading.details.controlRelay = true;
    },
    fetchSubscriberControlRelaySuccess: (
      state,
      { payload }: PayloadAction<{ controlRelay: ControlRelay; id: Subscriber['id'] }>
    ) => {
      state.loading.details.controlRelay = false;
      const subscriber = findDataByCallback<Subscriber>(state.data, subscriber => subscriber.id === payload.id);

      if (subscriber) {
        state.data = updateArrayWithCallback(
          state.data,
          assoc('controlRelay' as keyof Subscriber, payload.controlRelay, subscriber)
        );
      }
    },
    fetchSubscriberControlRelayFailed: (state, { payload }: PayloadAction<ResponseError>) => {
      state.loading.details.controlRelay = false;
      state.errors.details = payload;
    },

    // Reset subscriber messages

    resetSubscriberControlRelay(
      state,
      {
        payload: { businessUnitId, id },
      }: PayloadAction<{ businessUnitId: Subscriber['businessUnitId']; id: Subscriber['id'] }>
    ) {
      const subscriber = findDataByCallback<Subscriber>(
        state.data,
        subscriber => subscriber.id === id && subscriber.businessUnitId === businessUnitId
      );

      if (subscriber) {
        state.data = updateArrayWithCallback(state.data, assoc('controlRelay' as keyof Subscriber, {}, subscriber));
      }
    },

    createTestModeSubscriberInit(
      state,
      action: PayloadAction<{
        businessUnitId: BusinessUnit['id'];
        id: Subscriber['id'];
        timePeriod: number;
      }>
    ) {
      state.loading.details.testMode = true;
    },
    createTestModeSubscriberSuccess(state) {
      state.loading.details.testMode = false;
    },
    createTestModeSubscriberFailed(state, { payload }: PayloadAction<ResponseError>) {
      state.loading.details.testMode = false;
      state.errors.details = payload;
    },

    updateTestModeSubscriberInit(
      state,
      action: PayloadAction<{
        businessUnitId: BusinessUnit['id'];
        id: Subscriber['id'];
        timePeriod: number;
      }>
    ) {
      state.loading.details.testMode = true;
    },
    updateTestModeSubscriberSuccess(state) {
      state.loading.details.testMode = false;
    },
    updateTestModeSubscriberFailed(state, { payload }: PayloadAction<ResponseError>) {
      state.loading.details.testMode = false;
      state.errors.details = payload;
    },

    deleteTestModeSubscriberInit(
      state,
      action: PayloadAction<{
        businessUnitId: BusinessUnit['id'];
        id: Subscriber['id'];
      }>
    ) {
      state.loading.details.testMode = true;
    },
    deleteTestModeSubscriberSuccess(state) {
      state.loading.details.testMode = false;
    },
    deleteTestModeSubscriberFailed(state, { payload }: PayloadAction<ResponseError>) {
      state.loading.details.testMode = false;
      state.errors.details = payload;
    },

    // FACP zones

    fetchSubscriberFACPUnitZonesInit: (
      state,
      payload: PayloadAction<
        PaginationRequestPayload & { businessUnitId: Subscriber['businessUnitId']; id: Subscriber['id'] }
      >
    ) => {
      state.loading.facp.list = true;
    },

    fetchSubscriberFACPUnitZonesSuccess: (
      state,
      { payload: { facpZones, id } }: PayloadAction<{ facpZones: FACPZones[]; id: Subscriber['id'] }>
    ) => {
      const subscriber = findDataByCallback<Subscriber>(state.data, subscriber => subscriber.id === id);

      if (subscriber) {
        state.data = updateArrayWithCallback(state.data, assoc('facpZones' as keyof Subscriber, facpZones, subscriber));
      }

      state.loading.facp.list = false;
    },

    fetchSubscriberFACPUnitZonesFailed: (state, { payload }: PayloadAction<ResponseError>) => {
      state.loading.facp.list = false;
      state.errors.facp = payload;
    },

    deleteSubscriberFACPZoneEventInit(
      state,
      {
        payload,
      }: PayloadAction<
        PaginationRequestPayload & {
          businessUnitId: BusinessUnit['id'];
          id: Subscriber['id'];
          zoneEventId: number;
        }
      >
    ) {
      state.loading.facp.delete = true;
      state.status.facp = null;
    },

    deleteSubscriberFACPZoneEventSuccess(state) {
      state.loading.facp.delete = false;
      state.status.facp = 'deleted';
    },

    deleteSubscriberFACPZoneEventFailed(state, { payload }: PayloadAction<ResponseError>) {
      state.loading.facp.delete = false;
      state.errors.facp = payload;
    },

    addSubscriberFACPZoneEventInit(
      state,
      {
        payload,
      }: PayloadAction<
        PaginationRequestPayload & {
          businessUnitId: BusinessUnit['id'];
          id: Subscriber['id'];
          values: Partial<FACPZones>;
        }
      >
    ) {
      state.loading.facp.update = true;
    },

    addSubscriberFACPZoneEventSuccess(state) {
      state.loading.facp.update = false;
    },

    addSubscriberFACPZoneEventFailed(state, { payload }: PayloadAction<ResponseError>) {
      state.loading.facp.update = false;
      state.errors.facp = payload;
    },

    updateSubscriberFACPZoneEventInit(
      state,
      {
        payload,
      }: PayloadAction<
        PaginationRequestPayload & {
          businessUnitId: BusinessUnit['id'];
          id: Subscriber['id'];
          zoneEventId: number;
          values: Partial<FACPZones>;
        }
      >
    ) {
      state.loading.facp.update = true;
      state.status.facp = null;
    },

    updateSubscriberFACPZoneEventSuccess(state) {
      state.loading.facp.update = false;
      state.status.facp = 'updated';
    },

    updateSubscriberFACPZoneEventFailed(state, { payload }: PayloadAction<ResponseError>) {
      state.loading.facp.update = false;
      state.errors.facp = payload;
    },

    importFACPConfigurationSubscribersInit(
      state,
      action: PayloadAction<
        PaginationRequestPayload & { files: File[]; businessUnitId: Subscriber['businessUnitId']; id: Subscriber['id']; skipFirstRow?: boolean }
      >
    ) {
      state.loading.facp.import = true;
      state.status.facp = null;
    },
    importFACPConfigurationSubscribersSuccess(state) {
      state.loading.facp.import = false;
      state.status.facp = 'imported';
    },
    importFACPConfigurationSubscribersFailed(state, { payload }: PayloadAction<ResponseError>) {
      state.loading.facp.import = false;
      state.errors.facp = payload;
    },

    exportFACPZoneConfigurationInit(
      state,
      { payload }: PayloadAction<{ businessUnitId: BusinessUnit['id']; id: Subscriber['id'] }>
    ) {
      state.loading.facp.export = true;
    },

    exportFACPZoneConfigurationSuccess: state => {
      state.loading.facp.export = false;
    },

    exportFACPZoneConfigurationFailed: (state, { payload }: PayloadAction<ResponseError>) => {
      state.loading.facp.export = false;
      state.errors.facp = payload;
    },

    fetchZoneNotificationsInit: (
      state,
      payload: PayloadAction<{ businessUnitId: Subscriber['businessUnitId'], id: Subscriber['id']}>
    ) => {
      state.loading.facp.notifications = true;
    },

    fetchZoneNotificationsSuccess: (
      state,
      { payload: { zoneNotifications, id } }: PayloadAction<{ zoneNotifications: ZoneNotifications, id: Subscriber['id'] }>
    ) => {
      const subscriber = findDataByCallback<Subscriber>(state.data, subscriber => subscriber.id === id);

      if (subscriber) {
        state.data = updateArrayWithCallback(state.data, assoc('zoneNotifications' as keyof Subscriber, zoneNotifications, subscriber));
      }

      state.loading.facp.notifications = false;
    },

    fetchZoneNotificationsFailed: (state, { payload }: PayloadAction<ResponseError>) => {
      state.loading.facp.notifications = false;
      state.errors.facp = payload;
    },

    updateZoneNotificationsInit(
      state,
      {
        payload: { businessUnitId, id, values },
      }: PayloadAction<{
        id: Subscriber['id'];
        businessUnitId: Subscriber['businessUnitId'];
        values: Partial<ZoneNotifications>;
      }>
    ) {
      state.loading.facp.notifications = true;
    },

    exportFilteredSubscribersInit: (state, { payload }: PayloadAction<PageFilters>) => {
      state.loading.list.export = true;
    },
    exportSubscribersSuccess: state => {
      state.loading.list.export = false;
    },
    exportSubscribersFailed: (state, action: PayloadAction<ResponseError>) => {
      state.loading.list.export = false;
      state.errors.list = action.payload;
    },

    exportBUSubscribersInit: (
      state,
      { payload }: PayloadAction<{ businessUnitId: Subscriber['businessUnitId'], section: SubscribersListSection }>
    ) => {
      state.loading.list.export = true;
    },

    // Fetch subscriber dependencies
    fetchSubscriberDependenciesInit: (
      state,
      {
        payload: { subId },
      }: PayloadAction<
        PaginationRequestPayload & { subId: Subscriber['id']; buId: Subscriber['businessUnitId']; period: string }
      >
    ) => {
      state.loading.details.dependencies = true;
    },
    fetchSubscriberDependenciesSuccess: (
      state,
      { payload: { subId, content } }: PayloadAction<PaginationResponse<UnitDependent[]> & { subId: Subscriber['id'] }>
    ) => {
      state.loading.details.dependencies = false;

      const unit = findDataByCallback<Subscriber>(state.data, subscriber => subscriber.id === subId);

      if (unit) {
        state.data = updateArrayWithCallback(state.data, assoc('dependencies' as keyof Subscriber, content, unit));
      }
    },
    fetchSubscriberDependenciesFailed: (state, { payload }: PayloadAction<ResponseError>) => {
      state.loading.details.dependencies = false;
      state.errors.details = payload;
    },

    fetchTestModeSubsIdsInit(state) {
      state.testModeSubsIds.loading = true;
    },
    fetchTestModeSubsIdsSuccess(state, { payload }: PayloadAction<TestModeUnitID[]>) {
      state.testModeSubsIds.loading = false;
      state.testModeSubsIds.list = payload;
    },
    fetchTestModeSubsIdsFailed(state, { payload }: PayloadAction<ResponseError>) {
      state.testModeSubsIds.loading = false;
      state.testModeSubsIds.error = payload;
    },

    fetchSubscriberCustomNotesInit(state, { payload: { buId, id } }: PayloadAction<{ buId: Subscriber['businessUnitId']; id: Subscriber['id'] }>) {
      state.loading.customNote.list = true;
    },

    fetchSubscriberCustomNotesSuccess(state, { payload: { id, buId, notes } }: PayloadAction<{id: Subscriber['id']; buId: Subscriber['businessUnitId']; notes: CustomNote[]}>) {
      state.loading.customNote.list = false;
      const subscriber = findDataByCallback<Subscriber>(
        state.data,
        subscriber => subscriber.id === id && subscriber.businessUnitId === buId
      );

      if (subscriber) {
        state.data = updateArrayWithCallback(state.data, assoc('customNotes' as keyof Subscriber, notes, subscriber));
      }
    },

    fetchSubscriberCustomNotesFailed(state, { payload }: PayloadAction<ResponseError>) {
      state.loading.customNote.list = false;
      state.errors.details = payload;
    },

    createSubscriberCustomNoteInit(state, { payload: { buId, id, values } }: PayloadAction<{ buId: Subscriber['businessUnitId']; id: Subscriber['id']; values: CustomNote }>) {
      state.loading.customNote.list = true;
    },

    updateSubscriberCustomNoteInit(state, { payload: { buId, id, noteId, values } }: PayloadAction<{ buId: Subscriber['businessUnitId']; id: Subscriber['id']; noteId: CustomNote['id']; values: CustomNote }>) {
      state.loading.customNote.list = true;
    },

    deleteSubscriberCustomNoteInit(
      state,
      { payload }: PayloadAction<{ buId: Subscriber['businessUnitId']; id: Subscriber['id'], noteId: CustomNote['id'] }>
    ) {
      state.loading.customNote.delete = true;
    },
    deleteSubscriberCustomNoteSuccess(
      state,
      { payload }: PayloadAction<{ buId: Subscriber['businessUnitId']; id: Subscriber['id'] }>
    ) {
      state.loading.customNote.delete = false;
    },
    deleteSubscriberCustomNoteFailed(state, { payload }: PayloadAction<ResponseError>) {
      state.loading.customNote.delete = false;
      state.errors.details = payload;
    },

    fetchReverseGeoCodingInit(state, { payload }: PayloadAction<{ id: Subscriber['id'], businessUnitId: Subscriber['businessUnitId'] }>) {
      state.loading.details.reverseGeoCoding = true;
    },
    fetchReverseGeoCodingSuccess(state, { payload: { id, businessUnitId, values } }: PayloadAction<{ id: Subscriber['id'], businessUnitId: Subscriber['businessUnitId'], values: ReverseGeoCoding[] }>) {
      state.loading.details.reverseGeoCoding = false;
      const subscriber = findDataByCallback<Subscriber>(
        state.data,
        subscriber => subscriber.id === id && subscriber.businessUnitId === businessUnitId
      );

      if (subscriber) {
        state.data = updateArrayWithCallback(state.data, assoc('reverseGeoCoding' as keyof Subscriber, values?.[0], subscriber));
      }
    },
    fetchReverseGeoCodingFailed: (state, action: PayloadAction<ResponseError>) => {
      state.loading.details.reverseGeoCoding = false;
      state.errors.details = action.payload;
    },
    clearReverseGeoCoding(state, { payload }: PayloadAction<{ id: Subscriber['id'], businessUnitId: Subscriber['businessUnitId'] }>) {
      const subscriber = findDataByCallback<Subscriber>(
        state.data,
        subscriber => subscriber.id === payload.id && subscriber.businessUnitId === payload.businessUnitId
      );

      if (subscriber) {
        state.data = updateArrayWithCallback(state.data, assoc('reverseGeoCoding' as keyof Subscriber, null, subscriber));
      }
    },
    resetStatus: state => {
      state.status.details = null;
    },

    resetFACPStatus: state => {
      state.status.facp = null;
    },
    // Reset subscriber data
    resetSubscriberData: state => {
      Object.assign(state, initialState);
    },
  },
  extraReducers: {
    [sharedActions.reset.toString()]: state => Object.assign(state, initialState),
  },
});

const getSubscribersSlice = (state: AES.RootState) => state.subscribers;

const getSubscriberById = (id: Subscriber['id'], businessUnitId: Subscriber['businessUnitId']) =>
  createDraftSafeSelector(
    getSubscribersSlice,
    state => state.data.find(sub => sub.id === id && sub.businessUnitId === businessUnitId) as Subscriber
  );

export const selectors = {
  getSubscribersSlice,

  getSubscribersList: createDraftSafeSelector(getSubscribersSlice, state => state.data),
  getSubscriberById,
  getLoaders: createDraftSafeSelector(getSubscribersSlice, state => state.loading),
  isSubscribersListLoading: createDraftSafeSelector(getSubscribersSlice, state => state.loading.list),
  getSubscribersSettingsErrors: createDraftSafeSelector(getSubscribersSlice, state => state.errors.settings),
  getStatus: createDraftSafeSelector(getSubscribersSlice, state => state.status),
  getSubscriberIds: createDraftSafeSelector(getSubscribersSlice, state => state.subscriberIds),
  getDealers: (id: Subscriber['id'], businessUnitId: Subscriber['businessUnitId']) =>
    createDraftSafeSelector(
      getSubscribersSlice,
      state =>
        state.data.find(sub => sub.id === id && sub.businessUnitId === businessUnitId)?.dealers as AssignedDealer[]
    ),

  getZonesConfigurationError: createDraftSafeSelector(getSubscribersSlice, state => state.errors.zones),
  getDetailsErrors: createDraftSafeSelector(getSubscribersSlice, state => state.errors.details),

  isActiveSubscriber: (id: Subscriber['id'], businessUnitId: Subscriber['businessUnitId']) =>
    createDraftSafeSelector(
      getSubscriberById(id, businessUnitId),
      (subscriber: Subscriber) => subscriber.status !== 'INACTIVE'
    ),

  getSubscriberNotifications: (id: Subscriber['id'], businessUnitId: Subscriber['businessUnitId']) =>
    createDraftSafeSelector(
      getSubscribersSlice,
      state =>
        state.data.find(sub => sub.id === id && sub.businessUnitId === businessUnitId)
          ?.notifications as SubscriberNotifications
    ),
  getSubscriberNotificationsErrors: createDraftSafeSelector(getSubscribersSlice, state => state.errors.notifications),
  getInactiveSubscriberDependency: (id: Subscriber['id'], businessUnitId: Subscriber['businessUnitId']) =>
    createDraftSafeSelector(
      getSubscribersSlice,
      state => state.data.find(sub => sub.id === id && sub.businessUnitId === businessUnitId)?.inactiveDependency
    ),
  getFACPErrors: createDraftSafeSelector(getSubscribersSlice, state => state.errors.facp),
  getTestModeSubscribers: createDraftSafeSelector(getSubscribersSlice, state => state.testModeSubsIds),
  getSubscriberFACPNotifications: (id: Subscriber['id'], businessUnitId: Subscriber['businessUnitId']) =>
    createDraftSafeSelector(
      getSubscribersSlice,
      state =>
        state.data.find(sub => sub.id === id && sub.businessUnitId === businessUnitId)
          ?.zoneNotifications as ZoneNotifications
    ),
  getSubscriberCustomNote: (id: Subscriber['id'], businessUnitId: Subscriber['businessUnitId']) =>
    createDraftSafeSelector(
      getSubscribersSlice,
      state =>
        state.data.find(sub => sub.id === id && sub.businessUnitId === businessUnitId)
          ?.customNotes as CustomNote[]
    ),
};

const fetchSubscribersEpic = (action$: ActionsObservable<AnyAction>) =>
  action$.pipe(
    filter(action => actions.fetchSubscribersInit.match(action)),
    mergeMap(({ payload }) => {
      const searchParams = getPaginationQueryParams(payload);

      return http.getJSON<PaginationResponse<Subscriber>>(api.subscribers.all(searchParams)).pipe(
        mergeMap(res =>
          of(actions.fetchSubscribersSuccess(res), pageActions.setPagePagination(extractPaginationValues(res)))
        ),
        catchError(err => of(actions.fetchSubscribersFailed(getResponseError(err))))
      );
    })
  );

const fetchSubscribersByBusinessUnitEpic = (action$: ActionsObservable<AnyAction>) =>
  action$.pipe(
    filter(action => actions.fetchSubscribersByBusinessUnitInit.match(action)),
    mergeMap(({ payload: { pagination, initial, businessUnitId, section = 'all' } }) => {
      const searchParams = getPaginationQueryParams({ pagination, initial });

      return http
        .getJSON<PaginationResponse<Subscriber>>(api.subscribers.byBU(businessUnitId, section, searchParams))
        .pipe(
          mergeMap(res =>
            of(
              actions.fetchSubscribersByBusinessUnitSuccess(res),
              pageActions.setPagePagination(extractPaginationValues(res))
            )
          ),
          catchError(err => {
            const error = getResponseError(err);

            return of(actions.fetchSubscribersByBusinessUnitFailed(error));
          })
        );
    })
  );

const fetchSubscriberByIdEpic = (action$: ActionsObservable<AnyAction>) =>
  action$.pipe(
    filter(actions.fetchSubscriberByIdInit.match),
    mergeMap(({ payload: { id, businessUnitId, notify } }) =>
      http.getJSON<Subscriber>(api.subscribers.byId(businessUnitId, id)).pipe(
        mergeMap(subscriber => {
          const epicActions: AnyAction[] = [pageActions.setPageFound(), actions.fetchSubscriberByIdSuccess(subscriber)];

          if (notify) {
            epicActions.push(
              notificationsActions.enqueue({
                message: 'Subscriber general info updated',
                options: {
                  variant: 'success',
                },
              })
            );
          }

          return of(...epicActions);
        }),
        catchError(err => {
          const error = getResponseError(err);

          return of(actions.fetchSubscriberByIdFailed(error), pageActions.setPageNotFound());
        })
      )
    )
  );

const fetchSubscriberRoutesEpic = (action$: ActionsObservable<AnyAction>) =>
  action$.pipe(
    filter(actions.fetchSubscriberRoutesInit.match),
    mergeMap(({ payload: { id, businessUnitId, notify } }) =>
      http.getJSON<UnitRoute[]>(api.subscribers.routes(businessUnitId, id)).pipe(
        mergeMap(routes => {
          const epicActions: AnyAction[] = [actions.fetchSubscriberRoutesSuccess({ routes, id, businessUnitId })];

          if (notify) {
            epicActions.push(
              notificationsActions.enqueue({
                message: 'Subscriber routes updated',
                options: {
                  variant: 'success',
                },
              })
            );
          }

          return of(...epicActions);
        })
      )
    ),
    catchError(err => {
      const error = getResponseError(err);

      return of(actions.fetchSubscriberRoutesFailed(error));
    })
  );

export const fetchSubscriberInfo = (action$: ActionsObservable<AnyAction>) =>
  action$.pipe(
    filter(actions.fetchSubscriberPartialInfoInit.match),
    mergeMap(({ payload: { id, businessUnitId, section } }) =>
      http.getJSON<Partial<Subscriber>>(api.subscribers.info(businessUnitId, id, section)).pipe(
        mergeMap(info =>
          of(
            actions.fetchSubscriberPartialInfoSuccess({
              id,
              businessUnitId,
              section,
              info: info || {},
            })
          )
        ),
        catchError(err =>
          of(
            actions.fetchSubscriberPartialInfoFailed({
              section,
              error: getResponseError(err),
            })
          )
        )
      )
    )
  );

export const requestSubscriberData = (action$: ActionsObservable<AnyAction>) =>
  action$.pipe(
    filter(actions.requestSubscriberDataInit.match),
    mergeMap(({ payload: { id, businessUnitId, section, reportingRoute } }) =>
      http.post(api.subscribers.info(businessUnitId, id, section), reportingRoute).pipe(
        mergeMap(() => {
          const messageParams = section === 'routing-tables' ? 'peers' : section;
          return of(
            actions.requestSubscriberDataSuccess({
              section,
            }),
            notificationsActions.enqueue({
              message: `Subscriber ${messageParams} info requested`,
              options: { variant: 'success' },
            })
          );
        }),
        catchError(err => of(actions.requestSubscriberDataFailed({ section, error: getResponseError(err) })))
      )
    )
  );

const fetchSubscriberSettingsEpic = (action$: ActionsObservable<AnyAction>) =>
  action$.pipe(
    filter(actions.fetchSubscriberSettingsInit.match),
    mergeMap(({ payload: { id, businessUnitId, section } }) =>
      http.getJSON(api.subscribers.settings(businessUnitId, id, section)).pipe(
        mergeMap(values => of(actions.fetchSubscriberSettingsSuccess({ businessUnitId, id, section, values }))),
        catchError(err => of(actions.fetchSubscriberSettingsFailed({ section, error: getResponseError(err) })))
      )
    )
  );

const fetchSubscriberSettingsDefaultEpic = (action$: ActionsObservable<AnyAction>) =>
  action$.pipe(
    filter(actions.fetchSubscriberSettingsDefaultInit.match),
    mergeMap(({ payload: { id, businessUnitId, section } }) =>
      http
        .getJSON(
          section !== 'timing'
            ? api.subscribers.settingsDefault(businessUnitId, id, section)
            : api.subscribers.settingsTimingDefault
        )
        .pipe(
          mergeMap(values =>
            of(actions.fetchSubscriberSettingsDefaultSuccess({ businessUnitId, id, section, values }))
          ),
          catchError(err => of(actions.fetchSubscriberSettingsDefaultFailed({ section, error: getResponseError(err) })))
        )
    )
  );

const requestSubscriberSettingsEpic = (action$: ActionsObservable<AnyAction>) =>
  action$.pipe(
    filter(actions.requestSubscriberSettingsInit.match),
    mergeMap(({ payload: { id, businessUnitId, section, reportingRoute } }) =>
      http.post(api.subscribers.settings(businessUnitId, id, section), reportingRoute).pipe(
        mergeMap(() =>
          of(
            actions.requestSubscriberSettingsSuccess({ section }),
            notificationsActions.enqueue({
              message: `Subscriber ${section} settings requested`,
              options: { variant: 'success' },
            })
          )
        ),
        catchError(err => of(actions.requestSubscriberSettingsFailed({ section, error: getResponseError(err) })))
      )
    )
  );

const updateSubscriberSettingsEpic = (action$: ActionsObservable<AnyAction>) =>
  action$.pipe(
    filter(actions.updateSubscriberSettingsInit.match),
    mergeMap(({ payload: { id, businessUnitId, section, values } }) =>
      http.put(api.subscribers.settings(businessUnitId, id, section), values).pipe(
        mergeMap(() => {
          if (section !== 'supervision') {
            return of(
              actions.updateSubscriberSettingsSuccess({ section }),
              actions.fetchSubscriberSettingsSuccess({ businessUnitId, id, section, values }),
              actions.fetchSubscriberSettingsInit({ id, businessUnitId, section }),
              notificationsActions.enqueue({
                message: `Subscriber ${section} settings updated`,
                options: { variant: 'success' },
              })
            );
          }

          return of(
            actions.updateSubscriberSettingsSuccess({ section }),
            actions.fetchSubscriberSettingsSuccess({ businessUnitId, id, section, values }),
            notificationsActions.enqueue({
              message: `Subscriber ${section} settings updated`,
              options: { variant: 'success' },
            })
          );
        }),
        catchError(err => of(actions.updateSubscriberSettingsFailed({ section, error: getResponseError(err) })))
      )
    )
  );

const updateSubscriberDetailsInfoEpic = (action$: ActionsObservable<AnyAction>) =>
  action$.pipe(
    filter(actions.updateSubscriberDetailsInfoInit.match),
    mergeMap(({ payload: { id, businessUnitId, values } }) =>
      http.patch(api.subscribers.byId(businessUnitId, id), values).pipe(
        mergeMap(res =>
          of(
            actions.updateSubscriberDetailsSuccess(getResponsePayload(res)),
            notificationsActions.enqueue({
              message: 'Subscriber detail info updated',
              options: { variant: 'success' },
            })
          )
        ),
        catchError(err => {
          const error = getResponseError(err);

          return of(actions.updateSubscriberDetailsFailed({ error }));
        })
      )
    )
  );

const updateSubscriberSettingsModeEpic = (action$: ActionsObservable<AnyAction>) =>
  action$.pipe(
    filter(actions.updateSubscriberSettingsModeInit.match),
    mergeMap(({ payload: { id, businessUnitId, mode, values, fieldName } }) =>
      http.put(api.subscribers.settingsMode(businessUnitId, id, mode), values).pipe(
        mergeMap(() =>
          of(
            actions.updateSubscriberSettingsModeSuccess({ id, businessUnitId, mode, values, fieldName }),
            notificationsActions.enqueue({
              message: `Subscriber ${mode} mode updated`,
              options: { variant: 'success' },
            })
          )
        ),
        catchError(err => of(actions.updateSubscriberSettingsModeFailed({ mode, error: getResponseError(err) })))
      )
    )
  );

const requestSubscriberRemoteResetEpic = (action$: ActionsObservable<AnyAction>) =>
  action$.pipe(
    filter(actions.requestSubscriberRemoteResetInit.match),
    mergeMap(({ payload: { id, businessUnitId } }) =>
      http.post(api.subscribers.settingsRemoteReset(businessUnitId, id)).pipe(
        mergeMap(() =>
          of(
            actions.requestSubscriberRemoteResetSuccess(),
            notificationsActions.enqueue({
              message: 'Subscriber remote reset',
              options: { variant: 'success' },
            })
          )
        ),
        catchError(err => of(actions.requestSubscriberRemoteResetFailed({ error: getResponseError(err) })))
      )
    )
  );

const updateSubscriberRFEpic = (action$: ActionsObservable<AnyAction>) =>
  action$.pipe(
    filter(actions.updateSubscriberRFInit.match),
    mergeMap(({ payload: { id, businessUnitId, path } }) =>
      http.post(api.subscribers.settingsRf(businessUnitId, id, path)).pipe(
        mergeMap(() =>
          of(
            actions.updateSubscriberRFSuccess(),
            actions.fetchSubscriberSettingsInit({ id, businessUnitId, section: 'rf-settings' }),
            notificationsActions.enqueue({
              message: 'Subscriber RF Setting updated',
              options: { variant: 'success' },
            })
          )
        ),
        catchError(err => {
          const error = getResponseError(err);

          return of(actions.updateSubscriberRFFailed({ error }));
        })
      )
    )
  );

const fetchSubscriberMessages = (actions$: ActionsObservable<AnyAction>) =>
  actions$.pipe(
    filter(actions.fetchSubscriberMessagesInit.match),
    mergeMap(({ payload: { businessUnitId, id } }) =>
      http.getJSON<SubscriberMessage[]>(api.subscribers.messages(businessUnitId, id)).pipe(
        mergeMap(messages => of(actions.fetchSubscriberMessagesSuccess({ messages, businessUnitId, id }))),
        catchError(err => of(actions.fetchSubscriberMessagesFailed(getResponseError(err))))
      )
    )
  );

const sendSubscriberMessage = (actions$: ActionsObservable<AnyAction>) =>
  actions$.pipe(
    filter(actions.sendSubscriberMessageInit.match),
    mergeMap(({ payload: { businessUnitId, id, message, reportingRoute } }) =>
      http.post(api.subscribers.messages(businessUnitId, id), { message, reportingRoute }).pipe(
        mergeMap(() =>
          of(
            actions.sendSubscriberMessageSuccess(),
            notificationsActions.enqueue({
              message: 'Subscriber Message sent',
              options: { variant: 'success' },
            })
          )
        ),
        catchError(err => of(actions.sendSubscriberMessageFailed(getResponseError(err))))
      )
    )
  );

const deleteSubscriberEpic = (action$: ActionsObservable<AnyAction>) =>
  action$.pipe(
    filter(actions.deleteSubscriberInit.match),
    mergeMap(({ payload: { businessUnitId, id, backTo = appRoutes.subscribers } }) =>
      http.delete(api.subscribers.byId(businessUnitId, id)).pipe(
        mergeMap(() =>
          of(
            actions.deleteSubscriberSuccess({ id, businessUnitId }),
            push(backTo),
            notificationsActions.enqueue({
              message: `Subscriber ID ${intToHex(id)} has been deleted`,
              options: { variant: 'success' },
            })
          )
        ),
        catchError(err => {
          const error = getResponseError(err);

          return of(actions.deleteSubscriberFailed({ error }));
        })
      )
    )
  );

const fetchSubscriberZonesConfigurationEpic = (action$: ActionsObservable<AnyAction>) =>
  action$.pipe(
    filter(actions.fetchSubscriberZonesConfigurationInit.match),
    mergeMap(({ payload: { id, businessUnitId, notify = false } }) =>
      http.getJSON<SubscriberZonesConfiguration[]>(api.subscribers.zonesConfiguration(businessUnitId, id)).pipe(
        mergeMap(zones => {
          const epicActions: AnyAction[] = [
            actions.fetchSubscriberZonesConfigurationSuccess({
              id,
              businessUnitId,
              zones,
            }),
          ];

          if (notify) {
            epicActions.push(
              notificationsActions.enqueue({
                message: 'Subscriber zones configuration updated',
                options: {
                  variant: 'success',
                },
              })
            );
          }

          return of(...epicActions);
        }),
        catchError(err => of(actions.fetchSubscriberZonesConfigurationFailed(getResponseError(err))))
      )
    )
  );

const updateSubscriberZonesConfigurationEpic = (action$: ActionsObservable<AnyAction>) =>
  action$.pipe(
    filter(actions.updateSubscriberZonesConfigurationInit.match),
    mergeMap(({ payload: { id, businessUnitId, zoneProgramingDtoList } }) =>
      http.post(api.subscribers.zonesConfiguration(businessUnitId, id), { zoneProgramingDtoList }).pipe(
        mergeMap(res =>
          of(
            actions.updateSubscriberZonesConfigurationSuccess(),
            notificationsActions.enqueue({
              message: 'Update subscriber zones configuration requested',
              options: {
                variant: 'success',
              },
            })
          )
        ),
        catchError(err => of(actions.updateSubscriberZonesConfigurationFailed(getResponseError(err))))
      )
    )
  );

const activateSubscriberEpic = (action$: ActionsObservable<AnyAction>) =>
  action$.pipe(
    filter(actions.activateSubscriberInit.match),
    mergeMap(({ payload: { businessUnitId, id, status } }) => {
      const active = status === 'INACTIVE' ? 'ACTIVE' : 'INACTIVE';
      const params = querystring.stringify({ active });

      return http.post(api.subscribers.activate(businessUnitId, id, params)).pipe(
        mergeMap(() =>
          of(actions.activateSubscriberSuccess(), actions.fetchSubscriberByIdInit({ businessUnitId, id }))
        ),
        catchError(err => {
          const error = getResponseError(err);

          return of(actions.activateSubscriberFailed(error));
        })
      );
    })
  );

const fetchSubscribersIdsEpic = (action$: ActionsObservable<AnyAction>) =>
  action$.pipe(
    filter(action => actions.fetchSubscribersIdsInit.match(action)),
    mergeMap(({ payload }) => {
      const searchParams = getPaginationQueryParams(payload);

      return http
        .getJSON<PaginationResponse<{ id: number; unitType: string }[]>>(
          api.subscribers.subscribersIds(payload.businessUnitId, searchParams)
        )
        .pipe(
          mergeMap(res =>
            of(actions.fetchSubscribersIdsSuccess(res), pageActions.setPagePagination(extractPaginationValues(res)))
          ),
          catchError(err => {
            const error = getResponseError(err);

            return of(actions.fetchSubscribersIdsFailed(error));
          })
        );
    })
  );

const fetchSubscriberHistoryEpic = (action$: ActionsObservable<AnyAction>) =>
  action$.pipe(
    filter(action => actions.fetchSubscriberHistoryInit.match(action)),
    mergeMap(({ payload }) => {
      const searchParams = getPaginationQueryParams(payload);

      return http
        .getJSON<PaginationResponse<SubscriberHistory>>(
          api.subscribers.eventHistory.all(payload.businessUnitId, payload.id, payload.period, searchParams)
        )
        .pipe(
          mergeMap(res =>
            of(
              actions.fetchSubscriberHistorySuccess({ content: res.content, id: payload.id }),
              pageActions.setPagePagination(extractPaginationValues(res)))
          ),
          catchError(err => {
            const error = getResponseError(err);

            return of(actions.fetchSubscriberHistoryFailed(error));
          })
        );
    })
  );

const exportSubscriberEventHistoryEpic = (action$: ActionsObservable<AnyAction>) =>
  action$.pipe(
    filter(actions.exportSubscriberEventHistoryInit.match),
    mergeMap(({ payload: { buId, id, period } }) =>
      http
        .call({
          method: 'GET',
          url: api.subscribers.eventHistory.export(buId, id, period),
          responseType: 'blob' as 'json',
        })
        .pipe(
          mergeMap(res => {
            const fileName = `${period}_Event_History.csv`;
            saveFile(res.response, fileName);
            return of(
              actions.exportSubscriberEventHistorySuccess(),
              notificationsActions.enqueue({
                message: `${fileName} has been exported`,
                options: { variant: 'success' },
              })
            );
          }),
          catchError(err => of(actions.exportSubscriberEventHistoryFailed(getResponseError(err))))
        )
    )
  );

const ahjreportSubscriberEpic = (action$: ActionsObservable<AnyAction>) =>
  action$.pipe(
    filter(actions.ahjreportSubscriberInit.match),
    mergeMap(({ payload: { businessUnitId, id, emailAddress } }) =>
      http.post(api.subscribers.ahjReportEmail(businessUnitId, id), { emailAddress }).pipe(
        mergeMap(() =>
          of(
            actions.ahjreportSubscriberSuccess(),
            notificationsActions.enqueue({
              message: 'AHJ report request is sent',
              options: { variant: 'success' },
            })
          )
        ),
        catchError(err => {
          const error = getResponseError(err);
          return of(actions.ahjreportSubscriberFailed(error));
        })
      )
    )
  );

const fetchSubscriberDealersEpic = (action$: ActionsObservable<AnyAction>) =>
  action$.pipe(
    filter(actions.fetchAssignedDealersInit.match),
    mergeMap(({ payload: { id, businessUnitId } }) =>
      http.getJSON<AssignedDealer[]>(api.subscribers.dealers(businessUnitId, id)).pipe(
        mergeMap(dealers => of(actions.fetchAssignedDealersSuccess({ dealers, id, businessUnitId }))),
        catchError(err => {
          const error = getResponseError(err);

          return of(actions.fetchAssignedDealersFailed(error));
        })
      )
    )
  );

const fetchSubscriberNotificationsEpic = (action$: ActionsObservable<AnyAction>) =>
  action$.pipe(
    filter(actions.fetchSubscriberNotificationsInit.match),
    mergeMap(({ payload: { id, businessUnitId } }) =>
      http.getJSON<SubscriberNotifications>(api.subscribers.notifications(businessUnitId, id)).pipe(
        mergeMap(notifications => of(actions.fetchSubscriberNotificationsSuccess({ notifications, id }))),
        catchError(err => {
          const error = getResponseError(err);

          return of(actions.fetchSubscriberNotificationsFailed(error));
        })
      )
    )
  );

const updateSubscriberNotificationsEpic = (action$: ActionsObservable<AnyAction>) =>
  action$.pipe(
    filter(actions.updateSubscriberNotificationsInit.match),
    mergeMap(({ payload: { id, businessUnitId, values } }) =>
      http.put(api.subscribers.notifications(businessUnitId, id), values).pipe(
        mergeMap(res =>
          of(
            actions.fetchSubscriberNotificationsSuccess({ notifications: getResponsePayload(res), id }),
            notificationsActions.enqueue({
              message: 'Subscriber notification updated',
              options: { variant: 'success' },
            })
          )
        ),
        catchError(err => {
          const error = getResponseError(err);

          return of(actions.fetchSubscriberNotificationsFailed(error));
        })
      )
    )
  );

const deleteInactiveSubscriberEpic = (action$: ActionsObservable<AnyAction>) =>
  action$.pipe(
    filter(actions.deleteInactiveSubscriberInit.match),
    mergeMap(({ payload: { businessUnitId, id, backTo, pagination, section } }) =>
      http.delete(api.subscribers.byId(businessUnitId, id)).pipe(
        mergeMap(() =>
          of(
            actions.deleteInactiveSubscriberSuccess({ id, businessUnitId }),
            actions.fetchSubscribersByBusinessUnitInit({ pagination, businessUnitId, section }),
            notificationsActions.enqueue({
              message: `Subscriber ID ${intToHex(id)} has been deleted`,
              options: { variant: 'success' },
            })
          )
        ),
        catchError(err => {
          const error = getResponseError(err);

          return of(actions.deleteInactiveSubscriberFailed({ error }));
        })
      )
    )
  );

const fetchSubscriberPeersEpic = (action$: ActionsObservable<AnyAction>) =>
  action$.pipe(
    filter(actions.fetchSubscriberPeersInit.match),
    mergeMap(({ payload: { id, businessUnitId } }) =>
      http.getJSON<SubscriberPeers>(api.subscribers.peers(businessUnitId, id)).pipe(
        mergeMap(peers => of(actions.fetchSubscriberPeersSuccess({ peers, id }))),
        catchError(err => {
          const error = getResponseError(err);

          return of(actions.fetchSubscriberPeersFailed(error));
        })
      )
    )
  );

const nctModeSubscriberEpic = (action$: ActionsObservable<AnyAction>) =>
  action$.pipe(
    filter(actions.nctModeSubscriberInit.match),
    mergeMap(({ payload: { pagination, businessUnitId, id, mode, fromBU } }) =>
      http.post(api.subscribers.nctChangeMode(businessUnitId, id, mode)).pipe(
        mergeMap(() => {
          const epicActions: AnyAction[] = [
            actions.nctModeSubscriberSuccess({ mode }),
            notificationsActions.enqueue({
              message: `Subscriber ID ${intToHex(id)} has been set to ${mode} mode`,
              options: { variant: 'success' },
            }),
          ];

          epicActions.push(
            fromBU
              ? actions.fetchSubscribersByBusinessUnitInit({
                pagination: pagination as Pagination,
                businessUnitId,
                section: 'nct',
              })
              : actions.fetchSubscriberByIdInit({ id, businessUnitId, notify: false })
          );

          return of(...epicActions);
        }),
        catchError(err => {
          const error = getResponseError(err);
          return of(actions.nctModeSubscriberFailed(error));
        })
      )
    )
  );

const fetchInactiveSubscriberDependencyEpic = (action$: ActionsObservable<AnyAction>) =>
  action$.pipe(
    filter(action => actions.fetchInactiveSubscriberDependencyInit.match(action)),
    mergeMap(({ payload: { id, businessUnitId } }) =>
      http.getJSON<UnitDependent[]>(api.subscribers.inactiveDependency(businessUnitId, id)).pipe(
        mergeMap(dependency => of(actions.fetchInactiveSubscriberDependencySuccess({ dependency, id }))),
        catchError(err => {
          const error = getResponseError(err);

          return of(
            actions.fetchInactiveSubscriberDependencyFailed(),
            notificationsActions.enqueue({
              message: error.message,
              options: {
                variant: 'error',
              },
            })
          );
        })
      )
    )
  );

const fetchSubscriberControlRelayEpic = (actions$: ActionsObservable<AnyAction>) =>
  actions$.pipe(
    filter(actions.fetchSubscriberControlRelayInit.match),
    mergeMap(({ payload: { businessUnitId, id } }) =>
      http.getJSON<ControlRelay>(api.subscribers.controlRelaySubscribers(businessUnitId, id)).pipe(
        mergeMap(res => of(actions.fetchSubscriberControlRelaySuccess({ controlRelay: res, id }))),
        catchError(err => of(actions.fetchSubscriberControlRelayFailed(getResponseError(err))))
      )
    )
  );
export const createTestModeSubscriberEpic = (action$: ActionsObservable<AnyAction>) =>
  action$.pipe(
    filter(actions.createTestModeSubscriberInit.match),
    mergeMap(({ payload: { id, businessUnitId, timePeriod } }) =>
      http.post(api.subscribers.testMode(businessUnitId, id), { timePeriod }).pipe(
        mergeMap(() =>
          of(
            actions.createTestModeSubscriberSuccess(),
            actions.fetchSubscriberByIdInit({ id, businessUnitId }),
            notificationsActions.enqueue({
              message: `Subscriber ${intToHex(id)} Test Mode is enabled`,
              options: { variant: 'success' },
            })
          )
        ),
        catchError(err => of(actions.createTestModeSubscriberFailed(getResponseError(err))))
      )
    )
  );

export const updateTestModeSubscriberEpic = (action$: ActionsObservable<AnyAction>) =>
  action$.pipe(
    filter(actions.updateTestModeSubscriberInit.match),
    mergeMap(({ payload: { id, businessUnitId, timePeriod } }) =>
      http.put(api.subscribers.testMode(businessUnitId, id), { timePeriod }).pipe(
        mergeMap(() =>
          of(
            actions.updateTestModeSubscriberSuccess(),
            actions.fetchSubscriberByIdInit({ id, businessUnitId }),
            notificationsActions.enqueue({
              message: `Subscriber ${intToHex(id)} Test Mode time period is updated`,
              options: { variant: 'success' },
            })
          )
        ),
        catchError(err => of(actions.updateTestModeSubscriberFailed(getResponseError(err))))
      )
    )
  );

export const deleteTestModeSubscriberEpic = (action$: ActionsObservable<AnyAction>) =>
  action$.pipe(
    filter(actions.deleteTestModeSubscriberInit.match),
    mergeMap(({ payload: { id, businessUnitId } }) =>
      http.delete(api.subscribers.testMode(businessUnitId, id)).pipe(
        mergeMap(() =>
          of(
            actions.deleteTestModeSubscriberSuccess(),
            actions.fetchSubscriberByIdInit({ id, businessUnitId }),
            notificationsActions.enqueue({
              message: `Subscriber ${intToHex(id)} Test Mode is disabled`,
              options: { variant: 'success' },
            })
          )
        ),
        catchError(err => of(actions.deleteTestModeSubscriberFailed(getResponseError(err))))
      )
    )
  );

const importFACPConfigurationSubscribersEpic = (
  action$: ActionsObservable<AnyAction>,
  state$: StateObservable<AES.RootState>
) =>
  action$.pipe(
    filter(actions.importFACPConfigurationSubscribersInit.match),
    mergeMap(({ payload: { businessUnitId, id, files, skipFirstRow, pagination } }) => {
      const formData = new FormData();

      files.forEach(file => formData.append('file', file, file.name));

      return ajax({
        method: 'POST',
        url: api.subscribers.facp.import(businessUnitId, id, skipFirstRow),
        body: formData,
        headers: {
          Authorization: `Bearer ${state$.value.auth.accessToken}`,
        },
      }).pipe(
        delay(300),
        mergeMap(res =>
          of(
            actions.importFACPConfigurationSubscribersSuccess(),
            actions.fetchSubscriberFACPUnitZonesInit({ businessUnitId, id, pagination }),
            notificationsActions.enqueue({
              message: `${res.response.result}`,
              options: { variant: 'success' },
            })
          )
        ),
        catchError(err => of(actions.importFACPConfigurationSubscribersFailed(getResponseError(err))))
      );
    })
  );

const exportFACPZoneConfigurationEpic = (action$: ActionsObservable<AnyAction>) =>
  action$.pipe(
    filter(actions.exportFACPZoneConfigurationInit.match),
    mergeMap(({ payload: { businessUnitId, id } }) =>
      http
        .call({
          method: 'GET',
          url: api.subscribers.facp.export(businessUnitId, id),
          responseType: 'blob' as 'json',
        })
        .pipe(
          mergeMap(res => {
            const fileName = `Subscriber-${intToHex(id)}_FACPZoneConfiguration.csv`;
            saveFile(res.response, fileName);
            return of(
              actions.exportFACPZoneConfigurationSuccess(),
              notificationsActions.enqueue({
                message: `${fileName} has been exported`,
                options: { variant: 'success' },
              })
            );
          }),
          catchError(err => of(actions.exportFACPZoneConfigurationFailed(getResponseError(err))))
        )
    )
  );

export const fetchSubscriberFACPUnitZonesEpic = (action$: ActionsObservable<AnyAction>) =>
  action$.pipe(
    filter(actions.fetchSubscriberFACPUnitZonesInit.match),
    mergeMap(({ payload }) => {
      const searchParams = getPaginationQueryParams(payload);
      const id = payload.id;

      return http
        .getJSON<PaginationResponse<FACPZones>>(
          api.subscribers.facp.zoneEvents(payload.businessUnitId, id, searchParams)
        )
        .pipe(
          mergeMap(res =>
            of(
              actions.fetchSubscriberFACPUnitZonesSuccess({ facpZones: res.content, id }),
              pageActions.setPagePagination(extractPaginationValues(res))
            )
          ),
          catchError(err => of(actions.fetchSubscriberFACPUnitZonesFailed(getResponseError(err))))
        );
    })
  );

const deleteSubscriberFACPZoneEventEpic = (action$: ActionsObservable<AnyAction>) =>
  action$.pipe(
    filter(actions.deleteSubscriberFACPZoneEventInit.match),
    mergeMap(({ payload: { businessUnitId, id, zoneEventId, pagination } }) =>
      http.delete(api.subscribers.facp.byZoneEventId(businessUnitId, id, zoneEventId)).pipe(
        mergeMap(() =>
          of(
            actions.deleteSubscriberFACPZoneEventSuccess(),
            actions.fetchSubscriberFACPUnitZonesInit({ businessUnitId, id, pagination }),
            notificationsActions.enqueue({
              message: `FACP zone ID ${zoneEventId} has been deleted`,
              options: { variant: 'success' },
            })
          )
        ),
        catchError(err => of(actions.deleteSubscriberFACPZoneEventFailed(getResponseError(err))))
      )
    )
  );

const addSubscriberFACPZoneEventEpic = (action$: ActionsObservable<AnyAction>) =>
  action$.pipe(
    filter(actions.addSubscriberFACPZoneEventInit.match),
    mergeMap(({ payload: { businessUnitId, id, values, pagination } }) =>
      http.post(api.subscribers.facp.zoneEvents(businessUnitId, id, ''), values).pipe(
        mergeMap(() =>
          of(
            actions.addSubscriberFACPZoneEventSuccess(),
            actions.fetchSubscriberFACPUnitZonesInit({ businessUnitId, id, pagination }),
            notificationsActions.enqueue({
              message: 'FACP zone has been added',
              options: { variant: 'success' },
            })
          )
        ),
        catchError(err => of(actions.addSubscriberFACPZoneEventFailed(getResponseError(err))))
      )
    )
  );

const updateSubscriberFACPZoneEventEpic = (action$: ActionsObservable<AnyAction>) =>
  action$.pipe(
    filter(actions.updateSubscriberFACPZoneEventInit.match),
    mergeMap(({ payload: { businessUnitId, id, zoneEventId, values, pagination } }) =>
      http.put(api.subscribers.facp.byZoneEventId(businessUnitId, id, zoneEventId), values).pipe(
        mergeMap(() =>
          of(
            actions.updateSubscriberFACPZoneEventSuccess(),
            actions.fetchSubscriberFACPUnitZonesInit({ businessUnitId, id, pagination }),
            notificationsActions.enqueue({
              message: `FACP zone ID ${zoneEventId} has been updated`,
              options: { variant: 'success' },
            })
          )
        ),
        catchError(err => of(actions.updateSubscriberFACPZoneEventFailed(getResponseError(err))))
      )
    )
  );

export const fetchZoneNotificationsEpic = (action$: ActionsObservable<AnyAction>) =>
  action$.pipe(
    filter(actions.fetchZoneNotificationsInit.match),
    mergeMap(({ payload }) => {
      const id = payload.id;

      return http
        .getJSON<ZoneNotifications>(
          api.subscribers.facp.notifications(payload.businessUnitId, id)).pipe(
          mergeMap(res =>
            of(
              actions.fetchZoneNotificationsSuccess({ zoneNotifications: res, id }),
            )
          ),
          catchError(err => of(actions.fetchZoneNotificationsFailed(getResponseError(err))))
        );
    })
  );

const updateZoneNotificationsEpic = (action$: ActionsObservable<AnyAction>) =>
  action$.pipe(
    filter(actions.updateZoneNotificationsInit.match),
    mergeMap(({ payload: { id, businessUnitId, values } }) =>
      http.put(api.subscribers.facp.notifications(businessUnitId, id), values).pipe(
        mergeMap(res =>
          of(
            actions.fetchZoneNotificationsSuccess({ zoneNotifications: getResponsePayload(res), id }),
            notificationsActions.enqueue({
              message: 'Subscriber Zone Notifications updated',
              options: { variant: 'success' },
            })
          )
        ),
        catchError(err => {
          const error = getResponseError(err);

          return of(actions.fetchZoneNotificationsFailed(error));
        })
      )
    )
  );

const exportFilteredSubscribersEpic = (action$: ActionsObservable<AnyAction>) =>
  action$.pipe(
    filter(actions.exportFilteredSubscribersInit.match),
    mergeMap(({ payload }) => {
      const filter = mapFilters(payload);

      return http
        .call({
          method: 'GET',
          url: api.subscribers.export(querystring.stringify(filter)),
          responseType: 'blob' as 'json',
        })
        .pipe(
          mergeMap(res => {
            const fileName = 'Filtered_Subscribers.csv';
            saveFile(res.response, fileName);
            return of(
              actions.exportSubscribersSuccess(),
              notificationsActions.enqueue({
                message: `${fileName} has been exported`,
                options: { variant: 'success' },
              })
            );
          }),
          catchError(err => of(actions.exportSubscribersFailed(getResponseError(err))))
        );
    })
  );

const exportBUSubscribersEpic = (action$: ActionsObservable<AnyAction>) =>
  action$.pipe(
    filter(actions.exportBUSubscribersInit.match),
    mergeMap(({ payload: { businessUnitId, section } }) =>
      http
        .call({
          method: 'GET',
          url: api.subscribers.exportBUSubscribers(businessUnitId, section),
          responseType: 'blob' as 'json',
        })
        .pipe(
          mergeMap(res => {
            const fileName = `Subscribers_${section}.csv`;
            saveFile(res.response, fileName);
            return of(
              actions.exportSubscribersSuccess(),
              notificationsActions.enqueue({
                message: `${fileName} has been exported`,
                options: { variant: 'success' },
              })
            );
          }),
          catchError(err => of(actions.exportSubscribersFailed(getResponseError(err))))
        ))
  );

const fetchSubscriberDependenciesEpic = (action$: ActionsObservable<AnyAction>) =>
  action$.pipe(
    filter(actions.fetchSubscriberDependenciesInit.match),
    mergeMap(({ payload: { buId, subId, period, ...payload } }) => {
      const searchParams = getPaginationQueryParams(payload);

      return http
        .getJSON<PaginationResponse<UnitDependent[]>>(api.subscribers.dependencies(buId, subId, period, searchParams))
        .pipe(
          mergeMap(res =>
            of(
              actions.fetchSubscriberDependenciesSuccess({ ...res, subId }),
              pageActions.setPagePagination(extractPaginationValues(res))
            )
          ),
          catchError(err => {
            const error = getResponseError(err);

            return of(actions.fetchSubscriberDependenciesFailed(error));
          })
        );
    })
  );

const fetchTestModeSubsIdsEpic = (actions$: ActionsObservable<AnyAction>) =>
  actions$.pipe(
    filter(actions.fetchTestModeSubsIdsInit.match),
    mergeMap(() =>
      http.getJSON<TestModeUnitID[]>(api.subscribers.testModeSubsIds).pipe(
        mergeMap(res => of(actions.fetchTestModeSubsIdsSuccess(res))),
        catchError(err => of(actions.fetchTestModeSubsIdsFailed(getResponseError(err))))
      )
    )
  );

const fetchSubscriberCustomNotesEpic = (action$: ActionsObservable<AnyAction>) =>
  action$.pipe(
    filter(actions.fetchSubscriberCustomNotesInit.match),
    mergeMap(({ payload: { buId, id } }) =>
      http.getJSON<CustomNote[]>(api.subscribers.customNote.list(buId, id)).pipe(
        mergeMap(res => of(actions.fetchSubscriberCustomNotesSuccess({ id, buId, notes: res }))),
        catchError(err => {
          const error = getResponseError(err);

          return of(actions.fetchSubscriberCustomNotesFailed(error));
        })
      )
    )
  );

const createSubscriberCustomNoteEpic = (action$: ActionsObservable<AnyAction>) =>
  action$.pipe(
    filter(actions.createSubscriberCustomNoteInit.match),
    mergeMap(({ payload: { id, buId, values } }) =>
      http.post(api.subscribers.customNote.list(buId, id), values).pipe(
        mergeMap(res =>
          of(
            actions.fetchSubscriberCustomNotesInit({ buId, id }),
            notificationsActions.enqueue({
              message: 'Subscriber Custom Note created',
              options: { variant: 'success' },
            })
          )
        ),
        catchError(err => {
          const error = getResponseError(err);

          return of(actions.fetchSubscriberCustomNotesFailed(error));
        })
      )
    )
  );

const updateSubscriberCustomNoteEpic = (action$: ActionsObservable<AnyAction>) =>
  action$.pipe(
    filter(actions.updateSubscriberCustomNoteInit.match),
    mergeMap(({ payload: { id, buId, noteId, values } }) =>
      http.patch(api.subscribers.customNote.byId(buId, id, noteId), values).pipe(
        mergeMap(res =>
          of(
            actions.fetchSubscriberCustomNotesSuccess({ id, buId, notes: getResponsePayload(res) }),
            actions.fetchSubscriberCustomNotesInit({ buId, id }),
            notificationsActions.enqueue({
              message: 'Subscriber Custom Note updated',
              options: { variant: 'success' },
            })
          )
        ),
        catchError(err => {
          const error = getResponseError(err);

          return of(actions.fetchSubscriberCustomNotesFailed(error));
        })
      )
    )
  );

const deleteSubscriberCustomNoteEpic = (action$: ActionsObservable<AnyAction>) =>
  action$.pipe(
    filter(actions.deleteSubscriberCustomNoteInit.match),
    mergeMap(({ payload: { buId, id, noteId } }) =>
      http.delete(api.subscribers.customNote.byId(buId, id, noteId)).pipe(
        mergeMap(() =>
          of(
            actions.deleteSubscriberCustomNoteSuccess({ id, buId }),
            actions.fetchSubscriberCustomNotesInit({ buId, id }),
            notificationsActions.enqueue({
              message: 'Custom Note has been deleted',
              options: { variant: 'success' },
            })
          )
        ),
        catchError(err => {
          const error = getResponseError(err);

          return of(actions.deleteSubscriberCustomNoteFailed(error));
        })
      )
    )
  );

const fetchReverseGeoCodingEpic = (action$: ActionsObservable<AnyAction>) =>
  action$.pipe(
    filter(actions.fetchReverseGeoCodingInit.match),
    mergeMap(({ payload: { businessUnitId, id } }) =>
      http.getJSON<ReverseGeoCoding[]>(api.subscribers.location(businessUnitId, id)).pipe(
        mergeMap(values => {
          const epicActions: AnyAction[] = [actions.fetchReverseGeoCodingSuccess({ businessUnitId, id, values })];
          if (!values?.length) {
            epicActions.push(
              notificationsActions.enqueue({
                message: 'Reverse GeoCoding not available',
                options: {
                  variant: 'error',
                },
              })
            );
          }
          return of(...epicActions);
        }),
        catchError(err => {
          const error = getResponseError(err);
          return of(actions.fetchReverseGeoCodingFailed(error));
        })
      )
    )
  );

export const epics = combineEpics(
  fetchSubscribersEpic,
  fetchSubscriberByIdEpic,
  fetchSubscribersByBusinessUnitEpic,
  fetchSubscriberRoutesEpic,
  fetchSubscriberInfo,
  requestSubscriberData,
  fetchSubscriberSettingsEpic,
  fetchSubscriberSettingsDefaultEpic,
  requestSubscriberSettingsEpic,
  updateSubscriberSettingsEpic,
  updateSubscriberSettingsModeEpic,
  requestSubscriberRemoteResetEpic,
  updateSubscriberRFEpic,
  updateSubscriberDetailsInfoEpic,
  deleteSubscriberEpic,
  fetchSubscriberZonesConfigurationEpic,
  updateSubscriberZonesConfigurationEpic,
  fetchSubscriberMessages,
  sendSubscriberMessage,
  activateSubscriberEpic,
  fetchSubscribersIdsEpic,
  fetchSubscriberHistoryEpic,
  exportSubscriberEventHistoryEpic,
  ahjreportSubscriberEpic,
  fetchSubscriberDealersEpic,
  fetchSubscriberNotificationsEpic,
  updateSubscriberNotificationsEpic,
  deleteInactiveSubscriberEpic,
  fetchSubscriberPeersEpic,
  nctModeSubscriberEpic,
  fetchInactiveSubscriberDependencyEpic,
  fetchSubscriberControlRelayEpic,
  createTestModeSubscriberEpic,
  updateTestModeSubscriberEpic,
  deleteTestModeSubscriberEpic,
  fetchSubscriberFACPUnitZonesEpic,
  deleteSubscriberFACPZoneEventEpic,
  addSubscriberFACPZoneEventEpic,
  updateSubscriberFACPZoneEventEpic,
  importFACPConfigurationSubscribersEpic,
  exportFACPZoneConfigurationEpic,
  fetchZoneNotificationsEpic,
  updateZoneNotificationsEpic,
  exportFilteredSubscribersEpic,
  exportBUSubscribersEpic,
  fetchSubscriberDependenciesEpic,
  fetchTestModeSubsIdsEpic,
  fetchSubscriberCustomNotesEpic,
  createSubscriberCustomNoteEpic,
  updateSubscriberCustomNoteEpic,
  deleteSubscriberCustomNoteEpic,
  fetchReverseGeoCodingEpic
);
export const allEpics = {
  fetchSubscribersEpic,
  fetchSubscriberByIdEpic,
  fetchSubscribersByBusinessUnitEpic,
  fetchSubscriberRoutesEpic,
  fetchSubscriberInfo,
  requestSubscriberData,
  fetchSubscriberSettingsEpic,
  requestSubscriberSettingsEpic,
  fetchSubscriberSettingsDefaultEpic,
  updateSubscriberSettingsEpic,
  updateSubscriberSettingsModeEpic,
  requestSubscriberRemoteResetEpic,
  updateSubscriberRFEpic,
  updateSubscriberDetailsInfoEpic,
  deleteSubscriberEpic,
  fetchSubscriberZonesConfigurationEpic,
  updateSubscriberZonesConfigurationEpic,
  fetchSubscriberMessages,
  sendSubscriberMessage,
  activateSubscriberEpic,
  fetchSubscribersIdsEpic,
  fetchSubscriberHistoryEpic,
  exportSubscriberEventHistoryEpic,
  ahjreportSubscriberEpic,
  fetchSubscriberDealersEpic,
  fetchSubscriberNotificationsEpic,
  updateSubscriberNotificationsEpic,
  deleteInactiveSubscriberEpic,
  fetchSubscriberPeersEpic,
  nctModeSubscriberEpic,
  fetchInactiveSubscriberDependencyEpic,
  fetchSubscriberControlRelayEpic,
  createTestModeSubscriberEpic,
  updateTestModeSubscriberEpic,
  deleteTestModeSubscriberEpic,
  fetchSubscriberFACPUnitZonesEpic,
  deleteSubscriberFACPZoneEventEpic,
  addSubscriberFACPZoneEventEpic,
  updateSubscriberFACPZoneEventEpic,
  importFACPConfigurationSubscribersEpic,
  exportFACPZoneConfigurationEpic,
  fetchZoneNotificationsEpic,
  updateZoneNotificationsEpic,
  exportFilteredSubscribersEpic,
  exportBUSubscribersEpic,
  fetchSubscriberDependenciesEpic,
  fetchTestModeSubsIdsEpic,
  fetchSubscriberCustomNotesEpic,
  createSubscriberCustomNoteEpic,
  updateSubscriberCustomNoteEpic,
  deleteSubscriberCustomNoteEpic,
  fetchReverseGeoCodingEpic
};

declare global {
  namespace AES {
    export interface Actions {
      subscribers: typeof actions;
    }
    export interface RootState {
      subscribers: SubscribersState;
    }

    export interface Selectors {
      subscribers: typeof selectors;
    }
  }
}
