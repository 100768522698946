import {
  format,
  fromUnixTime,
  addMinutes,
  intervalToDuration,
  formatDuration,
  differenceInSeconds,
  formatDistance,
  startOfDay,
  toDate,
} from 'date-fns';
import { utcToZonedTime, format as zoneFormat } from 'date-fns-tz';
import { is } from 'ramda';

import { AM_PM_HOUR_MIN_TIME_FORMAT, DATE_FORMAT, FULL_DAY_FORMAT, HOUR_MIN_TIME_FORMAT, MONTH_DAY_FORMAT, SERVER_DATE_AM_PM_FORMAT, SERVER_DATE_FORMAT } from '~constants';

export const getDate = (date: Date | number, unix = false) => {
  if (typeof date === 'number' && unix) {
    return fromUnixTime(date);
  }

  if (typeof date === 'number' && date.toString().length < 12) {
    return fromUnixTime(date);
  }

  return new Date(date);
};

export const formatDate = (date: Date | number | undefined, unix = false, formatter = DATE_FORMAT, zone = '') => {
  if (!date) {
    return null;
  }

  if (zone) {
    return zoneFormat(utcToZonedTime(getDate(date), zone), formatter, { timeZone: zone });
  }

  return format(getDate(date, unix), formatter);
};

export const serverDateFormat = (date: Date | number | undefined, unix = false, formatter = DATE_FORMAT, zone = '') => {
  const dateFormatter = formatter === DATE_FORMAT ? SERVER_DATE_FORMAT : SERVER_DATE_AM_PM_FORMAT;

  if (!date) {
    return null;
  }

  return formatDate(date, unix, dateFormatter, zone);
};

export const getUTCTime = (localTime: string | Date, dateFormat = 'yyyy/MM/dd HH:mm', prefix = ''): string => {
  try {
    const date = typeof localTime === 'string' ? new Date(localTime) : localTime;
    const utc = format(addMinutes(date, date.getTimezoneOffset()), dateFormat);
    date.toUTCString();

    return prefix ? `${prefix} ${utc}` : utc;
  } catch (err) {
    return '';
  }
};

export const parseTimeValues = (value: string, splitter: string) => value.split(splitter).map(v => parseInt(v));

export const parseUTCTime = (date: string, time: string): string => {
  const [year, month, day] = parseTimeValues(date, '/');
  const [hours, minutes] = parseTimeValues(time, ':');

  const value = new Date();
  value.setUTCFullYear(year, month - 1, day);
  value.setUTCHours(hours, minutes);

  return value.toString();
};

export const mergeDateAndTime = (date: Date | string, time: Date | string): string => {
  const dateValue = new Date(date);
  const timeValue = new Date(time);
  const hours = timeValue.getHours();
  const minutes = timeValue.getMinutes();

  dateValue.setHours(hours, minutes);

  return dateValue.toString();
};

const getTimeValue = (v: number | undefined, size = 2) => {
  let value = `${v}`;

  while (value.length < size) {
    value = `0${value}`;
  }

  return value;
};

export const getElapsedTime = (
  start: number | Date,
  end: number | Date
): { moreThanDayAgo: boolean; value: string } => {
  const diff = differenceInSeconds(end, start);

  if (diff > 60 * 60 * 24) {
    return { moreThanDayAgo: true, value: formatDistance(end, start) };
  }

  const { hours, minutes, seconds } = intervalToDuration({
    start,
    end,
  });

  return { moreThanDayAgo: false, value: `${getTimeValue(hours)}:${getTimeValue(minutes)}:${getTimeValue(seconds)}` };
};

/**
 * Get time duration
 * @param timestamp Date timestamp in seconds
 * @returns Human readable time duration
 */
export const getTimeDuration = (timestamp?: number): string => {
  if (!is(Number, timestamp) || !timestamp) {
    return '';
  }

  return formatDuration(
    intervalToDuration({
      start: 0,
      end: timestamp * 1000,
    })
  );
};

export const convertUnixEpochToTimestamp = (value: number): number => value * 1000;

export const monthAndDayDateFormatter = value => {
  const dateObj = toDate(value);

  const formatDate = format(dateObj, MONTH_DAY_FORMAT);

  return formatDate;
};

export const getStartDayTimestamp = (value: string | Date) => {
  const date = startOfDay(new Date(value));

  return Math.floor(date.getTime() / 1000);
};

export const hoursAndMinutes = (hours: number, minutes: number) => {
  const hoursDisplay = hours > 0 ? hours + (hours === 1 ? ' hour ' : ' hours ') : '';
  const minutesDisplay = minutes > 0 ? minutes + (minutes === 1 ? ' minute' : ' minutes') : '';

  return hoursDisplay + minutesDisplay;
};

export const secondsToMinutes = (value: number) => {
  const days = Math.floor(value / (3600 * 24));
  const hours = Math.floor((value % (3600 * 24)) / 3600);
  const minutes = Math.floor((value % 3600) / 60);
  const daysDisplay = days > 0 ? days + (days === 1 ? ' day ' : ' days ') : '';
  const hoursDisplay = hours > 0 ? hours + (hours === 1 ? ' hour ' : ' hours ') : '';
  const minutesDisplay = minutes > 0 ? minutes + (minutes === 1 ? ' minute' : ' minutes') : '';

  return daysDisplay + hoursDisplay + minutesDisplay;
};

export const hoursAndDays = (totalHours: number) => {
  const hours = totalHours < 24 ? `${totalHours} hours` : '';
  const numberOfDays = Math.floor(totalHours / 24);
  const days = totalHours > 24 ?
    numberOfDays === 1 ? `${numberOfDays} day ` : `${numberOfDays} days `
    + `${Math.floor(totalHours % 24)} hours` : '';

  return days + hours;
};

export const utcDateTimeStringToLocalNumber = (utcString: string | undefined) => {
  if (!utcString || typeof utcString !== 'string') {
    return utcString;
  }

  const offset = new Date().getTimezoneOffset();
  const localDate = addMinutes(new Date(utcString), -offset) as unknown as string;

  return Date.parse(localDate);
};

export const getDayFromTimestamp = (date: number | undefined, unix = false, formatter = FULL_DAY_FORMAT) => {
  if (!date) {
    return null;
  }

  return format(getDate(date, unix), formatter);
};

export const getTimeFromTimestamp = (date: number | undefined, unix = false, timeFormat?: boolean) => {
  const dateFormatter = timeFormat ? HOUR_MIN_TIME_FORMAT : AM_PM_HOUR_MIN_TIME_FORMAT;

  if (!date) {
    return null;
  }

  return format(getDate(date, unix), dateFormatter);
};
