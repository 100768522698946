import { createStyles, Theme } from '@material-ui/core';
import { withStyles, WithStyles } from '@material-ui/styles';
import clsx from 'clsx';
import { memo } from 'react';

import { NavLink } from '~ui-kit';
import { intToHex } from '~utils';

const styles = (theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      position: 'relative',
      justifyContent: 'center',
      borderWidth: 1,
      borderStyle: 'solid',
      borderColor: theme.palette.primary.main,
      color: theme.palette.primary.main,
      borderRadius: 4,
      minWidth: 60,
      marginRight: theme.spacing(1),
      '&:hover': {
        borderColor: theme.palette.greyColor.main,
        color: theme.palette.greyColor.main,
      },
    },
  });

interface RoutingLinkProps extends WithStyles<typeof styles> {
  id?: number;
  name?: string;
  linkTo?: string;
  blank?: boolean;
}

export const RoutingLink = memo(
  withStyles(styles)(({ id, name, classes, linkTo = '', blank }: RoutingLinkProps) => {
    const label = id ? intToHex(id) : name;

    if (!linkTo) {
      return <span className={classes.root}>{label}</span>;
    }

    return (
      <NavLink
        to={linkTo}
        classes={{ root: clsx(classes.root) }}
        className="routing-link"
        target={ blank ? '_blank' : '' }
      >
        {label}
      </NavLink>
    );
  })
);
