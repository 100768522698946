import iconAdd from '~assets/images/icons/add.svg';
import iconArrow from '~assets/images/icons/arrow.svg';
import iconAttention from '~assets/images/icons/attention.svg';
import iconBack from '~assets/images/icons/back.svg';
import iconBurglary from '~assets/images/icons/burglary.svg';
import iconBusinessUnit from '~assets/images/icons/business-unit.svg';
import iconCalendar from '~assets/images/icons/calendar.svg';
import iconChecked from '~assets/images/icons/checked.svg';
import iconClose from '~assets/images/icons/close.svg';
import iconCollapse from '~assets/images/icons/collapse.svg';
import iconColoredSquare from '~assets/images/icons/colored-square.svg';
import iconColumns from '~assets/images/icons/columns.svg';
import iconControlRelay from '~assets/images/icons/controlRelay.svg';
import iconCopy from '~assets/images/icons/copy.svg';
import iconDark from '~assets/images/icons/dark.svg';
import iconDashboard from '~assets/images/icons/dashboard.svg';
import iconDealer from '~assets/images/icons/dealer.svg';
import iconDelete from '~assets/images/icons/delete.svg';
import iconDone from '~assets/images/icons/done.svg';
import iconDownload from '~assets/images/icons/download.svg';
import iconDrop from '~assets/images/icons/drop.svg';
import iconEdit from '~assets/images/icons/edit.svg';
import iconExpand from '~assets/images/icons/expand.svg';
import iconFilter from '~assets/images/icons/filter.svg';
import iconFire from '~assets/images/icons/fire.svg';
import iconGeography from '~assets/images/icons/geography.svg';
import iconAntenna from '~assets/images/icons/geography/antenna.svg';
import iconBurglaryFilled from '~assets/images/icons/geography/burglary-filled.svg';
import iconBurglaryOutlined from '~assets/images/icons/geography/burglary-outlined.svg';
import iconCircleFilledGreen from '~assets/images/icons/geography/circle-filled-green.svg';
import iconCircleFilledOrange from '~assets/images/icons/geography/circle-filled-orange.svg';
import iconCircleFilledRed from '~assets/images/icons/geography/circle-filled-red.svg';
import iconExtender from '~assets/images/icons/geography/extender.svg';
import iconFireFilled from '~assets/images/icons/geography/fire-filled.svg';
import iconFireOutlined from '~assets/images/icons/geography/fire-outlined.svg';
import iconHybridCircle from '~assets/images/icons/geography/hybrid-circle.svg';
import iconHybridFilled from '~assets/images/icons/geography/hybrid-filled.svg';
import iconInactive from '~assets/images/icons/geography/inactive.svg';
import iconIPLinkCircle from '~assets/images/icons/geography/ip-link-circle.svg';
import iconIPLinkFilled from '~assets/images/icons/geography/ip-link-filled.svg';
import iconMultiple from '~assets/images/icons/geography/multiple.svg';
import iconSystemFilled from '~assets/images/icons/geography/system-filled.svg';
import iconSystemOutlined from '~assets/images/icons/geography/system-outlined.svg';
import iconGPS from '~assets/images/icons/gps.svg';
import iconHelp from '~assets/images/icons/help.svg';
import iconHide from '~assets/images/icons/hide.svg';
import iconHybridOutlined from '~assets/images/icons/hybrid-outlined.svg';
import iconInfo from '~assets/images/icons/info.svg';
import iconIPLinkOutlined from '~assets/images/icons/ip-link-outlined.svg';
import iconLight from '~assets/images/icons/light.svg';
import iconLiveTraffic from '~assets/images/icons/live-traffic.svg';
import iconManual from '~assets/images/icons/manual.svg';
import iconNoConnection from '~assets/images/icons/no-connection.svg';
import iconNonAES from '~assets/images/icons/non-aes.svg';
import iconNotification from '~assets/images/icons/notification.svg';
import iconNumberCircle from '~assets/images/icons/numberCircle.svg';
import iconPause from '~assets/images/icons/pause.svg';
import iconRadioChecked from '~assets/images/icons/radio-checked.svg';
import iconRadio from '~assets/images/icons/radio.svg';
import iconResume from '~assets/images/icons/resume.svg';
import iconSearch from '~assets/images/icons/search.svg';
import iconServerConfig from '~assets/images/icons/server-config.svg';
import iconSettings from '~assets/images/icons/settings.svg';
import iconShow from '~assets/images/icons/show.svg';
import iconSortAsc from '~assets/images/icons/sort-asc.svg';
import iconSortDesc from '~assets/images/icons/sort-desc.svg';
import iconSubscribers from '~assets/images/icons/subscribers.svg';
import iconSuccess from '~assets/images/icons/success.svg';
import iconSystemSettings from '~assets/images/icons/system-settings.svg';
import iconSupport from '~assets/images/icons/tech-support.svg';
import iconTime from '~assets/images/icons/time.svg';
import iconUnchecked from '~assets/images/icons/unchecked.svg';
import iconUp from '~assets/images/icons/up.svg';
import iconUpdate from '~assets/images/icons/update.svg';
import iconUser from '~assets/images/icons/user.svg';
import iconChannel from '~assets/images/icons/youtube-channel.svg';
import iconZoom from '~assets/images/icons/zoom.svg';

export const icons = {
  iconAdd,
  iconArrow,
  iconAttention,
  iconBack,
  iconBurglary,
  iconBusinessUnit,
  iconCalendar,
  iconChannel,
  iconChecked,
  iconClose,
  iconCollapse,
  iconColumns,
  iconCopy,
  iconDark,
  iconDashboard,
  iconDealer,
  iconDelete,
  iconDone,
  iconDownload,
  iconDrop,
  iconEdit,
  iconExpand,
  iconFilter,
  iconFire,
  iconGeography,
  iconAntenna,
  iconBurglaryFilled,
  iconBurglaryOutlined,
  iconFireFilled,
  iconFireOutlined,
  iconHybridFilled,
  iconHybridOutlined,
  iconHybridCircle,
  iconSystemFilled,
  iconSystemOutlined,
  iconHelp,
  iconHide,
  iconInfo,
  iconIPLinkFilled,
  iconIPLinkOutlined,
  iconIPLinkCircle,
  iconLight,
  iconLiveTraffic,
  iconManual,
  iconNoConnection,
  iconNotification,
  iconRadioChecked,
  iconRadio,
  iconControlRelay,
  iconSearch,
  iconServerConfig,
  iconShow,
  iconSortAsc,
  iconSortDesc,
  iconSubscribers,
  iconSupport,
  iconSuccess,
  iconSystemSettings,
  iconUnchecked,
  iconUp,
  iconUpdate,
  iconTime,
  iconSettings,
  iconUser,
  iconNonAES,
  iconNumberCircle,
  iconPause,
  iconResume,
  iconCircleFilledRed,
  iconCircleFilledOrange,
  iconCircleFilledGreen,
  iconInactive,
  iconColoredSquare,
  iconExtender,
  iconGPS,
  iconZoom,
  iconMultiple,
};
