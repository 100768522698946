import { isValid } from 'date-fns';

// import { SettingsTab } from './settingsSlice';

import { api } from '~constants';

export type SettingsTab =
  | 'system'
  | 'server'
  | 'network'
  | 'tech-options'
  | 'sub-tools'
  | 'coordinate'
  | 'check-in'
  | 'antennas'
  | 'call-sign'
  | 'instances'
  | 'maintenance'
  | 'self-monitoring'
  | 'tcp';

export type SystemSettingsSection =
  | 'length-unit'
  | 'database-dump'
  | 'password'
  | 'user-inactive-days'
	| 'mfa'
  | 'token-expiration';

export const getSettingsRequestConfig = (
  tab: SettingsTab = 'network'
): { url: string; title: string; handler: 'put' | 'patch' } => {
  switch (tab) {
    case 'system':
      return { url: api.settings.system, title: 'System', handler: 'put' };

      // case 'length-unit':
      //   return { url: api.settings.lengthUnit, title: 'Elevation', handler: 'patch' };

    case 'server':
      return { url: api.settings.server, title: 'Server', handler: 'patch' };

    case 'tech-options':
      return { url: api.settings.techOptions, title: 'Tech Option', handler: 'put' };

    case 'sub-tools':
      return { url: api.settings.subTools, title: 'Subtools', handler: 'put' };

    case 'coordinate':
      return { url: api.settings.coordinate, title: 'Coordinate', handler: 'patch' };

    case 'check-in':
      return { url: api.settings.checkIn, title: 'Check-in Grace', handler: 'put' };

    case 'call-sign':
      return { url: api.settings.callSign, title: 'FCC', handler: 'patch' };

    case 'instances':
      return { url: api.settings.instances, title: 'Instances', handler: 'patch' };

      // case 'password':
      //   return { url: api.settings.password, title: 'Password Expiration', handler: 'patch' };

      // case 'user-inactive-days':
      //   return { url: api.settings.userInactivity, title: 'User Inactivity', handler: 'patch' };

      // case 'mfa':
      //   return { url: api.settings.mfa, title: 'Two-Factor Authentication', handler: 'patch' };

      // case 'token-expiration':
      //   return { url: api.settings.tokenExpiration, title: 'Token Expiration Period', handler: 'patch' };

    default:
      return { url: api.settings.network, title: 'Network', handler: 'put' };
  }
};

export const getSystemSettingsRequestConfig = (
  section: SystemSettingsSection = 'length-unit'
): { url: string; title: string; handler: 'put' | 'patch' } => {
  switch (section) {
    case 'password':
      return { url: api.settings.password, title: 'Password Expiration', handler: 'patch' };

    case 'user-inactive-days':
      return { url: api.settings.userInactivity, title: 'User Inactivity', handler: 'patch' };

    case 'mfa':
      return { url: api.settings.mfa, title: 'Two-Factor Authentication', handler: 'patch' };

    case 'token-expiration':
      return { url: api.settings.tokenExpiration, title: 'Token Expiration Period', handler: 'patch' };

    default:
      return { url: api.settings.lengthUnit, title: 'Elevation', handler: 'patch' };
  }
};

export const validateDateAndTimeFields = (date, time) =>
  date !== time && isValid(new Date(date)) && isValid(new Date(time));
