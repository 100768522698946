import { Button, Grid, withStyles } from '@material-ui/core';
import { Form, Formik } from 'formik';
import { equals } from 'ramda';
import { Fragment, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { getMobileCarriersOption } from '~pages/BusinessUnitDetails/Recipients&Triggers/Details/RecipientGeneralForm.config';
import { DeleteButton } from '~ui-kit/Buttons';

import {
  addMobileInfo,
  prepareRequestPayload,
  removeMobileInfo,
  notificationValidationSchema,
  removeEmailField,
  addEmailField,
  getAppRoutes,
  getInitialValue,
} from './UnitNotificationSettings.config';
import { UnitNotificationSettingsEventsList } from './UnitNotificationSettingsEventsList';

import { PageDetailsPanel, PageDetailsNavigation, PageContent, PageLoader } from '~components';
import { FullHeightPage } from '~hocs';
import {
  IPLinkNotifications,
  MobileCarrier,
  PageProps,
  ResponseError,
  SubscriberNotifications,
  UnitType,
} from '~models';
import { boundActions, selectors } from '~store';
import { AddMoreButton, FormPanel, FormPanelWrapper, FormTextField, FormSelect, FormSwitchField } from '~ui-kit';
import { getFormErrors, getUnitLabelByUnitType, intToHex } from '~utils';

const FormWrapper = FullHeightPage(Form);
const RemoveGrid = withStyles({ root: { display: 'flex' } })(Grid);
const RemoveButton = withStyles({ root: { marginTop: 12, alignSelf: 'flex-start' } })(DeleteButton);

export type UnitNotificationSettingsPageProps = {
  id: number;
  businessUnitId: number;
  unitType: UnitType;
  notifications: IPLinkNotifications | SubscriberNotifications;
  loading: boolean;
  error: ResponseError | null | undefined;
  props: PageProps;
};

export const UnitNotificationSettings = ({
  id,
  businessUnitId,
  unitType,
  notifications,
  loading,
  error,
  props,
}: UnitNotificationSettingsPageProps) => {
  const mobileCarriers = useSelector(selectors.businessUnits.getMobileCarriers(businessUnitId), equals);
  const mobileCarriersOption = getMobileCarriersOption((mobileCarriers as unknown) as MobileCarrier[]);
  const initialValues = getInitialValue(notifications);
  const [turnOffNotification, setTurnOffNotification] = useState(false);
  const hasNotificationEvents = notifications?.events !== null;

  useEffect(() => {
    boundActions.businessUnits.fetchMobileCarriersInit({ id: businessUnitId });
  }, []);

  return (
    <Fragment>
      {loading && <PageLoader fullscreen />}
      <Formik
        initialValues={initialValues}
        validationSchema={notificationValidationSchema}
        onSubmit={values => {
          switch (unitType) {
            case 'IP_LINK':
              boundActions.ipLinks.updateIPLinkNotificationsInit({
                id,
                businessUnitId,
                values: prepareRequestPayload(values),
              });
              break;
            case 'SUBSCRIBER':
              boundActions.subscribers.updateSubscriberNotificationsInit({
                id,
                businessUnitId,
                values: prepareRequestPayload(values),
              });
              break;
            case 'HYBRID':
              boundActions.hybrids.updateHybridNotificationsInit({
                id,
                businessUnitId,
                values: prepareRequestPayload(values),
              });
              break;
          }
        }}
      >
        {({ values, setFieldValue, setErrors }) => {
          useEffect(() => {
            setErrors(getFormErrors(error));
          }, [error]);

          useEffect(() => {
            if (equals(initialValues, values)) {
              if (hasNotificationEvents) {
                setTurnOffNotification(true);
              }
              return;
            }
            if (turnOffNotification) {
              if (values?.events.every(e => e.isEnabled === false)) {
                setFieldValue('sendNotification', false);
                setTurnOffNotification(false);
              }
              return;
            } else if (values.events.some(e => e.isEnabled === true)) {
              return setFieldValue('sendNotification', true);
            }
            setFieldValue('sendNotification', false);
          }, [values.events]);

          useEffect(() => {
            if (!initialValues.sendNotification) {
              setFieldValue('sendNotification', initialValues.sendNotification);
            }
          }, []);

          const onSelectAll = () => {
            if (values.events.every(e => e.isEnabled === true)) {
              return values.events.map((e, i) => setFieldValue(`events[${i}].isEnabled`, false));
            }
            return values.events.map((e, i) => setFieldValue(`events[${i}].isEnabled`, true));
          };

          return (
            <FormWrapper>
              <PageDetailsPanel
                id={id}
                backTo={(props.query?.backTo as string) || getAppRoutes(unitType)}
                getPanelTitle={({ id }) => `${getUnitLabelByUnitType(unitType)} ${intToHex(id as number)}`}
                routes={props.routes}
              >
                <Button variant="contained" color="primary" type="submit" disabled={equals(initialValues, values)}>
                  Save
                </Button>
              </PageDetailsPanel>

              <PageDetailsNavigation routes={props.routes} query={props.query} />

              <PageContent>
                <Grid container spacing={2}>
                  <Grid item xs={12} md={12}>
                    <FormPanel
                      title="Fault Trigger List"
                      loading={loading}
                      renderActions={() => (
                        <Button onClick={() => onSelectAll()} variant="contained" color="primary" size="small">
                          {values.events.every(e => e.isEnabled === true) ? 'Deselect All' : 'Select All'}
                        </Button>
                      )}
                    >
                      <Grid container>
                        <Grid item xs={12} md={4}>
                          <FormSwitchField
                            activeText={<b>Send Notification</b>}
                            text={<b>Send Notification</b>}
                            name="sendNotification"
                            onClick={() => setTurnOffNotification(true)}
                            disabled={values.events.every(e => e.isEnabled === false)}
                          />
                        </Grid>
                        <Fragment>
                          {values.events?.map((event, index) => (
                            <UnitNotificationSettingsEventsList event={event} index={index} key={index} />
                          ))}
                        </Fragment>
                      </Grid>
                    </FormPanel>
                  </Grid>

                  <Grid item xs={12} md={6}>
                    <FormPanelWrapper title="Phone Number" loading={loading}>
                      {values.mobileInfo?.length
                        ? values.mobileInfo.map((field, i) => {
                          const mobileName = `mobileInfo[${i}].mobileCarrierId`;
                          const phoneName = `mobileInfo[${i}].phoneNumber`;

                          return (
                            <Grid container key={i} spacing={2}>
                              <Grid item xs={12} md={4}>
                                <FormSelect label="Mobile Carrier" name={mobileName} options={mobileCarriersOption} />
                              </Grid>
                              <RemoveGrid item xs={12} md={8}>
                                <FormTextField
                                  label="Phone Number (10 digits only)"
                                  name={phoneName}
                                  disabled={!field.mobileCarrierId}
                                  type="text"
                                  cleanable
                                />

                                <RemoveButton
                                  onClick={() => {
                                    setFieldValue('mobileInfo', removeMobileInfo(values.mobileInfo, i));
                                  }}
                                />
                              </RemoveGrid>
                            </Grid>
                          );
                        })
                        : null}

                      <Grid item xs={12} md={12}>
                        <AddMoreButton
                          onClick={() => {
                            setFieldValue('mobileInfo', addMobileInfo(values.mobileInfo));
                          }}
                        >
                          Add Phone Number
                        </AddMoreButton>
                      </Grid>
                    </FormPanelWrapper>
                  </Grid>

                  <Grid item xs={12} md={6}>
                    <FormPanelWrapper title="Emails" loading={loading}>
                      {values.emails?.length
                        ? values.emails.map((field, i) => (
                          <Grid container key={i} spacing={2}>
                            <RemoveGrid item xs={12}>
                              <FormTextField name={`emails[${i}]`} type="text" label="Email Address" cleanable />

                              <RemoveButton
                                onClick={() => {
                                  setFieldValue('emails', removeEmailField(values.emails, i));
                                }}
                              />
                            </RemoveGrid>
                          </Grid>
                        ))
                        : null}
                      <Grid item xs={12} md={12}>
                        <AddMoreButton
                          onClick={() => {
                            setFieldValue('emails', addEmailField(values.emails));
                          }}
                        >
                          Add Email Address
                        </AddMoreButton>
                      </Grid>
                    </FormPanelWrapper>
                  </Grid>
                </Grid>
              </PageContent>
            </FormWrapper>
          );
        }}
      </Formik>
    </Fragment>
  );
};
