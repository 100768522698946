import { Button } from '@material-ui/core';
import { equals } from 'ramda';
import { Fragment, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Redirect } from 'react-router';

import { AlarmAutomationFormWrapper } from '../Form';

import { NoLicense, PageLoader } from '~components';
import { appDetailRoutes } from '~constants';
import { boundActions, selectors } from '~store';

export const AlarmAutomationCreate = () => {
  const loaders = useSelector(selectors.alarmAutomation.getAlarmAutomationLoading, equals);
  const alarmAutomationCreated = useSelector(selectors.alarmAutomation.isAlarmAutomationCreated);
  const isPageNoLicense = useSelector(selectors.page.isPageNoLicense);

  useEffect(
    () => () => {
      boundActions.alarmAutomation.resetAlarmAutomationStatus();
    },
    []
  );

  if (alarmAutomationCreated) {
    return <Redirect to={appDetailRoutes.settingsAlarmAutomation} />;
  }

  if (isPageNoLicense) {
    return <NoLicense />;
  }

  return (
    <Fragment>
      {loaders.create && <PageLoader fullscreen />}

      <AlarmAutomationFormWrapper
        title="Add Alarm Automation Configuration"
        backTo={appDetailRoutes.settingsAlarmAutomation}
        onSubmit={({ alarmAutomation }) => {
          boundActions.alarmAutomation.createAlarmAutomationInit(alarmAutomation);
        }}
      >
        <Button variant="contained" color="primary" type="submit" disabled={loaders.create}>
          Save
        </Button>
      </AlarmAutomationFormWrapper>
    </Fragment>
  );
};
