import { ControlRelay } from './controlRelay';
import { AssignedDealer } from './dealer';
import { GeoCoordinates } from './geography';
import { UnitDependent, UnitRoute, UnitType } from './unit';

export type SubscriberGeneralInfo = {
  businessUnitId: number;
  businessUnitName: string;
  id: number;
  status?: 'NORMAL' | 'OFF_NORMAL' | 'INACTIVE';
  addedAt?: number;
  address1?: string;
  address2?: string;
  antenna?: string;
  apId?: number | string;
  city?: string;
  compassNumber?: number;
  country?: string;
  customer?: string;
  dealersCount?: number;
  dealers?: AssignedDealer[];
  note?: string;
  stateOrProvince?: string;
  zipOrPostalCode?: string;
  messages?: SubscriberMessage[];
  lastUpdated?: number;
  alarmTTL?: number;
  checkInTTL?: number;
  statusTTL?: number;
  troubleTTL?: number;
  commTimeoutDelay?: number;
};

export type SubscriberHardware = {
  isHybrid?: boolean;
  model?: string;
  panelInterface?: string;
  revision?: string;
  serialNum?: string;
  type?: 'Burg' | 'Fire' | 'Amr' | 'FMM' | 'Gps' | 'Unknown';
  isRelayConnected?: boolean;
  lastUpdated?: number;
};

export type SubscriberZones = {
  restoral?: string;
  zone?: string;
  lastUpdated?: number;
};

export type SubscriberRadioStatus = {
  linkLayer?: number;
  netCon?: number;
  lastUpdated?: number;
};

export type SubscriberIPConfig = {
  isStatic?: boolean;
  businessUnitGroupId?: number;
  dns1?: string;
  dns2?: string;
  gatewayIp?: string;
  macAddress?: string;
  netMask?: string;
  primaryReceiverPublicIp?: string;
  primaryReceiverPublicPort?: number;
  reportingRoute?:
    | 'Radio and Internet'
    | 'Internet Only'
    | 'Radio Only'
    | 'Internet with Radio Backup'
    | 'Radio with Internet Backup';
  secondaryReceiverPublicIp?: string;
  secondaryReceiverPublicPort?: number;
  staticIp?: string;
  version?: number;
  lastUpdated?: number;
};

export type SubscriberSettings = {
  ['radio-packet-life']: {
    alarm: number | null;
    checkin: number | null;
    intelliTap: number | null;
    restoral: number | null;
    specials: number | null;
    status: number | null;
    trouble: number | null;
    manualPath: boolean;
    route: null;
    lastUpdated?: number;
  };
  timing: {
    ackDelay: number;
    eventReporting: number;
    ipCheckinHrs: number;
    ipCheckinMins: number;
    loopResponse: number | null;
    manualPath: boolean;
    radioCheckinHrs: number;
    radioCheckinMins: number;
    reportDelay: number;
    route: null;
    lastUpdated?: number;
  };
  modes: {
    intelliTap: number;
    lineCut: number;
    repeater: number;
    manualPath: boolean;
    route: null;
    lastUpdated?: number;
  };
  ['rf-settings']: {
    subscriberRFStatus: 'ON' | 'OFF' | 'UNDEFINED';
    lastUpdated?: number;
  };
  supervision: {
    supervisionEnable: boolean;
  };
};

export enum SubscriberZone {
  NORMALLY_OPEN = 0,
  NORMALLY_CLOSED = 1,
  SUPERVISED = 2,
  FIRE = 3,
  INVERTED_FIRE = 4,
  BYPASSED = 5,
  STEADY = 6,
  PULSE_TEMPORAL = 7,
}

export enum SubscriberZoneLabel {
  FIRE = 1,
  SUPERVISORY = 2,
  BURGLARY = 3,
  SYSTEM_TROUBLE = 4,
  AC_FAILURE = 5,
  NORMAL = 6,
}

export type SubscriberZonesConfiguration = {
  configurations: {
    isFire: boolean;
    isInverted: boolean;
    zones: {
      zoneValue: SubscriberZone;
      zoneLabelValue: SubscriberZoneLabel;
      isRestoral: boolean;
      zoneOptions: [{ label: string; value: SubscriberZone }];
      zoneLabelOptions: [{ label: string; value: SubscriberZoneLabel }];
    }[];
  }[];
};

export type SubscriberMessage = {
  businessUnitId: number;
  unitId: number;
  id: number;
  text: string;
  type: 'INCOMING' | 'OUTGOING';
  status: 'SENT' | 'DELIVERED' | 'FAILED';
  created: number;
  manualPath: boolean;
  message: string;
  route: null;
};

export type SubscriberTopTalker = {
  eventsCount?: number;
  /* Timestamp */
  lastCheckInTimestamp?: number;
  partOfTotalSignals?: number;
  topEventsCount?: number;
  topStatusCode?: number;
  address?: string;
};

export type SubscriberTopRepeater = {
  address?: string;
  repeatsCount10days?: number;
  repeatsCount24hours?: number;
};

export type SubscriberLateCheckIn = {
  lastCheckInTimestamp?: number;
  period?: number;
};

export type SubscriberFrequentCheckIn = {
  checkInCount?: number;
  lastCheckInTimestamp?: number;
};

export type SubscriberServiceLog = {
  unitId?: number;
  buId?: number;
  eventLabel?: string;
  address1?: string;
  address2?: string;
  city?: string;
  state?: string;
  zipCode?: string;
  modelNumber?: string;
  modelRev?: string;
  status?: string;
};

export type SubscriberNonRecommended = {
  eventReportingPath?:
    | 'Radio and Internet'
    | 'Internet Only'
    | 'Radio Only'
    | 'Internet with Radio Backup'
    | 'Radio with Internet Backup';
  subscriberStatus?: 'NORMAL' | 'OFF_NORMAL' | 'INACTIVE';
  subscriberType?: 'Burg' | 'Fire' | 'Amr' | 'FMM' | 'Gps' | 'Unknown';
  checkIn?: number;
  alarm?: number;
  trouble?: number;
  intelliTap?: number;
  special?: number;
  radioCheckInHrs?: number;
  radioCheckInMin?: number;
  ipCheckInHrs?: number;
  ipCheckInMin?: number;
};

export type SubscriberInfo = {
  hardware: {
    isHybrid?: boolean;
    model?: string;
    panelInterface?: string;
    revision?: string;
    serialNum?: string;
    type?: 'Burg' | 'Fire' | 'Amr' | 'FMM' | 'Gps' | 'Unknown';
    isRelayConnected?: boolean;
    lastUpdated?: number;
    reportingRoute?:
      | 'Radio and Internet'
      | 'Internet Only'
      | 'Radio Only'
      | 'Internet with Radio Backup'
      | 'Radio with Internet Backup';
  };

  zones: {
    restoral?: string;
    zone?: string;
    lastUpdated?: number;
  };

  ['radio-status']: {
    linkLayer?: number;
    netCon?: number;
    lastUpdated?: number;
  };

  ['ip-config']: {
    isStatic?: boolean;
    businessUnitGroupId?: number;
    dns1?: string;
    dns2?: string;
    gatewayIp?: string;
    macAddress?: string;
    netMask?: string;
    primaryReceiverPublicIp?: string;
    primaryReceiverPublicPort?: number;
    secondaryReceiverPublicIp?: string;
    secondaryReceiverPublicPort?: number;
    staticIp?: string;
    version?: number;
    lastUpdated?: number;
  };
};

export type FACPZones = {
  id: number;
  areaCode: string;
  description: string;
  eventCode: string;
  eventType: string;
  mcpEnabled: boolean;
  autoAckEnabled: boolean;
  zoneCode: string;
  color: FACPColor;
  isTest: boolean;
  sound: number;
};

export type FACPNotificationPhoneNumber = {
  facpeventNotifcationId: number;
  mobileCarrierId: number;
  phoneNumber: string;
};

export type FACPNotificationEvents ={
  description: string;
  facpEventId: number;
};

export type FACPNotificationTrigger ={
  isEnabled: boolean | null;
  facpEventId: number;
};

export type ZoneNotifications = {
  sendNotification: boolean | null;
  phoneNumbers: FACPNotificationPhoneNumber[] | [];
  emailAddresses: string[];
  allEvents: FACPNotificationEvents[] | [];
  events: FACPNotificationTrigger[] | [];
};

export type FACPColors = ['Blue', 'Red', 'Yellow', 'Green'];

export enum FACPColor {
  Blue = 0,
  Red = 1,
  Yellow = 2,
  Green = 3,
}

export type CustomNote = {
  id: number;
  note: string;
  title: string;
};

export type ReverseGeoCoding = {
  city: string;
  country: string;
  formattedAddress: string;
  number: string;
  state: string;
  street: string;
  zip: string;
};

export type SubscriberMode = 'NCT' | 'SUBSCRIBER';

export type Subscriber = SubscriberGeneralInfo &
  SubscriberHardware &
  SubscriberZones &
  SubscriberRadioStatus &
  SubscriberIPConfig &
  GeoCoordinates &
  SubscriberTopTalker &
  SubscriberTopRepeater &
  SubscriberLateCheckIn &
  SubscriberFrequentCheckIn &
  SubscriberServiceLog &
  SubscriberNonRecommended & {
    routes?: UnitRoute[];
    macAddress?: string;
    faultsCount?: number;
    eventReporting?: number;
    ipCheckinHrs?: number;
    ipCheckinMins?: number;
    radioCheckinHrs?: number;
    radioCheckinMins?: number;
    settings?: SubscriberSettings;
    subscriberInfo?: SubscriberInfo;
    zones?: SubscriberZonesConfiguration[];
    eventHistory?: SubscriberHistory[];
    peers?: SubscriberPeers;
    facpZones?: FACPZones[];
    zoneNotifications?: ZoneNotifications;
    customNotes?: CustomNote[];
    wifiAccess?: string; // TODO: not implemented on API side
    dealer?: string; // TODO: not implemented on API side

    notifications?: SubscriberNotifications;
    createdAt?: number;
    postalCode?: string;
    inactiveSince?: string;
    inactiveDependency?: UnitDependent[];
    dependent?: number;
    connectCount?: number;
    connectionStatus?: boolean;
    connectionTime?: number;
    mode?: SubscriberMode;
    controlRelay?: ControlRelay;
    testMode?: boolean;
    autoTestSupervision?: boolean;
    dependencies?: UnitDependent[];
    dependentCalcTimestamp?: number;
    dependentDayCount?: number;
    dependentDecadeCount?: number;
    reverseGeoCoding?: ReverseGeoCoding;
    hasExtender?: boolean;
  };

export type SubscriberRFStatus = 'ON' | 'OFF' | 'UNDEFINED';
export type SubscriberRFInfo = {
  buId?: Subscriber['businessUnitId'];
  lastUpdated?: number;
  subscriberId: Subscriber['id'];
  subscriberRFStatus: SubscriberRFStatus;
};
export type SubscriberTTLStatus = 'CHECKED' | 'UNCHECKED';
export type SubscriberTTLInfo = { subscriberId: Subscriber['id']; subscriberTtlStatus: SubscriberTTLStatus };

export type SubscribersListSection =
  | 'all'
  | 'top-talkers'
  | 'top-repeaters'
  | 'late-check-in'
  | 'frequent-check-in'
  | 'service-log'
  | 'recently-added'
  | 'non-recommended-ttl'
  | 'inactive'
  | 'nct'
  | 'ap-mismatch'
  | 'extenders';

export type SubscriberHistory = {
  alarmTime: number;
  apId: number;
  businessUnitId: number;
  eventCode: string;
  eventName: string;
  lineCard: number;
  unitId: number;
};

export type SubscriberHistoryPeriod = 'TEN_DAYS' | 'MONTH';

export type SubscriberNotifications = {
  emails: string[];
  sendRestoral: boolean;
  sendNotification: boolean;
  allEvents: {
    eventName: string;
    eventLabel: string;
    faultCode: string;
  }[];
  events: {
    event: string;
    isEnable: boolean;
  }[];
  mobileInfo: { mobileCarrierId: number | null; phoneNumber: string | null }[] | null;
};

type SubscriberPeersLabel = 'Good' | 'Marginal';

export type SubscriberPeers = {
  peers: {
    linkLayer: number;
    netCon: number;
    quality: {
      label: SubscriberPeersLabel;
      value: string;
    };
    unitId: number;
    unitType: UnitType;
  }[];
  timestamp: number;
};
