import { equals } from 'ramda';
import { Fragment, useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { appDetailRoutes } from '~constants/routes';
import { getInitialRequestParams } from '~features/page/pageUtils';

import { SettingsPanel } from '../../SettingsPanel';

import { AlarmAutomationListCard } from './Card';
import { AlarmAutomationDeleteDialog } from './Dialog';

import { NoLicense, PageContent, PaginatedDataList } from '~components';
import { AlarmAutomation, PageProps } from '~models';
import { boundActions, selectors } from '~store';
import { LinkButton } from '~ui-kit';

export const AlarmAutomationList = (props: PageProps) => {
  const [isOpenDialog, setOpenDialog] = useState(false);

  const alarmAutomation = useSelector(selectors.alarmAutomation.getAlarmAutomationData, equals);
  const isLoading = useSelector(selectors.alarmAutomation.getAlarmAutomationLoading, equals);
  const pagination = useSelector(selectors.page.getPagePagination, equals);
  const isPageNoLicense = useSelector(selectors.page.isPageNoLicense);

  useEffect(() => {
    boundActions.alarmAutomation.fetchAlarmAutomationInit(getInitialRequestParams(pagination));

    return () => {
      boundActions.page.resetPageState();
    };
  }, []);

  const onPaginationChange = useCallback(
    pagination => boundActions.alarmAutomation.fetchAlarmAutomationInit({ pagination }),
    []
  );

  const handleDelete = (id: number, isParentBuName: boolean) => {
    if (!isParentBuName) {
      boundActions.alarmAutomation.deleteAlarmAutomationInit({ id });

      return;
    }

    setOpenDialog(true);
  };

  if (isPageNoLicense) {
    return <NoLicense />;
  }

  return (
    <Fragment>
      <SettingsPanel {...props}>
        <LinkButton
          variant="contained"
          color="primary"
          type="submit"
          linkTo={appDetailRoutes.settingsAlarmAutomationCreate}
        >
          Add new
        </LinkButton>
      </SettingsPanel>

      <PageContent>
        <PaginatedDataList<AlarmAutomation>
          data={alarmAutomation}
          loading={isLoading.list}
          onPaginationChange={onPaginationChange}
          renderItem={aa => <AlarmAutomationListCard alarmAutomation={aa} onDelete={handleDelete} />}
        />

        <AlarmAutomationDeleteDialog isOpen={isOpenDialog} onClose={() => setOpenDialog(false)} />
      </PageContent>
    </Fragment>
  );
};
