import { IconButton, IconButtonProps, Tooltip } from '@material-ui/core';
import { withStyles, WithStyles } from '@material-ui/styles';
import clsx from 'clsx';

import { styles } from './DownloadButton.styles';

import { Icon } from '~ui-kit';

export type DownloadButtonProps = IconButtonProps &
  WithStyles<typeof styles> & {
    tooltip?: string;
  };

export const DownloadButton = withStyles(styles)(
  ({ classes, tooltip = 'Download file', ...props }: DownloadButtonProps) => (
    <Tooltip title={tooltip} placement="top">
      <IconButton {...props} classes={{ root: clsx(classes.root) }}>
        <Icon icon="download" classes={{ root: clsx(classes.icon) }} />
      </IconButton>
    </Tooltip>
  )
);
