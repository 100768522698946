import { Paper, Button, Typography, WithStyles, withStyles } from '@material-ui/core';
import { Formik, Form } from 'formik';
import { equals } from 'ramda';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import * as Yup from 'yup';

import { styles } from '../Auth.styles';

import { LoginLogo } from './LoginLogo';

import { boundActions, selectors } from '~store';
import { FormTextField, Required } from '~ui-kit';
import { getFormErrors } from '~utils';

const validationSchema = Yup.object().shape({
  username: Yup.string().required('Username is required'),
  password: Yup.string().required('Password is required'),
});

export const Login = withStyles(styles)(({ classes }: WithStyles<typeof styles>) => {
  const info = useSelector(selectors.app.getInstanceInfo, equals);
  const error = useSelector(selectors.auth.getAuthError, equals);
  const isLoading = useSelector(selectors.auth.isLoginProcessing);

  return (
    <Paper className={classes.paper}>
      <LoginLogo />

      <Typography className={classes.title} variant="h6" color="primary">
        Sign In
      </Typography>

      {info.instanceType ? (
        <Typography className={classes.instance} variant="h4" color="primary">
        to {info.instanceType} Instance
        </Typography>
      ) : null}

      <Formik
        initialValues={{ username: '', password: '' }}
        validationSchema={validationSchema}
        onSubmit={values => {
          boundActions.auth.loginInit(values);
        }}
      >
        {({ setErrors }) => {
          useEffect(() => {
            setErrors(getFormErrors(error));
          }, [error]);

          return (
            <Form className={classes.form}>
              <FormTextField name="username" label={<Required>Username</Required>} type="text" cleanable />
              <FormTextField name="password" label={<Required>Password</Required>} type="password" cleanable />

              <Button
                fullWidth
                type="submit"
                className={classes.submit}
                variant="contained"
                color="primary"
                disabled={isLoading}
              >
                Sign In
              </Button>
            </Form>
          );
        }}
      </Formik>
    </Paper>
  );
});
