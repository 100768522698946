/* eslint-disable indent */
import { AccordionSummary as MuiAccordionSummary, AccordionSummaryProps, Divider, withStyles } from '@material-ui/core';
import { WithStyles } from '@material-ui/styles';
import clsx from 'clsx';
import { useCallback } from 'react';

import { Icon } from '../Icon/Icon';

import { styles } from './CardSummary.styles';

export type CustomCardSummaryProps = AccordionSummaryProps &
  WithStyles<typeof styles> & {
    color?: string;
    status?: CardStatus;
    expandable?: boolean;
    hideIcon?: boolean;
  };

export type CardStatus = 'success' | 'warning' | 'error' | 'default' | 'inactive' | 'self-monitoring';

export const CardSummary = withStyles(styles)(
  ({ expandable, hideIcon = false, classes, ...props }: CustomCardSummaryProps) => {
    const showIcon = expandable && !hideIcon;
    const extraProps = showIcon
      ? {
          expandIcon: <Icon icon="drop" />,
          IconButtonProps: {
            disableRipple: true,
            classes: { root: clsx(classes.iconRoot), edgeEnd: clsx(classes.iconEdgeEnd) },
          },
        }
      : {};

    const onClick = useCallback(e => e.stopPropagation(), []);

    return (
      <MuiAccordionSummary
        {...props}
        {...extraProps}
        className={props.status}
        classes={{ root: clsx(classes.cardSummaryRoot), content: clsx(classes.cardSummaryContent) }}
      >
        <div className={classes.innerContent} onClick={onClick}>
          {props.children}
        </div>

        {showIcon ? <Divider flexItem orientation="vertical" /> : null}
      </MuiAccordionSummary>
    );
  }
);
