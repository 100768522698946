import { Grid, IconButton } from '@material-ui/core';
import { Formik, Form } from 'formik';
import { Fragment, PropsWithChildren, useState } from 'react';

import { AlarmAutomation } from '~models/alarmAutomation';

import { AlarmAutomationForm } from './AlarmAutomationForm';
import { AlarmAutomationFormValues, validationSchema, getAlarmAutomationFormValues, getInfoDialogContent } from './config';

import { InfoSectionDialog, PageContent, PagePanel } from '~components';
import { FormPanel, FormGroup, Icon } from '~ui-kit';

type AlarmAutomationFormWrapperProps = PropsWithChildren<{
  title: string;
  backTo: string;
  showing?: boolean;
  alarmAutomation?: AlarmAutomation;
  onSubmit?: (values: AlarmAutomationFormValues) => void;
}>;

export const AlarmAutomationFormWrapper = ({
  title,
  backTo,
  showing,
  alarmAutomation,
  onSubmit = () => {},
  children,
}: AlarmAutomationFormWrapperProps) => {
  const initialValues = getAlarmAutomationFormValues(alarmAutomation);
  const [infoDialogOpened, setInfoDialogOpened] = useState<boolean>(false);

  return (
    <Fragment>
      <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={onSubmit}>
        <Form>
          {title && (
            <PagePanel title={title} backTo={backTo}>
              {children}
            </PagePanel>
          )}

          <PageContent>
            <Grid container>
              <Grid item xs={12} md={6}>
                <FormPanel
                  title="Alarm Automation Settings"
                  renderActions={() => (
                    <IconButton onClick={() => setInfoDialogOpened(!infoDialogOpened)}>
                      <Icon icon="info" />
                    </IconButton>
                  )}
                >
                  <FormGroup title="TCP Server Parameters">
                    <AlarmAutomationForm showing={showing} />
                  </FormGroup>
                </FormPanel>
              </Grid>
            </Grid>
          </PageContent>
        </Form>
      </Formik>
      <InfoSectionDialog
        open={infoDialogOpened}
        onConfirm={() => setInfoDialogOpened(!infoDialogOpened)}
        getContent={getInfoDialogContent}
      />
    </Fragment>
  );
};
