import { Field } from 'formik';

import { RadioGroup, RadioGroupProps } from '../Radio/RadioGroup';

export type FormRadioGroupProps = Partial<RadioGroupProps> & {
  name: string;
};

export const FormRadioGroup = ({ name, showing, ...props }: FormRadioGroupProps) => (
  <Field name={name}>{({ field }) => <RadioGroup {...props} {...field} showing={showing} />}</Field>
);
