/* eslint-disable newline-per-chained-call */
import { Button } from '@material-ui/core';
import { equals } from 'ramda';
import { useCallback, useEffect } from 'react';
import { useSelector } from 'react-redux';

import { userHasPermissions } from '~features/users/usersUtils';

import { MultipleBUMigration } from '../MultipleBUMigration/MultipleBUMigration';

import { SelectMigratedBUTitle } from './Title';

import { useSubscription } from '~hooks';
import { AppDatabaseMigrationStatus, PermissionName } from '~models';
import { boundActions, selectors } from '~store';
import { Dialog, DialogActions } from '~ui-kit';

export const SelectMigratedBUDialog = () => {
  const currentUser = useSelector(selectors.auth.getCurrentUser, equals);
  const { selectMigratedBU } = useSelector(selectors.app.getAppDialogs, equals);
  const hasPermissions = userHasPermissions(currentUser, [PermissionName.SETTINGS_VIEW]);
  const {
    status,
    withWarnings,
    migratedBUList,
  } = useSelector(selectors.app.getDatabaseMigrationState, equals);
  const isDatabaseMigrationFinished = useSelector(selectors.app.isDatabaseMigrationFinished);

  useSubscription<{ status: AppDatabaseMigrationStatus; withWarnings: boolean }>('/migration/notification', msg => {
    const { status, withWarnings } = msg.data;

    if (status === 'STARTED') {
      boundActions.notifications.enqueue({
        message: 'Database migration started',
        key: 'migration-started',
        options: {
          variant: 'success',
          autoHideDuration: null,
        },
      });

      boundActions.app.setDatabaseMigrationStatus(status);
    }

    if (status === 'FAILED') {
      boundActions.notifications.close('migration-started');

      boundActions.notifications.enqueue({
        message: 'Database migration failed',
        options: {
          variant: 'error',
          autoHideDuration: null,
        },
      });

      boundActions.app.setDatabaseMigrationStatus(status);
      boundActions.app.setDatabaseMigrationWithWarnings(withWarnings);
    }

    if (status === 'FINISHED') {
      boundActions.notifications.close('migration-started');

      boundActions.notifications.enqueue({
        message: 'Database migration done',
        options: {
          variant: 'success',
          autoHideDuration: null,
        },
      });

      boundActions.app.setDatabaseMigrationStatus(status);
      boundActions.app.setDatabaseMigrationWithWarnings(withWarnings);
    }
  });

  const reloadPage = useCallback(() => window.location.reload(), []);

  const onBackToList = useCallback(() => boundActions.app.setDatabaseMigrationStatus('NOT_STARTED'), []);

  useEffect(() => {
    boundActions.app.setDatabaseMigrationStatus('NOT_STARTED');

    if (!selectMigratedBU && migratedBUList.length) {
      boundActions.app.cleanDatabaseArchiveInit();
    }
  }, [selectMigratedBU]);

  useEffect(() => {
    if (!isDatabaseMigrationFinished) {
      boundActions.app.setDatabaseMigrationWithWarnings(false);
    }
  }, [status]);

  return (
    <Dialog open={selectMigratedBU && hasPermissions} maxWidth="sm" PaperProps={{ style: { width: '100%' } }}>
      <SelectMigratedBUTitle />

      <MultipleBUMigration />

      {(isDatabaseMigrationFinished && !withWarnings) && (
        <DialogActions>
          <Button type="button" variant="outlined" color="primary" onClick={reloadPage}>
            No
          </Button>

          <Button type="button" variant="contained" color="primary" onClick={onBackToList}>
            Yes
          </Button>
        </DialogActions>
      )}
    </Dialog>
  );
};
