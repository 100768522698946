import { isNil } from 'ramda';

import { getAlarmZone } from '~features/alarms/utils';

import { appDetailRoutes, appRoutes } from '~constants';
import { IconType } from '~icons';
import { Alarm, AlarmType, PermissionName, FACPColor } from '~models';
import { CardStatus, NavLink } from '~ui-kit';
import { intToHex } from '~utils';

export const getAlarmMeta = (type: AlarmType) => {
  let status: CardStatus = 'default';
  let icon: IconType = 'info';
  let title = 'Unknown';

  switch (type) {
    case 'FIRE': {
      title = 'Fire';
      status = 'error';
      icon = 'fire';
      break;
    }

    case 'MEDICAL': {
      title = 'Medical';
      status = 'error';
      break;
    }

    case 'PANIC': {
      title = 'Panic';
      status = 'error';
      break;
    }

    case 'BURGLARY': {
      title = 'Burglary';
      status = 'warning';
      icon = 'burglary';
      break;
    }

    case 'SUPERVISORY': {
      title = 'Supervisory';
      icon = 'system-outlined';
      break;
    }

    case 'OTHER': {
      title = 'Other';
      break;
    }

    case 'UNKNOWN': {
      title = 'Unknown';
      break;
    }

    default:
      break;
  }

  return {
    status,
    title,
    icon,
  };
};

export const getAlarmUnitLabel = (alarm: Alarm): string => {
  if (alarm?.unitType === 'SUBSCRIBER') {
    return 'Subscriber';
  }

  if (alarm?.unitType === 'HYBRID') {
    return 'Hybrid';
  }

  if (alarm?.unitType === 'MNR') {
    return 'Receiver';
  }

  return 'IP Link';
};

export const getAlarmUnitLink = (alarm: Alarm): string => {
  if (alarm?.unitType === 'SUBSCRIBER') {
    return appDetailRoutes.subscriberDetails(alarm.businessUnitId, alarm.unitId);
  }

  if (alarm?.unitType === 'HYBRID') {
    return appDetailRoutes.hybridDetails(alarm.businessUnitId, alarm.unitId);
  }

  if (alarm?.unitType === 'IP_LINK') {
    return appDetailRoutes.ipLinkDetails(alarm.businessUnitId, alarm.unitId);
  }

  return appRoutes.root;
};

export const isFullAlarm = (alarm: Alarm) => {
  const hasAlarmNull = alarm.code === null || alarm.cid === null;
  const zone = getAlarmZone(alarm).slice(1);
  const isWrongZone = isNaN(Number(zone)) || zone.length <= 2;

  if (hasAlarmNull || isWrongZone) {
    return false;
  }

  return true;
};

export const getAlarmUnitLabelField = (alarm: Alarm, backTo?: string) => {
  if (alarm?.unitType === 'SUBSCRIBER') {
    const link = appDetailRoutes.subscriberDetails(alarm.businessUnitId, alarm.unitId, backTo);

    return (<NavLink to={link}>{intToHex(alarm.unitId)}</NavLink>);
  }

  if (alarm?.unitType === 'HYBRID') {
    const link = appDetailRoutes.hybridDetails(alarm.businessUnitId, alarm.unitId, backTo);

    return (<NavLink to={link}>{intToHex(alarm.unitId)}</NavLink>);
  }

  if (alarm?.unitType === 'IP_LINK') {
    const link = appDetailRoutes.ipLinkDetails(alarm.businessUnitId, alarm.unitId, backTo);

    return (<NavLink to={link}>{intToHex(alarm.unitId)}</NavLink>);
  }

  return intToHex(alarm.unitId);
};

export const getNoteDialogPermission = (alarm: Alarm) => {
  if (alarm.unitType === 'SUBSCRIBER' || alarm.unitType === 'HYBRID') {
    return [PermissionName.SUBSCRIBERS_VIEW];
  }

  if (alarm.unitType === 'IP_LINK') {
    return [PermissionName.IP_LINK_VIEW];
  }

  return [];
};

export const getFACPColorLabel = (value: FACPColor | null) => {
  if (isNil(value)) {
    return '';
  }

  return `facp-${FACPColor[value]?.toLowerCase()}`;
};

