import { Button } from '@material-ui/core';
import { PropsWithChildren } from 'react';

import { Dialog, DialogContent, DialogActions, DialogProps, DialogTitle } from '~ui-kit';

type InformPromptDialogProps = Partial<DialogProps> &
  PropsWithChildren<{
    open: boolean;
    title?: string;
    buttonName?: string;
    onConfirm: () => void;
  }>;

export const InformPromptDialog = ({
  open = false,
  title = '',
  buttonName = 'OK',
  maxWidth = 'xs',
  onConfirm,
  children,
  ...props }: InformPromptDialogProps) => (
  <Dialog fullWidth maxWidth={maxWidth} open={open} onClose={onConfirm} {...props}>
    { title ?
      <DialogTitle title={title} /> :
      null
    }
    <DialogContent>{children}</DialogContent>
    <DialogActions>
      <Button variant="contained" color="primary" onClick={onConfirm}>
        {buttonName}
      </Button>
    </DialogActions>
  </Dialog>
);
