export const DATE_DTO_FORMAT = 'yyyy-MM-dd';
export const DAY_FORMAT = 'EEE MMM d';
export const FULL_DAY_FORMAT = 'EEEE';
export const TIME_FORMAT = 'HH:mm:ss';
export const HOUR_MIN_TIME_FORMAT = 'HH:00';
export const AM_PM_TIME_FORMAT = 'hh:mm:ss a';
export const AM_PM_HOUR_MIN_TIME_FORMAT = 'hh:00 a';
export const YEAR_FORMAT = 'yyyy';
export const TIMEZONE_FORMAT = 'z';
export const DATE_FORMAT = [DAY_FORMAT, TIME_FORMAT, YEAR_FORMAT].join(' ');
export const MONTH_DAY_FORMAT = 'LLLL do';
export const DATE_AM_PM_FORMAT = [DAY_FORMAT, AM_PM_TIME_FORMAT, YEAR_FORMAT].join(' ');
export const SERVER_DATE_FORMAT = [DAY_FORMAT, YEAR_FORMAT, TIME_FORMAT].join(' ');
export const SERVER_DATE_AM_PM_FORMAT = [DAY_FORMAT, YEAR_FORMAT, AM_PM_TIME_FORMAT].join(' ');
