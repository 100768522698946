import { sortBy, map, assoc, propOr } from 'ramda';

import {
  AlarmAutomation,
  AlarmAutomationBusinessUnits,
  AlarmAutomationPrimarySocket,
  AlarmAutomationStatuses,
} from '~models';
import { CardStatus } from '~ui-kit';

export const sortAlarmAutomationSockets = (aa?: AlarmAutomation): AlarmAutomation | null => {
  if (!aa) {
    return null;
  }

  return assoc('sockets', sortBy(propOr(0, 'number'), propOr([], 'sockets', aa)), aa);
};

export const sortAlarmAutomationIpAddresses = (alarmAutomation: AlarmAutomation[]): Required<AlarmAutomation>[] =>
  map(aa => sortAlarmAutomationSockets(aa) as Required<AlarmAutomation>, alarmAutomation);

export const getPrimaryAlarmAutomationLabel = (primarySocket?: AlarmAutomationPrimarySocket | null | undefined) => {
  if (!primarySocket) {
    return 'N/A';
  }

  return `IP: ${primarySocket.primaryIp}, Port: ${primarySocket.port}`;
};

export const getAlarmAutomationAllIPsLabel = (primarySocket: AlarmAutomationPrimarySocket) => {
  if (!primarySocket) {
    return 'N/A';
  }
  const ipAddressesList = primarySocket.ipAddresses?.join(', ');

  return `Port: ${primarySocket.port} / IP: ${ipAddressesList}`;
};

export const sortAlarmAutomationBusinessUnits = (
  links?: AlarmAutomationBusinessUnits[]
): AlarmAutomationBusinessUnits[] => {
  if (!links?.length) {
    return [];
  }

  return sortBy(propOr(0, 'id'), links);
};

export const getAlarmAutomationStatus = (
  dataStatus?: AlarmAutomation['status'] | null | undefined
): { status: { value: CardStatus; title: string } } => {
  if (!dataStatus) {
    return { status: { value: 'warning', title: 'N/A' } };
  }

  const status = dataStatus === 'UP' ? 'success' : 'error';
  const title = dataStatus === 'UP' ? 'Up' : 'Down';

  return { status: { value: status, title } };
};

export const updateAlarmAutomationWithStatuses = (
  units: AlarmAutomation[],
  statuses: AlarmAutomationStatuses
): AlarmAutomation[] => units.map(unit => assoc('status', statuses[unit.id] || unit.status, unit));

export const mapAlarmAutomationValues = (
  alarmAutomation: Partial<AlarmAutomation>,
  values: Partial<AlarmAutomation>
) => {
  const sockets = values.sockets?.map(socket => {
    const existing = alarmAutomation.sockets?.find(({ ipAddress }) => socket.ipAddress === ipAddress);

    if (existing) {
      return { ...socket, id: existing.id };
    }

    return socket;
  });

  return { ...values, sockets };
};
