import { SwitchClassKey, StyleRules, Theme } from '@material-ui/core';

type SwitchStyles = Partial<StyleRules<SwitchClassKey>>;

export const switchStyles = (theme: Theme): SwitchStyles => ({
  root: {
    width: 56,
    height: 36,
    padding: 12,
  },
  thumb: {
    width: 18,
    height: 18,
  },
  track: {
    opacity: 1,
    backgroundColor: '#8C95AB',
  },
  switchBase: {
    color: '#BEC9E1',

    '&.Mui-checked': {
      color: '#6FABD6 !important',
      '& + .MuiSwitch-track': {
        opacity: '1 !important',
        backgroundColor: `${'#5480A4'} !important`,
      },
    },

    '&.Mui-disabled': {
      color: '#5F6570 !important',
      '& + .MuiSwitch-track': {
        opacity: 1,
        backgroundColor: '#5F677E !important',
      },

      '&.Mui-checked': {
        color: '#6E8BB0 !important',
        '& + .MuiSwitch-track': {
          opacity: '1 !important',
          backgroundColor: `${'#314761 !important'}`,
        },
      },
    },
  },
});
