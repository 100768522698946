import { equals } from 'ramda';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { Route, Switch, useRouteMatch } from 'react-router-dom';

import { AlarmAutomationParams } from '../AlarmAutomation';
import { AlarmAutomationEdit } from '../Edit';
import { AlarmAutomationGeneral } from '../General';

import { PageLoader, NoResults } from '~components';
import { appDetailRoutes } from '~constants';
import { boundActions, selectors } from '~store';

export const AlarmAutomationDetails = () => {
  const { id }: AlarmAutomationParams = useParams();
  const alarmAutomationId = parseInt(id);
  const alarmAutomation = useSelector(selectors.alarmAutomation.getAlarmAutomationById(alarmAutomationId), equals);
  const { details: isLoading } = useSelector(selectors.alarmAutomation.getAlarmAutomationLoading, equals);
  const isPageNotFound = useSelector(selectors.page.isPageNotFound);
  const match = useRouteMatch();

  useEffect(() => {
    if (!alarmAutomation) {
      boundActions.alarmAutomation.fetchAlarmAutomationByIdInit(alarmAutomationId);
    }
  }, []);

  if (isPageNotFound) {
    return <NoResults backTo={appDetailRoutes.settingsAlarmAutomation} panelTitle="Alarm Automation" />;
  }

  if (isLoading || !alarmAutomation) {
    return <PageLoader />;
  }

  return (
    <Switch>
      <Route exact path={match.path}>
        <AlarmAutomationGeneral alarmAutomation={alarmAutomation} />
      </Route>

      <Route exact path={`${match.path}/edit`}>
        <AlarmAutomationEdit alarmAutomation={alarmAutomation} />
      </Route>
    </Switch>
  );
};
