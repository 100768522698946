import beepSound from '~assets/media/beep.mp3';
import signal1 from '~assets/media/signal_1.mp3';
import signal10 from '~assets/media/signal_10.mp3';
import signal2 from '~assets/media/signal_2.mp3';
import signal3 from '~assets/media/signal_3.mp3';
import signal4 from '~assets/media/signal_4.mp3';
import signal5 from '~assets/media/signal_5.mp3';
import signal6 from '~assets/media/signal_6.mp3';
import signal7 from '~assets/media/signal_7.mp3';
import signal8 from '~assets/media/signal_8.mp3';
import signal9 from '~assets/media/signal_9.mp3';

export const getFACPSoundSrc = (type = 0) => {
  switch (type) {
    case 1: {
      return signal1;
    }

    case 2: {
      return signal2;
    }

    case 3: {
      return signal3;
    }

    case 4: {
      return signal4;
    }

    case 5: {
      return signal5;
    }

    case 6: {
      return signal6;
    }

    case 7: {
      return signal7;
    }

    case 8: {
      return signal8;
    }

    case 9: {
      return signal9;
    }

    case 10: {
      return signal10;
    }

    default:
      return beepSound;
  }
};
