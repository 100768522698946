import { Theme } from '@material-ui/core';
import { createStyles } from '@material-ui/styles';

export const styles = ({ spacing }: Theme) =>
  createStyles({
    root: {
      '&  .MuiTextField-root': {
        marginBottom: 0,
      },
    },

    deleteButton: {
      marginTop: 12,
      alignSelf: 'flex-start',
    },

    addButton: {
      marginTop: spacing(-3),
    },
  });
