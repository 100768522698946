import { Button, ButtonGroup, IconButton, Typography, Grid, withStyles, Theme, WithStyles } from '@material-ui/core';
import { Fragment, useMemo } from 'react';

import { styles } from './TabSections.styles';

import { Icon, LastDataUpdatedFormPanelSubtitle } from '~ui-kit';
import { getCountTitle } from '~utils';

export const GridWrapper = withStyles((theme: Theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: theme.spacing(2),
    minHeight: 36,
  },
}))(Grid);

export type TabSectionItem<T> = {
  label: string;
  title?: string;
  tooltip: string;
  section: T;
  hideIcon?: boolean;
  hideExport?: boolean;
  lastUpdatedTime?: boolean;
};

const ExportButton = withStyles((theme: Theme) => ({
  root: {
    marginLeft: theme.spacing(2),
  },
}))(Button);

type TabSectionsProps = WithStyles<typeof styles> & {
  section: string;
  items: TabSectionItem<string>[];
  onChange(section: string): void;
  onExport?: () => void;
  onOpen?: (section: string) => void;
  countNumber?: number;
  timeFormat?: string;
  lastUpdatedTime?: number;
};

export const TabSections = withStyles(styles)(
  ({
    section,
    countNumber = 0,
    onChange,
    onExport,
    onOpen,
    items,
    timeFormat,
    lastUpdatedTime,
    classes,
  }: TabSectionsProps) => {
    const selected = useMemo(() => items.find(config => config.section === section), [section]);
    const countTitle = getCountTitle(countNumber, selected?.title || selected?.label);

    return (
      <Fragment>
        <GridWrapper>
          <Typography color="primary" variant="h3">
            {countTitle}

            {!selected?.hideIcon && (
              <IconButton onClick={() => onOpen?.(section)}>
                <Icon icon="info" />
              </IconButton>
            )}

            {!!countNumber && onExport && !selected?.hideExport && (
              <ExportButton size="small" variant="contained" color="primary" onClick={() => onExport?.()}>
                Export
              </ExportButton>
            )}
          </Typography>

          <ButtonGroup>
            {items.map(config => (
              <Button
                size="small"
                color="primary"
                variant={config.section === section ? 'contained' : 'outlined'}
                key={config.section}
                onClick={() => onChange(config.section)}
              >
                {config.label}
              </Button>
            ))}
          </ButtonGroup>
        </GridWrapper>
        {selected?.lastUpdatedTime && (
          <LastDataUpdatedFormPanelSubtitle
            title="Last Updated: "
            timestamp={lastUpdatedTime}
            timeFormat={timeFormat as string}
            className={classes.timestamp}
          />
        )}
      </Fragment>
    );
  }
);
