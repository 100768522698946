import { createSlice, createEntityAdapter, createDraftSafeSelector, PayloadAction, AnyAction } from '@reduxjs/toolkit';
import { push } from 'connected-react-router';
import { ActionsObservable, combineEpics, StateObservable } from 'redux-observable';
import { of } from 'rxjs';
import { ajax } from 'rxjs/ajax';
import { catchError, filter, mergeMap, delay } from 'rxjs/operators';

import { getImportCSVUnitsNotificationConfig } from '~features/businessUnits/utils';

import { actions as notificationsActions } from '../notifications/notificationsSlice';
import { actions as pageActions } from '../page/pageSlice';
import { getPaginationQueryParams } from '../page/pageUtils';
import { actions as sharedActions } from '../sharedSlice';

import { api, appDetailRoutes, appRoutes } from '~constants';
import {
  PaginationRequestPayload,
  PaginationResponse,
  ResponseError,
  PageFilters,
  PageSorting,
  User,
  Role,
  UserHistory,
} from '~models';
import { http } from '~services';
import { getResponseError, getResponsePayload, saveFile } from '~utils';

const usersEntity = createEntityAdapter<User>({
  selectId: user => user.id,
});

type UsersState = {
  data: ReturnType<typeof usersEntity.getInitialState>;
  roles: Role[];
  history: UserHistory[];
  loading: {
    list: boolean;
    details: boolean;
    roles: boolean;
    create: boolean;
    update: boolean;
    delete: boolean;
    forceLogout: boolean;
    resetPassword: boolean;
    history: boolean;
    activate: boolean;
		resetMFA: boolean;
    import: {
      template: boolean;
      users: boolean;
    };
    export: {
      CSVFiles: boolean;
      auditTrail: boolean;
    };
  };
  status: 'logged-out' | 'reseted' | 'users-imported' | 'found' | 'created' | 'mfa-reseted' | null;
  error: ResponseError | null;
};

export const initialState: UsersState = {
  data: usersEntity.getInitialState(),
  roles: [],
  history: [],
  loading: {
    list: false,
    details: false,
    roles: false,
    create: false,
    update: false,
    delete: false,
    forceLogout: false,
    resetPassword: false,
    history: false,
    activate: false,
    resetMFA: false,
    import: {
      template: false,
      users: false,
    },
    export: {
      CSVFiles: false,
      auditTrail: false,
    },
  },
  status: null,
  error: null,
};

export const { name, reducer, actions } = createSlice({
  name: 'users',
  initialState,
  reducers: {
    // Fetch users
    fetchUsersInit(state, action: PayloadAction<PaginationRequestPayload & PageFilters & PageSorting>) {
      state.loading.list = true;
      state.error = null;
    },
    fetchUsersSuccess(state, { payload: { content } }: PayloadAction<PaginationResponse<User>>) {
      state.loading.list = false;
      state.data = usersEntity.setAll(state.data, content);
    },
    fetchUsersFailed(state, action: PayloadAction<ResponseError>) {
      state.loading.list = false;
    },

    // Fetch user
    fetchUserDetailsInit(state, action: PayloadAction<User['id']>) {
      state.loading.details = true;
      state.error = null;
    },
    fetchUserDetailsSuccess(state, { payload }: PayloadAction<User>) {
      state.loading.details = false;

      if (state.data.entities[payload.id]) {
        state.data = usersEntity.updateOne(state.data, {
          id: payload.id,
          changes: payload,
        });
      } else {
        state.data = usersEntity.addOne(state.data, payload);
      }
    },
    fetchUserDetailsFailed(state, action: PayloadAction<ResponseError>) {
      state.loading.details = false;
    },

    // Fetch user by username
    fetchUserByUsernameInit(state, action: PayloadAction<User['username']>) {
      state.status = null;
      state.loading.details = true;
      state.error = null;
    },
    fetchUserByUsernameSuccess(state) {
      state.status = 'found';
    },

    // Fetch available user roles
    fetchAvailableUserRolesInit(state) {
      state.loading.roles = true;
    },
    fetchAvailableUserRolesSuccess(state, { payload }: PayloadAction<Role[]>) {
      state.loading.roles = false;
      state.roles = payload;
    },
    fetchAvailableUserRolesFailed(state, action: PayloadAction<ResponseError>) {
      state.loading.roles = false;
    },

    // Create user
    createUserInit(state, action: PayloadAction<Partial<User>>) {
      state.loading.create = true;
      state.error = null;
    },
    createUserSuccess(state) {
      state.loading.create = false;
      state.status = 'created';
    },
    createUserFailed(state, { payload }: PayloadAction<ResponseError>) {
      state.loading.create = false;
      state.error = payload;
    },

    // Update user
    updateUserInit(state, action: PayloadAction<Partial<User>>) {
      state.loading.update = true;
      state.error = null;
    },
    updateUserSuccess(state, { payload }: PayloadAction<User>) {
      state.loading.update = false;
      state.data = usersEntity.updateOne(state.data, { changes: payload, id: payload.id });
    },
    updateUserFailed(state, { payload }: PayloadAction<ResponseError>) {
      state.loading.update = false;
      state.error = payload;
    },

    // Force logout
    forceUserLogoutInit(state, { payload }: PayloadAction<User['id']>) {
      state.status = null;
      state.loading.forceLogout = true;
      state.data = usersEntity.updateOne(state.data, {
        id: payload,
        changes: { meta: { isLoading: true } },
      });
    },
    forceUserLogoutSuccess(state, { payload }: PayloadAction<User['id']>) {
      state.status = 'logged-out';
      state.loading.forceLogout = false;
      state.data = usersEntity.updateOne(state.data, {
        id: payload,
        changes: { meta: { isLoading: false } },
      });
      localStorage.clear();
    },
    forceUserLogoutFailed(state, { payload: { id } }: PayloadAction<{ id: User['id']; error: ResponseError }>) {
      state.loading.forceLogout = false;
      state.data = usersEntity.updateOne(state.data, {
        id,
        changes: { meta: { isLoading: false } },
      });
    },

    // Delete user
    deleteUserInit(state, action: PayloadAction<User['id']>) {
      state.loading.delete = true;
    },
    deleteUserSuccess(state, { payload }: PayloadAction<User['id']>) {
      state.loading.delete = false;
      state.data = usersEntity.removeOne(state.data, payload);
    },
    deleteUserFailed(state, action: PayloadAction<ResponseError>) {
      state.loading.delete = false;
    },

    // Reset password
    resetPasswordInit(state, action: PayloadAction<User['id']>) {
      state.loading.resetPassword = true;
      state.status = null;
    },
    resetPasswordSuccess(state) {
      state.loading.resetPassword = false;
      state.status = 'reseted';
    },
    resetPasswordFailed(state, action: PayloadAction<ResponseError>) {
      state.loading.resetPassword = false;
    },

    // Fetch users history
    fetchUsersHistoryInit(state, action: PayloadAction<PaginationRequestPayload & PageFilters & PageSorting>) {
      state.loading.history = true;
      state.error = null;
    },
    fetchUsersHistorySuccess(state, { payload: { content } }: PayloadAction<PaginationResponse<UserHistory>>) {
      state.loading.history = false;
      state.history = content;
    },
    fetchUsersHistoryFailed(state, action: PayloadAction<ResponseError>) {
      state.loading.history = false;
    },

    // Import CSV file
    importCSVFilesInit: (state, action: PayloadAction<{ files: File[] }>) => {
      state.loading.import.users = true;
      state.status = null;
    },
    importCSVFilesSuccess: state => {
      state.loading.import.users = false;
      state.status = 'users-imported';
    },
    importCSVFilesFailed: (state, action: PayloadAction<ResponseError>) => {
      state.loading.import.users = false;
    },

    // Export CSV file
    exportCSVFilesInit(state, { payload: { rolesToExport } }: PayloadAction<{ rolesToExport: string }>) {
      state.loading.export.CSVFiles = true;
    },
    exportCSVFilesSuccess(state) {
      state.loading.export.CSVFiles = false;
    },
    exportCSVFilesFailed(state, { payload }: PayloadAction<{ error: ResponseError }>) {
      state.loading.export.CSVFiles = false;
    },

    // Export template
    exportXLSTemplateInit: state => {
      state.loading.import.template = true;
    },
    exportXLSTemplateSuccess: state => {
      state.loading.import.template = false;
    },
    exportXLSTemplateFailed: (state, action: PayloadAction<ResponseError>) => {
      state.loading.import.template = false;
    },

    // Export Audit Trail
    exportAuditTrailInit(state, { payload }: PayloadAction<{ fromDate: string; toDate: string }>) {
      state.loading.export.auditTrail = true;
    },
    exportAuditTrailSuccess(state) {
      state.loading.export.auditTrail = false;
    },
    exportAuditTrailFailed(state, { payload }: PayloadAction<{ error: ResponseError }>) {
      state.loading.export.auditTrail = false;
      state.error = payload.error;
    },

    activateUserInit(
      state,
      action: PayloadAction<{ userId: User['id']; isEnabled: boolean; user: User }>
    ) {
      state.loading.activate = true;
    },

    activateUserFailed(state, { payload }: PayloadAction<ResponseError>) {
      state.loading.activate = false;
    },

    // Reset MFA
    resetMFAInit(state, action: PayloadAction<User['id']>) {
      state.loading.resetMFA = true;
      state.status = null;
    },
    resetMFASuccess(state) {
      state.loading.resetMFA = false;
      state.status = 'mfa-reseted';
    },
    resetMFAFailed(state, { payload }: PayloadAction<ResponseError>) {
      state.loading.resetMFA = false;
      state.error = payload;
    },

    // Reset status
    resetStatus(state) {
      state.status = null;
    },

    // Reset
    reset(state) {
      Object.assign(state, initialState);
    },
  },
  extraReducers: {
    [sharedActions.reset.toString()]: state => {
      Object.assign(state, initialState);
    },
  },
});

const getUsersState = (state: AES.RootState) => state.users;
const getUsersData = createDraftSafeSelector(getUsersState, state => state.data);
const usersEntitiesSelectors = usersEntity.getSelectors();
export const selectors = {
  getUsersState,

  getUsersData,
  getLoaders: createDraftSafeSelector(getUsersState, state => state.loading),
  getUsersList: createDraftSafeSelector(getUsersData, usersEntitiesSelectors.selectAll),
  getUserById: (id: User['id']) =>
    createDraftSafeSelector(getUsersData, data => usersEntitiesSelectors.selectById(data, id)),

  getAvailableUserRoles: createDraftSafeSelector(getUsersState, state => state.roles),

  getUsersStatus: createDraftSafeSelector(getUsersState, state => state.status),
  isStatusFound: createDraftSafeSelector(getUsersState, state => state.status === 'found'),
  getUsersError: createDraftSafeSelector(getUsersState, state => state.error),

  getUsersHistory: createDraftSafeSelector(getUsersState, state => state.history),
  areUsersImported: createDraftSafeSelector(getUsersState, state => state.status === 'users-imported'),
};

const fetchUsersEpic = (action$: ActionsObservable<AnyAction>) =>
  action$.pipe(
    filter(actions.fetchUsersInit.match),
    mergeMap(({ payload }) => {
      const searchParams = getPaginationQueryParams(payload);

      return http.getJSON<PaginationResponse<User>>(api.users.list(searchParams)).pipe(
        mergeMap(payload => of(pageActions.setPagePagination(payload), actions.fetchUsersSuccess(payload))),
        catchError(err => of(actions.fetchUsersFailed(getResponseError(err))))
      );
    })
  );

const fetchUserDetailsEpic = (action$: ActionsObservable<AnyAction>) =>
  action$.pipe(
    filter(actions.fetchUserDetailsInit.match),
    mergeMap(({ payload }) =>
      http.getJSON<User>(api.users.byId(payload)).pipe(
        mergeMap(user => of(actions.fetchUserDetailsSuccess(user))),
        catchError(err => of(actions.fetchUserDetailsFailed(getResponseError(err)), pageActions.setPageNotFound()))
      )
    )
  );

const fetchUserByUsernameEpic = (action$: ActionsObservable<AnyAction>) =>
  action$.pipe(
    filter(actions.fetchUserByUsernameInit.match),
    mergeMap(({ payload }) =>
      http.getJSON<User>(api.users.byUsername(payload)).pipe(
        mergeMap(user => of(
          actions.fetchUserDetailsSuccess(user),
          actions.fetchUserByUsernameSuccess(),
          notificationsActions.enqueue({
            message: `User ${payload} found`,
            options: { variant: 'success' },
          }),
        )),
        catchError(err => of(actions.fetchUserDetailsFailed(getResponseError(err))))
      )
    )
  );

export const fetchAvailableUserRolesEpic = (action$: ActionsObservable<AnyAction>) =>
  action$.pipe(
    filter(actions.fetchAvailableUserRolesInit.match),
    mergeMap(() =>
      http.getJSON<Role[]>(api.users.roles).pipe(
        mergeMap(payload => of(actions.fetchAvailableUserRolesSuccess(payload))),
        catchError(err => of(actions.fetchAvailableUserRolesFailed(getResponseError(err))))
      )
    )
  );

export const createUserEpic = (action$: ActionsObservable<AnyAction>) =>
  action$.pipe(
    filter(actions.createUserInit.match),
    mergeMap(({ payload }) =>
      http.post(api.users.create, payload).pipe(
        mergeMap(() =>
          of(
            actions.createUserSuccess(),
            notificationsActions.enqueue({
              message: 'User successfully created',
              options: {
                variant: 'success',
              },
            }),
            push(appRoutes.users)
          )
        ),
        catchError(err => of(actions.createUserFailed(getResponseError(err))))
      )
    )
  );

export const updateUserEpic = (action$: ActionsObservable<AnyAction>) =>
  action$.pipe(
    filter(actions.updateUserInit.match),
    mergeMap(({ payload }) =>
      http.put(api.users.byId(payload.id as number), payload).pipe(
        mergeMap(res =>
          of(
            actions.updateUserSuccess(getResponsePayload(res)),
            notificationsActions.enqueue({
              message: 'User successfully updated',
              options: {
                variant: 'success',
              },
            }),
            push(appDetailRoutes.userDetails(payload.id as number))
          )
        ),
        catchError(err => of(actions.updateUserFailed(getResponseError(err))))
      )
    )
  );

export const forceUserLogoutEpic = (action$: ActionsObservable<AnyAction>) =>
  action$.pipe(
    filter(actions.forceUserLogoutInit.match),
    mergeMap(({ payload }) =>
      http.post(api.users.forceLogout(payload)).pipe(
        mergeMap(() =>
          of(
            actions.forceUserLogoutSuccess(payload),
            notificationsActions.enqueue({ message: 'User successfully logged out', options: { variant: 'success' } }),
            actions.fetchUserDetailsInit(payload)
          )
        ),
        catchError(err => of(actions.forceUserLogoutFailed({ id: payload, error: getResponseError(err) })))
      )
    )
  );

export const deleteUserEpic = (action$: ActionsObservable<AnyAction>) =>
  action$.pipe(
    filter(actions.deleteUserInit.match),
    mergeMap(({ payload }) =>
      http.delete(api.users.byId(payload)).pipe(
        mergeMap(() => of(actions.deleteUserSuccess(payload), push(appRoutes.users))),
        catchError(err => of(actions.deleteUserFailed(getResponseError(err))))
      )
    )
  );

export const resetUserPasswordEpic = (action$: ActionsObservable<AnyAction>) =>
  action$.pipe(
    filter(actions.resetPasswordInit.match),
    mergeMap(({ payload }) =>
      http.put(api.users.resetPassword(payload)).pipe(
        mergeMap(() =>
          of(
            actions.resetPasswordSuccess(),
            notificationsActions.enqueue({
              message: 'Password has been reset to default',
              options: {
                variant: 'success',
              },
            })
          )
        ),
        catchError(err => of(actions.resetPasswordFailed(getResponseError(err))))
      )
    )
  );

const fetchUsersHistoryEpic = (action$: ActionsObservable<AnyAction>) =>
  action$.pipe(
    filter(actions.fetchUsersHistoryInit.match),
    mergeMap(({ payload }) => {
      const searchParams = getPaginationQueryParams(payload);

      return http.getJSON<PaginationResponse<UserHistory>>(api.users.historyList(searchParams)).pipe(
        mergeMap(payload => of(pageActions.setPagePagination(payload), actions.fetchUsersHistorySuccess(payload))),
        catchError(err => of(actions.fetchUsersHistoryFailed(getResponseError(err))))
      );
    })
  );

const importCSVFilesEpic = (action$: ActionsObservable<AnyAction>, state$: StateObservable<AES.RootState>) =>
  action$.pipe(
    filter(actions.importCSVFilesInit.match),
    mergeMap(({ payload: { files } }) => {
      const formData = new FormData();

      files.forEach(file => formData.append('file', file, file.name));

      return ajax({
        method: 'POST',
        url: api.users.import,
        body: formData,
        headers: {
          Authorization: `Bearer ${state$.value.auth.accessToken}`,
        },
      }).pipe(
        delay(300),
        mergeMap(res => {
          const { variant, message } = getImportCSVUnitsNotificationConfig(res.response);

          return of(actions.importCSVFilesSuccess(), notificationsActions.enqueue({ message, options: { variant } }));
        }),
        catchError(err =>
          of(
            actions.importCSVFilesFailed(getResponseError(err)),
            notificationsActions.enqueue({ message: getResponseError(err).message, options: { variant: 'error' } })
          )
        )
      );
    })
  );

const exportCSVFilesEpic = (actions$: ActionsObservable<AnyAction>, state$: StateObservable<AES.RootState>) =>
  actions$.pipe(
    filter(actions.exportCSVFilesInit.match),
    mergeMap(({ payload: { rolesToExport } }) =>
      http
        .call({
          method: 'GET',
          url: api.users.export(rolesToExport),
          responseType: 'blob' as 'json',
        })
        .pipe(
          mergeMap(res => {
            const fileName = 'users.csv';

            saveFile(res.response, 'users.csv');

            return of(
              actions.exportCSVFilesSuccess(),
              notificationsActions.enqueue({
                message: `${fileName} has been exported`,
                options: {
                  variant: 'success',
                },
              })
            );
          }),
          catchError(err => of(actions.exportCSVFilesFailed({ error: getResponseError(err) })))
        )
    )
  );

const exportXLSTemplateEpic = (action$: ActionsObservable<AnyAction>) =>
  action$.pipe(
    filter(actions.exportXLSTemplateInit.match),
    mergeMap(() =>
      http
        .call({
          method: 'GET',
          url: api.users.importTemplate,
          responseType: 'blob' as 'json',
        })
        .pipe(
          mergeMap(res => {
            const fileName = 'user_template.xlsx';
            saveFile(res.response, 'user_template.xlsx');

            return of(
              actions.exportXLSTemplateSuccess(),
              notificationsActions.enqueue({
                message: `${fileName} has been exported`,
                options: { variant: 'success' },
              })
            );
          }),
          catchError(err => of(actions.exportXLSTemplateFailed(getResponseError(err))))
        )
    )
  );
const exportAuditTrailEpic = (actions$: ActionsObservable<AnyAction>, state$: StateObservable<AES.RootState>) =>
  actions$.pipe(
    filter(actions.exportAuditTrailInit.match),
    mergeMap(({ payload: { fromDate, toDate } }) =>
      http
        .call({
          method: 'GET',
          url: api.users.exportAuditTrail(fromDate, toDate),
          responseType: 'blob' as 'json',
        })
        .pipe(
          mergeMap(res => {
            const fileName = 'AuditTrail.csv';

            saveFile(res.response, 'AuditTrail.csv');

            return of(
              actions.exportAuditTrailSuccess(),
              notificationsActions.enqueue({
                message: `${fileName} has been exported`,
                options: {
                  variant: 'success',
                },
              })
            );
          }),
          catchError(err => of(actions.exportAuditTrailFailed({ error: getResponseError(err) })))
        )
    )
  );

export const activateUserEpic = (action$: ActionsObservable<AnyAction>) =>
  action$.pipe(
    filter(actions.activateUserInit.match),
    mergeMap(({ payload: { userId, isEnabled, user } }) => {
      const status = isEnabled ? 'Inactivated' : 'Activated';
      const payload = { ...user, isEnabled: !isEnabled };
      return http.patch(api.users.activate(userId), { accountStatus: { isEnabled: !isEnabled } }).pipe(
        mergeMap(() =>
          of(
            actions.updateUserInit(payload),
            notificationsActions.enqueue({
              message: `User access has been ${status}`,
              options: {
                variant: 'success',
              },
            })
          )
        ),
        catchError(err => of(actions.activateUserFailed(getResponseError(err))))
      );
    })
  );

export const resetUserMFAEpic = (action$: ActionsObservable<AnyAction>) =>
  action$.pipe(
    filter(actions.resetMFAInit.match),
    mergeMap(({ payload }) =>
      http.put(api.users.resetMFA(payload)).pipe(
        mergeMap(() =>
          of(
            actions.resetMFASuccess(),
            notificationsActions.enqueue({
              message: '2FA has been reset',
              options: {
                variant: 'success',
              },
            })
          )
        ),
        catchError(err => of(actions.resetMFAFailed(getResponseError(err))))
      )
    )
  );

export const epics = combineEpics(
  fetchUsersEpic,
  fetchUserDetailsEpic,
  fetchUserByUsernameEpic,
  fetchAvailableUserRolesEpic,
  createUserEpic,
  updateUserEpic,
  forceUserLogoutEpic,
  deleteUserEpic,
  resetUserPasswordEpic,
  fetchUsersHistoryEpic,
  importCSVFilesEpic,
  exportCSVFilesEpic,
  exportXLSTemplateEpic,
  exportAuditTrailEpic,
  activateUserEpic,
  resetUserMFAEpic,
);

export const allEpics = {
  fetchUsersEpic,
  fetchUserDetailsEpic,
  fetchUserByUsernameEpic,
  fetchAvailableUserRolesEpic,
  createUserEpic,
  updateUserEpic,
  forceUserLogoutEpic,
  deleteUserEpic,
  resetUserPasswordEpic,
  fetchUsersHistoryEpic,
  importCSVFilesEpic,
  exportCSVFilesEpic,
  exportXLSTemplateEpic,
  exportAuditTrailEpic,
  activateUserEpic,
  resetUserMFAEpic,
};

declare global {
  namespace AES {
    export interface Actions {
      users: typeof actions;
    }

    export interface RootState {
      users: UsersState;
    }

    export interface Selectors {
      users: typeof selectors;
    }
  }
}
