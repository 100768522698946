import { Grid, GridProps, Typography } from '@material-ui/core';
import { equals } from 'ramda';
import { useSelector } from 'react-redux';

import { selectors } from '~store';
import { formatDate } from '~utils';

export type LastDataUpdatedFormPanelSubtitleProps = Partial<GridProps> & {
  title?: string;
  timestamp?: number;
  timeFormat: string;
};

export const LastDataUpdatedFormPanelSubtitle = ({
  title = 'Last data received: ',
  timestamp,
  timeFormat,
  ...props
}: LastDataUpdatedFormPanelSubtitleProps) => {
  const timeZone = useSelector(selectors.profile.getUserTimeZone, equals);

  return (
    <Grid {...props}>
      {title}

      <Typography variant="subtitle1" color="textPrimary" style={{ display: 'inline' }}>
        {timestamp ? formatDate(timestamp, false, timeFormat, timeZone) : ''}
      </Typography>
    </Grid>
  );
};

