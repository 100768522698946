import { Field, FieldProps, useFormikContext } from 'formik';
import { useCallback } from 'react';

import { Chips, ChipsProps } from '../Chips/Chips';
import { SelectOption } from '../Select/Select';

export type FormChipsFieldProps = Partial<ChipsProps> & {
  name: string;
};

export const FormChipsField = ({ name, options = [], ...props }: FormChipsFieldProps) => {
  const { setFieldValue } = useFormikContext();

  const onSelect = useCallback((selected: SelectOption['value'][]) => {
    setFieldValue(name, selected);
  }, []);

  return (
    <Field name={name}>
      {({ field: { value } }: FieldProps) => (
        <Chips selected={value} options={options} onSelect={onSelect} {...props} />
      )}
    </Field>
  );
};
