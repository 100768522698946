import { createStyles, Theme } from '@material-ui/core';

export const styles = ({ spacing }: Theme) =>
  createStyles({
    paper: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      padding: spacing(7),
    },

    title: {
      marginBottom: spacing(5),
    },

    content: {
      marginBottom: spacing(3),
    },

    confirmBtn: {
      marginTop: spacing(7),
      marginBottom: spacing(3),
    }
  });
