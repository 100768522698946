import { withStyles, WithStyles } from '@material-ui/core';
import { PropsWithChildren } from 'react';

import { styles } from './InformationBadge.styles';

import { IconType } from '~icons';
import { Icon } from '~ui-kit';

export type InformationBadgeProps = WithStyles<typeof styles> &
  PropsWithChildren<{
    icon?: IconType;
  }>;

export const InformationBadge = withStyles(styles)(({ classes, icon = 'info', children }: InformationBadgeProps) => (
  <div className={classes.root}>
    <div className={classes.inner}>
      <Icon icon={icon} classes={{ root: classes.icon }} />
      <p className={classes.description}>{children}</p>
    </div>
  </div>
));
