import { FormControlLabel, withStyles, FormHelperText } from '@material-ui/core';
import { WithStyles } from '@material-ui/styles';
import clsx from 'clsx';
import { Field } from 'formik';

import { styles } from './FormCheckbox.styles';

import { Checkbox, CheckboxProps } from '~ui-kit';

export type FormCheckboxProps = WithStyles<typeof styles> &
  Partial<CheckboxProps> & {
    name: string;
    label: string;
    helperText?: string;
  };

export const FormCheckbox = withStyles(styles)(({ name, label, classes, ...props }: FormCheckboxProps) => (
  <Field name={name}>
    {({ field, meta: { error } }) => (
      <div className={classes.root}>
        <FormControlLabel
          classes={{ root: clsx(classes.control) }}
          control={<Checkbox {...props} {...field} checked={field.value} />}
          label={label}
        />
        <FormHelperText error={Boolean(error)}>{error || props.helperText}</FormHelperText>
      </div>
    )}
  </Field>
));
