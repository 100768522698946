import { Theme } from '@material-ui/core';
import { CSSProperties } from '@material-ui/styles';

export const styles = ({ palette: { background, primary, text }, spacing }: Theme) => ({
  chart: {
    display: 'inline-block',
    alignItems: 'center',
    padding: spacing(3),
    marginBottom: spacing(2),
    background: background.paper,
    '& #center-center': {
      height: 250,
      minWidth: 500,
    },
  },
  titleText: {
    textAlign: 'left',
    color: primary.main,
    fontWeight: 500,
    fontSize: '18px',
  } as CSSProperties,
  axisLabel: {
    fontStyle: 'italic',
  },
  pieText: {
    fill: text.primary,
  },
  pieLine: {
    stroke: text.primary,
    opacity: 0.7,
  },
  rtl: {
    direction: 'rtl',
  } as CSSProperties,
  legend: {
    display: 'flex',
    flexDirection: 'row',
  } as CSSProperties,
  legendItem: {
    width: 'auto',
    paddingRight: 0,
  },
});
