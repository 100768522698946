import { Button, Grid, withStyles } from '@material-ui/core';
import { Form, Formik } from 'formik';
import { Fragment, useState, useCallback } from 'react';

import { PageLoader } from '~components/Page';
import { CustomNote } from '~models/subscriber';

import { DeleteDialog } from '../Dialogs/DeleteDialog';

import { CustomNoteDialog } from './CustomNoteDialog';

import { DataPlaceholder, FormPanelWrapper, FormTextField, Icon } from '~ui-kit';

export type CustomNoteFormProps = {
  customNote: CustomNote[];
  loading: boolean;
  onAddEdit: (values: CustomNote) => void;
  onDelete: (values: CustomNote) => void;
};

const RemoveGrid = withStyles({
  root: { display: 'flex', justifyContent: 'space-evenly', marginBottom: 3, alignItems: 'center' },
})(Grid);

export const CustomNoteForm = ({ customNote, loading, onAddEdit, onDelete }: CustomNoteFormProps) => {
  const [selectedNote, setSelectedNote] = useState<CustomNote | null>(null);
  const [addEditDialogOpened, setAddEditDialogOpened] = useState<boolean>(false);
  const [deleteDialogOpened, setDeleteDialogOpened] = useState<boolean>(false);

  const onDeleteDialogToggle = useCallback(
    (note: CustomNote) => {
      setSelectedNote(note);
      setDeleteDialogOpened(!deleteDialogOpened);
    },
    [deleteDialogOpened]
  );

  const onAddEditDialogToggle = useCallback(
    (note: CustomNote) => {
      setSelectedNote(note);
      setAddEditDialogOpened(!addEditDialogOpened);
    },
    [addEditDialogOpened]
  );

  return (
    <Fragment>
      <Formik
        initialValues={{
          notes: customNote || [],
        }}
        enableReinitialize
        onSubmit={() => {}}
      >
        {({ values }) => (
          <Fragment>
            <Form>
              <FormPanelWrapper
                title="Custom Notes"
                loading={loading}
                renderActions={() => (
                  <Button
                    onClick={() => {
                      setAddEditDialogOpened(true);
                      setSelectedNote(null);
                    }}
                    variant="contained"
                    color="primary"
                    size="small"
                  >
                    Add
                  </Button>
                )}
              >
                {loading ? (
                  <PageLoader />
                ) : values?.notes?.length ? (
                  values?.notes.map((field, i) => {
                    const notesName = `notes[${i}].note`;
                    const titleName = `notes[${i}].title`;

                    return (
                      <Grid container key={i} spacing={2}>
                        <Grid item xs={12} md={3}>
                          <FormTextField label="Title" name={titleName} disabled />
                        </Grid>

                        <Grid item xs={12} md={8}>
                          <FormTextField label="Text" name={notesName} disabled />
                        </Grid>

                        <RemoveGrid item xs={12} md={1}>
                          <Grid onClick={() => onAddEditDialogToggle(field)}>
                            <Icon icon="settings" />
                          </Grid>

                          <Grid onClick={() => onDeleteDialogToggle(field)}>
                            <Icon icon="delete" />
                          </Grid>
                        </RemoveGrid>
                      </Grid>
                    );
                  })
                ) : (
                  <DataPlaceholder />
                )}
              </FormPanelWrapper>
            </Form>

            <CustomNoteDialog
              open={addEditDialogOpened}
              note={selectedNote as CustomNote}
              onSave={values => {
                onAddEdit(values as CustomNote);
                setAddEditDialogOpened(false);
              }}
              onCancel={() => setAddEditDialogOpened(false)}
            />

            <DeleteDialog
              onConfirm={() => {
                onDelete(selectedNote as CustomNote);
                setDeleteDialogOpened(false);
              }}
              onCancel={() => setDeleteDialogOpened(false)}
              open={deleteDialogOpened}
            >
              Are you sure you want to delete Custom Note for {`${selectedNote?.title}`} ?
            </DeleteDialog>
          </Fragment>
        )}
      </Formik>
    </Fragment>
  );
};
