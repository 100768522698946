import { createStyles, Theme } from '@material-ui/core';

import { colors, statusColors, highContrastColors } from '~styles/colors';

import { classNames } from '../muiClassNames';

import { matchStyles } from '~utils';

export const styles = (theme: Theme) => {
  const {
    palette: { type, blueColor, whiteColor },
    spacing,
  } = theme;
  const getStyles = matchStyles(type);

  return createStyles({
    // Card summary
    cardSummaryRoot: {
      // root
      padding: spacing(1, 1, 1, 2),
      boxShadow: colors.BOX_SHADOW,
      borderLeft: `${spacing(1)}px solid ${colors.TRANSPARENT}`,
      minHeight: spacing(7),
      backgroundColor: getStyles(blueColor.main, whiteColor.main),
      borderRadius: spacing(1) / 2,
      [`&.${classNames.expanded}`]: {
        minHeight: spacing(7),
      },
      ...statusColors(theme, 'borderLeftColor'),
      ...highContrastColors(theme),
    },
    cardSummaryContent: {
      // content
      margin: 'auto 0',
      width: 'calc(100% - 50px)',
      [`&.${classNames.expanded}`]: {
        margin: 'auto 0',
      },
    },

    // Inner content
    innerContent: {
      display: 'flex',
      marginRight: 'auto',
      alignItems: 'center',
      minHeight: spacing(5),
      width: '100%',
      ...highContrastColors(theme),
    },

    // Icon
    iconRoot: {
      // root
      padding: spacing(1),
    },
    iconEdgeEnd: {
      // edgeEnd
      marginRight: 0,
      marginLeft: spacing(0.5),
    },
  });
};
