import {
  SubscriberInfoSection,
  SubscriberSettingsSection,
  SubscriberSettingsMode,
} from '~features/subscribers/subscribersSlice';
import { NotificationsListSections } from '~pages/BusinessUnitDetails/Recipients&Triggers/NotificationSections.config';

import {
  ExportUnitsFileType,
  IPLinkHistoryPeriod,
  IPLinksListSection,
  MeshListSections,
  SubscriberHistoryPeriod,
  SubscriberMode,
  SubscribersListSection,
  SubToolsExecuteSection,
  NetworkPulsePeriod,
  ScheduledDBDumpStatus,
  AddOnLicenseType,
  JobType
} from '~models';

const prefix = '/api';

const constructUrl = (...urls: (string | number)[]) => urls.join('/');

const reportUrl = constructUrl(prefix, 'report');
const alarmUrl = constructUrl(prefix, 'alarm');
const systemUrl = constructUrl(prefix, 'system');
const businessUnitsUrl = constructUrl(prefix, 'business-units');
const usersUrl = constructUrl(prefix, 'users');
const settingsUrl = constructUrl(prefix, 'settings');
const subscribersUrl = constructUrl(prefix, 'subscribers');
const ipLinksUrl = constructUrl(prefix, 'ip-links');
const networkHealthScoreUrl = constructUrl(prefix, 'network-health-score');
const hybridsUrl = constructUrl(prefix, 'hybrids');
const databaseMigrationUrl = constructUrl(systemUrl, 'migrate');
const licenseUrl = constructUrl(prefix, 'license');
const dealersUrl = constructUrl(prefix, 'dealers');
const antennasUrl = constructUrl(prefix, 'antennas');
const searchUrl = constructUrl(prefix, 'search');
const controlRelaysUrl = constructUrl(prefix, 'control-relays');
const liveTrafficUrl = constructUrl(prefix, 'live-traffic');
const authUrl = constructUrl(prefix, 'auth');
const auth = {
  login: constructUrl(authUrl, 'login'),
  logout: constructUrl(authUrl, 'logout'),
  mfa: {
    info: constructUrl(authUrl, 'mfa', 'info'),
    register: constructUrl(authUrl, 'mfa', 'registration'),
    validate: constructUrl(authUrl, 'mfa', 'validate'),
    reset: constructUrl(usersUrl, 'profile', 'reset-mfa'),
  },
};
const report = {
  fetch: reportUrl,
  preFetch: constructUrl(reportUrl, 'count'),
};
const alarmAutomation = {
  all: constructUrl(prefix, 'alarm-automation'),
  byId: (id: number) => constructUrl(prefix, 'alarm-automation', id),
  primarySockets: constructUrl(prefix, 'alarm-automation', 'list'),
  connectivity: constructUrl(prefix, 'alarm-automation', 'connectivity'),
};
const alarm = {
  mute: constructUrl(alarmUrl, 'silent'),
  resolve: constructUrl(alarmUrl, 'resolve'),
  acknowledge: constructUrl(alarmUrl, 'acknowledge'),
  acknowledgeAll: constructUrl(alarmUrl, 'acknowledge-all'),
  acknowledgedAlarms: (query: string) => constructUrl(alarmUrl, `acknowledge?${query}`),
  customNotes: (businessUnitId: number, id: number, unitType: string) =>
    constructUrl(businessUnitsUrl, businessUnitId, unitType, id, 'notes'),
  inactiveUnits: (searchParams: string) => `${constructUrl(businessUnitsUrl, 'units', 'inactive')}?${searchParams}`,
};
const system = {
  health: constructUrl(systemUrl, 'health'),
  info: constructUrl(systemUrl, 'info'),
  ledPanel: constructUrl(systemUrl, 'led-panel'),
  databaseMigration: {
    uploadFile: constructUrl(databaseMigrationUrl, 'upload'),
    migrate: databaseMigrationUrl,
    clean: constructUrl(databaseMigrationUrl, 'clean'),
    downloadLog: constructUrl(databaseMigrationUrl, 'log'),
  },
  databaseDumps: {
    all: constructUrl(systemUrl, 'database', 'dump'),
    byId: (fileName: string) => constructUrl(systemUrl, 'database', 'dump', fileName),
    scheduledStatus: constructUrl(settingsUrl, 'dump'),
    schedule: (status: ScheduledDBDumpStatus) => constructUrl(settingsUrl, `dump?jobStatus=${status}`),
  },
  ttlThresholds: constructUrl(systemUrl, 'ttl-thresholds'),
};
const businessUnits = {
  ids: (query: string) => `${constructUrl(businessUnitsUrl, 'ids')}?${query}`,
  byId: (id: number) => constructUrl(businessUnitsUrl, id),
  all: (searchParams: string) => `${businessUnitsUrl}?${searchParams}`,
  listFilters: constructUrl(businessUnitsUrl, 'filters'),
  create: businessUnitsUrl,
  createDefault: constructUrl(businessUnitsUrl, 'default'),
  update: (id: number) => constructUrl(businessUnitsUrl, id),
  health: {
    byBU: (businessUnitId: number) => constructUrl(networkHealthScoreUrl, 'business-unit', businessUnitId, 'latest'),
    ipLinksHybridsByBU: (calls: string, businessUnitId: number, searchParams: string) =>
      `${constructUrl(networkHealthScoreUrl, 'business-unit', businessUnitId, 'latest', calls)}?${searchParams}`,
    exportToCSV: (businessUnitId: number) =>
      constructUrl(networkHealthScoreUrl, 'export', 'business-unit', businessUnitId),
  },
  routes: (id: number) => constructUrl(businessUnitsUrl, id, 'routes'),
  networkPulse: (id: number, period: NetworkPulsePeriod = 'TEN_DAYS') => constructUrl(businessUnitsUrl, id, `network-pulse?period=${period}`),
  networkHealthAnalysis: (businessUnitId: number) =>
    constructUrl(businessUnitsUrl, businessUnitId, 'network-health-analysis'),
  importTemplate: (templateType: string, templateName: string) => constructUrl(businessUnitsUrl, templateType, templateName, 'template'),
  import: (id: number) => constructUrl(businessUnitsUrl, id, 'units', 'import'),
  clearData: (id: number) => constructUrl(businessUnitsUrl, id, 'clean', 'data'),
  export: (id: number, type: ExportUnitsFileType, query) => {
    const params = query ? `?${query}` : '';

    return constructUrl(businessUnitsUrl, id, 'units', 'export', `${type}${params}`);
  },
  nonAES: {
    export: (id: number) => constructUrl(businessUnitsUrl, id, 'non-aes-units', 'export'),
    import: (id: number) => constructUrl(businessUnitsUrl, id, 'non-aes-units', 'import'),
  },
  rfSettings: (id: number, searchParams?: string) =>
    `${constructUrl(businessUnitsUrl, id, 'subscribers', 'rf-settings')}?${searchParams}`,
  ttlSettings: {
    all: (id: number) => constructUrl(businessUnitsUrl, id, 'ttl-settings'),
    subscribers: (id: number, query?: string) => {
      const searchParams = query ? `?${query}` : '';
      return `${constructUrl(businessUnitsUrl, id, 'ttl-settings', 'subscribers')}${searchParams}`;
    },
  },
  activeUsersCount: (id: number) => constructUrl(businessUnitsUrl, id, 'active-users', 'count'),
  signalsLoad: (id: number, searchParams: string) =>
    `${constructUrl(businessUnitsUrl, id, 'signals-loaded')}?${searchParams}`,
  mesh: (id: number, section: MeshListSections, searchParams: string) =>
    `${constructUrl(businessUnitsUrl, id, 'mesh')}/${section}?${searchParams}`,
  notification: (id: number, section: MeshListSections, searchParams: string) =>
    `${constructUrl(businessUnitsUrl, id, 'notifications')}/${section}?${searchParams}`,
  notificationDetails: (businessUnitId: number, id: number, section: string) =>
    constructUrl(businessUnitsUrl, businessUnitId, 'notifications', section, id),
  notificationCreate: (businessUnitId: number, section: string) =>
    constructUrl(businessUnitsUrl, businessUnitId, 'notifications', section),
  notificationAssociated: (businessUnitId: number, id: number, section: string) =>
    constructUrl(businessUnitsUrl, businessUnitId, 'notifications', section, id, 'associations'),
  fetchUnitsByRange: (buId: number, path: string, query: string) =>
    `${constructUrl(businessUnitsUrl, buId, path, 'range')}?${query}`,
  findUnitById: (buId: number, unitId: number) => constructUrl(businessUnitsUrl, buId, 'units', unitId),
  netCon: {
    list: (buId: number, searchParams?: string) =>
      `${constructUrl(businessUnitsUrl, buId, 'subscribers', 'net-con')}?${searchParams}`,
    update: (buId: number, id: number) => constructUrl(businessUnitsUrl, buId, 'subscribers', id, 'net-con'),
  },
  totalSignals: (buId: number, period: string) =>
    constructUrl(businessUnitsUrl, buId, `total-signals-received?period=${period}`),
  subsOverTime: (buId: number, period: string) =>
    constructUrl(businessUnitsUrl, buId, `subscriber-overtime?period=${period}`),
  geoCenter: (buId: number) => constructUrl(businessUnitsUrl, buId, 'coordinates'),
  logo: (buId: number) => constructUrl(businessUnitsUrl, buId, 'logo'),
  badPackets: (businessUnitId: number, searchParams?: string) =>
    `${constructUrl(businessUnitsUrl, businessUnitId, 'bad-packets')}?${searchParams}`,
  importNotifications: (buId: number, section: NotificationsListSections) => constructUrl(businessUnitsUrl, buId, 'notifications', section, 'import'),
  unitsNotifications: {
    list: (buId: number) => constructUrl(businessUnitsUrl, buId, 'units', 'notifications'),
    export: (buId: number) => constructUrl(businessUnitsUrl, buId, 'notifications', 'export'),
    import: (buId: number) => constructUrl(businessUnitsUrl, buId, 'notifications', 'import'),
  },
  unitNotificationInfo: (buId: number, unitId: number, userId: number, path: string) =>
    constructUrl(businessUnitsUrl, buId, path, unitId, 'users', userId, 'notification'),
  mobileCarriers: () => constructUrl(prefix, 'mobile-carriers'),
  bulkFACPImport: (businessUnitId: number, unit: string, skipFirstRow?: boolean) =>
    `${constructUrl(businessUnitsUrl, businessUnitId, 'facp-events', unit, 'import')}?skipFirstRow=${skipFirstRow}`,
  jobsTimestamp: (buId: number, jobType: JobType) =>
    `${constructUrl(businessUnitsUrl, buId, 'jobs')}?job=${jobType}`,
};

const getFaults = (businessUnitId: number, id: number, type: string, params?: string) => {
  const searchParams = params ? `?${params}` : '';

  return `${constructUrl(businessUnitsUrl, businessUnitId, type, id, 'faults')}${searchParams}`;
};
const faults = {
  subscriber: (businessUnitId: number, id: number, searchParams?: string) =>
    getFaults(businessUnitId, id, 'subscribers', searchParams),
  ipLink: (businessUnitId: number, id: number, searchParams?: string) =>
    getFaults(businessUnitId, id, 'ip-links', searchParams),
  hybrid: (businessUnitId: number, id: number, searchParams?: string) =>
    getFaults(businessUnitId, id, 'hybrids', searchParams),
  businessUnit: (id: number, section: string, params?: string) => {
    const searchParams = params ? `${params}` : '';
    return `${constructUrl(businessUnitsUrl, id, section, 'faults')}?${searchParams}`;
  },
  listFilters: (businessUnitId: number, section?: string) => constructUrl(businessUnitsUrl, businessUnitId, section as string, 'faults', 'filters'),
};
const ipLinks = {
  byId: (businessUnitId: number, id: number) => constructUrl(businessUnitsUrl, businessUnitId, 'ip-links', id),
  list: (searchParams: string) => `${ipLinksUrl}?${searchParams}`,
  listFilters: constructUrl(ipLinksUrl, 'filters'),
  ids: (businessUnitId: number, searchParams?: string) =>
    constructUrl(businessUnitsUrl, businessUnitId, 'ip-links', `ids?${searchParams}`),
  byBU: (businessUnitId: number, section: IPLinksListSection, searchParams: string) => {
    const paths = section === 'all' ? [`ip-links?${searchParams}`] : ['ip-links', `${section}?${searchParams}`];

    return constructUrl(businessUnitsUrl, businessUnitId, ...paths);
  },
  reset: (businessUnitId: number, id: number) =>
    constructUrl(businessUnitsUrl, businessUnitId, 'ip-links', id, 'reset'),
  dependencies: (businessUnitId: number, id: number, period: string, searchParams: string) =>
    constructUrl(businessUnitsUrl, businessUnitId, 'ip-links', id, `dependencies?period=${period}&${searchParams}`),
  restore: (businessUnitId: number, id: number) =>
    constructUrl(businessUnitsUrl, businessUnitId, 'ip-links', id, 'restore'),
  eventHistory: {
    all: (businessUnitId: number, id: number, period: IPLinkHistoryPeriod, searchParams: string) =>
      `${constructUrl(businessUnitsUrl, businessUnitId, 'ip-links', id, 'events')}?period=${period}&${searchParams}`,
    export: (businessUnitId: number, id: number, period: IPLinkHistoryPeriod) =>
      `${constructUrl(businessUnitsUrl, businessUnitId, 'ip-links', id, 'events', 'export')}?period=${period}`,
  },
  notifications: (businessUnitId: number, id: number) =>
    constructUrl(businessUnitsUrl, businessUnitId, 'ip-links', id, 'notification'),
  dealers: (businessUnitId: number, id: number) =>
    constructUrl(businessUnitsUrl, businessUnitId, 'ip-links', id, 'dealers'),
  customNote: {
    list: (buId: number, id: number) =>
      constructUrl(businessUnitsUrl, buId, 'ip-links', id, 'notes'),
    byId: (buId: number, id: number, noteId: number) =>
      constructUrl(businessUnitsUrl, buId, 'ip-links', id, 'notes', noteId),
  },
  location: (buId: number, id: number) =>
    constructUrl(businessUnitsUrl, buId, 'ip-links', id, 'location'),
};
const users = {
  list: (search: string) => `${usersUrl}?${search}`,
  current: constructUrl(usersUrl, 'current'),
  byId: (id: number) => constructUrl(usersUrl, id),
  byUsername: (username: string) => constructUrl(usersUrl, `username?username=${username}`),
  profile: constructUrl(usersUrl, 'profile'),
  updatePassword: constructUrl(usersUrl, 'profile', 'password'),
  roles: constructUrl(prefix, 'roles'),
  create: usersUrl,
  forceLogout: (id: number) => constructUrl(usersUrl, id, 'logout'),
  resetPassword: (id: number) => constructUrl(usersUrl, id, 'reset-password'),
  activate: (id: number) => constructUrl(usersUrl, id, 'activate'),
  listFilters: constructUrl(usersUrl, 'filters'),
  historyList: (search: string) => `${usersUrl}/history?${search}`,
  historyListFilters: constructUrl(usersUrl, 'history', 'filters'),
  import: constructUrl(usersUrl, 'import'),
  importTemplate: constructUrl(usersUrl, 'import', 'template'),
  export: (rolesToExport: string) => constructUrl(usersUrl, `export?role=${rolesToExport}`),
  exportAuditTrail: (fromDate: string, toDate: string) =>
    constructUrl(usersUrl, 'history', `export?fromDate=${fromDate}&toDate=${toDate}`),
  resetMFA: (id: number) => constructUrl(usersUrl, id, 'reset-mfa'),
};
const settings = {
  checkIn: constructUrl(settingsUrl, 'check-in'),
  network: constructUrl(settingsUrl, 'network'),
  system: constructUrl(settingsUrl, 'system'),
  server: constructUrl(settingsUrl, 'server'),
  techOptions: constructUrl(settingsUrl, 'tech-option'),
  subTools: constructUrl(settingsUrl, 'sub-tools'),
  coordinate: constructUrl(settingsUrl, 'coordinates'),
  lengthUnit: constructUrl(settingsUrl, 'length-unit'),
  callSign: constructUrl(settingsUrl, 'call-sign'),
  instances: constructUrl(settingsUrl, 'network', 'instances'),
  webRelay: constructUrl(settingsUrl, 'web-relay'),
  maintenance: constructUrl(settingsUrl, 'ssl', 'csr'),
  sslFilesUpload: constructUrl(settingsUrl, 'ssl', 'upload'),
  executeSubTools: (section: SubToolsExecuteSection) => constructUrl(settingsUrl, 'sub-tools', section),
  import: constructUrl(businessUnitsUrl, 'units', 'import'),
  weeklyReport: constructUrl(settingsUrl, 'report'),
  password: constructUrl(settingsUrl, 'password'),
  userInactivity: constructUrl(settingsUrl, 'user-inactive-days'),
  mfa: constructUrl(settingsUrl, 'mfa'),
  tokenExpiration: constructUrl(settingsUrl, 'token-expiration'),
  tcp: {
    status: constructUrl(settingsUrl, 'network', 'tcp'),
    action: (query: string) => constructUrl(settingsUrl, 'network', 'tcp', query),
  },
  selfMonitoring: {
    webRelay: constructUrl(settingsUrl, 'self-monitoring', 'web-relay'),
    monitor: constructUrl(settingsUrl, 'self-monitoring', 'web-relay', 'monitor'),
    highContrast: constructUrl(settingsUrl, 'self-monitoring', 'high-contrast'),
  },
};

const getSubscriberById = (businessUnitId: number, id: number) =>
  constructUrl(businessUnitsUrl, businessUnitId, 'subscribers', id);
const getSubscriberFACP = (businessUnitId: number, id: number, params?: string) => {
  const searchParams = params ? `?${params}` : '';
  return `${constructUrl(businessUnitsUrl, businessUnitId, 'subscribers', id, 'facp-events')}${searchParams}`;
};

const subscribers = {
  all: (searchParams: string) => `${subscribersUrl}?${searchParams}`,
  listFilters: constructUrl(subscribersUrl, 'filters'),
  export: (filter: string) => `${constructUrl(subscribersUrl, 'export')}?${filter}`,
  byBU: (businessUnitId: number, section: SubscribersListSection, searchParams: string) => {
    const paths = section === 'all' ? [`subscribers?${searchParams}`] : ['subscribers', `${section}?${searchParams}`];

    return constructUrl(businessUnitsUrl, businessUnitId, ...paths);
  },
  byId: getSubscriberById,
  routes: (businessUnitId: number, id: number) => constructUrl(getSubscriberById(businessUnitId, id), 'routes'),
  info: (businessUnitId: number, id: number, section: SubscriberInfoSection) =>
    constructUrl(getSubscriberById(businessUnitId, id), section),
  settings: (businessUnitId: number, id: number, section: SubscriberSettingsSection) =>
    constructUrl(getSubscriberById(businessUnitId, id), section),
  settingsDefault: (businessUnitId: number, id: number, section: SubscriberSettingsSection) =>
    constructUrl(getSubscriberById(businessUnitId, id), section, 'default'),
  settingsTimingDefault: constructUrl(subscribersUrl, 'timing', 'default'),
  settingsMode: (businessUnitId: number, id: number, mode: SubscriberSettingsMode) =>
    constructUrl(getSubscriberById(businessUnitId, id), 'modes', mode),
  settingsRemoteReset: (businessUnitId: number, id: number) =>
    constructUrl(getSubscriberById(businessUnitId, id), 'remote-reset'),
  settingsRf: (businessUnitId: number, id: number, path: string) =>
    constructUrl(getSubscriberById(businessUnitId, id), path),
  zonesConfiguration: (businessUnitId: number, id: number) =>
    constructUrl(getSubscriberById(businessUnitId, id), 'zone'),
  messages: (businessUnitId: number, id: number) => constructUrl(getSubscriberById(businessUnitId, id), 'messages'),
  activate: (businessUnitId: number, id: number, query: string) =>
    `${constructUrl(getSubscriberById(businessUnitId, id), 'activate')}?${query}`,
  dealers: (businessUnitId: number, id: number) => constructUrl(getSubscriberById(businessUnitId, id), 'dealers'),

  subscribersIds: (businessUnitId: number, searchParams: string) =>
    `${constructUrl(businessUnitsUrl, businessUnitId, 'subscribers', 'ids')}?${searchParams}`,
  eventHistory: {
    all: (businessUnitId: number, id: number, period: SubscriberHistoryPeriod, searchParams: string) =>
      `${constructUrl(getSubscriberById(businessUnitId, id), 'events')}?period=${period}&${searchParams}`,
    export: (buId: number, id: number, period: SubscriberHistoryPeriod) =>
      `${constructUrl(getSubscriberById(buId, id), 'events', 'export')}?period=${period}`,
  },
  ahjReportEmail: (businessUnitId: number, id: number) =>
    constructUrl(getSubscriberById(businessUnitId, id), 'ahj-report'),
  notifications: (businessUnitId: number, id: number) =>
    constructUrl(getSubscriberById(businessUnitId, id), 'notification'),
  peers: (businessUnitId: number, id: number) => constructUrl(getSubscriberById(businessUnitId, id), 'peers'),
  nctChangeMode: (businessUnitId: number, id: number, mode: SubscriberMode) =>
    `${constructUrl(getSubscriberById(businessUnitId, id), 'nct')}?mode=${mode}`,
  inactiveDependency: (businessUnitId: number, id: number) =>
    `${constructUrl(businessUnitsUrl, businessUnitId, 'subscribers', id, 'inactive', 'dependency')}`,
  controlRelaySubscribers: (businessUnitId: number, id: number) =>
    `${constructUrl(businessUnitsUrl, businessUnitId, 'subscribers', id, 'control-relay-new')}`,
  testMode: (businessUnitId: number, id: number) =>
    `${constructUrl(businessUnitsUrl, businessUnitId, 'subscribers', id, 'mode', 'test')}`,
  exportBUSubscribers: (businessUnitId: number, section: SubscribersListSection) => {
    const paths = section === 'all' ? ['export'] : [section, 'export'];
    return `${constructUrl(businessUnitsUrl, businessUnitId, 'subscribers', ...paths)}`;
  },
  facp: {
    zoneEvents: (businessUnitId: number, id: number, searchParams: string) =>
      constructUrl(getSubscriberFACP(businessUnitId, id, searchParams)),
    byZoneEventId: (businessUnitId: number, id: number, zoneEventId: number) =>
      constructUrl(getSubscriberFACP(businessUnitId, id), zoneEventId),
    import: (businessUnitId: number, id: number, skipFirstRow?: boolean) =>
      `${constructUrl(getSubscriberFACP(businessUnitId, id), 'import')}?skipFirstRow=${skipFirstRow}`,
    export: (businessUnitId: number, id: number) =>
      constructUrl(getSubscriberFACP(businessUnitId, id), 'export'),
    notifications: (businessUnitId: number, id: number) =>
      constructUrl(getSubscriberFACP(businessUnitId, id), 'notifications'),
  },
  dependencies: (businessUnitId: number, id: number, period: string, searchParams: string) =>
    constructUrl(businessUnitsUrl, businessUnitId, 'subscribers', id, `dependencies?period=${period}&${searchParams}`),
  testModeSubsIds: constructUrl(subscribersUrl, 'test'),
  customNote: {
    list: (buId: number, id: number) =>
      constructUrl(getSubscriberById(buId, id), 'notes'),
    byId: (buId: number, id: number, noteId: number) =>
      constructUrl(getSubscriberById(buId, id), 'notes', noteId),
  },
  location: (buId: number, id: number) =>
    constructUrl(getSubscriberById(buId, id), 'location'),
};
const websocket = (token: string) => constructUrl(prefix, `stomp-endpoint?access_token=Bearer ${token}`);
const nonAESUnit = {
  list: (businessUnitId: number, searchParams: string) =>
    constructUrl(businessUnitsUrl, businessUnitId, 'non-aes-units', `?${searchParams}`),
  all: (businessUnitId: number) => constructUrl(businessUnitsUrl, businessUnitId, 'non-aes-units'),
  byId: (businessUnitId: number, id: number) => constructUrl(businessUnitsUrl, businessUnitId, 'non-aes-units', id),
};
const getHybridById = (businessUnitId: number, id: number) =>
  constructUrl(businessUnitsUrl, businessUnitId, 'hybrids', id);
export const getHybridFACP = (businessUnitId: number, id: number, params?: string) => {
  const searchParams = params ? `?${params}` : '';
  return `${constructUrl(businessUnitsUrl, businessUnitId, 'hybrids', id, 'facp-events')}${searchParams}`;
};
const hybrids = {
  all: (searchParams: string) => `${hybridsUrl}?${searchParams}`,
  byId: getHybridById,
  byBU: (businessUnitId: number, searchParams: string) =>
    constructUrl(businessUnitsUrl, businessUnitId, `hybrids?${searchParams}`),
  settings: (businessUnitId: number, id: number, section: SubscriberSettingsSection) =>
    constructUrl(getHybridById(businessUnitId, id), section),
  settingsDefault: (businessUnitId: number, id: number, section: SubscriberSettingsSection) =>
    constructUrl(getHybridById(businessUnitId, id), section, 'default'),
  settingsTimingDefault: constructUrl(hybridsUrl, 'timing', 'default'),
  settingsRf: (businessUnitId: number, id: number, path: string) =>
    constructUrl(getHybridById(businessUnitId, id), path),
  settingsMode: (businessUnitId: number, id: number, mode: SubscriberSettingsMode) =>
    constructUrl(getHybridById(businessUnitId, id), 'modes', mode),
  routes: (businessUnitId: number, id: number) => constructUrl(getHybridById(businessUnitId, id), 'routes'),
  messages: (businessUnitId: number, id: number) => constructUrl(getHybridById(businessUnitId, id), 'messages'),
  activate: (businessUnitId: number, id: number, query: string) =>
    `${constructUrl(getHybridById(businessUnitId, id), 'activate')}?${query}`,
  eventHistory: {
    all: (businessUnitId: number, id: number, period: SubscriberHistoryPeriod, searchParams: string) =>
      `${constructUrl(getHybridById(businessUnitId, id), 'events')}?period=${period}&${searchParams}`,
    export: (buId: number, id: number, period: SubscriberHistoryPeriod) =>
      `${constructUrl(getHybridById(buId, id), 'events', 'export')}?period=${period}`,
  },
  info: (businessUnitId: number, id: number, section: SubscriberInfoSection) =>
    constructUrl(getHybridById(businessUnitId, id), section),
  remoteReset: (buId: number, id: number) => constructUrl(getHybridById(buId, id), 'remote-reset'),
  dealers: (businessUnitId: number, id: number) => constructUrl(getHybridById(businessUnitId, id), 'dealers'),
  notifications: (businessUnitId: number, id: number) =>
    constructUrl(getHybridById(businessUnitId, id), 'notification'),
  peers: (businessUnitId: number, id: number) => constructUrl(getHybridById(businessUnitId, id), 'peers'),
  dependencies: (businessUnitId: number, id: number, period: string, searchParams: string) =>
    constructUrl(businessUnitsUrl, businessUnitId, 'hybrids', id, `dependencies?period=${period}&${searchParams}`),
  facp: {
    zoneEvents: (businessUnitId: number, id: number, searchParams: string) =>
      constructUrl(getHybridFACP(businessUnitId, id, searchParams)),
    byZoneEventId: (businessUnitId: number, id: number, zoneEventId: number) =>
      constructUrl(getHybridFACP(businessUnitId, id), zoneEventId),
    notifications: (businessUnitId: number, id: number) =>
      constructUrl(getHybridFACP(businessUnitId, id), 'notifications'),
    import: (businessUnitId: number, id: number, skipFirstRow?: boolean) =>
      `${constructUrl(getHybridFACP(businessUnitId, id), 'import')}?skipFirstRow=${skipFirstRow}`,
    export: (businessUnitId: number, id: number) =>
      constructUrl(getHybridFACP(businessUnitId, id), 'export'),
  },
  customNote: {
    list: (buId: number, id: number) =>
      constructUrl(getHybridById(buId, id), 'notes'),
    byId: (buId: number, id: number, noteId: number) =>
      constructUrl(getHybridById(buId, id), 'notes', noteId),
  },
  location: (buId: number, id: number) =>
    constructUrl(getHybridById(buId, id), 'location'),
};
const reportingRoutes = {
  subscriber: (businessUnitId: number, id: number) =>
    constructUrl(getSubscriberById(businessUnitId, id), 'outbound', 'routes'),
};

const getLicenseAgreement = () => constructUrl(licenseUrl, 'agreement');
const license = {
  details: licenseUrl,
  activate: constructUrl(licenseUrl, 'activate'),
  agreement: {
    accept: getLicenseAgreement(),
    download: constructUrl(getLicenseAgreement(), 'download'),
  },
  addOnLicense: {
    details: (type: AddOnLicenseType) => constructUrl(licenseUrl, 'add-ons', type),
    activate: (type: AddOnLicenseType) => constructUrl(licenseUrl, 'add-ons', type, 'activate'),
  },
};

const getDealerById = (id: number) => constructUrl(dealersUrl, id);
const getDealerAssignedUsers = (id: number) => constructUrl(getDealerById(id), 'users');
const getDealerAssignedUnits = (id: number) => constructUrl(getDealerById(id), 'units');
const getDealerAssignedIPLinks = (id: number) => constructUrl(getDealerById(id), 'ip-links');
const dealers = {
  all: (searchParams: string) => `${dealersUrl}?${searchParams}`,
  ids: `${constructUrl(dealersUrl, 'ids')}`,
  byId: (id: number) => getDealerById(id),
  update: dealersUrl,
  create: dealersUrl,
  import: constructUrl(dealersUrl, 'import'),
  users: {
    all: (dealerId: number) => getDealerAssignedUsers(dealerId),
    assign: (dealerId: number, userId: number) => constructUrl(getDealerAssignedUsers(dealerId), userId, 'add'),
    delete: (dealerId: number, userId: number) => constructUrl(getDealerAssignedUsers(dealerId), userId, 'remove'),
    uploadUserCSV: (dealerId: number) => constructUrl(getDealerAssignedUsers(dealerId), 'import'),
  },
  units: {
    all: (dealerId: number) => getDealerAssignedUnits(dealerId),
    byId: (dealerId: number, unitId: number) => constructUrl(getDealerAssignedUnits(dealerId), unitId),
    assign: (dealerId: number) => constructUrl(getDealerById(dealerId), 'units/'),
    uploadUnitCSV: (dealerId: number) => constructUrl(getDealerAssignedUnits(dealerId), 'import'),
  },
  ipLinks: {
    all: (dealerId: number) => getDealerAssignedIPLinks(dealerId),
    byId: (dealerId: number, ipLinkId: number) => constructUrl(getDealerAssignedIPLinks(dealerId), ipLinkId),
    assign: (dealerId: number) => constructUrl(getDealerById(dealerId), 'ip-links/'),
    uploadIPLinkCSV: (dealerId: number) => constructUrl(getDealerAssignedIPLinks(dealerId), 'import'),
  },
};

const antenna = {
  all: antennasUrl,
  byId: (id: number) => constructUrl(antennasUrl, 'remove', id),
  list: `${constructUrl(antennasUrl, 'list')}`,
};

const search = {
  searchById: (unitId: number) => `${searchUrl}?unitId=${unitId}`,
};

const liveTraffic = {
  all: liveTrafficUrl,
};

const getControlRelayById = (id: number) => constructUrl(controlRelaysUrl, id);
const controlRelays = {
  all: (model: string, searchParams: string) => `${controlRelaysUrl}?model=${model}&${searchParams}`,
  byId: (id: number) => getControlRelayById(id),
  create: controlRelaysUrl,
  updateRelayState: (controlRelayId: number, relayId: number) =>
    constructUrl(getControlRelayById(controlRelayId), 'relays', relayId),
  unitIds: (businessUnitId: number, model: string, searchParams: string) =>
    `${constructUrl(controlRelaysUrl, 'ids')}?buId=${businessUnitId}&model=${model}&${searchParams}`,
  copySettings: (buId: number) => `${constructUrl(controlRelaysUrl, 'copy')}?buId=${buId}`,
  sendOutbound: (businessUnitId: number, unitId: number, type: string) =>
    constructUrl(businessUnitsUrl, businessUnitId, type, unitId, 'control-relays-status'),
};

const geography = {
  coordinates: (id: number) => constructUrl(businessUnitsUrl, id, 'units', 'coordinates'),
  routes: (id: number) => constructUrl(businessUnitsUrl, id, 'geo', 'routes'),
  linkLayers: (id: number) => constructUrl(businessUnitsUrl, id, 'geo', 'link-layer'),
  dependency: (id: number) => constructUrl(businessUnitsUrl, id, 'geo', 'dependency'),
  geoCenter: (buId: number) => constructUrl(businessUnitsUrl, buId, 'coordinates'),
  errorsCount: (buId: number) => constructUrl(businessUnitsUrl, buId, 'coordinates', 'errors', 'count'),
  faults: {
    byUnitType: (businessUnitId: number) =>
      constructUrl(businessUnitsUrl, businessUnitId, 'geo', 'faults', 'unit-type'),
    byUnitCode: (businessUnitId: number) =>
      constructUrl(businessUnitsUrl, businessUnitId, 'geo', 'faults', 'unit-code'),
  },
  address: (query: string) => `${constructUrl(businessUnitsUrl, 'address-lookup')}?${query}`,
  exportInvalidAddresses: (businessUnitId: number) =>
    constructUrl(businessUnitsUrl, businessUnitId, 'coordinates', 'errors'),
};

export const api = {
  auth,
  report,
  alarmAutomation,
  alarm,
  system,
  businessUnits,
  faults,
  ipLinks,
  users,
  settings,
  subscribers,
  nonAESUnit,
  hybrids,
  reportingRoutes,
  license,
  websocket,
  dealers,
  antenna,
  search,
  controlRelays,
  geography,
  liveTraffic,
};
