import { AlarmAutomationFormWrapper } from '../Form';

import { appDetailRoutes } from '~constants';
import { AlarmAutomation } from '~models';
import { LinkButton } from '~ui-kit';

interface AlarmAutomationGeneralProps {
  alarmAutomation: AlarmAutomation;
}

export const AlarmAutomationGeneral = ({ alarmAutomation }: AlarmAutomationGeneralProps) => {
  const portNumber = alarmAutomation?.sockets[0].portNumber;

  return (
    <AlarmAutomationFormWrapper
      title={`Alarm Automation Port Number ${portNumber as number}`}
      backTo={appDetailRoutes.settingsAlarmAutomation}
      showing
      alarmAutomation={alarmAutomation}
    >
      <LinkButton
        color="primary"
        variant="contained"
        type="button"
        linkTo={appDetailRoutes.settingsAlarmAutomationEdit(alarmAutomation.id)}
      >
        Edit
      </LinkButton>
    </AlarmAutomationFormWrapper>
  );
};
