import { Button, Typography, withStyles, WithStyles, Tooltip, Grid, useTheme } from '@material-ui/core';
import clsx from 'clsx';
import { equals } from 'ramda';
import { useCallback, memo, PropsWithChildren, Fragment } from 'react';
import { useSelector } from 'react-redux';

import ackIcon from '~assets/images/icons/ack.svg';
import { getAlarmCode } from '~features/alarms/utils';
import { getFACPColor } from '~styles/colors';

import { styles } from './AlarmListCard.styles';
import { CustomNotesButton } from './CustomNotesButton';
import { getAlarmMeta, getAlarmUnitLabel, getAlarmUnitLabelField, getNoteDialogPermission, isFullAlarm, getFACPColorLabel } from './utils';

import { ProtectedContent } from '~components';
import { appDetailRoutes } from '~constants';
import { Alarm, AlarmManualAck, PermissionName } from '~models';
import { selectors } from '~store';
import {
  Card,
  CardDetails,
  CardSummary,
  ContentField,
  IconText,
  ContentWrapper,
  Divider,
  NavLink,
  CardColumn,
} from '~ui-kit';
import { formatDate } from '~utils';

export interface AlarmListCardCallbacks {
  onSilence?(alarm: Alarm): void;
  setAlarm?(alarm: Alarm): void;
  onAlarmsCount?(alarmCount: number, alarm: Alarm): void;
  setDialog?(): void;
  onExpand(alarm: Alarm, expanded: boolean): void;
  getDate?(alarm: Alarm): number;
  count?: number;
  showDetailsStatus?: boolean;
  showTooltip?: boolean;
}

export type AlarmListCardProps = PropsWithChildren<WithStyles<typeof styles>> &
  AlarmListCardCallbacks & {
    alarm: Alarm;
    columns?: string[];
    backTo?: string;
    expanded?: boolean;
    timeFormat: string;
  };

export const AlarmListCard = memo(
  withStyles(styles)(
    ({
      alarm,
      columns = [],
      backTo,
      onExpand,
      onSilence = () => {},
      setAlarm = () => {},
      onAlarmsCount = () => {},
      setDialog = () => {},
      getDate = (alarm: Alarm) => alarm.createdAt,
      count = 0,
      showTooltip = false,
      showDetailsStatus = false,
      classes,
      expanded,
      timeFormat,
    }: AlarmListCardProps) => {
      if (!alarm) {
        return null;
      }

      const { status, title, icon } = getAlarmMeta(alarm.alarmType);
      const isExpanded = expanded ?? alarm.meta?.isExpanded;
      const unitLabel = getAlarmUnitLabel(alarm);
      const timeZone = useSelector(selectors.profile.getUserTimeZone, equals);
      const isSelfMonitoring = useSelector(selectors.app.isSelfMonitoring, equals);
      const isHighContrast = useSelector(selectors.app.isHighContrast, equals);
      const theme = useTheme();
      const facpColor = getFACPColor(theme, alarm.facpColor);
      const facpColorLabel = getFACPColorLabel(alarm.facpColor);

      const alarmTitle = (
        <IconText
          label={alarm.alarmTypeDescription}
          icon={icon}
          status={status}
          classes={{ root: clsx(classes.titleRoot), title: clsx(classes.title) }}
        />
      );
      const isAcknowledgeAllBtn = isFullAlarm(alarm);

      const onAckCallback = useCallback(() => {
        setAlarm(alarm);
      }, [alarm]);

      const onSilenceCallback = useCallback(() => {
        onSilence(alarm);
      }, [alarm]);

      const onAckAllCallback = useCallback(() => {
        onAlarmsCount(count, alarm);
        setDialog();
      }, [count, alarm]);

      const onResolveCallback = useCallback(() => {
        setDialog();
        setAlarm(alarm);
      }, [alarm]);

      const onExpandCallback = useCallback(
        (e: unknown, panelExpanded: boolean) => {
          if (expanded) {
            return;
          }

          onExpand(alarm, panelExpanded);
        },
        [alarm, isExpanded]
      );

      const getBtnStyle = (disabled: boolean, type?) => {
        if (disabled) {
          return {};
        }

        if (type) {
          return { background: facpColor };
        }

        return {
          borderColor: facpColor,
          color: facpColor,
        };
      };

      if (alarm.isTest && alarm.manualAck === AlarmManualAck.ACKNOWLEDGED) {
        return null;
      }

      return (
        <Card
          key={alarm.id}
          expandable
          expanded={isExpanded}
          onChange={onExpandCallback}
          classes={{ root: clsx(classes.root) }}
          TransitionProps={{ timeout: 0, unmountOnExit: true, in: isExpanded }}
        >
          <CardSummary
            status={status}
            expandable
            hideIcon={expanded}
            classes={{
              cardSummaryRoot: clsx(
                classes.cardSummary,
                facpColorLabel,
                { 'high-contrast': isHighContrast },
              )
            }}
          >
            {showTooltip ? (
              <Tooltip title={alarm.alarmTypeDescription} placement="right">
                {alarmTitle}
              </Tooltip>
            ) : (
              alarmTitle
            )}

            <Divider flexItem orientation="vertical" spacing="left" />

            <ContentWrapper spacing="both" classes={{ root: classes.fieldsWrapper }}>
              <ContentField title={unitLabel} width={80} classes={{ root: classes.field }}>
                {getAlarmUnitLabelField(alarm, backTo)}
              </ContentField>
              <Divider flexItem orientation="vertical" spacing="right" />

              {count ? (
                <Fragment>
                  <CardColumn value="count" columns={columns}>
                    <ContentField title="Alarms count" width={110} classes={{ root: classes.field }}>
                      {count}
                    </ContentField>
                  </CardColumn>

                  <Divider flexItem orientation="vertical" spacing="right" />
                </Fragment>
              ) : null}

              <ContentField title="Address" classes={{ root: classes.address }}>
                <Tooltip title={`${alarm?.address1}\n${alarm.address2}` ?? ''}>
                  <Grid>{alarm?.address1 || 'N/A'}</Grid>
                </Tooltip>
              </ContentField>

              {isSelfMonitoring && (
                <Fragment>
                  <Divider flexItem orientation="vertical" spacing="right" />

                  <ContentField title="Description" classes={{ root: classes.address }}>
                    <Tooltip title={alarm?.facpDescription ?? ''}>
                      <Grid>{alarm?.facpDescription || 'N/A'}</Grid>
                    </Tooltip>
                  </ContentField>
                </Fragment>
              )}
            </ContentWrapper>

            <CardColumn columns={columns} value="actions">
              <ContentWrapper spacing="both" classes={{ root: clsx(classes.actions, { expanded }) }}>
                <div className={classes.buttons}>
                  {isAcknowledgeAllBtn && (
                    <ProtectedContent permissions={[PermissionName.DASHBOARD_ACKNOWLEDGE_ALL]}>
                      <Button
                        variant="outlined"
                        color="primary"
                        size="small"
                        className={classes.btnAckAll}
                        disabled={alarm.meta?.isLoading || count === 1}
                        style={getBtnStyle(alarm.meta?.isLoading || count === 1)}
                        onClick={onAckAllCallback}
                      >
                          Ack All
                      </Button>
                    </ProtectedContent>
                  )}

                  <Divider flexItem orientation="vertical" spacing="left" />
                  <ProtectedContent permissions={[PermissionName.DASHBOARD_SILENCE]}>
                    <Button
                      variant="outlined"
                      color="primary"
                      size="small"
                      disabled={alarm.silent || alarm.meta?.isLoading}
                      style={getBtnStyle(Boolean(alarm.silent || alarm.meta?.isLoading))}
                      onClick={onSilenceCallback}
                    >
                        Silence
                    </Button>
                  </ProtectedContent>

                  <ProtectedContent permissions={[PermissionName.DASHBOARD_ACKNOWLEDGE]}>
                    <Button
                      variant="contained"
                      color="primary"
                      size="small"
                      disabled={alarm.meta?.isLoading}
                      style={getBtnStyle(Boolean(alarm.meta?.isLoading), 'ack')}
                      onClick={onAckCallback}
                    >
                        Ack
                    </Button>
                  </ProtectedContent>
                </div>
              </ContentWrapper>
            </CardColumn>

            <CardColumn columns={columns} value="acknowledged">
              <Divider flexItem orientation="vertical" spacing="right" />

              {isSelfMonitoring ? (
                <ContentWrapper classes={{ root: classes.resolveBtn }}>
                  <Button
                    variant="contained"
                    color="primary"
                    size="small"
                    onClick={onResolveCallback}
                  >
                      Resolve
                  </Button>
                </ContentWrapper>
              ) : (
                <ContentWrapper classes={{ root: classes.acknowledged }}>
                  <img src={ackIcon} className="icon" />
                  <Typography variant="body1">Acknowledged</Typography>
                </ContentWrapper>
              )}
            </CardColumn>
          </CardSummary>

          <CardDetails
            classes={{ root: clsx(
              classes.cardDetails,
              facpColorLabel,
              { 'high-contrast': isHighContrast },
            ) }}
            status={status}
          >
            <ContentField title="Date & Time" width={200}>
              <Tooltip title={formatDate(getDate(alarm), false, timeFormat, timeZone) as string}>
                <Grid>{formatDate(getDate(alarm), false, timeFormat, timeZone)}</Grid>
              </Tooltip>
            </ContentField>

            <ProtectedContent permissions={getNoteDialogPermission(alarm)}>
              <ContentWrapper classes={{ root: classes.notes }}>
                {alarm.unitType !== 'MNR' &&
                  <CustomNotesButton
                    unitType={alarm.unitType}
                    unitId={alarm.unitId}
                    businessUnitId={alarm.businessUnitId}
                    backTo={backTo }
                  />
                }
              </ContentWrapper>
            </ProtectedContent>

            <ContentField title="Code" width={180}>
              {getAlarmCode(alarm).trim()}
            </ContentField>
            <ContentField title="Business Unit" width={250}>
              <NavLink to={appDetailRoutes.businessUnitDetails(alarm.businessUnitId, backTo)}>
                <Tooltip title={alarm.businessUnitName}>
                  <Grid>{alarm.businessUnitName}</Grid>
                </Tooltip>
              </NavLink>
            </ContentField>
            <ContentField title="Alarm type" width={150}>
              {title}
            </ContentField>
          </CardDetails>
        </Card>
      );
    }
  )
);
