import { Typography, withStyles, WithStyles } from '@material-ui/core';
import clsx from 'clsx';
import { forwardRef } from 'react';

import { CardStatus } from '../Card/CardSummary';
import { Icon } from '../Icon';
import { NavLink } from '../NavLink';

import { styles } from './IconText.styles';

import { IconType } from '~icons';

export type CardTitleProps = WithStyles<typeof styles> & {
  status?: CardStatus;
  color?: string;
  iconColor?: string;
  icon?: IconType;
  label?: string | number;
  to?: string;
};

export const IconText = withStyles(styles)(
  forwardRef(({ classes, label, icon, iconColor, status, color, to, ...props }: CardTitleProps, ref) => (
    <div {...props} className={clsx(classes.root, status)} style={{ color }} ref={ref as () => HTMLDivElement | null}>
      {icon ? <Icon icon={icon} classes={{ root: clsx(classes.icon) }} color={iconColor} className={status} /> : null}
      <Typography variant="button" className={clsx(classes.title, status)}>
        {to ? <NavLink to={to}>{label}</NavLink> : label}
      </Typography>
    </div>
  ))
);
