import { Typography, WithStyles, withStyles } from '@material-ui/core';
import { PropsWithChildren } from 'react';
import { useSelector } from 'react-redux';

import { CardListPlaceholderImage } from '../DataPlaceholder/DataPlaceholder';
import { getPlaceholderImageSrc } from '../DataPlaceholder/utils';

import { styles } from './Placeholder.styles';

import { selectors } from '~store';

export type PlaceholderProps = PropsWithChildren<WithStyles<typeof styles>> & {
  title?: string;
  image?: CardListPlaceholderImage;
};

export const Placeholder = withStyles(styles)(
  ({ title = 'No Data', image = 'alerts', classes, children }: PlaceholderProps) => {
    const theme = useSelector(selectors.theme.selectTheme);
    const imageSrc = getPlaceholderImageSrc(image, theme);

    return (
      <div className={classes.placeholder}>
        <img src={imageSrc} className="image" />
        <Typography className="title" variant="body1">
          {title}
        </Typography>
        {children}
      </div>
    );
  }
);
