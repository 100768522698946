export enum PermissionName {
    'DASHBOARD_VIEW' = 'DASHBOARD_VIEW',
    'DASHBOARD_ACKNOWLEDGE' = 'DASHBOARD_ACKNOWLEDGE',
    'DASHBOARD_ACKNOWLEDGE_ALL' = 'DASHBOARD_ACKNOWLEDGE_ALL',
    'DASHBOARD_SILENCE' = 'DASHBOARD_SILENCE',
    'DASHBOARD_EXPORT' = 'DASHBOARD_EXPORT',
    'DEALER_VIEW' = 'DEALER_VIEW',
    'DEALER_ADD_UNIT' = 'DEALER_ADD_UNIT',
    'DEALER_EDIT_UNIT' = 'DEALER_EDIT_UNIT',
    'DEALER_DELETE_UNIT' = 'DEALER_DELETE_UNIT',
    'EDIT_ANTENNA_LIST' = 'EDIT_ANTENNA_LIST',
    'PROFILE_VIEW' = 'PROFILE_VIEW',
    'PROFILE_EDIT' = 'PROFILE_EDIT',
    'PROFILE_STORAGE_SETTINGS' = 'PROFILE_STORAGE_SETTINGS',
    'PROFILE_CHANGE_PASSWORD' = 'PROFILE_CHANGE_PASSWORD',
    'PROFILE_LICENSE_DETAILS' = 'PROFILE_LICENSE_DETAILS',
    'BU_VIEW' = 'BU_VIEW',
    'BU_ADD_UNIT' = 'BU_ADD_UNIT',
    'BU_EDIT_UNIT' = 'BU_EDIT_UNIT',
    'BU_DELETE_UNIT' = 'BU_DELETE_UNIT',
    'BU_VIEW_LIVE_TRAFFIC' = 'BU_VIEW_LIVE_TRAFFIC',
    'SUBSCRIBERS_VIEW' = 'SUBSCRIBERS_VIEW',
    'SUBSCRIBERS_ADD_UNIT' = 'SUBSCRIBERS_ADD_UNIT',
    'SUBSCRIBERS_EDIT_UNIT' = 'SUBSCRIBERS_EDIT_UNIT',
    'SUBSCRIBERS_DELETE_UNIT' = 'SUBSCRIBERS_DELETE_UNIT',
    'SUBSCRIBERS_VIEW_LIVE_TRAFFIC' = 'SUBSCRIBERS_VIEW_LIVE_TRAFFIC',
    'SUBSCRIBERS_ASSIGN_UNIT' = 'SUBSCRIBERS_ASSIGN_UNIT',
    'SET_DEFAULT_BU' = 'SET_DEFAULT_BU',
    'IP_LINK_VIEW' = 'IP_LINK_VIEW',
    'IP_LINK_ADD_UNIT' = 'IP_LINK_ADD_UNIT',
    'IP_LINK_EDIT_UNIT' = 'IP_LINK_EDIT_UNIT',
    'IP_LINK_DELETE_UNIT' = 'IP_LINK_DELETE_UNIT',
    'IP_LINK_ASSIGN_UNIT' = 'IP_LINK_ASSIGN_UNIT',
    'NON_AES_UNITS_VIEW' = 'NON_AES_UNITS_VIEW',
    'NON_AES_UNITS_ADD_UNIT' = 'NON_AES_UNITS_ADD_UNIT',
    'NON_AES_UNITS_EDIT_UNIT' = 'NON_AES_UNITS_EDIT_UNIT',
    'NON_AES_UNITS_DELETE_UNIT' ='NON_AES_UNITS_DELETE_UNIT',
    'USER_VIEW' = 'USER_VIEW',
    'USER_ADD' = 'USER_ADD',
    'USER_EDIT' = 'USER_EDIT',
    'USER_DELETE' = 'USER_DELETE',
    'USERS_HISTORY' = 'USERS_HISTORY',
    'GEO_VIEW' = 'GEO_VIEW',
    'IMPORT_EXPORT_VIEW' = 'IMPORT_EXPORT_VIEW',
    'IMPORT_EXPORT_IMPORT' = 'IMPORT_EXPORT_IMPORT',
    'IMPORT_EXPORT_EXPORT' = 'IMPORT_EXPORT_EXPORT',
    'SETTINGS_VIEW' = 'SETTINGS_VIEW',
    'LIVE_TRAFFIC_VIEW' = 'LIVE_TRAFFIC_VIEW',
    'VIEW_ALL_UNITS' = 'VIEW_ALL_UNITS',
    'CONTROL_RELAY_VIEW' = 'CONTROL_RELAY_VIEW',
    'CONTROL_RELAY_ADD' = 'CONTROL_RELAY_ADD',
    'CONTROL_RELAY_EDIT' = 'CONTROL_RELAY_EDIT',
    'CONTROL_RELAY_DELETE' = 'CONTROL_RELAY_DELETE',
    'FACP_VIEW' = 'FACP_VIEW',
    'FACP_EDIT' = 'FACP_EDIT',
    'FACP_AUTO_ACK' = 'FACP_AUTO_ACK',
    'FACP_TEST_MODE' = 'FACP_TEST_MODE',
    'EDIT_USER_ACCOUNT_STATUS' = 'EDIT_USER_ACCOUNT_STATUS',
    'LED_PANEL' = 'LED_PANEL',
    'BAD_PKT_VIEW' ='BAD_PKT_VIEW',
    'FCC_SETTINGS_VIEW' = 'FCC_SETTINGS_VIEW',
    'FCC_SETTINGS_EDIT' = 'FCC_SETTINGS_EDIT',
    'NMS_ADDRESS_IMPORT' = 'NMS_ADDRESS_IMPORT',
    'USER_NOTIFICATION' = 'USER_NOTIFICATION',
    'ELEVATION_IN_METERS_EDIT' = 'ELEVATION_IN_METERS_EDIT',
    'ELEVATION_IN_METERS_VIEW' = 'ELEVATION_IN_METERS_VIEW',
    'SOUND_SETTING_VIEW' = 'SOUND_SETTING_VIEW',
    'SOUND_SETTING_EDIT' = 'SOUND_SETTING_EDIT',
    'MFA_SETTING_EDIT' = 'MFA_SETTING_EDIT',
    'MFA_SETTING_VIEW' = 'MFA_SETTING_VIEW',
    'USER_INACTIVITY_EDIT' = 'USER_INACTIVITY_EDIT',
    'USER_INACTIVITY_VIEW' = 'USER_INACTIVITY_VIEW',
    'PASSWORD_EXPIRY_EDIT' = 'PASSWORD_EXPIRY_EDIT',
    'PASSWORD_EXPIRY_VIEW' = 'PASSWORD_EXPIRY_VIEW',
    'DB_MIGRATE' = 'DB_MIGRATE',
    'DB_DUMP' = 'DB_DUMP',
    'SERVER_VIEW' = 'SERVER_VIEW',
    'SERVER_EDIT' = 'SERVER_EDIT',
    'NETWORK_VIEW' = 'NETWORK_VIEW',
    'NETWORK_EDIT' = 'NETWORK_EDIT',
    'SUBTOOLS_VIEW' = 'SUBTOOLS_VIEW',
    'SUBTOOLS_EDIT' = 'SUBTOOLS_EDIT',
    'TECH_OPTIONS_VIEW' = 'TECH_OPTIONS_VIEW',
    'TECH_OPTIONS_EDIT' = 'TECH_OPTIONS_EDIT',
    'CHECKIN_GRACE_PERIOD_VIEW' = 'CHECKIN_GRACE_PERIOD_VIEW',
    'CHECKIN_GRACE_PERIOD_EDIT' = 'CHECKIN_GRACE_PERIOD_EDIT',
    'MAINTENANCE_VIEW' = 'MAINTENANCE_VIEW',
    'MAINTENANCE_EDIT' = 'MAINTENANCE_EDIT',
    'ALARM_AUTOMATION_VIEW' = 'ALARM_AUTOMATION_VIEW',
    'ALARM_AUTOMATION_EDIT' = 'ALARM_AUTOMATION_EDIT',
    'ANTENNA_VIEW' = 'ANTENNA_VIEW',
    'SELF_MON_SETTINGS_VIEW' = 'SELF_MON_SETTINGS_VIEW',
    'SELF_MON_SETTINGS_EDIT' = 'SELF_MON_SETTINGS_EDIT',
    'TOKEN_EXPIRATION_VIEW' = 'TOKEN_EXPIRATION_VIEW',
    'TOKEN_EXPIRATION_EDIT' = 'TOKEN_EXPIRATION_EDIT'
}

export type Permission = {
  groupName: string;
  id: number;
  label: string;
  name: PermissionName | string;
  parent: boolean;
  disabled: boolean;
};
