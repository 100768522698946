import { Button } from '@material-ui/core';
import { Fragment, useState, memo } from 'react';

import { UnitNotesDialog } from '~components/Dialogs/UnitNotesDialog';

import { UnitType } from '~models';

interface CustomNotesButtonProps {
  unitType: UnitType;
  unitId: number;
  businessUnitId: number;
  backTo?: string;
}

export const CustomNotesButton = memo(({ unitType, unitId, businessUnitId, backTo }: CustomNotesButtonProps) => {
  const [open, setOpen] = useState(false);

  return <Fragment>
    <Button
      variant="outlined"
      color="primary"
      size="small"
      onClick={() => setOpen(true)}
    >
      Notes
    </Button>

    <UnitNotesDialog
      open={open}
      backTo={backTo}
      unit={{ unitType, unitId, businessUnitId }}
      onClose={() => setOpen(false)}
    />
  </Fragment>;
});
