import { createStyles, Theme } from '@material-ui/core';

import backgroundImage from '~assets/images/login-background.png';

export const styles = ({ spacing }: Theme) =>
  createStyles({
    root: {
      height: '100%',
      backgroundPosition: 'center',
      backgroundSize: 'cover',
      background: `linear-gradient(90deg, rgba(38, 50, 76, 0.53) 17.57%, #182134 100%), url(${backgroundImage})`,
    },
    container: {
      margin: spacing(0, 'auto'),
    },
    paper: {
      padding: spacing(7),
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
    logo: {
      width: 170,
      height: 57,
      marginBottom: spacing(3),
    },
    subheader: {
      marginBottom: spacing(4),
    },
    title: {
      marginBottom: spacing(3),
    },
    instance: {
      marginBottom: spacing(5),
      fontStyle: 'italic',
      fontWeight: 'normal',
    },
    input: {
      width: '100%',
      marginBottom: spacing(6),
    },
    submit: {
      margin: spacing(0, 'auto', 4),
    },
    forgotPassword: {
      fontWeight: 600,
    },
    form: {
      textAlign: 'center',
    },
  });
