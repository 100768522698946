import { withQueryParams } from '~utils/page';

import { PageQuery } from '~models';

export const appRoutes = {
  root: '/',
  kioskMode: '/kiosk',
  authenticate: '/authenticate',
  dashboard: '/dashboard',
  subscribers: '/subscribers',
  businessUnits: '/business-units',
  ipLinks: '/ip-links',
  geography: '/geography',
  settings: '/settings',
  profile: '/profile',
  liveTraffic: '/live-traffic',
  help: '/help',
  users: '/users',
  hybrids: '/hybrids',
  dealers: '/dealers',
  search: '/search',
  controlRelays: '/control-relays',
  notFound: '/not-found',
};

export const appDetailRoutes = {
  dashboardTab: (tab: string) => `${appRoutes.dashboard}/${tab}`,
  dashboardUnacknowledged: `${appRoutes.dashboard}/unacknowledged`,
  dashboardTest: `${appRoutes.dashboard}/test`,
  dashboardInactive: `${appRoutes.dashboard}/inactive`,

  businessUnitCreate: `${appRoutes.businessUnits}/create`,
  businessUnitDetails: (id: number, backTo?: string) =>
    withQueryParams(`${appRoutes.businessUnits}/${id}`, backTo ? ({ backTo } as PageQuery) : undefined),
  businessUnitDashboard: (id: number, backTo?: string) =>
    withQueryParams(`${appRoutes.businessUnits}/${id}/dashboard`, backTo ? ({ backTo } as PageQuery) : undefined),
  businessUnitEdit: (id: number) => `${appRoutes.businessUnits}/${id}/edit`,
  businessUnitGeneral: (id: number, backTo?: string) =>
    withQueryParams(`${appRoutes.businessUnits}/${id}/general`, backTo ? ({ backTo } as PageQuery) : undefined),
  businessUnitIpLinks: (id: number) => `${appRoutes.businessUnits}/${id}/ip-links`,
  businessUnitSubscribers: (id: number) => `${appRoutes.businessUnits}/${id}/subscribers`,
  businessUnitHybrids: (id: number) => `${appRoutes.businessUnits}/${id}/hybrids`,
  businessUnitMesh: (id: number) => `${appRoutes.businessUnits}/${id}/mesh`,
  businessUnitNonAES: (id: number) => `${appRoutes.businessUnits}/${id}/non-aes`,
  businessUnitNonAESCreate: (id: number) => `${appRoutes.businessUnits}/${id}/non-aes/create`,
  businessUnitNonAESDetails: (businessUnitId: number, id: number, backTo?: string) =>
    withQueryParams(`${appRoutes.businessUnits}/${businessUnitId}/non-aes/${id}`, backTo ? ({ backTo } as PageQuery) : undefined),
  businessUnitNonAESEdit: (businessUnitId: number, id: number) =>
    `${appRoutes.businessUnits}/${businessUnitId}/non-aes/${id}/edit`,
  businessUnitIpLinksLoad: (businessUnitId: number) => `${appRoutes.businessUnits}/${businessUnitId}/signal-loaded`,

  businessUnitNotifications: (id: number) => `${appRoutes.businessUnits}/${id}/notifications`,
  businessUnitNotificationsTriggers: (id: number) => `${appRoutes.businessUnits}/${id}/notifications/triggers`,
  businessUnitNotificationsRecipients: (id: number) => `${appRoutes.businessUnits}/${id}/notifications/recipients`,

  businessUnitRecipientCreate: (businessUnitId: number) =>
    `${appRoutes.businessUnits}/${businessUnitId}/notifications/recipients/create`,
  businessUnitRecipientDetails: (businessUnitId: number, id: number, backTo?: string) =>
    withQueryParams(
      `${appRoutes.businessUnits}/${businessUnitId}/notifications/recipients/${id}`,
      backTo ? ({ backTo } as PageQuery) : undefined
    ),
  businessUnitRecipientAssociations: (businessUnitId: number, id: number) =>
    `${appRoutes.businessUnits}/${businessUnitId}/notifications/recipients/${id}/associations`,
  businessUnitTriggerCreate: (businessUnitId: number) =>
    `${appRoutes.businessUnits}/${businessUnitId}/notifications/triggers/create`,
  businessUnitTriggerDetails: (businessUnitId: number, id: number, backTo?: string) =>
    withQueryParams(
      `${appRoutes.businessUnits}/${businessUnitId}/notifications/triggers/${id}`,
      backTo ? ({ backTo } as PageQuery) : undefined
    ),
  businessUnitTriggerAssociations: (businessUnitId: number, id: number) =>
    `${appRoutes.businessUnits}/${businessUnitId}/notifications/triggers/${id}/associations`,

  subscriberDetails: (businessUnitId: number, id: number, backTo?: string) =>
    withQueryParams(`${appRoutes.subscribers}/${businessUnitId}/${id}`, backTo ? ({ backTo } as PageQuery) : undefined),
  subscriberGeneral: (businessUnitId: number, id: number, backTo?: string) =>
    withQueryParams(`${appRoutes.subscribers}/${businessUnitId}/${id}/general`, backTo ? ({ backTo } as PageQuery) : undefined),
  hybridDetails: (businessUnitId: number, id: number, backTo?: string) =>
    withQueryParams(`${appRoutes.hybrids}/${businessUnitId}/${id}`, backTo ? ({ backTo } as PageQuery) : undefined),
  hybridGeneral: (businessUnitId: number, id: number, backTo?: string) =>
    withQueryParams(`${appRoutes.hybrids}/${businessUnitId}/${id}/general`, backTo ? ({ backTo } as PageQuery) : undefined),
  ipLinkDetails: (businessUnitId: number, id: number, backTo?: string) =>
    withQueryParams(`${appRoutes.ipLinks}/${businessUnitId}/${id}`, backTo ? ({ backTo } as PageQuery) : undefined),
  ipLinkGeneral: (businessUnitId: number, id: number, backTo?: string) =>
    withQueryParams(`${appRoutes.ipLinks}/${businessUnitId}/${id}/general`, backTo ? ({ backTo } as PageQuery) : undefined),

  dealerCreate: `${appRoutes.dealers}/create`,
  dealerEdit: `${appRoutes.dealers}/edit`,
  dealerDetails: (id: number, backTo?: string) =>
    withQueryParams(`${appRoutes.dealers}/${id}`, backTo ? ({ backTo } as PageQuery) : undefined),

  settingsSystem: `${appRoutes.settings}/system`,
  settingsAlarmAutomation: `${appRoutes.settings}/alarm-automation`,
  settingsAlarmAutomationCreate: `${appRoutes.settings}/alarm-automation/create`,
  settingsAlarmAutomationDetails: (id: number, backTo?: string) =>
    withQueryParams(`${appRoutes.settings}/alarm-automation/${id}`,
      backTo ? ({ backTo } as PageQuery) : undefined
    ),
  settingsAlarmAutomationEdit: (id: number) => `${appRoutes.settings}/alarm-automation/${id}/edit`,

  usersCreate: `${appRoutes.users}/create`,
  usersHistory: `${appRoutes.users}/history`,
  userDetails: (id: number, backTo?: string) =>
    withQueryParams(`${appRoutes.users}/${id}`, backTo ? ({ backTo } as PageQuery) : undefined),
  userEdit: (id: number) => `${appRoutes.users}/${id}/edit`,
  userClone: (id: number) => `${appRoutes.users}/${id}/clone`,

  unitDetails: (unitId: number) => `${appRoutes.search}/${unitId}`,

  burgRelaysList: `${appRoutes.controlRelays}/burg`,
  fireRelaysList: `${appRoutes.controlRelays}/fire`,
  controlRelayDetails: (id: number, backTo?: string) =>
    withQueryParams(`${appRoutes.controlRelays}/${id}`, backTo ? ({ backTo } as PageQuery) : undefined),
  controlRelayCreate: `${appRoutes.controlRelays}/create`,
  controlRelayEdit: (id: number) => `${appRoutes.controlRelays}/${id}/edit`,
  subscribersControlRelay: (businessUnitId: number, id: number, backTo?: string) =>
    withQueryParams(
      `${appRoutes.subscribers}/${businessUnitId}/${id}/control-relay`,
      backTo ? ({ backTo } as PageQuery) : undefined
    ),
  subscribersControlRelayEdit: (businessUnitId: number, id: number, backTo?: string) =>
    withQueryParams(
      `${appRoutes.subscribers}/${businessUnitId}/${id}/control-relay-edit`,
      backTo ? ({ backTo } as PageQuery) : undefined
    ),
  subscribersControlRelayCreate: (businessUnitId: number, id: number, backTo?: string) =>
    withQueryParams(
      `${appRoutes.subscribers}/${businessUnitId}/${id}/control-relay-create`,
      backTo ? ({ backTo } as PageQuery) : undefined
    ),
};
