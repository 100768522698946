import { Grid, Container, Paper, withStyles, createStyles, Theme } from '@material-ui/core';
import { WithStyles } from '@material-ui/styles';
import clsx from 'clsx';
import { equals } from 'ramda';
import { forwardRef, Fragment, memo, useEffect } from 'react';
import { useSelector } from 'react-redux';

import { userHasPermissions } from '~features/users/usersUtils';

import { CurrentAlerts } from '../CurrentAlerts/CurrentAlerts';
import { LedPanel } from '../LedPanel/LedPanel';
import { RecentAlarm } from '../RecentAlarm/RecentAlarm';

import { useSubscription } from '~hooks';
import { LedStatus, PermissionName } from '~models';
import { boundActions, selectors } from '~store';

const styles = ({ spacing }: Theme) =>
  createStyles({
    root: {
      padding: spacing(2, 3, 0),
    },
    panel: {
      height: '100%',
      maxHeight: 90,
      display: 'flex',
      overflow: 'hidden',
    },
  });

export const AppPanel = memo(
  withStyles(styles)(
    forwardRef(({ classes, ...props }: WithStyles<typeof styles>, ref) => {
      const isWebsocketConnected = useSelector(selectors.app.isWebsocketConnected);
      const currentUser = useSelector(selectors.auth.getCurrentUser, equals);
      const hasPermissions = userHasPermissions(currentUser, [PermissionName.LED_PANEL]);

      useSubscription<{ status: LedStatus }>('led/alarm-automation', ({ data: { status } }) => {
        boundActions.app.setLedPanelStatus({ status, led: 'automation' });
      });

      useSubscription<{ status: LedStatus }>('led/rf-interference', ({ data: { status } }) => {
        boundActions.app.setLedPanelStatus({ status, led: 'rf' });
      });

      useSubscription<{ status: LedStatus }>('led/ethernet', ({ data: { status } }) => {
        boundActions.app.setLedPanelStatus({ status, led: 'ethernet' });
      });

      useSubscription<{ status: LedStatus }>('led/cpu', ({ data: { status } }) => {
        boundActions.app.setLedPanelStatus({ status, led: 'cpu' });
      });

      useEffect(() => {
        if (isWebsocketConnected && hasPermissions) {
          boundActions.app.fetchLedPanelStatusesInit();
        }
      }, [isWebsocketConnected, hasPermissions]);

      return (
        <Container className={classes.root} ref={ref as () => HTMLDivElement | null} maxWidth="xl">
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <Paper className={classes.panel}>
                <RecentAlarm />
              </Paper>
            </Grid>

            {hasPermissions ? (
              <Fragment>
                <Grid item xs={3}>
                  <Paper className={clsx(classes.panel)}>
                    <LedPanel />
                  </Paper>
                </Grid>
                <Grid item xs={3}>
                  <Paper className={classes.panel}>
                    <CurrentAlerts />
                  </Paper>
                </Grid>
              </Fragment>
            ) : (
              <Grid item xs={6}>
                <Paper className={classes.panel}>
                  <CurrentAlerts />
                </Paper>
              </Grid>
            )}
          </Grid>
        </Container>
      );
    })
  )
);
