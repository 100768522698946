import { IconButton } from '@material-ui/core';
import { WithStyles, withStyles } from '@material-ui/styles';
import clsx from 'clsx';
import { equals } from 'ramda';
import { Fragment, useCallback, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';

import { PaginatedSubscribersListDialog } from '~components/SubscribersListPopUp/SubscribersListPopUp';
import { getUserTimeFormat } from '~features/users/usersUtils';
import { FormPanel, LastDataUpdatedFormPanelSubtitle } from '~ui-kit/Forms';

import { NHAReportDialog } from '../Dialogs/NHAReportDialog';
import { ScoreBar } from '../ScoreBar/ScoreBar';

import { HealthScoreSectionProps, HealthScoreSection } from './HealthScoreSection';
import { getInfoDialogContent } from './NetworkHealthScore.config';
import { styles } from './NetworkHealthScore.styles';

import { InfoSectionDialog } from '~components';
import { appDetailRoutes } from '~constants';
import { BusinessUnitHealth, LatestHelthScoreType } from '~models';
import { boundActions, selectors } from '~store';
import { CalendarButton, DataPlaceholder, Divider, DownloadButton, Icon } from '~ui-kit';

type NetworkHealthScoreProps = WithStyles<typeof styles> & {
  health?: BusinessUnitHealth;
  onRefresh?: () => void;
  loading?: boolean;
  backTo?: string;
};

export const NetworkHealthScore = withStyles(styles)(
  ({ classes, health, onRefresh, loading, backTo }: NetworkHealthScoreProps) => {
    const [subscribersDialogOpened, setSubscribersDialogOpened] = useState(false);
    const [infoDialogOpened, setInfoDialogOpened] = useState<boolean>(false);
    const [dialogTitle, setDialogTitle] = useState('');
    const account = useSelector(selectors.profile.getAccount, equals);
    const timeFormat = getUserTimeFormat(account);
    const lastUpdatedTime = useMemo(() => Math.floor(Date.now() / 1000), [loading]);
    const businessUnitId = health?.businessUnitId;
    const {
      details: {
        health: { report: isReportLoading },
        networkHealthAnalysis: isNetworkAnalysisLoading,
      },
    } = useSelector(selectors.businessUnits.getBusinessUnitsLoaders);
    const pagination = useSelector(selectors.page.getPagePagination, equals);
    const businessUnit = useSelector(selectors.businessUnits.getBusinessUnit(businessUnitId as number), equals);
    const currentUser = useSelector(selectors.auth.getCurrentUser, equals);
    const [reportDialogOpened, setReportDialogOpened] = useState<boolean>(false);
    const isTechSupport = currentUser?.role === 'ROLE_TECH_SUPPORT';

    const onNHAReportDialogToggle = useCallback(() => {
      setReportDialogOpened(!reportDialogOpened);
    }, [reportDialogOpened]);

    const onExportFile = useCallback(() => {
      if (businessUnitId) {
        boundActions.businessUnits.exportBusinessUnitHealthScoreReportInit(businessUnitId);
      }
    }, [businessUnitId]);

    const onExportReport = useCallback(() => {
      setReportDialogOpened(false);
      if (businessUnitId) {
        boundActions.notifications.enqueue({
          message: 'NHA requested, please wait',
          options: {
            variant: 'success',
          },
        });
        boundActions.businessUnits.exportBusinessUnitNetworkHealthAnalysisReportInit({
          buId: businessUnitId,
          buName: businessUnit?.name as string,
        });
      }
    }, [businessUnitId]);

    const onDialogCloseToggle = useCallback(() => {
      setSubscribersDialogOpened(false);
    }, []);

    const onDialogOpenToggle = useCallback(
      (calls, title) => {
        setDialogTitle(title);

        if (businessUnitId) {
          boundActions.businessUnits.fetchBusinessUnitPopUpHealthScoreInit({
            pagination: { ...pagination, size: 40, number: -1 },
            calls,
            businessUnitId,
          });
        }
        setSubscribersDialogOpened(!subscribersDialogOpened);
      },
      [businessUnitId]
    );

    const renderActions = useMemo(
      () => () => {
        if (!health) {
          return null;
        }

        return (
          <Fragment>
            <IconButton onClick={() => setInfoDialogOpened(!infoDialogOpened)}>
              <Icon icon="info" />
            </IconButton>

            {isTechSupport &&
            <CalendarButton
              onClick={onNHAReportDialogToggle}
              disabled={isNetworkAnalysisLoading}
              tooltip="Monthly NHA"
            />}

            <DownloadButton onClick={onExportFile} disabled={isReportLoading} tooltip="Export in CSV" />
          </Fragment>
        );
      },
      [businessUnitId, onExportFile, isReportLoading]
    );

    return (
      <Fragment>
        <FormPanel
          onRefresh={onRefresh}
          title="Network Health Score"
          subTitle={
            <LastDataUpdatedFormPanelSubtitle timestamp={!loading ? lastUpdatedTime : 0} timeFormat={timeFormat} />
          }
          classes={{ root: clsx(classes.root) }}
          loading={loading}
          renderActions={renderActions}
        >
          {businessUnitId ? (
            <PaginatedSubscribersListDialog
              open={subscribersDialogOpened}
              onDialogClose={onDialogCloseToggle}
              businessUnitId={businessUnitId}
              dialogTitle={dialogTitle}
              backTo={appDetailRoutes.businessUnitDashboard(businessUnitId, backTo)}
            />
          ) : null}

          {health?.isDataPresent ? (
            <Fragment>
              <ScoreBar label="Network Health Score" value={health?.networkHealthScore as number} />

              <Divider orientation="horizontal" classes={{ root: clsx(classes.divider) }} />

              <div className={classes.sections}>
                {([
                  {
                    title: 'IP Link & Hybrid at Fault',
                    point: health.ipLinkAndHybridPoint,
                    units: health.ipLinkAndHybridUnitIds,
                    count: health.ipLinkAndHybridUnitCount,
                    calls: 'ip-links-hybrids' as LatestHelthScoreType,
                  },
                  {
                    title: 'Subscribers at Fault',
                    point: health.subscriberPoint,
                    units: health.subscriberUnitIds,
                    count: health.subscriberUnitCount,
                    calls: 'subscribers' as LatestHelthScoreType,
                  },
                  {
                    title: 'Late Check-in',
                    point: health.laterCheckInPoint,
                    units: health.laterCheckInUnitIds,
                    count: health.laterCheckInUnitCount,
                    calls: 'late-check-in-units' as LatestHelthScoreType,
                  },
                  {
                    title: 'Ack Delays',
                    point: health.ackDelaysPoint,
                    units: health.ackDelaysUnitIds,
                    count: health.ackDelaysUnitCount,
                    calls: 'ack-delay-units' as LatestHelthScoreType,
                  },
                ] as HealthScoreSectionProps[]).map((props, i) => (
                  <HealthScoreSection
                    key={i}
                    {...props}
                    businessUnitId={health.businessUnitId}
                    onViewAll={onDialogOpenToggle}
                    backTo={appDetailRoutes.businessUnitDashboard(health.businessUnitId, backTo)}
                  />
                ))}
              </div>
            </Fragment>
          ) : (
            <DataPlaceholder classes={{ root: clsx(classes.placeholder) }} />
          )}
        </FormPanel>

        <InfoSectionDialog
          open={infoDialogOpened}
          onConfirm={() => setInfoDialogOpened(!infoDialogOpened)}
          getContent={getInfoDialogContent}
        />

        <NHAReportDialog onConfirm={onExportReport} onCancel={onNHAReportDialogToggle} open={reportDialogOpened} />
      </Fragment>
    );
  }
);
