import { Theme } from '@material-ui/core';
import { createStyles, withStyles, WithStyles } from '@material-ui/styles';
import clsx from 'clsx';

import { RoutingLink } from './RoutingLink';

import { BusinessUnit, UnitRoute, UnitRouteHop, UnitType } from '~models';
import { getLinkByUnitType } from '~utils';

const minLinkSize = 60;
const linkMargin = 8;

const styles = (theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      flexWrap: 'nowrap',
      width: (minLinkSize + linkMargin) * 10 - linkMargin,
    },
    link: {
      minWidth: minLinkSize,
      marginRight: linkMargin,
      '&::before': {
        content: '""',
        position: 'absolute',
        display: 'block',
        top: '50%',
        left: 'calc(100% + 1px)',
        width: linkMargin,
        height: 1,
        backgroundColor: theme.palette.primary.main,
      },
      '&:first-child': {
        borderColor: theme.palette.greyColor.main,
        color: theme.palette.greyColor.main,
        cursor: 'auto',
        '&::before': {
          backgroundColor: theme.palette.greyColor.main,
        },
      },
      '&:last-child': {
        marginRight: 0,
        '&::before': {
          content: 'none',
        },
      },
    },
  });

interface RoutingProps extends WithStyles<typeof styles> {
  route: UnitRoute;
  count?: number;
  businessUnitId: BusinessUnit['id'];
  backTo?: string;
}

interface LiveTrafficRoutingProps extends WithStyles<typeof styles> {
  route: UnitRouteHop[];
  count?: number;
  unitId: number;
  deliveryId: number;
  deliveryType: UnitType;
  businessUnitId: BusinessUnit['id'];
  backTo?: string;
}

export const Routing = withStyles(styles)(({ route, count = 0, businessUnitId, backTo, classes }: RoutingProps) => {
  const width = (minLinkSize + linkMargin) * (count + 1) - linkMargin;
  const styles = count + 1 ? { width } : {};

  return (
    <div className={classes.root} style={styles}>
      <RoutingLink key={route.id} id={route.id} classes={{ root: clsx(classes.link) }} />
      {route.hops.map((unit, i) => (
        <RoutingLink
          key={i}
          id={unit.id}
          classes={{ root: clsx(classes.link) }}
          linkTo={getLinkByUnitType(unit.unitType, businessUnitId, unit.id, backTo)}
        />
      ))}
    </div>
  );
});

export const LiveTrafficRouting = withStyles(styles)(
  ({ route,
    deliveryId,
    deliveryType,
    unitId,
    count = 0,
    businessUnitId,
    backTo,
    classes
  }: LiveTrafficRoutingProps) => {
    if (count === 0) {
      return null;
    }

    const width = (minLinkSize + linkMargin) * (count + 1) - linkMargin;
    const styles = count + 1 ? { width } : {};

    return (
      <div className={classes.root} style={styles}>
        <RoutingLink key={unitId} id={unitId} classes={{ root: clsx(classes.link) }} />
        {route.map((unit, i) => (
          <>
            {unitId !== unit.id && deliveryId !== unit.id && (
              <RoutingLink
                key={i}
                id={unit.id}
                classes={{ root: clsx(classes.link) }}
                linkTo={getLinkByUnitType(unit.unitType, businessUnitId, unit.id, backTo)}
              />
            )}
          </>
        ))}
        <RoutingLink
          key={deliveryId}
          id={deliveryId}
          classes={{ root: clsx(classes.link) }}
          linkTo={getLinkByUnitType(deliveryType, businessUnitId, deliveryId, backTo)}
        />
      </div>
    );
  }
);
