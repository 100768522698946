import { Button } from '@material-ui/core';

import { Dialog, DialogContent, DialogActions } from '~ui-kit/Dialog';

interface AlarmAutomationDeleteDialogProps {
  isOpen: boolean;
  onClose: () => void;
}

export const AlarmAutomationDeleteDialog = ({ isOpen, onClose }: AlarmAutomationDeleteDialogProps) => (
  <Dialog open={isOpen} maxWidth="xs">
    <DialogContent>
      Cannot delete this AA Configuration because it has Business Unit/s assigned. In order to delete it, first change
      AA Configuration on Business Unit/s page
    </DialogContent>

    <DialogActions>
      <Button variant="contained" size="medium" color="primary" onClick={onClose}>
        Ok
      </Button>
    </DialogActions>
  </Dialog>
);
