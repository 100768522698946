import { AnyAction, createDraftSafeSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { pickAll } from 'ramda';
import { load } from 'redux-localstorage-simple';
import { ActionsObservable, combineEpics, StateObservable } from 'redux-observable';
import { of } from 'rxjs';
import { catchError, filter, mergeMap } from 'rxjs/operators';

import { actions as appActions } from '../app/appSlice';
import { actions as notificationsActions } from '../notifications/notificationsSlice';
import { actions as profileActions } from '../profile/profileSlice';
import { actions as sharedActions } from '../sharedSlice';

import { api } from '~constants';
import { RequestStatus, ResponseError, User } from '~models';
import { http } from '~services';
import { getResponseError } from '~utils';

type LoginPayload = {
  accessToken: string;
  refreshToken: string | null;
  mfaToken?: string | null;
  mfaRegister?: boolean;
  mfaRequired?: boolean;
  username?: string;
};

export interface AuthState {
  accessToken: string | null;
  refreshToken: string | null;
  isAuthorized: boolean | null;
  currentUser: User | null;
  mfa: {
    loading?: boolean;
    mfaToken: string | null;
    mfaRegister: boolean;
    mfaRequired: boolean;
    username: string;
    qrCode?: string;
    secret?: string;
    error?: ResponseError | null;
  } | null;
  statuses: {
    login: RequestStatus;
    logout: RequestStatus;
  };
  error: ResponseError | null;
}

export const defaultState: AuthState = {
  accessToken: null,
  refreshToken: null,
  isAuthorized: null,
  currentUser: null,
  mfa: null,
  statuses: {
    login: RequestStatus.Idle,
    logout: RequestStatus.Idle,
  },
  error: null,
};

const loaded = load({ states: ['auth'], disableWarnings: true }) as { auth: AuthState };
const prevState = pickAll(['accessToken', 'error'], loaded.auth || {}) as AuthState;

export const initialState = {
  ...defaultState,
  ...prevState,
  statuses: { ...defaultState.statuses },
} as AuthState;

export const { actions, reducer, name } = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    // Login
    loginInit(state, action: PayloadAction<{ username: string; password: string }>) {
      state.accessToken = null;
      state.mfa = null;

      state.error = null;
      state.isAuthorized = null;
      state.statuses.login = RequestStatus.Pending;
    },
    loginSuccess(state, { payload }: PayloadAction<LoginPayload>) {
      state.accessToken = payload.accessToken;
      state.refreshToken = payload.refreshToken;

      state.statuses.login = RequestStatus.Success;
      if (payload.accessToken) {
        state.isAuthorized = true;
      }
      if (payload.mfaToken?.length) {
        state.mfa = {
          mfaToken: payload.mfaToken,
          mfaRequired: payload.mfaRequired as boolean,
          mfaRegister: payload.mfaRegister as boolean,
          username: payload.username as string,
        };
      }
    },
    loginFail(state, { payload }: PayloadAction<ResponseError>) {
      state.error = payload;
      state.statuses.login = RequestStatus.Failure;
    },

    // Logout
    logoutInit(state) {
      state.statuses.logout = RequestStatus.Pending;
    },
    logoutSuccess(state) {
      state.statuses.logout = RequestStatus.Success;
      localStorage.clear();
    },
    logoutFail(state) {
      state.statuses.logout = RequestStatus.Failure;
    },

    // Set auth token
    setAuthToken(state, { payload: { accessToken, refreshToken } }: PayloadAction<LoginPayload>) {
      state.accessToken = accessToken;
      state.refreshToken = refreshToken;
    },

    // Set authorization
    setAuthorization(state, { payload }: PayloadAction<boolean>) {
      if (payload) {
        state.refreshToken = null;
        state.isAuthorized = true;
      }
    },

    // Fetch current user
    fetchCurrentUserInit(state, action: PayloadAction<{ loginOnSuccess: boolean }>) {},
    fetchCurrentUserSuccess(state, { payload }: PayloadAction<User>) {
      state.currentUser = payload;
    },
    fetchCurrentUserFailed(state, action: PayloadAction<ResponseError>) {},

    // Update required password
    updateRequiredPassword(state, { payload }: PayloadAction<boolean>) {
      if (state.currentUser) {
        state.currentUser.updateRequired = payload;
      }
    },

    fetchMFAInfoInit(state, { payload }: PayloadAction<{mfaToken: string, username: string}>) {
      if (state.mfa) {
        state.mfa.loading = true;
      }
    },
    fetchMFAInfoSuccess(state, { payload }: PayloadAction<{mfaToken: string, qrCode: string, secret: string}>) {
      if (state.mfa) {
        state.mfa.loading = false;
        state.mfa.mfaToken = payload.mfaToken;
        state.mfa.qrCode = payload.qrCode;
        state.mfa.secret = payload.secret;
      }
    },
    fetchMFAInfoFailed(state, { payload }: PayloadAction<ResponseError>) {
      if (state.mfa) {
        state.mfa.loading = false;
      }
      state.error = payload;
    },

    registerMFAInit(
      state,
      { payload }: PayloadAction<{mfaToken: string, username: string, totp: string}>) {
      if (state.mfa) {
        state.mfa.loading = true;
      }
    },
    registerMFASuccess(state) {
      state.mfa = null;
    },
    registerMFAFailed(state, { payload }: PayloadAction<ResponseError>) {
      if (state.mfa) {
        state.mfa.loading = false;
      }
      state.error = payload;
    },

    validateMFAInit(
      state,
      { payload }: PayloadAction<{mfaToken: string, username: string, totp: string}>) {
      if (state.mfa) {
        state.mfa.loading = true;
      }
    },
    validateMFASuccess(state, { payload }: PayloadAction<string>) {
      state.mfa = null;
      state.accessToken = payload;
      state.isAuthorized = true;
      state.statuses.login = RequestStatus.Success;
    },
    validateMFAFailed(state, { payload }: PayloadAction<ResponseError>) {
      if (state.mfa) {
        state.mfa.loading = false;
        state.mfa.error = payload;
      }
    },
    resetMFASettingsInit() {},
    resetMFASettingsFailed(state, { payload }: PayloadAction<ResponseError>) {
      state.error = payload;
    },
  },
  extraReducers: {
    [sharedActions.reset.toString()]: state => {
      Object.assign(state, defaultState);
      state.isAuthorized = false;
    },
  },
});

const getAuthState = (state: AES.RootState) => state.auth;
export const selectors = {
  getAuthState,

  isLoginProcessing: createDraftSafeSelector(getAuthState, state => state.statuses.login === RequestStatus.Pending),
  isLogoutProcessing: createDraftSafeSelector(getAuthState, state => state.statuses.logout === RequestStatus.Pending),

  isAuthorized: createDraftSafeSelector(getAuthState, state => state.isAuthorized),

  getAuthToken: createDraftSafeSelector(getAuthState, state => state.accessToken),
  getAuthError: createDraftSafeSelector(getAuthState, state => state.error),

  getMFA: createDraftSafeSelector(getAuthState, state => state.mfa),

  getCurrentUser: createDraftSafeSelector(getAuthState, state => state.currentUser),

  shouldUpdatePassword: createDraftSafeSelector(getAuthState, state => state.currentUser?.updateRequired),
};

const userLoginEpic = (action$: ActionsObservable<AnyAction>) =>
  action$.pipe(
    filter(actions.loginInit.match),
    mergeMap(({ payload }) =>
      http.post(api.auth.login, payload).pipe(
        mergeMap(({ response: { accessToken, mfaToken, mfaRequired, mfaRegister, username } }) =>
          of(
            actions.loginSuccess({
              accessToken,
              mfaToken,
              mfaRequired,
              mfaRegister,
              username,
              refreshToken: null,
            }),
            appActions.setAppDialog({ dialog: 'firstLoginStepper', value: true })
          )
        ),
        catchError(err => of(actions.loginFail(getResponseError(err))))
      )
    )
  );

const userLogoutEpic = (action$: ActionsObservable<AnyAction>) =>
  action$.pipe(
    filter(actions.logoutInit.match),
    mergeMap(() =>
      http.post(api.auth.logout).pipe(
        mergeMap(() => of(actions.logoutSuccess())),
        catchError(() => of(actions.logoutFail(), sharedActions.reset()))
      )
    )
  );

const userLogoutSuccessEpic = (action$: ActionsObservable<AnyAction>) =>
  action$.pipe(
    filter(actions.logoutSuccess.match),
    mergeMap(() => of(sharedActions.reset()))
  );

const fetchCurrentUserEpic = (action$: ActionsObservable<AnyAction>, state$: StateObservable<AES.RootState>) =>
  action$.pipe(
    filter(actions.fetchCurrentUserInit.match),
    mergeMap(({ payload: { loginOnSuccess } }) =>
      http.getJSON<User>(api.users.current).pipe(
        mergeMap(user => {
          const epicActions: AnyAction[] = [
            actions.fetchCurrentUserSuccess(user),
            profileActions.get(),
            appActions.fetchSystemInfoInit(),
          ];

          if (loginOnSuccess) {
            epicActions.push(
              actions.setAuthorization(true)
            );
          }

          return of(...epicActions);
        }),
        catchError(err => of(actions.fetchCurrentUserFailed(getResponseError(err))))
      )
    )
  );

const fetchMFAInfoEpic = (action$: ActionsObservable<AnyAction>) =>
  action$.pipe(
    filter(actions.fetchMFAInfoInit.match),
    mergeMap(({ payload }) =>
      http.post(api.auth.mfa.info, payload).pipe(
        mergeMap(({ response: { mfaToken, qrCode, secret } }) =>
          of(actions.fetchMFAInfoSuccess({ mfaToken, qrCode, secret }))),
        catchError(err => of(actions.fetchMFAInfoFailed(getResponseError(err))))
      )
    )
  );

const registerMFAEpic = (action$: ActionsObservable<AnyAction>) =>
  action$.pipe(
    filter(actions.registerMFAInit.match),
    mergeMap(({ payload }) =>
      http.post(api.auth.mfa.register, payload).pipe(
        mergeMap(() =>
          of(
            actions.registerMFASuccess(),
            notificationsActions.enqueue({
              message: 'Registration complete',
              options: { variant: 'success' },
            })
          )
        ),
        catchError(err => of(actions.registerMFAFailed(getResponseError(err))))
      )
    )
  );

const validateMFAEpic = (action$: ActionsObservable<AnyAction>) =>
  action$.pipe(
    filter(actions.validateMFAInit.match),
    mergeMap(({ payload }) =>
      http.post(api.auth.mfa.validate, payload).pipe(
        mergeMap(({ response: { accessToken } }) => of(
          actions.validateMFASuccess(accessToken),
          appActions.setAppDialog({ dialog: 'firstLoginStepper', value: true }),
        )),
        catchError(err => of(actions.validateMFAFailed(getResponseError(err))))
      )
    )
  );

const resetMFASettingsEpic = (action$: ActionsObservable<AnyAction>) =>
  action$.pipe(
    filter(actions.resetMFASettingsInit.match),
    mergeMap(() =>
      http.put(api.auth.mfa.reset).pipe(
        mergeMap(() => of(actions.logoutSuccess())),
        catchError(err => of(actions.resetMFASettingsFailed(getResponseError(err))))
      )
    )
  );

export const epics = combineEpics(
  userLoginEpic,
  userLogoutEpic,
  userLogoutSuccessEpic,
  fetchCurrentUserEpic,
  fetchMFAInfoEpic,
  registerMFAEpic,
  validateMFAEpic,
  resetMFASettingsEpic
);
export const allEpics = {
  userLoginEpic,
  userLogoutEpic,
  userLogoutSuccessEpic,
  fetchCurrentUserEpic,
  fetchMFAInfoEpic,
  registerMFAEpic,
  validateMFAEpic,
  resetMFASettingsEpic,
};

declare global {
  namespace AES {
    export interface Actions {
      auth: typeof actions;
    }
    export interface RootState {
      auth: AuthState;
    }
    export interface Selectors {
      auth: typeof selectors;
    }
  }
}
