import * as querystring from 'querystring';

import { AnyAction, createDraftSafeSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { push } from 'connected-react-router';
import { assoc, assocPath } from 'ramda';
import { combineEpics, ActionsObservable, StateObservable } from 'redux-observable';
import { of } from 'rxjs';
import { ajax } from 'rxjs/ajax';
import { filter, mergeMap, catchError, delay } from 'rxjs/operators';

import { actions as notificationsActions } from '../notifications/notificationsSlice';
import { actions as pageActions } from '../page/pageSlice';
import { extractPaginationValues, getPaginationQueryParams } from '../page/pageUtils';
import { actions as sharedActions } from '../sharedSlice';
import {
  SubscriberInfoSection,
  SubscriberSettingsMode,
  SubscriberSettingsSection,
} from '../subscribers/subscribersSlice';
import { updateSubscriberInfo, updateSubscribersWithRoutes } from '../subscribers/subscribersUtils';

import { api, appRoutes } from '~constants';
import {
  SubscriberSettings,
  Subscriber,
  ResponseError,
  PaginationRequestPayload,
  PaginationResponse,
  UnitRoute,
  SubscriberZonesConfiguration,
  SubscriberMessage,
  ReportingRoute,
  SubscriberHistory,
  SubscriberHistoryPeriod,
  BusinessUnit,
  AssignedDealer,
  SubscriberNotifications,
  SubscriberPeers,
  UnitDependent,
  FACPZones,
  ZoneNotifications,
  CustomNote,
  ReverseGeoCoding,
} from '~models';
import { http } from '~services';
import {
  findDataByCallback,
  getResponseError,
  getResponsePayload,
  intToHex,
  saveFile,
  updateArray,
  updateArrayWithCallback,
} from '~utils';

export interface HybridsState {
  data: Subscriber[];
  loading: {
    list: boolean;
    details: { [key in SubscriberInfoSection]: boolean } & {
      general: boolean;
      update: boolean;
      routes: boolean;
      messages: {
        all: boolean;
        send: boolean;
      };
      activate: boolean;
      history: {
        all: boolean;
        export: boolean;
      };
      dealers: boolean;
      notifications: boolean;
      peers: boolean;
      dependencies: boolean;
      reverseGeoCoding: boolean;
    };
    facp: {
      list: boolean;
      update: boolean;
      delete: boolean;
      notifications: boolean;
      import: boolean;
      export: boolean;
    };
    settings: {
      sections: { [key in SubscriberSettingsSection]: boolean };
      modes: { [key in SubscriberSettingsMode]: boolean };
      request: { [key in SubscriberSettingsSection]: boolean };
      'remote-reset': boolean;
      rf: boolean;
      defaultSetting: {
        'radio-packet-life': boolean;
        timing: boolean;
      };
    };
    zones: boolean;
    delete: boolean;
    customNote: {
      list: boolean;
      delete: boolean;
    };
  };
  status: {
    details: 'updated' | null;
    messages: 'send' | null;
    facp: 'updated' | 'deleted' | 'imported' | null;
  };
  errors: {
    list: ResponseError | null;
    details: ResponseError | null;
    settings: { [key in SubscriberSettingsSection]: ResponseError | null };
    zones: ResponseError | null;
    messages: ResponseError | null;
    'remote-reset': ResponseError | null;
    peers: ResponseError | null;
    notifications: ResponseError | null;
    facp: ResponseError | null;
  };
}

export const initialState: HybridsState = {
  data: [],
  loading: {
    list: false,
    details: {
      general: false,
      update: false,
      routes: false,
      hardware: false,
      zones: false,
      'radio-status': false,
      'ip-config': false,
      'routing-tables': false,
      messages: {
        all: false,
        send: false,
      },
      activate: false,
      history: {
        all: false,
        export: false,
      },
      dealers: false,
      notifications: false,
      peers: false,
      dependencies: false,
      reverseGeoCoding: false,
    },
    facp: {
      list: false,
      update: false,
      delete: false,
      notifications: false,
      import: false,
      export: false,
    },
    settings: {
      sections: {
        'radio-packet-life': false,
        timing: false,
        modes: false,
        'rf-settings': false,
        supervision: false,
      },
      request: {
        'radio-packet-life': false,
        timing: false,
        modes: false,
        'rf-settings': false,
        supervision: false,
      },
      modes: {
        intellitap: false,
        'line-cut': false,
        repeater: false,
      },
      'remote-reset': false,
      rf: false,
      defaultSetting: {
        'radio-packet-life': false,
        timing: false,
      },
    },
    zones: false,
    delete: false,
    customNote: {
      list: false,
      delete: false,
    },
  },
  status: {
    details: null,
    messages: null,
    facp: null,
  },
  errors: {
    list: null,
    details: null,
    settings: { 'radio-packet-life': null, timing: null, modes: null, 'rf-settings': null, supervision: null },
    zones: null,
    messages: null,
    'remote-reset': null,
    peers: null,
    notifications: null,
    facp: null,
  },
};

export const { name, actions, reducer } = createSlice({
  name: 'hybrids',
  initialState,
  reducers: {
    // Fetch hybrids list with pagination
    fetchHybridsWithParamsInit: (state, payload: PayloadAction<PaginationRequestPayload>) => {
      state.loading.list = true;
    },
    fetchHybridsWithParamsSuccess: (state, { payload: { content } }: PayloadAction<PaginationResponse<Subscriber>>) => {
      state.loading.list = false;

      state.data = content;
    },
    fetchHybridsWithParamsFailed: (state, { payload }: PayloadAction<ResponseError>) => {
      state.loading.list = false;
      state.errors.list = payload;
    },

    // Fetch hybrids by bu
    fetchHybridsByBUInit: (
      state,
      payload: PayloadAction<PaginationRequestPayload & { businessUnitId: Subscriber['businessUnitId'] }>
    ) => {
      state.loading.list = true;
    },

    // Fetch hybrid by id
    fetchHybridByIdInit: (
      state,
      {
        payload: { businessUnitId, id },
      }: PayloadAction<{ businessUnitId: Subscriber['businessUnitId']; id: Subscriber['id']; notify?: boolean }>
    ) => {
      state.loading.details.general = true;
    },
    fetchHybridByIdSuccess: (state, { payload }: PayloadAction<Subscriber>) => {
      state.loading.details.general = false;
      state.data = updateArray(state.data, payload);
    },
    fetchHybridByIdFailed: (state, { payload }: PayloadAction<ResponseError>) => {
      state.loading.details.general = false;
      state.errors.details = payload;
    },

    // Update hybrid details
    updateHybridDetailsInit: (
      state,
      {
        payload,
      }: PayloadAction<{
        businessUnitId: Subscriber['businessUnitId'];
        id: Subscriber['id'];
        values: Partial<Subscriber>;
      }>
    ) => {
      state.loading.details.update = true;
      state.status.details = null;
    },
    updateHybridDetailsSuccess: (state, { payload }: PayloadAction<Subscriber>) => {
      const hybrid = findDataByCallback(
        state.data,
        hybrid => hybrid.id === payload.id && hybrid.businessUnitId === payload.businessUnitId
      ) as Subscriber;

      state.loading.details.update = false;
      state.status.details = 'updated';

      state.data = updateArrayWithCallback(
        state.data,
        updateSubscriberInfo(hybrid, payload),
        (e, u) => e.id === payload.id && u.businessUnitId === payload.businessUnitId
      );
    },
    updateHybridDetailsFailed: (state, { payload }: PayloadAction<ResponseError>) => {
      state.loading.details.update = false;
      state.errors.details = payload;
    },

    // Fetch Hybrid partial info
    fetchHybridPartialInfoInit(
      state,
      {
        payload: { section },
      }: PayloadAction<{
        id: Subscriber['id'];
        businessUnitId: Subscriber['businessUnitId'];
        section: SubscriberInfoSection;
      }>
    ) {
      state.loading.details[section] = true;
    },
    fetchHybridPartialInfoSuccess(
      state,
      {
        payload: { id, businessUnitId, section, info },
      }: PayloadAction<{
        id: Subscriber['id'];
        businessUnitId: Subscriber['businessUnitId'];
        section: SubscriberInfoSection;
        info: Partial<Subscriber>;
      }>
    ) {
      const hybrid = findDataByCallback(
        state.data,
        hybrid => hybrid.id === id && hybrid.businessUnitId === businessUnitId
      ) as Subscriber;

      state.loading.details[section] = false;
      state.data = updateArrayWithCallback(
        state.data,
        assocPath(['subscriberInfo', section], info, hybrid),
        (e, u) => e.id === id && u.businessUnitId === businessUnitId
      );
    },
    fetchHybridPartialInfoFailed(
      state,
      {
        payload: { section, error },
      }: PayloadAction<{
        section: SubscriberInfoSection;
        error: ResponseError;
      }>
    ) {
      state.loading.details[section] = false;
    },

    // Request Hybrid data
    requestHybridDataInit(
      state,
      {
        payload: { section },
      }: PayloadAction<{
        id: Subscriber['id'];
        businessUnitId: Subscriber['businessUnitId'];
        section: SubscriberInfoSection;
        reportingRoute?: ReportingRoute['route'];
      }>
    ) {
      state.loading.details[section] = true;
    },
    requestHybridDataSuccess(
      state,
      {
        payload: { section },
      }: PayloadAction<{
        section: SubscriberInfoSection;
      }>
    ) {
      state.loading.details[section] = false;
    },
    requestHybridDataFailed(
      state,
      {
        payload: { section },
      }: PayloadAction<{
        section: SubscriberInfoSection;
        error: ResponseError;
      }>
    ) {
      state.loading.details[section] = false;
    },

    // Fetch hybrid settings
    fetchHybridSettingsInit(
      state,
      {
        payload: { section },
      }: PayloadAction<{
        businessUnitId: Subscriber['businessUnitId'];
        id: Subscriber['id'];
        section: SubscriberSettingsSection;
      }>
    ) {
      state.loading.settings.sections[section] = true;
      state.errors.settings[section] = null;
    },
    fetchHybridSettingsSuccess<T>(
      state,
      {
        payload: { businessUnitId, id, section, values },
      }: PayloadAction<{
        businessUnitId: BusinessUnit['id'];
        id: Subscriber['id'];
        section: SubscriberSettingsSection;
        values: T;
      }>
    ) {
      const hybrid = findDataByCallback(
        state.data,
        hybrid => hybrid.id === id && hybrid.businessUnitId === businessUnitId
      ) as Subscriber;

      state.data = updateArrayWithCallback(
        state.data,
        assocPath(['settings', section], values, hybrid),
        (e, u) => e.id === id && u.businessUnitId === businessUnitId
      );

      state.loading.settings.sections[section] = false;
    },
    fetchHybridSettingsFailed(
      state,
      {
        payload: { section, error },
      }: PayloadAction<{ section: SubscriberSettingsSection; error: ResponseError | null }>
    ) {
      state.loading.settings.sections[section] = false;
      state.errors.settings[section] = error;
    },

    // Fetch hybrid settings default
    fetchHybridSettingsDefaultInit(
      state,
      {
        payload: { section },
      }: PayloadAction<{
        businessUnitId: BusinessUnit['id'];
        id: Subscriber['id'];
        section: SubscriberSettingsSection;
      }>
    ) {
      state.loading.settings.defaultSetting[section] = true;
      state.errors.settings[section] = null;
    },

    fetchHybridSettingsDefaultSuccess<T>(
      state,
      {
        payload: { businessUnitId, id, section, values },
      }: PayloadAction<{
        businessUnitId: BusinessUnit['id'];
        id: Subscriber['id'];
        section: SubscriberSettingsSection;
        values: T;
      }>
    ) {
      state.loading.settings.defaultSetting[section] = false;
      const hybridDefault = findDataByCallback(
        state.data,
        sub => sub.id === id && sub.businessUnitId === businessUnitId
      ) as Subscriber;
      state.data = updateArrayWithCallback(
        state.data,
        assocPath(['settings', section], values, hybridDefault),
        (e, u) => e.id === id && u.businessUnitId === businessUnitId
      );
    },

    fetchHybridSettingsDefaultFailed(
      state,
      {
        payload: { section, error },
      }: PayloadAction<{ section: SubscriberSettingsSection; error: ResponseError | null }>
    ) {
      state.loading.settings.defaultSetting[section] = false;
      state.errors.settings[section] = error;
    },

    // Request hybrid settings
    requestHybridSettingsInit(
      state,
      {
        payload: { section },
      }: PayloadAction<{
        businessUnitId: Subscriber['businessUnitId'];
        id: Subscriber['id'];
        section: SubscriberSettingsSection;
        reportingRoute?: ReportingRoute['route'];
      }>
    ) {
      state.loading.settings.request[section] = true;
      state.errors.settings[section] = null;
    },
    requestHybridSettingsSuccess(
      state,
      {
        payload: { section },
      }: PayloadAction<{
        section: SubscriberSettingsSection;
      }>
    ) {
      state.loading.settings.request[section] = false;
    },
    requestHybridSettingsFailed(
      state,
      { payload: { section, error } }: PayloadAction<{ section: SubscriberSettingsSection; error: ResponseError }>
    ) {
      state.loading.settings.request[section] = false;
      state.errors.settings[section] = error;
    },

    // Update Hybrid Settings
    updateHybridSettingsInit(
      state,
      {
        payload: { section },
      }: PayloadAction<{
        businessUnitId: Subscriber['businessUnitId'];
        id: Subscriber['id'];
        section: SubscriberSettingsSection;
        values: Partial<
          SubscriberSettings['timing'] | SubscriberSettings['radio-packet-life'] | SubscriberSettings['supervision']
        >;
      }>
    ) {
      state.loading.settings.sections[section] = true;
      state.errors.settings[section] = null;
    },
    updateHybridSettingsSuccess(
      state,
      { payload: { section } }: PayloadAction<{ section: SubscriberSettingsSection }>
    ) {
      state.loading.settings.sections[section] = false;
    },
    updateHybridSettingsFailed(
      state,
      { payload: { section, error } }: PayloadAction<{ section: SubscriberSettingsSection; error: ResponseError }>
    ) {
      state.loading.settings.sections[section] = false;
      state.errors.settings[section] = error;
    },

    //update rf hybrid settings
    updateHybridRFInit(
      state,
      { payload }: PayloadAction<{ businessUnitId: Subscriber['businessUnitId']; id: Subscriber['id']; path: string }>
    ) {
      state.loading.settings.rf = true;
    },
    updateHybridRFSuccess(state) {
      state.loading.settings.rf = false;
    },
    updateHybridRFFailed(state, payload) {
      state.loading.settings.rf = false;
    },

    //  Update hybrid settings mode
    updateHybridSettingsModeInit(
      state,
      {
        payload: { id, mode, values, fieldName },
      }: PayloadAction<{
        businessUnitId: Subscriber['businessUnitId'];
        id: Subscriber['id'];
        mode: SubscriberSettingsMode;
        values: Partial<SubscriberSettings['modes']>;
        fieldName: string;
      }>
    ) {
      state.loading.settings.modes[mode] = true;
    },
    updateHybridSettingsModeSuccess(
      state,
      {
        payload: { businessUnitId, id, mode, values, fieldName },
      }: PayloadAction<{
        businessUnitId: BusinessUnit['id'];
        id: Subscriber['id'];
        mode: SubscriberSettingsMode;
        values: Partial<SubscriberSettings['modes']>;
        fieldName: string;
      }>
    ) {
      state.loading.settings.modes[mode] = false;

      const hybrid = findDataByCallback(
        state.data,
        hybrid => hybrid.id === id && hybrid.businessUnitId === businessUnitId
      ) as Subscriber;

      state.data = updateArrayWithCallback(
        state.data,
        assocPath(['settings', 'modes', fieldName], values[fieldName], hybrid),
        (e, u) => e.id === id && u.businessUnitId === businessUnitId
      );
    },
    updateHybridSettingsModeFailed(
      state,
      {
        payload: { mode },
      }: PayloadAction<{
        mode: SubscriberSettingsMode;
        error: ResponseError | null;
      }>
    ) {
      state.loading.settings.modes[mode] = false;
    },

    //Delete hybrid
    deleteHybridInit(
      state,
      { payload }: PayloadAction<{ businessUnitId: Subscriber['businessUnitId']; id: Subscriber['id'] }>
    ) {
      state.loading.delete = true;
    },
    deleteHybridSuccess(
      state,
      { payload }: PayloadAction<{ businessUnitId: Subscriber['businessUnitId']; id: Subscriber['id'] }>
    ) {
      state.loading.delete = false;
    },
    deleteHybridFailed(state, { payload }: PayloadAction<ResponseError>) {
      state.loading.delete = false;
      state.errors.details = payload;
    },

    // Reset hybrid settings
    resetHybridSettings(
      state,
      { payload: { id, businessUnitId } }: PayloadAction<{ id: Subscriber['id']; businessUnitId: BusinessUnit['id'] }>
    ) {
      const hybrid = findDataByCallback(
        state.data,
        hybrid => hybrid.id === id && hybrid.businessUnitId === businessUnitId
      ) as Subscriber;

      if (hybrid) {
        state.data = updateArrayWithCallback(
          state.data,
          assocPath(['settings'], null, hybrid),
          (e, u) => e.id === id && u.businessUnitId === businessUnitId
        );
      }
    },

    // Fetch hybrids routes
    fetchHybridsRoutesInit(
      state,
      action: PayloadAction<{ id: Subscriber['id']; businessUnitId: Subscriber['businessUnitId']; notify?: boolean }>
    ) {
      state.loading.details.routes = true;
    },
    fetchHybridsRoutesSuccess(
      state,
      {
        payload: { id, businessUnitId, routes },
      }: PayloadAction<{ id: Subscriber['id']; businessUnitId: Subscriber['businessUnitId']; routes: UnitRoute[] }>
    ) {
      state.data = updateSubscribersWithRoutes(state.data, routes, businessUnitId, id);

      state.loading.details.routes = false;
    },
    fetchHybridsRoutesFailed(state, action: PayloadAction<ResponseError>) {
      state.loading.details.routes = false;
    },

    // Fetch hybrid zones configuration
    fetchHybridZonesConfigurationInit(
      state,
      action: PayloadAction<{ businessUnitId: Subscriber['businessUnitId']; id: Subscriber['id']; notify?: boolean }>
    ) {
      state.errors.zones = null;
      state.loading.zones = true;
    },
    fetchHybridZonesConfigurationSuccess(
      state,
      {
        payload: { id, businessUnitId, zones },
      }: PayloadAction<{
        businessUnitId: Subscriber['businessUnitId'];
        id: Subscriber['id'];
        zones: SubscriberZonesConfiguration[];
      }>
    ) {
      state.loading.zones = false;
      const hybrid = findDataByCallback(
        state.data,
        hybrid => hybrid.id === id && hybrid.businessUnitId === businessUnitId
      ) as Subscriber;

      if (hybrid) {
        state.data = updateArrayWithCallback(
          state.data,
          updateSubscriberInfo(hybrid, { zones }),
          (e, u) => e.id === id && u.businessUnitId === businessUnitId
        );
      }
    },
    fetchHybridZonesConfigurationFailed(state, { payload }: PayloadAction<ResponseError>) {
      state.errors.zones = payload;
      state.loading.zones = false;
    },

    // Update hybrid zones configuration
    updateHybridZonesConfigurationInit(
      state,
      action: PayloadAction<{
        businessUnitId: Subscriber['id'];
        id: Subscriber['id'];
        zoneProgramingDtoList: unknown[];
      }>
    ) {
      state.errors.zones = null;
      state.loading.zones = true;
    },
    updateHybridZonesConfigurationSuccess(state) {
      state.loading.zones = false;
    },
    updateHybridZonesConfigurationFailed(state, { payload }: PayloadAction<ResponseError>) {
      state.loading.zones = false;
      state.errors.zones = payload;
    },

    // Fetch hybrid messages
    fetchHybridMessageInit(
      state,
      { payload }: PayloadAction<{ businessUnitId: Subscriber['businessUnitId']; id: Subscriber['id'] }>
    ) {
      state.loading.details.messages.all = true;
    },
    fetchHybridMessageSuccess(
      state,
      {
        payload: { messages, businessUnitId, id },
      }: PayloadAction<{
        messages: SubscriberMessage[];
        businessUnitId: Subscriber['businessUnitId'];
        id: Subscriber['id'];
      }>
    ) {
      state.loading.details.messages.all = false;

      const hybrid = findDataByCallback<Subscriber>(
        state.data,
        hybrid => hybrid.id === id && hybrid.businessUnitId === businessUnitId
      );

      if (hybrid) {
        const value = (hybrid.messages || []).concat(messages);

        state.data = updateArrayWithCallback(state.data, assoc('messages' as keyof Subscriber, value, hybrid));
      }
    },
    fetchHybridMessageFailed(state, { payload }: PayloadAction<ResponseError>) {
      state.loading.details.messages.all = false;
      state.errors.messages = payload;
    },

    // Send hybrids message
    sendHybridMessageInit(
      state,
      {
        payload: { message },
      }: PayloadAction<{
        businessUnitId: Subscriber['businessUnitId'];
        id: Subscriber['id'];
        message: SubscriberMessage['message'];
        reportingRoute?: ReportingRoute['route'];
      }>
    ) {
      state.loading.details.messages.send = true;
      state.status.messages = null;
    },
    sendHybridMessageSuccess(state) {
      state.loading.details.messages.send = false;
      state.status.messages = 'send';
    },
    sendHybridMessageFailed(state, { payload }: PayloadAction<ResponseError>) {
      state.loading.details.messages.send = false;
      state.errors.messages = payload;
    },

    // Reset subscriber messages
    resetHybridMessages(
      state,
      {
        payload: { businessUnitId, id },
      }: PayloadAction<{ businessUnitId: Subscriber['businessUnitId']; id: Subscriber['id'] }>
    ) {
      const hybrid = findDataByCallback<Subscriber>(
        state.data,
        hybrid => hybrid.id === id && hybrid.businessUnitId === businessUnitId
      );

      if (hybrid) {
        state.data = updateArrayWithCallback(state.data, assoc('messages' as keyof Subscriber, [], hybrid));
      }
    },

    // Fetch hybrid history
    fetchHybridHistoryInit: (
      state,
      payload: PayloadAction<
        PaginationRequestPayload & {
          businessUnitId: Subscriber['businessUnitId'];
          period: SubscriberHistoryPeriod;
          id: Subscriber['id'];
        }
      >
    ) => {
      state.loading.details.history.all = true;
    },
    fetchHybridHistorySuccess: (
      state,
      { payload: { history, hybridId } }: PayloadAction<{ history: SubscriberHistory[]; hybridId: Subscriber['id'] }>
    ) => {
      state.loading.details.history.all = false;
      const hybrid = findDataByCallback<Subscriber>(state.data, hybrid => hybrid.id === hybridId);

      if (hybrid) {
        state.data = updateArrayWithCallback(state.data, assoc('eventHistory' as keyof Subscriber, history, hybrid));
      }
    },

    fetchHybridHistoryFailed: (state, action: PayloadAction<string>) => {
      state.loading.details.history.all = false;
    },

    requestHybridRemoteResetInit(
      state,
      { payload }: PayloadAction<{ buId: Subscriber['businessUnitId']; id: Subscriber['id'] }>
    ) {
      state.loading.settings['remote-reset'] = true;
      state.errors.settings['remote-reset'] = null;
    },

    requestHybridRemoteResetSuccess(state) {
      state.loading.settings['remote-reset'] = false;
    },

    requestHybridRemoteResetFailed(state, { payload: { error } }: PayloadAction<{ error: ResponseError }>) {
      state.loading.settings['remote-reset'] = false;
      state.errors.settings['remote-reset'] = error;
    },

    exportHybridEventHistoryInit(
      state,
      { payload }: PayloadAction<{ buId: BusinessUnit['id']; id: Subscriber['id']; period: SubscriberHistoryPeriod }>
    ) {
      state.loading.details.history.export = true;
    },
    exportHybridEventHistorySuccess: state => {
      state.loading.details.history.export = false;
    },
    exportHybridEventHistoryFailed: (state, action: PayloadAction<ResponseError>) => {
      state.loading.details.history.export = false;
    },

    // Fetch assigned dealers to hybrid
    fetchAssignedDealersInit: (
      state,
      action: PayloadAction<{ id: Subscriber['id']; businessUnitId: Subscriber['businessUnitId'] }>
    ) => {
      state.loading.details.dealers = true;
    },
    fetchAssignedDealersSuccess: (
      state,
      {
        payload: { dealers, id, businessUnitId },
      }: PayloadAction<{
        dealers: AssignedDealer[];
        id: Subscriber['id'];
        businessUnitId: Subscriber['businessUnitId'];
      }>
    ) => {
      const hybrid = findDataByCallback<Subscriber>(state.data, hybrid => hybrid.id === id);
      if (hybrid) {
        state.data = updateArrayWithCallback(state.data, assoc('dealers' as keyof Subscriber, dealers, hybrid));
      } else {
        state.data = [{ businessUnitId, id, dealers } as Subscriber];
      }
      state.loading.details.dealers = false;
    },
    fetchAssignedDealersFailed: (state, { payload }: PayloadAction<ResponseError>) => {
      state.loading.details.dealers = false;
      state.errors.details = payload;
    },

    fetchHybridNotificationsInit: (
      state,
      action: PayloadAction<{ id: Subscriber['id']; businessUnitId: Subscriber['businessUnitId'] }>
    ) => {
      state.loading.details.notifications = true;
    },
    fetchHybridNotificationsSuccess: (
      state,
      {
        payload: { notifications, id },
      }: PayloadAction<{ notifications: SubscriberNotifications; id: Subscriber['id'] }>
    ) => {
      const hybrid = findDataByCallback<Subscriber>(state.data, hybrid => hybrid.id === id);

      if (hybrid) {
        state.data = updateArrayWithCallback(
          state.data,
          assoc('notifications' as keyof Subscriber, notifications, hybrid)
        );
      }
      state.loading.details.notifications = false;
    },
    fetchHybridNotificationsFailed: (state, { payload }: PayloadAction<ResponseError>) => {
      state.loading.details.notifications = false;
      state.errors.notifications = payload;
    },

    updateHybridNotificationsInit(
      state,
      {
        payload: { businessUnitId, id, values },
      }: PayloadAction<{
        id: Subscriber['id'];
        businessUnitId: Subscriber['businessUnitId'];
        values: Partial<SubscriberNotifications>;
      }>
    ) {
      state.loading.details.notifications = true;
    },

    // Fetch hybrid Peers
    fetchHybridPeersInit: (
      state,
      payload: PayloadAction<{
        id: Subscriber['id'];
        businessUnitId: Subscriber['businessUnitId'];
      }>
    ) => {
      state.loading.details.peers = true;
    },
    fetchHybridPeersSuccess: (
      state,
      { payload: { peers, id } }: PayloadAction<{ peers: SubscriberPeers; id: Subscriber['id'] }>
    ) => {
      const hybrid = findDataByCallback<Subscriber>(state.data, hybrid => hybrid.id === id);

      if (hybrid) {
        state.data = updateArrayWithCallback(state.data, assoc('peers' as keyof Subscriber, peers, hybrid));
      }
      state.loading.details.peers = false;
    },
    fetchHybridPeersFailed: (state, action: PayloadAction<ResponseError>) => {
      state.loading.details.peers = false;
      state.errors.peers = action.payload;
    },

    // Fetch hybrid dependencies
    fetchHybridDependenciesInit: (
      state,
      {
        payload: { hybridId },
      }: PayloadAction<
        PaginationRequestPayload & { hybridId: Subscriber['id']; buId: Subscriber['businessUnitId']; period: string }
      >
    ) => {
      state.loading.details.dependencies = true;
    },
    fetchHybridDependenciesSuccess: (
      state,
      {
        payload: { hybridId, content },
      }: PayloadAction<PaginationResponse<UnitDependent[]> & { hybridId: Subscriber['id'] }>
    ) => {
      state.loading.details.dependencies = false;

      const hybrid = findDataByCallback<Subscriber>(state.data, hybrid => hybrid.id === hybridId);

      if (hybrid) {
        state.data = updateArrayWithCallback(state.data, assoc('dependencies' as keyof Subscriber, content, hybrid));
      }
    },
    fetchHybridDependenciesFailed: (state, { payload }: PayloadAction<ResponseError>) => {
      state.loading.details.dependencies = false;
      state.errors.details = payload;
    },

    fetchHybridFACPUnitZonesInit: (
      state,
      payload: PayloadAction<
        PaginationRequestPayload & { businessUnitId: Subscriber['businessUnitId']; id: Subscriber['id'] }
      >
    ) => {
      state.loading.facp.list = true;
    },

    fetchHybridFACPUnitZonesSuccess: (
      state,
      { payload: { facpZones, id } }: PayloadAction<{ facpZones: FACPZones[]; id: Subscriber['id'] }>
    ) => {
      const subscriber = findDataByCallback<Subscriber>(state.data, subscriber => subscriber.id === id);

      if (subscriber) {
        state.data = updateArrayWithCallback(state.data, assoc('facpZones' as keyof Subscriber, facpZones, subscriber));
      }

      state.loading.facp.list = false;
    },

    fetchHybridFACPUnitZonesFailed: (state, { payload }: PayloadAction<ResponseError>) => {
      state.loading.facp.list = false;
      state.errors.facp = payload;
    },

    addHybridFACPZoneEventInit(
      state,
      {
        payload,
      }: PayloadAction<
        PaginationRequestPayload & {
          businessUnitId: BusinessUnit['id'];
          id: Subscriber['id'];
          values: Partial<FACPZones>;
        }
      >
    ) {
      state.loading.facp.update = true;
    },

    addHybridFACPZoneEventSuccess(state) {
      state.loading.facp.update = false;
    },

    addHybridFACPZoneEventFailed(state, { payload }: PayloadAction<ResponseError>) {
      state.loading.facp.update = false;
      state.errors.facp = payload;
    },

    updateHybridFACPZoneEventInit(
      state,
      {
        payload,
      }: PayloadAction<
        PaginationRequestPayload & {
          businessUnitId: BusinessUnit['id'];
          id: Subscriber['id'];
          zoneEventId: number;
          values: Partial<FACPZones>;
        }
      >
    ) {
      state.loading.facp.update = true;
      state.status.facp = null;
    },

    updateHybridFACPZoneEventSuccess(state) {
      state.loading.facp.update = false;
      state.status.facp = 'updated';
    },

    updateHybridFACPZoneEventFailed(state, { payload }: PayloadAction<ResponseError>) {
      state.loading.facp.update = false;
      state.errors.facp = payload;
    },

    deleteHybridFACPZoneEventInit(
      state,
      {
        payload,
      }: PayloadAction<
        PaginationRequestPayload & {
          businessUnitId: BusinessUnit['id'];
          id: Subscriber['id'];
          zoneEventId: number;
        }
      >
    ) {
      state.loading.facp.delete = true;
      state.status.facp = null;
    },

    deleteHybridFACPZoneEventSuccess(state) {
      state.loading.facp.delete = false;
      state.status.facp = 'deleted';
    },

    deleteHybridFACPZoneEventFailed(state, { payload }: PayloadAction<ResponseError>) {
      state.loading.facp.delete = false;
      state.errors.facp = payload;
    },

    importFACPConfigurationHybridsInit(
      state,
      action: PayloadAction<
        PaginationRequestPayload & { files: File[]; businessUnitId: Subscriber['businessUnitId']; id: Subscriber['id']; skipFirstRow?: boolean }
      >
    ) {
      state.loading.facp.import = true;
      state.status.facp = null;
    },
    importFACPConfigurationHybridsSuccess(state) {
      state.loading.facp.import = false;
      state.status.facp = 'imported';
    },
    importFACPConfigurationHybridsFailed(state, { payload }: PayloadAction<ResponseError>) {
      state.loading.facp.import = false;
      state.errors.facp = payload;
    },

    exportFACPZoneConfigurationInit(
      state,
      { payload }: PayloadAction<{ businessUnitId: BusinessUnit['id']; id: Subscriber['id'] }>
    ) {
      state.loading.facp.export = true;
    },

    exportFACPZoneConfigurationSuccess: state => {
      state.loading.facp.export = false;
    },

    exportFACPZoneConfigurationFailed: (state, { payload }: PayloadAction<ResponseError>) => {
      state.loading.facp.export = false;
      state.errors.facp = payload;
    },

    fetchZoneNotificationsInit: (
      state,
      payload: PayloadAction<{ businessUnitId: Subscriber['businessUnitId'], id: Subscriber['id']}>
    ) => {
      state.loading.facp.notifications = true;
    },

    fetchZoneNotificationsSuccess: (
      state,
      { payload: { zoneNotifications, id } }: PayloadAction<{ zoneNotifications: ZoneNotifications, id: Subscriber['id'] }>
    ) => {
      const hybrid = findDataByCallback<Subscriber>(state.data, hybrid => hybrid.id === id);

      if (hybrid) {
        state.data = updateArrayWithCallback(state.data, assoc('zoneNotifications' as keyof Subscriber, zoneNotifications, hybrid));
      }

      state.loading.facp.notifications = false;
    },

    fetchZoneNotificationsFailed: (state, { payload }: PayloadAction<ResponseError>) => {
      state.loading.facp.notifications = false;
      state.errors.facp = payload;
    },

    updateZoneNotificationsInit(
      state,
      {
        payload: { businessUnitId, id, values },
      }: PayloadAction<{
        id: Subscriber['id'];
        businessUnitId: Subscriber['businessUnitId'];
        values: Partial<ZoneNotifications>;
      }>
    ) {
      state.loading.facp.notifications = true;
    },

    // activate/inactivate hybrid
    activateHybridInit(
      state,
      action: PayloadAction<{ businessUnitId: BusinessUnit['id']; id: Subscriber['id']; status: Subscriber['status'] }>
    ) {
      state.loading.details.activate = true;
      state.status.details = initialState.status.details;
    },
    activateHybridSuccess(state) {
      state.loading.details.activate = false;
      state.status.details = 'updated';
    },
    activateHybridFailed(state, { payload }: PayloadAction<ResponseError>) {
      state.loading.details.activate = false;
      state.errors.details = payload;
    },

    fetchHybridCustomNotesInit(state, { payload: { buId, id } }: PayloadAction<{ buId: Subscriber['businessUnitId']; id: Subscriber['id'] }>) {
      state.loading.customNote.list = true;
    },

    fetchHybridCustomNotesSuccess(state, { payload: { id, buId, notes } }: PayloadAction<{id: Subscriber['id']; buId: Subscriber['businessUnitId']; notes: CustomNote[]}>) {
      state.loading.customNote.list = false;
      const hybrid = findDataByCallback<Subscriber>(
        state.data,
        hybrid => hybrid.id === id && hybrid.businessUnitId === buId
      );

      if (hybrid) {
        state.data = updateArrayWithCallback(state.data, assoc('customNotes' as keyof Subscriber, notes, hybrid));
      }
    },

    fetchHybridCustomNotesFailed(state, { payload }: PayloadAction<ResponseError>) {
      state.loading.customNote.list = false;
      state.errors.details = payload;
    },

    createHybridCustomNoteInit(state, { payload: { buId, id, values } }: PayloadAction<{ buId: Subscriber['businessUnitId']; id: Subscriber['id']; values: CustomNote }>) {
      state.loading.customNote.list = true;
    },

    updateHybridCustomNoteInit(state, { payload: { buId, id, noteId, values } }: PayloadAction<{ buId: Subscriber['businessUnitId']; id: Subscriber['id']; noteId: CustomNote['id']; values: CustomNote }>) {
      state.loading.customNote.list = true;
    },

    deleteHybridCustomNoteInit(
      state,
      { payload }: PayloadAction<{ buId: Subscriber['businessUnitId']; id: Subscriber['id'], noteId: CustomNote['id'] }>
    ) {
      state.loading.customNote.delete = true;
    },
    deleteHybridCustomNoteSuccess(
      state,
      { payload }: PayloadAction<{ buId: Subscriber['businessUnitId']; id: Subscriber['id'] }>
    ) {
      state.loading.customNote.delete = false;
    },
    deleteHybridCustomNoteFailed(state, { payload }: PayloadAction<ResponseError>) {
      state.loading.customNote.delete = false;
      state.errors.details = payload;
    },

    fetchReverseGeoCodingInit(state, { payload }: PayloadAction<{ id: Subscriber['id'], businessUnitId: Subscriber['businessUnitId'] }>) {
      state.loading.details.reverseGeoCoding = true;
    },
    fetchReverseGeoCodingSuccess(state, { payload: { id, businessUnitId, values } }: PayloadAction<{ id: Subscriber['id'], businessUnitId: Subscriber['businessUnitId'], values: ReverseGeoCoding[] }>) {
      state.loading.details.reverseGeoCoding = false;
      const hybrid = findDataByCallback<Subscriber>(
        state.data,
        hybrid => hybrid.id === id && hybrid.businessUnitId === businessUnitId
      );

      if (hybrid) {
        state.data = updateArrayWithCallback(state.data, assoc('reverseGeoCoding' as keyof Subscriber, values?.[0], hybrid));
      }
    },
    fetchReverseGeoCodingFailed: (state, action: PayloadAction<ResponseError>) => {
      state.loading.details.reverseGeoCoding = false;
      state.errors.details = action.payload;
    },

    clearReverseGeoCoding(state, { payload }: PayloadAction<{ id: Subscriber['id'], businessUnitId: Subscriber['businessUnitId'] }>) {
      const hybrid = findDataByCallback<Subscriber>(
        state.data,
        hybrid => hybrid.id === payload.id && hybrid.businessUnitId === payload.businessUnitId
      );

      if (hybrid) {
        state.data = updateArrayWithCallback(state.data, assoc('reverseGeoCoding' as keyof Subscriber, null, hybrid));
      }
    },

    resetFACPStatus: state => {
      state.status.facp = null;
    },

    // Reset hybrid data
    resetHybridData: state => {
      Object.assign(state, initialState);
    },
  },
  extraReducers: {
    [sharedActions.reset.toString()]: state => Object.assign(state, initialState),
  },
});

const getHybridsState = (state: AES.RootState) => state.hybrids;

export const selectors = {
  getHybridsState,

  getLoaders: createDraftSafeSelector(getHybridsState, state => state.loading),
  getHybridsData: createDraftSafeSelector(getHybridsState, state => state.data),
  getHybridById: (id: Subscriber['id'], businessUnitId: Subscriber['businessUnitId']) =>
    createDraftSafeSelector(
      getHybridsState,
      state => state.data.find(hybrid => hybrid.id === id && hybrid.businessUnitId === businessUnitId) as Subscriber
    ),
  getHybridErrors: createDraftSafeSelector(getHybridsState, state => state.errors),
  getHybridZones: createDraftSafeSelector(getHybridsState, state => state.loading.zones),
  getHybridsStatus: createDraftSafeSelector(getHybridsState, state => state.status),
  getDealers: (id: Subscriber['id'], businessUnitId: Subscriber['businessUnitId']) =>
    createDraftSafeSelector(
      getHybridsState,
      state =>
        state.data.find(hybrid => hybrid.id === id && hybrid.businessUnitId === businessUnitId)
          ?.dealers as AssignedDealer[]
    ),
  getHybridNotifications: (id: Subscriber['id'], businessUnitId: Subscriber['businessUnitId']) =>
    createDraftSafeSelector(
      getHybridsState,
      state =>
        state.data.find(hybrid => hybrid.id === id && hybrid.businessUnitId === businessUnitId)
          ?.notifications as SubscriberNotifications
    ),
  getFACPErrors: createDraftSafeSelector(getHybridsState, state => state.errors.facp),
  isActiveHybrid: (id: Subscriber['id'], businessUnitId: Subscriber['businessUnitId']) =>
    createDraftSafeSelector(
      getHybridsState,
      state =>
        state.data.find(hybrid => hybrid.id === id && hybrid.businessUnitId === businessUnitId)
          ?.status !== 'INACTIVE'
    ),
  getHybridFACPNotifications: (id: Subscriber['id'], businessUnitId: Subscriber['businessUnitId']) =>
    createDraftSafeSelector(
      getHybridsState,
      state =>
        state.data.find(hybrid => hybrid.id === id && hybrid.businessUnitId === businessUnitId)
          ?.zoneNotifications as ZoneNotifications
    ),
  getHybridCustomNote: (id: Subscriber['id'], businessUnitId: Subscriber['businessUnitId']) =>
    createDraftSafeSelector(
      getHybridsState,
      state =>
        state.data.find(hybrid => hybrid.id === id && hybrid.businessUnitId === businessUnitId)
          ?.customNotes as CustomNote[]
    ),
};

const fetchHybridsWithParamsEpic = (action$: ActionsObservable<AnyAction>) =>
  action$.pipe(
    filter(actions.fetchHybridsWithParamsInit.match),
    mergeMap(({ payload }) => {
      const searchParams = getPaginationQueryParams(payload);

      return http.getJSON<PaginationResponse<Subscriber>>(api.hybrids.all(searchParams)).pipe(
        mergeMap(values =>
          of(
            actions.fetchHybridsWithParamsSuccess(values),
            pageActions.setPagePagination(extractPaginationValues(values))
          )
        ),
        catchError(err => of(actions.fetchHybridsWithParamsFailed(getResponseError(err))))
      );
    })
  );

const fetchHybridByIdEpic = (action$: ActionsObservable<AnyAction>) =>
  action$.pipe(
    filter(actions.fetchHybridByIdInit.match),
    mergeMap(({ payload: { id, businessUnitId, notify } }) =>
      http.getJSON<Subscriber>(api.hybrids.byId(businessUnitId, id)).pipe(
        mergeMap(hybrid => {
          const epicActions: AnyAction[] = [actions.fetchHybridByIdSuccess(hybrid), pageActions.setPageFound()];

          if (notify) {
            epicActions.push(
              notificationsActions.enqueue({
                message: 'Hybrid general info updated',
                options: {
                  variant: 'success',
                },
              })
            );
          }

          return of(...epicActions);
        }),
        catchError(err => of(actions.fetchHybridByIdFailed(getResponseError(err)), pageActions.setPageNotFound()))
      )
    )
  );

const fetchHybridsByBUEpic = (action$: ActionsObservable<AnyAction>) =>
  action$.pipe(
    filter(actions.fetchHybridsByBUInit.match),
    mergeMap(({ payload }) => {
      const searchParams = getPaginationQueryParams(payload);

      return http.getJSON<PaginationResponse<Subscriber>>(api.hybrids.byBU(payload.businessUnitId, searchParams)).pipe(
        mergeMap(values =>
          of(
            actions.fetchHybridsWithParamsSuccess(values),
            pageActions.setPagePagination(extractPaginationValues(values)),
          )
        ),
        catchError(err => of(actions.fetchHybridsWithParamsFailed(getResponseError(err))))
      );
    })
  );

const updateHybridDetailsEpic = (action$: ActionsObservable<AnyAction>) =>
  action$.pipe(
    filter(actions.updateHybridDetailsInit.match),
    mergeMap(({ payload: { id, businessUnitId, values } }) =>
      http.patch(api.hybrids.byId(businessUnitId, id), values).pipe(
        mergeMap(res =>
          of(
            actions.updateHybridDetailsSuccess(getResponsePayload(res)),
            notificationsActions.enqueue({
              message: 'Hybrid detail info updated',
              options: { variant: 'success' },
            })
          )
        ),
        catchError(err => of(actions.updateHybridDetailsFailed(getResponseError(err))))
      )
    )
  );

export const fetchHybridInfo = (action$: ActionsObservable<AnyAction>) =>
  action$.pipe(
    filter(actions.fetchHybridPartialInfoInit.match),
    mergeMap(({ payload: { id, businessUnitId, section } }) =>
      http.getJSON<Partial<Subscriber>>(api.hybrids.info(businessUnitId, id, section)).pipe(
        mergeMap(info =>
          of(
            actions.fetchHybridPartialInfoSuccess({
              id,
              businessUnitId,
              section,
              info: info || {},
            })
          )
        ),
        catchError(err => {
          const error = getResponseError(err);

          return of(
            actions.fetchHybridPartialInfoFailed({
              section,
              error,
            }),
            notificationsActions.enqueue({
              message: error.message,
              options: { variant: 'error' },
            })
          );
        })
      )
    )
  );

export const requestSubscriberData = (action$: ActionsObservable<AnyAction>) =>
  action$.pipe(
    filter(actions.requestHybridDataInit.match),
    mergeMap(({ payload: { id, businessUnitId, section, reportingRoute } }) =>
      http.post(api.hybrids.info(businessUnitId, id, section), reportingRoute).pipe(
        mergeMap(() => {
          const messageParams = section === 'routing-tables' ? 'peers' : section;
          return of(
            actions.requestHybridDataSuccess({
              section,
            }),
            notificationsActions.enqueue({
              message: `Hybrid ${messageParams} info requested`,
              options: { variant: 'success' },
            })
          );
        }),
        catchError(err => {
          const error = getResponseError(err);

          return of(
            actions.requestHybridDataFailed({ section, error }),
            notificationsActions.enqueue({
              message: error.message,
              options: { variant: 'error' },
            })
          );
        })
      )
    )
  );

const requestHybridSettingsEpic = (action$: ActionsObservable<AnyAction>) =>
  action$.pipe(
    filter(actions.requestHybridSettingsInit.match),
    mergeMap(({ payload: { id, businessUnitId, section, reportingRoute } }) =>
      http.post(api.hybrids.settings(businessUnitId, id, section), reportingRoute).pipe(
        mergeMap(() =>
          of(
            actions.requestHybridSettingsSuccess({ section }),
            notificationsActions.enqueue({
              message: `Hybrid ${section} settings requested`,
              options: { variant: 'success' },
            })
          )
        ),
        catchError(err => {
          const error = getResponseError(err);

          return of(
            actions.requestHybridSettingsFailed({ section, error }),
            notificationsActions.enqueue({
              message: error.message,
              options: {
                variant: 'error',
              },
            })
          );
        })
      )
    )
  );

const fetchHybridSettingsEpic = (action$: ActionsObservable<AnyAction>) =>
  action$.pipe(
    filter(actions.fetchHybridSettingsInit.match),
    mergeMap(({ payload: { id, businessUnitId, section } }) =>
      http.getJSON(api.hybrids.settings(businessUnitId, id, section)).pipe(
        mergeMap(values => of(actions.fetchHybridSettingsSuccess({ businessUnitId, id, section, values }))),
        catchError(err => {
          const error = getResponseError(err);

          return of(
            actions.fetchHybridSettingsFailed({ section, error }),
            notificationsActions.enqueue({
              message: error.message,
              options: { variant: 'error' },
            })
          );
        })
      )
    )
  );

const fetchHybridSettingsDefaultEpic = (action$: ActionsObservable<AnyAction>) =>
  action$.pipe(
    filter(actions.fetchHybridSettingsDefaultInit.match),
    mergeMap(({ payload: { id, businessUnitId, section } }) =>
      http
        .getJSON(
          section !== 'timing'
            ? api.hybrids.settingsDefault(businessUnitId, id, section)
            : api.hybrids.settingsTimingDefault
        )
        .pipe(
          mergeMap(values => of(actions.fetchHybridSettingsDefaultSuccess({ businessUnitId, id, section, values }))),
          catchError(err => of(actions.fetchHybridSettingsDefaultFailed({ section, error: getResponseError(err) })))
        )
    )
  );

const updateHybridSettingsEpic = (action$: ActionsObservable<AnyAction>) =>
  action$.pipe(
    filter(actions.updateHybridSettingsInit.match),
    mergeMap(({ payload: { id, businessUnitId, section, values } }) =>
      http.put(api.hybrids.settings(businessUnitId, id, section), values).pipe(
        mergeMap(() => {
          if (section !== 'supervision') {
            return of(
              actions.updateHybridSettingsSuccess({ section }),
              actions.fetchHybridSettingsSuccess({ businessUnitId, id, section, values }),
              actions.fetchHybridSettingsInit({ id, businessUnitId, section }),
              notificationsActions.enqueue({
                message: `Hybrid ${section} settings updated`,
                options: { variant: 'success' },
              })
            );
          }

          return of(
            actions.updateHybridSettingsSuccess({ section }),
            actions.fetchHybridSettingsSuccess({ businessUnitId, id, section, values }),
            notificationsActions.enqueue({
              message: `Hybrid ${section} settings updated`,
              options: { variant: 'success' },
            })
          );
        }),
        catchError(err => {
          const error = getResponseError(err);

          return of(actions.updateHybridSettingsFailed({ section, error }));
        })
      )
    )
  );

const updateHybridRFEpic = (action$: ActionsObservable<AnyAction>) =>
  action$.pipe(
    filter(actions.updateHybridRFInit.match),
    mergeMap(({ payload: { id, businessUnitId, path } }) =>
      http.post(api.hybrids.settingsRf(businessUnitId, id, path)).pipe(
        mergeMap(() =>
          of(
            actions.updateHybridRFSuccess(),
            notificationsActions.enqueue({
              message: 'Hybrid RF Setting updated',
              options: { variant: 'success' },
            })
          )
        ),
        catchError(err => {
          const error = getResponseError(err);

          return of(actions.updateHybridRFFailed({ error }));
        })
      )
    )
  );

const updateHybridSettingsModeEpic = (action$: ActionsObservable<AnyAction>) =>
  action$.pipe(
    filter(actions.updateHybridSettingsModeInit.match),
    mergeMap(({ payload: { id, businessUnitId, mode, values, fieldName } }) =>
      http.put(api.hybrids.settingsMode(businessUnitId, id, mode), values).pipe(
        mergeMap(() =>
          of(
            actions.updateHybridSettingsModeSuccess({ id, businessUnitId, mode, values, fieldName }),
            notificationsActions.enqueue({
              message: `Hybrid ${mode} mode updated`,
              options: { variant: 'success' },
            })
          )
        ),
        catchError(err => {
          const error = getResponseError(err);

          return of(
            actions.updateHybridSettingsModeFailed({ mode, error }),
            notificationsActions.enqueue({
              message: error.message,
              options: {
                variant: 'error',
              },
            })
          );
        })
      )
    )
  );

const deleteHybridEpic = (action$: ActionsObservable<AnyAction>) =>
  action$.pipe(
    filter(actions.deleteHybridInit.match),
    mergeMap(({ payload: { businessUnitId, id } }) =>
      http.delete(api.hybrids.byId(businessUnitId, id)).pipe(
        mergeMap(() => {
          const hexId = intToHex(id);

          return of(
            actions.deleteHybridSuccess({ id, businessUnitId }),
            push(appRoutes.hybrids),
            notificationsActions.enqueue({
              message: `Hybrid ID ${hexId} has been deleted`,
              options: { variant: 'success' },
            })
          );
        }),
        catchError(err => {
          const error = getResponseError(err);

          return of(actions.deleteHybridFailed(error));
        })
      )
    )
  );

const fetchHybridsRoutesEpic = (action$: ActionsObservable<AnyAction>) =>
  action$.pipe(
    filter(actions.fetchHybridsRoutesInit.match),
    mergeMap(({ payload: { id, businessUnitId, notify } }) =>
      http.getJSON<UnitRoute[]>(api.hybrids.routes(businessUnitId, id)).pipe(
        mergeMap(routes => {
          const epicActions: AnyAction[] = [actions.fetchHybridsRoutesSuccess({ routes, id, businessUnitId })];

          if (notify) {
            epicActions.push(
              notificationsActions.enqueue({
                message: 'Hybrid routes updated',
                options: {
                  variant: 'success',
                },
              })
            );
          }

          return of(...epicActions);
        })
      )
    ),
    catchError(err => {
      const error = getResponseError(err);

      return of(
        actions.fetchHybridsRoutesFailed(error),
        notificationsActions.enqueue({ message: error.message, options: { variant: 'error' } })
      );
    })
  );

const fetchHybridZonesConfigurationEpic = (action$: ActionsObservable<AnyAction>) =>
  action$.pipe(
    filter(actions.fetchHybridZonesConfigurationInit.match),
    mergeMap(({ payload: { id, businessUnitId, notify = false } }) =>
      http.getJSON<SubscriberZonesConfiguration[]>(api.subscribers.zonesConfiguration(businessUnitId, id)).pipe(
        mergeMap(zones => {
          const epicActions: AnyAction[] = [
            actions.fetchHybridZonesConfigurationSuccess({
              id,
              businessUnitId,
              zones,
            }),
          ];

          if (notify) {
            epicActions.push(
              notificationsActions.enqueue({
                message: 'Hybrid zones configuration updated',
                options: {
                  variant: 'success',
                },
              })
            );
          }

          return of(...epicActions);
        }),
        catchError(err => of(actions.fetchHybridZonesConfigurationFailed(getResponseError(err))))
      )
    )
  );

const updateHybridZonesConfigurationEpic = (action$: ActionsObservable<AnyAction>) =>
  action$.pipe(
    filter(actions.updateHybridZonesConfigurationInit.match),
    mergeMap(({ payload: { id, businessUnitId, zoneProgramingDtoList } }) =>
      http.post(api.subscribers.zonesConfiguration(businessUnitId, id), { zoneProgramingDtoList }).pipe(
        mergeMap(res =>
          of(
            actions.updateHybridZonesConfigurationSuccess(),
            notificationsActions.enqueue({
              message: 'Update hybrid zones configuration requested',
              options: {
                variant: 'success',
              },
            })
          )
        ),
        catchError(err => of(actions.updateHybridZonesConfigurationFailed(getResponseError(err))))
      )
    )
  );

const fetchHybridMessages = (actions$: ActionsObservable<AnyAction>) =>
  actions$.pipe(
    filter(actions.fetchHybridMessageInit.match),
    mergeMap(({ payload: { businessUnitId, id } }) =>
      http.getJSON<SubscriberMessage[]>(api.hybrids.messages(businessUnitId, id)).pipe(
        mergeMap(messages => of(actions.fetchHybridMessageSuccess({ messages, businessUnitId, id }))),
        catchError(err => of(actions.fetchHybridMessageFailed(getResponseError(err))))
      )
    )
  );

const sendHybridMessage = (actions$: ActionsObservable<AnyAction>) =>
  actions$.pipe(
    filter(actions.sendHybridMessageInit.match),
    mergeMap(({ payload: { businessUnitId, id, message, reportingRoute } }) =>
      http.post(api.hybrids.messages(businessUnitId, id), { message, reportingRoute }).pipe(
        mergeMap(() =>
          of(
            actions.sendHybridMessageSuccess(),
            notificationsActions.enqueue({
              message: 'Hybrid Message sent',
              options: { variant: 'success' },
            })
          )
        ),
        catchError(err => of(actions.sendHybridMessageFailed(getResponseError(err))))
      )
    )
  );

const fetchHybridHistoryEpic = (action$: ActionsObservable<AnyAction>) =>
  action$.pipe(
    filter(action => actions.fetchHybridHistoryInit.match(action)),
    mergeMap(({ payload }) => {
      const searchParams = getPaginationQueryParams(payload);

      return http
        .getJSON<PaginationResponse<SubscriberHistory>>(
          api.hybrids.eventHistory.all(payload.businessUnitId, payload.id, payload.period, searchParams)
        )
        .pipe(
          mergeMap(res =>
            of(
              actions.fetchHybridHistorySuccess({ history: res.content, hybridId: payload.id }),
              pageActions.setPagePagination(extractPaginationValues(res))
            )
          ),
          catchError(err => {
            const error = getResponseError(err);

            return of(
              actions.fetchHybridHistoryFailed(error.message),
              notificationsActions.enqueue({
                message: error.message,
                options: {
                  variant: 'error',
                },
              })
            );
          })
        );
    })
  );

const requestHybridRemoteResetEpic = (action$: ActionsObservable<AnyAction>) =>
  action$.pipe(
    filter(actions.requestHybridRemoteResetInit.match),
    mergeMap(({ payload: { buId, id } }) =>
      http.post(api.hybrids.remoteReset(buId, id)).pipe(
        mergeMap(() =>
          of(
            actions.requestHybridRemoteResetSuccess(),
            notificationsActions.enqueue({
              message: 'Hybrid Remote Reset',
              options: { variant: 'success' },
            })
          )
        ),
        catchError(err => {
          const error = getResponseError(err);

          return of(
            actions.requestHybridRemoteResetFailed({ error }),
            notificationsActions.enqueue({
              message: error.message,
              options: {
                variant: 'error',
              },
            })
          );
        })
      )
    )
  );

const exportHybridEventHistoryEpic = (action$: ActionsObservable<AnyAction>) =>
  action$.pipe(
    filter(actions.exportHybridEventHistoryInit.match),
    mergeMap(({ payload: { buId, id, period } }) =>
      http
        .call({
          method: 'GET',
          url: api.hybrids.eventHistory.export(buId, id, period),
          responseType: 'blob' as 'json',
        })
        .pipe(
          mergeMap(res => {
            const fileName = `${period}_Event_History.csv`;
            saveFile(res.response, fileName);
            return of(
              actions.exportHybridEventHistorySuccess(),
              notificationsActions.enqueue({
                message: `${fileName} has been exported`,
                options: { variant: 'success' },
              })
            );
          }),
          catchError(err => of(actions.exportHybridEventHistoryFailed(getResponseError(err))))
        )
    )
  );

const fetchHybridDealersEpic = (action$: ActionsObservable<AnyAction>) =>
  action$.pipe(
    filter(actions.fetchAssignedDealersInit.match),
    mergeMap(({ payload: { id, businessUnitId } }) =>
      http.getJSON<AssignedDealer[]>(api.hybrids.dealers(businessUnitId, id)).pipe(
        mergeMap(dealers => of(actions.fetchAssignedDealersSuccess({ dealers, id, businessUnitId }))),
        catchError(err => {
          const error = getResponseError(err);

          return of(actions.fetchAssignedDealersFailed(error));
        })
      )
    )
  );

const fetchHybridNotificationsEpic = (action$: ActionsObservable<AnyAction>) =>
  action$.pipe(
    filter(actions.fetchHybridNotificationsInit.match),
    mergeMap(({ payload: { id, businessUnitId } }) =>
      http.getJSON<SubscriberNotifications>(api.hybrids.notifications(businessUnitId, id)).pipe(
        mergeMap(notifications => of(actions.fetchHybridNotificationsSuccess({ notifications, id }))),
        catchError(err => {
          const error = getResponseError(err);

          return of(actions.fetchHybridNotificationsFailed(error));
        })
      )
    )
  );

const updateHybridNotificationsEpic = (action$: ActionsObservable<AnyAction>) =>
  action$.pipe(
    filter(actions.updateHybridNotificationsInit.match),
    mergeMap(({ payload: { id, businessUnitId, values } }) =>
      http.put(api.hybrids.notifications(businessUnitId, id), values).pipe(
        mergeMap(res =>
          of(
            actions.fetchHybridNotificationsSuccess({ notifications: getResponsePayload(res), id }),
            notificationsActions.enqueue({
              message: 'Hybrid notification updated',
              options: { variant: 'success' },
            })
          )
        ),
        catchError(err => {
          const error = getResponseError(err);

          return of(actions.fetchHybridNotificationsFailed(error));
        })
      )
    )
  );

const fetchHybridPeersEpic = (action$: ActionsObservable<AnyAction>) =>
  action$.pipe(
    filter(actions.fetchHybridPeersInit.match),
    mergeMap(({ payload: { id, businessUnitId } }) =>
      http.getJSON<SubscriberPeers>(api.hybrids.peers(businessUnitId, id)).pipe(
        mergeMap(peers => of(actions.fetchHybridPeersSuccess({ peers, id }))),
        catchError(err => {
          const error = getResponseError(err);

          return of(actions.fetchHybridPeersFailed(error));
        })
      )
    )
  );

const fetchHybridDependenciesEpic = (action$: ActionsObservable<AnyAction>) =>
  action$.pipe(
    filter(actions.fetchHybridDependenciesInit.match),
    mergeMap(({ payload: { buId, hybridId, period, ...payload } }) => {
      const searchParams = getPaginationQueryParams(payload);

      return http
        .getJSON<PaginationResponse<UnitDependent[]>>(api.hybrids.dependencies(buId, hybridId, period, searchParams))
        .pipe(
          mergeMap(res =>
            of(
              actions.fetchHybridDependenciesSuccess({ ...res, hybridId }),
              pageActions.setPagePagination(extractPaginationValues(res))
            )
          ),
          catchError(err => {
            const error = getResponseError(err);

            return of(actions.fetchHybridDependenciesFailed(error));
          })
        );
    })
  );

const fetchHybridFACPUnitZonesEpic = (action$: ActionsObservable<AnyAction>) =>
  action$.pipe(
    filter(actions.fetchHybridFACPUnitZonesInit.match),
    mergeMap(({ payload }) => {
      const searchParams = getPaginationQueryParams(payload);
      const id = payload.id;

      return http
        .getJSON<PaginationResponse<FACPZones>>(
          api.hybrids.facp.zoneEvents(payload.businessUnitId, id, searchParams)
        )
        .pipe(
          mergeMap(res =>
            of(
              actions.fetchHybridFACPUnitZonesSuccess({ facpZones: res.content, id }),
              pageActions.setPagePagination(extractPaginationValues(res))
            )
          ),
          catchError(err => of(actions.fetchHybridFACPUnitZonesFailed(getResponseError(err))))
        );
    })
  );

const addHybridFACPZoneEventEpic = (action$: ActionsObservable<AnyAction>) =>
  action$.pipe(
    filter(actions.addHybridFACPZoneEventInit.match),
    mergeMap(({ payload: { businessUnitId, id, values, pagination } }) =>
      http.post(api.hybrids.facp.zoneEvents(businessUnitId, id, ''), values).pipe(
        mergeMap(() =>
          of(
            actions.addHybridFACPZoneEventSuccess(),
            actions.fetchHybridFACPUnitZonesInit({ businessUnitId, id, pagination }),
            notificationsActions.enqueue({
              message: 'FACP zone has been added',
              options: { variant: 'success' },
            })
          )
        ),
        catchError(err => of(actions.addHybridFACPZoneEventFailed(getResponseError(err))))
      )
    )
  );

const updateHybridFACPZoneEventEpic = (action$: ActionsObservable<AnyAction>) =>
  action$.pipe(
    filter(actions.updateHybridFACPZoneEventInit.match),
    mergeMap(({ payload: { businessUnitId, id, zoneEventId, values, pagination } }) =>
      http.put(api.hybrids.facp.byZoneEventId(businessUnitId, id, zoneEventId), values).pipe(
        mergeMap(() =>
          of(
            actions.updateHybridFACPZoneEventSuccess(),
            actions.fetchHybridFACPUnitZonesInit({ businessUnitId, id, pagination }),
            notificationsActions.enqueue({
              message: `FACP zone ID ${zoneEventId} has been updated`,
              options: { variant: 'success' },
            })
          )
        ),
        catchError(err => of(actions.updateHybridFACPZoneEventFailed(getResponseError(err))))
      )
    )
  );

const deleteHybridFACPZoneEventEpic = (action$: ActionsObservable<AnyAction>) =>
  action$.pipe(
    filter(actions.deleteHybridFACPZoneEventInit.match),
    mergeMap(({ payload: { businessUnitId, id, zoneEventId, pagination } }) =>
      http.delete(api.hybrids.facp.byZoneEventId(businessUnitId, id, zoneEventId)).pipe(
        mergeMap(() =>
          of(
            actions.deleteHybridFACPZoneEventSuccess(),
            actions.fetchHybridFACPUnitZonesInit({ businessUnitId, id, pagination }),
            notificationsActions.enqueue({
              message: `FACP zone ID ${zoneEventId} has been deleted`,
              options: { variant: 'success' },
            })
          )
        ),
        catchError(err => of(actions.deleteHybridFACPZoneEventFailed(getResponseError(err))))
      )
    )
  );

const importFACPConfigurationHybridsEpic = (
  action$: ActionsObservable<AnyAction>,
  state$: StateObservable<AES.RootState>
) =>
  action$.pipe(
    filter(actions.importFACPConfigurationHybridsInit.match),
    mergeMap(({ payload: { businessUnitId, id, files, skipFirstRow, pagination } }) => {
      const formData = new FormData();

      files.forEach(file => formData.append('file', file, file.name));

      return ajax({
        method: 'POST',
        url: api.hybrids.facp.import(businessUnitId, id, skipFirstRow),
        body: formData,
        headers: {
          Authorization: `Bearer ${state$.value.auth.accessToken}`,
        },
      }).pipe(
        delay(300),
        mergeMap(res =>
          of(
            actions.importFACPConfigurationHybridsSuccess(),
            actions.fetchHybridFACPUnitZonesInit({ businessUnitId, id, pagination }),
            notificationsActions.enqueue({
              message: `${res.response.result}`,
              options: { variant: 'success' },
            })
          )
        ),
        catchError(err => of(actions.importFACPConfigurationHybridsFailed(getResponseError(err))))
      );
    })
  );

const exportFACPZoneConfigurationEpic = (action$: ActionsObservable<AnyAction>) =>
  action$.pipe(
    filter(actions.exportFACPZoneConfigurationInit.match),
    mergeMap(({ payload: { businessUnitId, id } }) =>
      http
        .call({
          method: 'GET',
          url: api.hybrids.facp.export(businessUnitId, id),
          responseType: 'blob' as 'json',
        })
        .pipe(
          mergeMap(res => {
            const fileName = `Hybrid-${intToHex(id)}_FACPZoneConfiguration.csv`;
            saveFile(res.response, fileName);
            return of(
              actions.exportFACPZoneConfigurationSuccess(),
              notificationsActions.enqueue({
                message: `${fileName} has been exported`,
                options: { variant: 'success' },
              })
            );
          }),
          catchError(err => of(actions.exportFACPZoneConfigurationFailed(getResponseError(err))))
        )
    )
  );

const fetchZoneNotificationsEpic = (action$: ActionsObservable<AnyAction>) =>
  action$.pipe(
    filter(actions.fetchZoneNotificationsInit.match),
    mergeMap(({ payload }) => {
      const id = payload.id;

      return http
        .getJSON<ZoneNotifications>(
          api.hybrids.facp.notifications(payload.businessUnitId, id)).pipe(
          mergeMap(res =>
            of(
              actions.fetchZoneNotificationsSuccess({ zoneNotifications: res, id }),
            )
          ),
          catchError(err => of(actions.fetchZoneNotificationsFailed(getResponseError(err))))
        );
    })
  );

const updateZoneNotificationsEpic = (action$: ActionsObservable<AnyAction>) =>
  action$.pipe(
    filter(actions.updateZoneNotificationsInit.match),
    mergeMap(({ payload: { id, businessUnitId, values } }) =>
      http.put(api.hybrids.facp.notifications(businessUnitId, id), values).pipe(
        mergeMap(res =>
          of(
            actions.fetchZoneNotificationsSuccess({ zoneNotifications: getResponsePayload(res), id }),
            notificationsActions.enqueue({
              message: 'Hybrid Zone Notifications updated',
              options: { variant: 'success' },
            })
          )
        ),
        catchError(err => {
          const error = getResponseError(err);

          return of(actions.fetchZoneNotificationsFailed(error));
        })
      )
    )
  );

const activateHybridEpic = (action$: ActionsObservable<AnyAction>) =>
  action$.pipe(
    filter(actions.activateHybridInit.match),
    mergeMap(({ payload: { businessUnitId, id, status } }) => {
      const active = status === 'INACTIVE' ? 'ACTIVE' : 'INACTIVE';
      const params = querystring.stringify({ status: active });

      return http.post(api.hybrids.activate(businessUnitId, id, params)).pipe(
        mergeMap(() =>
          of(actions.activateHybridSuccess(), actions.fetchHybridByIdInit({ businessUnitId, id }))
        ),
        catchError(err => {
          const error = getResponseError(err);

          return of(actions.activateHybridFailed(error));
        })
      );
    })
  );

const fetchHybridCustomNotesEpic = (action$: ActionsObservable<AnyAction>) =>
  action$.pipe(
    filter(actions.fetchHybridCustomNotesInit.match),
    mergeMap(({ payload: { buId, id } }) =>
      http.getJSON<CustomNote[]>(api.hybrids.customNote.list(buId, id)).pipe(
        mergeMap(res => of(actions.fetchHybridCustomNotesSuccess({ id, buId, notes: res }))),
        catchError(err => {
          const error = getResponseError(err);

          return of(actions.fetchHybridCustomNotesFailed(error));
        })
      )
    )
  );

const createHybridCustomNoteEpic = (action$: ActionsObservable<AnyAction>) =>
  action$.pipe(
    filter(actions.createHybridCustomNoteInit.match),
    mergeMap(({ payload: { id, buId, values } }) =>
      http.post(api.hybrids.customNote.list(buId, id), values).pipe(
        mergeMap(res =>
          of(
            actions.fetchHybridCustomNotesInit({ buId, id }),
            notificationsActions.enqueue({
              message: 'Hybrid Custom Note created',
              options: { variant: 'success' },
            })
          )
        ),
        catchError(err => {
          const error = getResponseError(err);

          return of(actions.fetchHybridCustomNotesFailed(error));
        })
      )
    )
  );

const updateHybridCustomNoteEpic = (action$: ActionsObservable<AnyAction>) =>
  action$.pipe(
    filter(actions.updateHybridCustomNoteInit.match),
    mergeMap(({ payload: { id, buId, noteId, values } }) =>
      http.patch(api.hybrids.customNote.byId(buId, id, noteId), values).pipe(
        mergeMap(res =>
          of(
            actions.fetchHybridCustomNotesSuccess({ id, buId, notes: getResponsePayload(res) }),
            actions.fetchHybridCustomNotesInit({ buId, id }),
            notificationsActions.enqueue({
              message: 'Hybrid Custom Note updated',
              options: { variant: 'success' },
            })
          )
        ),
        catchError(err => {
          const error = getResponseError(err);

          return of(actions.fetchHybridCustomNotesFailed(error));
        })
      )
    )
  );

const deleteHybridCustomNoteEpic = (action$: ActionsObservable<AnyAction>) =>
  action$.pipe(
    filter(actions.deleteHybridCustomNoteInit.match),
    mergeMap(({ payload: { buId, id, noteId } }) =>
      http.delete(api.hybrids.customNote.byId(buId, id, noteId)).pipe(
        mergeMap(() =>
          of(
            actions.deleteHybridCustomNoteSuccess({ id, buId }),
            actions.fetchHybridCustomNotesInit({ buId, id }),
            notificationsActions.enqueue({
              message: 'Custom Note has been deleted',
              options: { variant: 'success' },
            })
          )
        ),
        catchError(err => {
          const error = getResponseError(err);

          return of(actions.deleteHybridCustomNoteFailed(error));
        })
      )
    )
  );

const fetchReverseGeoCodingEpic = (action$: ActionsObservable<AnyAction>) =>
  action$.pipe(
    filter(actions.fetchReverseGeoCodingInit.match),
    mergeMap(({ payload: { businessUnitId, id } }) =>
      http.getJSON<ReverseGeoCoding[]>(api.hybrids.location(businessUnitId, id)).pipe(
        mergeMap(values => {
          const epicActions: AnyAction[] = [actions.fetchReverseGeoCodingSuccess({ businessUnitId, id, values })];
          if (!values?.length) {
            epicActions.push(
              notificationsActions.enqueue({
                message: 'Reverse GeoCoding not available',
                options: {
                  variant: 'error',
                },
              })
            );
          }
          return of(...epicActions);
        }),
        catchError(err => {
          const error = getResponseError(err);
          return of(actions.fetchReverseGeoCodingFailed(error));
        })
      )
    )
  );

export const epics = combineEpics(
  fetchHybridsWithParamsEpic,
  fetchHybridByIdEpic,
  fetchHybridsByBUEpic,
  updateHybridDetailsEpic,
  fetchHybridInfo,
  requestSubscriberData,
  requestHybridSettingsEpic,
  fetchHybridSettingsEpic,
  fetchHybridSettingsDefaultEpic,
  updateHybridSettingsEpic,
  updateHybridRFEpic,
  updateHybridSettingsModeEpic,
  deleteHybridEpic,
  fetchHybridsRoutesEpic,
  fetchHybridZonesConfigurationEpic,
  updateHybridZonesConfigurationEpic,
  fetchHybridMessages,
  sendHybridMessage,
  fetchHybridHistoryEpic,
  exportHybridEventHistoryEpic,
  requestHybridRemoteResetEpic,
  fetchHybridDealersEpic,
  fetchHybridNotificationsEpic,
  updateHybridNotificationsEpic,
  fetchHybridPeersEpic,
  fetchHybridDependenciesEpic,
  fetchHybridFACPUnitZonesEpic,
  addHybridFACPZoneEventEpic,
  updateHybridFACPZoneEventEpic,
  deleteHybridFACPZoneEventEpic,
  importFACPConfigurationHybridsEpic,
  exportFACPZoneConfigurationEpic,
  fetchZoneNotificationsEpic,
  updateZoneNotificationsEpic,
  activateHybridEpic,
  fetchHybridCustomNotesEpic,
  createHybridCustomNoteEpic,
  updateHybridCustomNoteEpic,
  deleteHybridCustomNoteEpic,
  fetchReverseGeoCodingEpic
);
export const allEpics = {
  fetchHybridsWithParamsEpic,
  fetchHybridByIdEpic,
  fetchHybridsByBUEpic,
  updateHybridDetailsEpic,
  fetchHybridInfo,
  requestSubscriberData,
  requestHybridSettingsEpic,
  fetchHybridSettingsEpic,
  fetchHybridSettingsDefaultEpic,
  updateHybridSettingsEpic,
  updateHybridRFEpic,
  updateHybridSettingsModeEpic,
  deleteHybridEpic,
  fetchHybridsRoutesEpic,
  fetchHybridZonesConfigurationEpic,
  updateHybridZonesConfigurationEpic,
  fetchHybridMessages,
  sendHybridMessage,
  fetchHybridHistoryEpic,
  exportHybridEventHistoryEpic,
  requestHybridRemoteResetEpic,
  fetchHybridDealersEpic,
  fetchHybridNotificationsEpic,
  updateHybridNotificationsEpic,
  fetchHybridPeersEpic,
  fetchHybridDependenciesEpic,
  fetchHybridFACPUnitZonesEpic,
  addHybridFACPZoneEventEpic,
  updateHybridFACPZoneEventEpic,
  deleteHybridFACPZoneEventEpic,
  importFACPConfigurationHybridsEpic,
  exportFACPZoneConfigurationEpic,
  fetchZoneNotificationsEpic,
  updateZoneNotificationsEpic,
  activateHybridEpic,
  fetchHybridCustomNotesEpic,
  createHybridCustomNoteEpic,
  updateHybridCustomNoteEpic,
  deleteHybridCustomNoteEpic,
  fetchReverseGeoCodingEpic
};

declare global {
  namespace AES {
    export interface Actions {
      hybrids: typeof actions;
    }

    export interface RootState {
      hybrids: HybridsState;
    }

    export interface Selectors {
      hybrids: typeof selectors;
    }
  }
}
