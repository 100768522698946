import { IconButton, IconButtonProps } from '@material-ui/core';
import { WithStyles, withStyles } from '@material-ui/styles';
import clsx from 'clsx';
import { memo } from 'react';

import { styles } from './RefreshButton.styles';

import { Icon } from '~ui-kit';

export type RefreshButtonProps = IconButtonProps &
  WithStyles<typeof styles> & {
    loading?: boolean;
    onClick?: () => void;
  };

export const RefreshButton = memo(
  withStyles(styles)(({ classes, loading, onClick, ...props }: RefreshButtonProps) => (
    <IconButton {...props} onClick={onClick} classes={{ root: clsx(classes.root) }}>
      <Icon icon="update" classes={{ root: loading ? clsx(classes.iconDisabled) : clsx(classes.icon) }} />
    </IconButton>
  ))
);
