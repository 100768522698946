export enum AlarmAutomationFormat {
  ADEMCO = 1,
  RADIONICS = 2,
  RADIONICS_CONTACT_ID = 6,
}

export enum AlarmAutomationDelivery {
  DELIVER_ALL = 1,
  SUBSCRIBER_SETTINGS = 0,
  NEVER = 2,
}

export interface AlarmAutomationPrimarySocket {
  id: number;
  ipAddresses?: string[];
  primaryIp: string;
  port: number;
}

export interface AlarmAutomationSocket {
  id?: number;
  ipAddress?: string;
  portNumber?: number;
  isHeartbeatEnabled?: boolean;
  number?: number;
}

export interface AlarmAutomationBusinessUnits {
  id: number;
  name: string;
  primary: boolean;
}

export interface AlarmAutomationMeta {
  isLoading?: boolean;
}
export interface AlarmAutomation {
  id: number;
  isServer: boolean;
  sockets: AlarmAutomationSocket[];
  businessUnits?: AlarmAutomationBusinessUnits[];
  status: 'UP' | 'DOWN';
  meta?: AlarmAutomationMeta;
}

export interface AlarmAutomationStatuses {
  [key: string]: AlarmAutomation['status'];
}
