import { Button } from '@material-ui/core';
import { equals } from 'ramda';
import { Fragment, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Redirect } from 'react-router';

import { mapAlarmAutomationValues } from '~features/alarmAutomation/utils';

import { AlarmAutomationFormWrapper } from '../Form';

import { PageLoader } from '~components';
import { appDetailRoutes } from '~constants';
import { AlarmAutomation } from '~models';
import { boundActions, selectors } from '~store';

interface AlarmAutomationGeneralProps {
  alarmAutomation: AlarmAutomation;
}

export const AlarmAutomationEdit = ({ alarmAutomation }: AlarmAutomationGeneralProps) => {
  const alarmAutomationId = alarmAutomation.id;
  const loaders = useSelector(selectors.alarmAutomation.getAlarmAutomationLoading, equals);
  const alarmAutomationEdited = useSelector(selectors.alarmAutomation.isAlarmAutomationEdited);
  const backTo = appDetailRoutes.settingsAlarmAutomationDetails(alarmAutomationId);

  useEffect(
    () => () => {
      boundActions.alarmAutomation.resetAlarmAutomationStatus();
    },
    []
  );

  if (alarmAutomationEdited) {
    return <Redirect to={backTo} />;
  }

  return (
    <Fragment>
      {loaders.edit && <PageLoader fullscreen />}

      <AlarmAutomationFormWrapper
        title="Edit Alarm Automation"
        backTo={backTo}
        alarmAutomation={alarmAutomation}
        onSubmit={({ alarmAutomation: values }) => {
          boundActions.alarmAutomation.updateAlarmAutomationInit(mapAlarmAutomationValues(alarmAutomation, values));
        }}
      >
        <Button color="primary" variant="contained" type="submit" disabled={loaders.edit}>
          Save
        </Button>
      </AlarmAutomationFormWrapper>
    </Fragment>
  );
};
