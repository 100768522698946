import { createStyles, Theme } from '@material-ui/core';

import { icons } from '~icons';
import { maskImageStyles } from '~utils';

export const styles = (theme: Theme) =>
  createStyles({
    root: {
      background: theme.palette.greyColor.main,
      display: 'block',
      maskPosition: 'center',
      maskRepeat: 'no-repeat',
      '&:hover': {
        background: theme.palette.primary.main,
      },
      '&[disabled], &.disabled': {
        background: `${theme.palette.greyColor.dark} !important`,
      },
    },
    arrow: maskImageStyles(icons.iconArrow),
    attention: maskImageStyles(icons.iconAttention),
    burglary: maskImageStyles(icons.iconBurglary),
    'business-unit': maskImageStyles(icons.iconBusinessUnit),
    calendar: maskImageStyles(icons.iconCalendar),
    close: maskImageStyles(icons.iconClose),
    collapse: maskImageStyles(icons.iconCollapse),
    columns: maskImageStyles(icons.iconColumns),
    copy: maskImageStyles(icons.iconCopy),
    dark: maskImageStyles(icons.iconDark),
    dashboard: maskImageStyles(icons.iconDashboard),
    dealer: maskImageStyles(icons.iconDealer),
    done: maskImageStyles(icons.iconDone),
    download: maskImageStyles(icons.iconDownload),
    drop: maskImageStyles(icons.iconDrop),
    edit: maskImageStyles(icons.iconEdit),
    expand: maskImageStyles(icons.iconExpand),
    filter: maskImageStyles(icons.iconFilter),
    fire: maskImageStyles(icons.iconFire),
    help: maskImageStyles(icons.iconHelp),
    info: maskImageStyles(icons.iconInfo),
    light: maskImageStyles(icons.iconLight),
    notification: maskImageStyles(icons.iconNotification),
    show: maskImageStyles(icons.iconShow),
    'system-settings': maskImageStyles(icons.iconSystemSettings),
    hide: maskImageStyles(icons.iconHide),
    'live-traffic': maskImageStyles(icons.iconLiveTraffic),
    search: maskImageStyles(icons.iconSearch),
    'sort-asc': maskImageStyles(icons.iconSortAsc),
    'sort-desc': maskImageStyles(icons.iconSortDesc),
    up: maskImageStyles(icons.iconUp),
    'ip-link-filled': maskImageStyles(icons.iconIPLinkFilled),
    'ip-link-outlined': maskImageStyles(icons.iconIPLinkOutlined),
    'no-connection': maskImageStyles(icons.iconNoConnection),
    'server-config': maskImageStyles(icons.iconServerConfig),
    subscribers: maskImageStyles(icons.iconSubscribers),
    back: maskImageStyles(icons.iconBack),
    update: maskImageStyles(icons.iconUpdate),
    checked: maskImageStyles(icons.iconChecked),
    unchecked: maskImageStyles(icons.iconUnchecked),
    success: maskImageStyles(icons.iconSuccess),
    radio: maskImageStyles(icons.iconRadio),
    'radio-checked': maskImageStyles(icons.iconRadioChecked),
    add: maskImageStyles(icons.iconAdd),
    geography: maskImageStyles(icons.iconGeography),
    delete: maskImageStyles(icons.iconDelete),
    'fire-filled': maskImageStyles(icons.iconFireFilled),
    'fire-outlined': maskImageStyles(icons.iconFireOutlined),
    'burglary-filled': maskImageStyles(icons.iconBurglaryFilled),
    'burglary-outlined': maskImageStyles(icons.iconBurglaryOutlined),
    'hybrid-filled': maskImageStyles(icons.iconHybridFilled),
    'hybrid-outlined': maskImageStyles(icons.iconHybridOutlined),
    'system-filled': maskImageStyles(icons.iconSystemFilled),
    'system-outlined': maskImageStyles(icons.iconSystemOutlined),
    antenna: maskImageStyles(icons.iconAntenna),
    manual: maskImageStyles(icons.iconManual),
    'tech-support': maskImageStyles(icons.iconSupport),
    'youtube-channel': maskImageStyles(icons.iconChannel),
    time: maskImageStyles(icons.iconTime),
    settings: maskImageStyles(icons.iconSettings),
    user: maskImageStyles(icons.iconUser),
    'non-aes': maskImageStyles(icons.iconNonAES),
    'relay-control': maskImageStyles(icons.iconControlRelay),
    'number-circle': maskImageStyles(icons.iconNumberCircle),
    pause: maskImageStyles(icons.iconPause),
    resume: maskImageStyles(icons.iconResume),
    'circle-filled-green': maskImageStyles(icons.iconCircleFilledGreen),
    'circle-filled-orange': maskImageStyles(icons.iconCircleFilledOrange),
    'circle-filled-red': maskImageStyles(icons.iconCircleFilledRed),
    inactive: maskImageStyles(icons.iconInactive),
    extender: maskImageStyles(icons.iconExtender),
    gps: maskImageStyles(icons.iconGPS),
    zoom: maskImageStyles(icons.iconZoom),
  });
