import { WithStyles, withStyles } from '@material-ui/core';
import { Fragment } from 'react';

import { styles } from '../Auth.styles';

import { Logo } from '~ui-kit';

export const LoginLogo = withStyles(styles)(({ classes }: WithStyles<typeof styles>) => (
  <Fragment>
    <Logo classes={{ logo: classes.logo }} />

    <span className={classes.subheader}>
        IntelliNet® Network Control Center
    </span>
  </Fragment>
));
