import { FormHelperText } from '@material-ui/core';

import { ResponseError } from '~models';

export interface FormErrorProps {
  error?: ResponseError | null;
  value?: string;
}

export const FormError = ({ error, value }: FormErrorProps) => {
  if (!error || !value) {
    return null;
  }

  return <FormHelperText error>{value}</FormHelperText>;
};
