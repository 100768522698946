import { Button } from '@material-ui/core';
import { useSelector } from 'react-redux';

import { selectors } from '~store';
import { DialogActions } from '~ui-kit';

interface SelectMigratedBUButtonsProps {
  isLoading: boolean;
  onCancel: () => void;
  onNext?: () => void;
}

export const SelectMigratedBUButtons = (
  { onCancel, isLoading, onNext }: SelectMigratedBUButtonsProps) => {
  const isDatabaseMigrationFinished = useSelector(selectors.app.isDatabaseMigrationFinished);
  const isDatabaseMigrationFailed = useSelector(selectors.app.isDatabaseMigrationFailed);
  const { withWarnings } = useSelector(selectors.app.getDatabaseMigrationState);

  if (isDatabaseMigrationFinished && !withWarnings) {
    return null;
  }

  if (isDatabaseMigrationFailed) {
    return (
      <DialogActions>
        <Button type="button" variant="contained" color="primary" onClick={onCancel}>
          Got It
        </Button>
      </DialogActions>
    );
  }

  if (withWarnings) {
    return (
      <DialogActions>
        <Button type="button" variant="contained" color="primary" onClick={onNext}>
          Next
        </Button>
      </DialogActions>
    );
  }

  return (
    <DialogActions>
      <Button type="button" variant="outlined" color="primary" onClick={onCancel} disabled={isLoading}>
        Cancel
      </Button>

      <Button type="submit" variant="contained" color="primary" disabled={isLoading}>
        Next
      </Button>
    </DialogActions>
  );
};
