import { Button, Grid, WithStyles, withStyles } from '@material-ui/core';
import { Formik, Form } from 'formik';
import { equals } from 'ramda';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';

import { styles } from './ActivateMFAApp.styles';
import { validationSchema, initialValues } from './config';

import { PageLoader } from '~components';
import { boundActions, selectors } from '~store';
import { DialogContent, DialogActions, FormTextField, Required } from '~ui-kit';

type ActivateMFAAppProps = WithStyles<typeof styles> & {
  onBack: () => void;
}

export const ActivateMFAApp = withStyles(styles)(({ onBack, classes }: ActivateMFAAppProps) => {
  const mfa = useSelector(selectors.auth.getMFA, equals);

  useEffect(() => {
    boundActions.auth.fetchMFAInfoInit({
      mfaToken: mfa?.mfaToken as string,
      username: mfa?.username as string,
    });
  }, []);

  if (mfa?.loading) {
    return <PageLoader />;
  }

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={({ code }) => {
        if (code.length) {
          boundActions.auth.registerMFAInit({
            mfaToken: mfa?.mfaToken as string,
            username: mfa?.username as string,
            totp: code,
          });
        }
      }}
    >
      {({ isValid, values }) => (
        <Form>
          <DialogContent>
            <Grid>
              Tap the Add icon or Add an Account option, then do either of the following:
            </Grid>
            <Grid>
              1. Tap ”Scan a QR code”, and your device camera is now ready to scan a QR code.
            </Grid>

            <Grid className={classes['qr-code']}>
              <img src={`data:image/jpeg;base64,${mfa?.qrCode}`} />
            </Grid>

            <Grid>
              {`2. Tap ”Enter a setup key”, and you are prompted to enter the Account and Key.
              You key is: `}
              <span className={classes.secret}>{mfa?.secret}</span>
            </Grid>

            <Grid className={classes.code}>
              <FormTextField
                name="code"
                type="string"
                label={<Required>Enter 6-digits code</Required>}
              />
            </Grid>
          </DialogContent>

          <DialogActions className={classes.buttons}>
            <Button
              type="button"
              variant="contained"
              color="primary"
              onClick={onBack}
            >
              Back
            </Button>

            <Button
              type="submit"
              variant="contained"
              color="primary"
              disabled={!isValid || !values.code.length}
            >
              Proceed to Login
            </Button>
          </DialogActions>
        </Form>
      )}
    </Formik>
  );
});

