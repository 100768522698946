import * as yup from 'yup';

export const validationSchema = yup.object().shape({
  defaultBusinessUnitName: yup
    .string()
    .required('Default Business Unit name is required')
});

export const getBUCheckboxOptions = (businessUnits: string[]) =>
  businessUnits.reduce((acc, item) => {
    acc[item] = false;
    return acc;
  }, {});

export const getBUCheckedValues = (values: {[key in string]: boolean}) => Object.entries(values)
  .filter(([_, value]) => value)
  .map(([key]) => key);

export const getInitialValues = (buList: string[]) => ({
  businessUnitNames: getBUCheckboxOptions(buList),
  defaultBusinessUnitName: '',
});

export const dbMessages = {
  warnings: 'Database migration complete with error and/or warnings.',
  complete: 'Complete.',
  failed: 'Database migration failed.',
};

