import { Button } from '@material-ui/core';
import { withStyles, WithStyles } from '@material-ui/styles';

import { appDetailRoutes } from '~constants/routes';
import { getAlarmAutomationStatus, sortAlarmAutomationBusinessUnits } from '~features/alarmAutomation/utils';

import { helpers } from '../../config';

import { styles } from './AlarmAutomationListCard.style';

import { BusinessUnitLinks } from '~components';
import { AlarmAutomation } from '~models';
import { CardActions, Card, CardSummary, Divider, ContentField, StatusText, ContentWrapper, CardTitle } from '~ui-kit';

export interface AlarmAutomationListCardProps extends WithStyles<typeof styles> {
  alarmAutomation: AlarmAutomation;
  backTo?: string;
  onDelete: (id: number, isParentBuName: boolean) => void;
}

export const AlarmAutomationListCard = withStyles(styles)(
  ({ alarmAutomation, classes, onDelete }: AlarmAutomationListCardProps) => {
    const isParentBuName = Boolean(alarmAutomation?.businessUnits?.length);
    const primarySocket = helpers.getPrimarySocket(alarmAutomation?.sockets);

    if (!alarmAutomation) {
      return null;
    }

    const { status } = getAlarmAutomationStatus(alarmAutomation.status);

    return (
      <Card key={alarmAutomation.id}>
        <CardSummary status={status.value}>
          <CardTitle
            title="Port Number"
            width={140}
            classes={{ root: classes.portNumber }}
            linkLabel={primarySocket?.portNumber}
            linkTo={appDetailRoutes.settingsAlarmAutomationDetails(alarmAutomation.id)}
          />

          <Divider flexItem orientation="vertical" spacing="right" />

          <ContentField title="Status" width={90}>
            <StatusText status={status.value}>{status.title}</StatusText>
          </ContentField>

          <Divider flexItem orientation="vertical" spacing="right" />

          <ContentField title="Primary IP Address">{primarySocket?.ipAddress}</ContentField>

          <Divider flexItem orientation="vertical" spacing="right" />

          <ContentField title="Primary Heartbeat" width="7%">
            {primarySocket?.isHeartbeatEnabled ? 'ON' : 'OFF'}
          </ContentField>

          <Divider flexItem orientation="vertical" spacing="right" />
          <ContentWrapper>
            <ContentField title="Business Units" width="680px">
              <BusinessUnitLinks links={sortAlarmAutomationBusinessUnits(alarmAutomation.businessUnits)} />
            </ContentField>
          </ContentWrapper>

          <CardActions>
            <Button
              variant="outlined"
              color="primary"
              size="small"
              disabled={alarmAutomation.meta?.isLoading}
              onClick={() => alarmAutomation.id && onDelete(alarmAutomation.id, isParentBuName)}
            >
              Delete
            </Button>
          </CardActions>
        </CardSummary>
      </Card>
    );
  }
);
