import { appDetailRoutes, appRoutes } from '~constants';
import { IconType } from '~icons';
import { BusinessUnit, UnitType } from '~models';

export const getLinkByUnitType = (type: UnitType, businessUnitId: BusinessUnit['id'], id: number, backTo?: string): string => {
  switch (type) {
    case 'SUBSCRIBER':
      return appDetailRoutes.subscriberDetails(businessUnitId, id, backTo);

    case 'HYBRID':
      return appDetailRoutes.hybridDetails(businessUnitId, id, backTo);

    case 'IP_LINK':
      return appDetailRoutes.ipLinkDetails(businessUnitId, id, backTo);

    case 'NON_AES':
      return appDetailRoutes.businessUnitNonAESDetails(businessUnitId, id);

    default:
      return appRoutes.root;
  }
};

export const getUnitLabelByUnitType = (type: UnitType) => {
  switch (type) {
    case 'SUBSCRIBER':
      return 'Subscriber ID';

    case 'HYBRID':
      return 'Hybrid ID';

    case 'IP_LINK':
      return 'IP Link ID';

    case 'NON_AES':
      return 'Non AES Unit ID';

    default:
      return 'Unit ID';
  }
};

export const getUnitIconByUnit = (type: UnitType): IconType => {
  switch (type) {
    case 'SUBSCRIBER':
      return 'subscribers';

    case 'HYBRID':
      return 'hybrid-outlined';

    case 'IP_LINK':
      return 'ip-link-outlined';

    default:
      return 'subscribers';
  }
};

