import { TextField, TextFieldProps } from '@material-ui/core';
import { Autocomplete as MuiAutocomplete, AutocompleteProps as MuiAutocompleteProps } from '@material-ui/lab';
import { WithStyles, withStyles } from '@material-ui/styles';
import clsx from 'clsx';

import { styles } from './AutocompleteField.style';

import { Icon, SelectOption } from '~ui-kit';

export type AutocompleteFieldProps = Omit<
  MuiAutocompleteProps<SelectOption, boolean, boolean, boolean>,
  'renderInput'
> &
  TextFieldProps &
  WithStyles<typeof styles> & {
    options?: SelectOption[];
    showing?: boolean;
    onClear?: () => void;
  };

export const AutocompleteField = withStyles(styles)(
  ({ className, showing, options = [], onClear, classes, helperText, error, ...props }: AutocompleteFieldProps) => (
    <MuiAutocomplete
      {...props}
      disabled={props.disabled ?? showing}
      options={options}
      classes={classes}
      closeIcon={<Icon icon="close" />}
      popupIcon={<Icon icon="drop" />}
      getOptionLabel={(option: SelectOption) => option.label as string}
      renderInput={inputProps => (
        <TextField
          {...inputProps}
          {...props}
          helperText={helperText}
          error={error}
          label={props.label}
          placeholder={props.placeholder}
          classes={{ root: classes.input }}
        />
      )}
      className={clsx(className, { showing })}
    />
  )
);
