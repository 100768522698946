import querystring from 'querystring';

import { createSlice, EntityAdapter, PayloadAction, AnyAction, createDraftSafeSelector } from '@reduxjs/toolkit';
import { groupBy, prop, keys } from 'ramda';
import { ViewportProps } from 'react-map-gl';
import { load } from 'redux-localstorage-simple';
import { ActionsObservable, combineEpics } from 'redux-observable';
import { of } from 'rxjs';
import { catchError, filter, mergeMap } from 'rxjs/operators';

import { http } from '~services/http';

import { actions as notificationsActions } from '../notifications/notificationsSlice';
import { actions as sharedActions } from '../sharedSlice';

import { entities } from './geographyAdapters';
import {
  getLinkLayersIds,
  getDependencyIds,
  mapGeoUnits,
  mapRoutesToFeatureCollection,
  mapRoutesWithGeolocation,
  mapRoutesWithKeyField,
  mapUnitsToFeatureCollection,
  mapToFeatureCollection,
  GeoUnitPayload,
  mapGeoUnitsByType,
  defaultViewport,
  getDuplicatedUnits,
} from './geographyUtils';

import { api } from '~constants';
import {
  BusinessUnit,
  UnitType,
  GeoUnitRoute,
  GeoCoordinates,
  ResponseError,
  GeoUnitModel,
  UnitRoute,
  MapLayerType,
  MapFilter,
  GeoFaultsByUnitType,
  GeoFaultsByCode,
  GeoLinkLayers,
  GeoDependency,
  BusinessUnitGeoCenter,
  DependencyTypeColor,
  GeoUnitSearch,
} from '~models';
import { getResponseError, saveFile } from '~utils';

export type GeographyState = {
  businessUnitId: BusinessUnit['id'] | null;
  mapLayerType: MapLayerType;
  mapFilter: MapFilter;
  viewport: ViewportProps;
  grouping: boolean;
  multipleIcons: boolean;
  geoCenterPerBU: BusinessUnitGeoCenter | null;
  loader: {
    general: boolean;
    address: boolean;
  };
  errorsCount: number | null;
  error?: ResponseError | null;

  units: {
    SUBSCRIBER: ReturnType<typeof entities.units.SUBSCRIBER.getInitialState>;
    HYBRID: ReturnType<typeof entities.units.HYBRID.getInitialState>;
    IP_LINK: ReturnType<typeof entities.units.IP_LINK.getInitialState>;
    NON_AES: ReturnType<typeof entities.units.NON_AES.getInitialState>;
  };
  routes: ReturnType<typeof entities.routes.getInitialState>;
  selected: {
    unit: GeoUnitModel | null;
    units: GeoUnitModel[] | null;
    duplicateUnits: {
      units: GeoUnitModel[];
      coordinates: Partial<GeoCoordinates>;
      address?: string | null;
    } | null;
    routeUnit: GeoUnitModel | null;
    route: GeoUnitRoute | null;
    unitSearch: GeoUnitSearch | null;
  };
  faults: {
    byUnitType: GeoFaultsByUnitType | null;
    byCode: GeoFaultsByCode | null;
  }
  linkLayers: {
    all: GeoLinkLayers;
    ids: number[];
  } | null;
  dependency: {
    all: GeoDependency;
    ids: { id: number, dependency: DependencyTypeColor }[];
  } | null;
};

const loaded = load({ states: [
  'geography.businessUnitId',
  'geography.mapLayerType',
  'geography.viewport',
  'geography.mapFilter'
],
disableWarnings: true }) as {
  geography: Partial<GeographyState>;
};

const defaultState: GeographyState = {
  businessUnitId: null,
  mapLayerType: MapLayerType.STREET,
  mapFilter: MapFilter.All,
  geoCenterPerBU: null,
  viewport: defaultViewport,
  grouping: false,
  multipleIcons: false,
  loader: {
    general: false,
    address: false,
  },
  errorsCount: null,
  error: null,
  units: {
    SUBSCRIBER: entities.units.SUBSCRIBER.getInitialState(),
    IP_LINK: entities.units.IP_LINK.getInitialState(),
    HYBRID: entities.units.HYBRID.getInitialState(),
    NON_AES: entities.units.NON_AES.getInitialState(),
  },
  routes: entities.routes.getInitialState(),
  selected: {
    unit: null,
    units: [],
    duplicateUnits: null,
    routeUnit: null,
    route: null,
    unitSearch: null,
  },
  faults: {
    byUnitType: null,
    byCode: null,
  },
  linkLayers: null,
  dependency: null,
};
export const initialState: GeographyState = {
  ...defaultState,
  ...(loaded.geography || {}),
};

export const { name, reducer, actions } = createSlice({
  name: 'geography',
  initialState,
  reducers: {
    // Select business unit
    selectBusinessUnit(state, { payload }: PayloadAction<BusinessUnit['id']>) {
      state.businessUnitId = payload;
      state.selected.route = null;
      state.selected.unit = null;
    },

    // Fetch units
    fetchUnitsInit(state, action: PayloadAction<{ businessUnitId: BusinessUnit['id'] }>) {
      state.loader.general = true;
    },
    fetchUnitsSuccess(state, { payload }: PayloadAction<GeoUnitPayload[]>) {
      const groups = groupBy(prop('unitType'), payload);

      (['HYBRID', 'SUBSCRIBER', 'IP_LINK', 'NON_AES'] as UnitType[]).forEach(unitType => {
        ((entities.units[unitType] as unknown) as EntityAdapter<GeoUnitModel>).setAll(
          state.units[unitType],
          mapGeoUnits(groups[unitType] || [], unitType)
        );
      });
      state.loader.general = false;
    },
    fetchUnitsFailed(state, action: PayloadAction<ResponseError>) {
      state.loader.general = false;
      state.error = action.payload;
    },

    // Select unit
    selectUnit<T>(state, { payload: { unitType, id } }: PayloadAction<{ unitType: UnitType; id: number }>) {
      if (unitType && id) {
        const unit = state.units[unitType].entities[id];

        if (unit) {
          state.selected.unit = unit as T;
        }
      }
    },
    clearSelectedUnit(state) {
      state.selected.unit = null;
    },

    // Select units
    selectUnits(state, { payload }: PayloadAction<GeoUnitModel[]>) {
      state.selected.units = payload;
    },

    // Select duplicate units
    selectDuplicateUnits(
      state, { payload }: PayloadAction<{units: GeoUnitModel[], coordinates: {latitude: number, longitude: number}}>
    ) {
      if (payload.units.length) {
        const selected = payload.units.filter(
          unit => state.units[unit.unitType]?.entities[unit.id]
        );

        if (selected.length) {
          state.selected.duplicateUnits = {
            units: selected,
            coordinates: payload.coordinates,
          };
        }
      }
    },
    clearSelectedDuplicateUnits(state) {
      state.selected.duplicateUnits = null;
    },

    // Select route unit
    selectRouteUnit(state, { payload }: PayloadAction<{ unitType: UnitType; id: number }>) {
      if (payload?.unitType && payload?.id) {
        const unit = state.units[payload.unitType].entities[payload.id];

        if (unit) {
          state.selected.routeUnit = unit;
        }
      }
    },

    // Select unit search
    selectUnitSearch(state, { payload }: PayloadAction<GeoUnitSearch>) {
      state.selected.unitSearch = payload;
    },
    clearSelectedUnitSearch(state) {
      state.selected.unitSearch = null;
    },

    // Fetch routes
    fetchRoutesInit(state, action: PayloadAction<BusinessUnit['id']>) {
      state.loader.general = true;
    },
    fetchRoutesSuccess(state, { payload }: PayloadAction<(UnitRoute & { key: number })[]>) {
      entities.routes.setAll(state.routes, payload);
      state.loader.general = false;
    },
    fetchRoutesFailed(state, action: PayloadAction<ResponseError>) {
      state.loader.general = false;
      state.error = action.payload;
    },

    // Select route
    selectRoute(state, { payload: { key, position } }: PayloadAction<{ key: number; position: GeoCoordinates }>) {
      const route = state.routes.entities[key];

      if (route) {
        state.selected.route = { ...route, position };
      }
    },
    clearSelectedRoute(state) {
      state.selected.route = null;
      state.selected.routeUnit = null;
    },
    clearRoutes(state) {
      state.routes = initialState.routes;
    },

    // Fetch faults by unit type
    fetchFaultsByUnitTypeInit(state, action: PayloadAction<BusinessUnit['id']>) {
      state.loader.general = true;
    },
    fetchFaultsByUnitTypeSuccess(state, { payload }: PayloadAction<GeoFaultsByUnitType>) {
      state.loader.general = false;
      if (keys(payload).length) {
        state.faults.byUnitType = payload;
        return;
      }

      state.faults.byUnitType = null;
    },
    fetchFaultsByUnitTypeFailed(state, action: PayloadAction<ResponseError>) {
      state.loader.general = false;
      state.error = action.payload;
    },

    // Fetch faults by code
    fetchFaultsByCodeInit(state, action: PayloadAction<BusinessUnit['id']>) {
      state.loader.general = true;
    },
    fetchFaultsByCodeSuccess(state, { payload }: PayloadAction<GeoFaultsByCode>) {
      state.loader.general = false;
      if (keys(payload).length) {
        state.faults.byCode = payload;
        return;
      }

      state.faults.byCode = null;
    },
    fetchFaultsByCodeFailed(state, action: PayloadAction<ResponseError>) {
      state.loader.general = false;
      state.error = action.payload;
    },
    clearFaults(state) {
      state.faults = initialState.faults;
    },

    // Fetch link layers
    fetchLinkLayersInit(state, action: PayloadAction<BusinessUnit['id']>) {
      state.loader.general = true;
    },
    fetchLinkLayersSuccess(state, { payload }: PayloadAction<GeoLinkLayers>) {
      state.loader.general = false;
      if (keys(payload).length) {
        state.linkLayers = {
          all: payload,
          ids: getLinkLayersIds(payload),
        };
        return;
      }

      state.linkLayers = null;
    },
    fetchLinkLayersFailed(state, action: PayloadAction<ResponseError>) {
      state.loader.general = false;
      state.error = action.payload;
    },
    clearLinkLayers(state) {
      state.linkLayers = initialState.linkLayers;
    },

    // Fetch dependency
    fetchDependencyInit(state, action: PayloadAction<BusinessUnit['id']>) {
      state.loader.general = true;
    },
    fetchDependencySuccess(state, { payload }: PayloadAction<GeoDependency>) {
      state.loader.general = false;
      if (keys(payload).length) {
        state.dependency = {
          all: payload,
          ids: getDependencyIds(payload)
        };
        return;
      }

      state.dependency = null;
    },
    fetchDependencyFailed(state, action: PayloadAction<ResponseError>) {
      state.loader.general = false;
      state.error = action.payload;
    },
    clearDependency(state) {
      state.dependency = initialState.dependency;
    },

    // Fetch geo center per bu
    fetchGeoCenterPerBUInit: (state, { payload }: PayloadAction<BusinessUnit['id']>) => {
      state.loader.general = true;
    },
    fetchGeoCenterPerBUSuccess: (
      state,
      { payload: { latitude, longitude } }: PayloadAction<BusinessUnitGeoCenter>
    ) => {
      state.loader.general = false;
      if (longitude || latitude) {
        state.geoCenterPerBU = { longitude, latitude };
        return;
      }

      state.geoCenterPerBU = null;
    },
    fetchGeoCenterPerBUFailed(state, action: PayloadAction<ResponseError>) {
      state.loader.general = false;
      state.error = action.payload;
    },

    // Fetch address per coordinates
    fetchAddressPerCoordinatesInit: (state, { payload }: PayloadAction<GeoCoordinates>) => {
      state.loader.address = true;
    },
    fetchAddressPerCoordinatesSuccess: (state, { payload }: PayloadAction<string>) => {
      state.loader.address = false;

      if (state.selected.duplicateUnits) {
        state.selected.duplicateUnits.address = payload;
      }
    },
    fetchAddressPerCoordinatesFailed(state, action: PayloadAction<ResponseError>) {
      state.loader.address = false;
      state.error = action.payload;
    },

    // Fetch errors count per bu
    fetchInvalidAddressesCountInit: (state, { payload }: PayloadAction<BusinessUnit['id']>) => {
      state.loader.general = true;
    },
    fetchInvalidAddressesCountSuccess: (state, { payload }: PayloadAction<number>) => {
      state.loader.general = false;
      state.errorsCount = payload ? payload : null;
    },
    fetchInvalidAddressesCountFailed(state, action: PayloadAction<ResponseError>) {
      state.loader.general = false;
      state.error = action.payload;
    },

    // Export invalid addresses list
    exportInvalidAddressesListInit(state, { payload }: PayloadAction<{ businessUnitId: BusinessUnit['id'] }>) {
      state.loader.general = true;
    },
    exportInvalidAddressesListSuccess: state => {
      state.loader.general = false;
    },
    exportInvalidAddressesListFailed: (state, action: PayloadAction<ResponseError>) => {
      state.loader.general = false;
      state.error = action.payload;
    },

    // Set viewport
    setViewport(state, { payload }: PayloadAction<ViewportProps>) {
      state.viewport = payload;
    },

    // Set grouping
    setGrouping(state, { payload }: PayloadAction<boolean>) {
      state.grouping = payload;
    },

    // Set multiple icons
    setMultipleIcons(state, { payload }: PayloadAction<boolean>) {
      state.multipleIcons = payload;
    },

    // Set map layer type
    setMapLayerType(state, { payload }: PayloadAction<GeographyState['mapLayerType']>) {
      state.mapLayerType = payload;
    },

    // Set map filter
    setMapFilter(state, { payload }: PayloadAction<GeographyState['mapFilter']>) {
      state.mapFilter = payload;
    },

    // Clear
    clear(state) {
      state.units = initialState.units;
      state.routes = initialState.routes;
      state.selected = initialState.selected;
    },

    // Clear filtering
    clearFiltering(state) {
      state.faults = initialState.faults;
      state.routes = initialState.routes;
      state.linkLayers = initialState.linkLayers;
      state.dependency = initialState.dependency;
    },

    // Reset
    reset(state) {
      Object.assign(state, initialState);
    },
  },
  extraReducers: {
    [sharedActions.reset.toString()]: state => Object.assign(state, defaultState),
  },
});

const getGeographyState = (state: AES.RootState) => state.geography;
const subscriberSelectors = entities.units.SUBSCRIBER.getSelectors<AES.RootState>(
  state => state.geography.units.SUBSCRIBER
);
const hybridSelectors = entities.units.HYBRID.getSelectors<AES.RootState>(state => state.geography.units.HYBRID);
const ipLinksSelectors = entities.units.IP_LINK.getSelectors<AES.RootState>(state => state.geography.units.IP_LINK);
const nonAESUnitsSelectors = entities.units.NON_AES.getSelectors<AES.RootState>(state => state.geography.units.NON_AES);
const routesSelectors = entities.routes.getSelectors<AES.RootState>(state => state.geography.routes);
const getUnits = createDraftSafeSelector(
  subscriberSelectors.selectAll,
  hybridSelectors.selectAll,
  ipLinksSelectors.selectAll,
  nonAESUnitsSelectors.selectAll,
  (subscribers, hybrid, ipLinks, nonAes) => [...subscribers, ...hybrid, ...ipLinks, ...nonAes]
);
const getSelectedUnit = createDraftSafeSelector(getGeographyState, state => state.selected.unit);
const getSelectedUnits = createDraftSafeSelector(getGeographyState, state => state.selected.units);
const getSelectedDuplicateUnits = createDraftSafeSelector(getGeographyState, state => state.selected.duplicateUnits);
const getSelectedRouteUnit = createDraftSafeSelector(getGeographyState, state => state.selected.routeUnit);
const getSelectedUnitSearch = createDraftSafeSelector(getGeographyState, state => state.selected.unitSearch);

const getRoutes = routesSelectors.selectAll;
const getSelectedRoute = createDraftSafeSelector(getGeographyState, state => state.selected.route);

export const selectors = {
  getGeographyState,

  getSelectedBusinessUnitId: createDraftSafeSelector(getGeographyState, state => state.businessUnitId),
  getUnits,
  getSelectedUnit,
  getSelectedUnits,
  getSelectedDuplicateUnits,
  getSelectedRouteUnit,
  getSelectedUnitSearch,
  getCurrentUnits: (ids: number[]) => createDraftSafeSelector(
    getUnits,
    units => units?.filter(unit => ids?.includes(unit.id))
  ),

  getSelectedUnitGeoCollection: createDraftSafeSelector(getSelectedUnit, unit =>
    mapUnitsToFeatureCollection(unit ? [unit] : [])
  ),
  getSelectedRouteUnitGeoCollection: createDraftSafeSelector(getSelectedRouteUnit, unit =>
    mapUnitsToFeatureCollection(unit ? [unit] : [])
  ),
  getAllUnitsGeoCollection: createDraftSafeSelector(getSelectedUnits, units => {
    const unitsByType = mapGeoUnitsByType(units || []);
    const duplicates = getDuplicatedUnits(units);

    return {
      ipLinks: mapUnitsToFeatureCollection(unitsByType.ipLinks ?? []),
      hybrids: mapUnitsToFeatureCollection(unitsByType.hybrids ?? []),
      units: mapUnitsToFeatureCollection(unitsByType.units ?? []),
      duplicates: mapToFeatureCollection(duplicates ?? [])
    };
  }
  ),

  getRoutes,
  getRoutesGeoCollection: createDraftSafeSelector(
    getRoutes,
    subscriberSelectors.selectEntities,
    ipLinksSelectors.selectEntities,
    hybridSelectors.selectEntities,
    (routes, subscribers, ipLinks, hybrid) => {
      const values = mapRoutesWithGeolocation(routes, subscribers, ipLinks, hybrid);

      return mapRoutesToFeatureCollection(values);
    }
  ),
  getSelectedRoute,
  getSelectedRouteGeoCollection: createDraftSafeSelector(
    getSelectedRoute,
    subscriberSelectors.selectEntities,
    ipLinksSelectors.selectEntities,
    hybridSelectors.selectEntities,
    (route, subscribers, ipLinks, hybrid) => {
      const values = mapRoutesWithGeolocation(route ? [route] : [], subscribers, ipLinks, hybrid);

      return mapRoutesToFeatureCollection(values);
    }
  ),

  getGeoFaults: createDraftSafeSelector(getGeographyState, state => state.faults),
  areGeoFaults: createDraftSafeSelector(
    getGeographyState, state => Object.values(state.faults).every(value => value !== null)
  ),

  getLinkLayers: createDraftSafeSelector(
    getGeographyState, state => state.linkLayers
  ),
  getDependency: createDraftSafeSelector(getGeographyState, state => state.dependency),

  getGeoCenterPerBU: createDraftSafeSelector(getGeographyState, state => state.geoCenterPerBU),
  getGeoViewport: createDraftSafeSelector(getGeographyState, state => state.viewport),
  isGeoGrouping: createDraftSafeSelector(getGeographyState, state => state.grouping),
  isGeoMultipleIcons: createDraftSafeSelector(getGeographyState, state => state.multipleIcons),
  getGeoAddress: createDraftSafeSelector(getGeographyState, state => state.selected.duplicateUnits?.address),

  getInvalidAddressesCount: createDraftSafeSelector(getGeographyState, state => state.errorsCount),

  getMapLayerType: createDraftSafeSelector(getGeographyState, state => state.mapLayerType),
  getMapFilter: createDraftSafeSelector(getGeographyState, state => state.mapFilter),
  getMapLoader: createDraftSafeSelector(getGeographyState, state => state.loader),
};

const fetchUnitsEpic = (action$: ActionsObservable<AnyAction>) =>
  action$.pipe(
    filter(actions.fetchUnitsInit.match),
    mergeMap(({ payload: { businessUnitId } }) =>
      http.getJSON<GeoUnitPayload[]>(api.geography.coordinates(businessUnitId)).pipe(
        mergeMap(units => of(
          actions.fetchUnitsSuccess(units)
        )),
        catchError(err => of(actions.fetchUnitsFailed(getResponseError(err))))
      )
    )
  );

const fetchRoutesEpic = (action$: ActionsObservable<AnyAction>) =>
  action$.pipe(
    filter(actions.fetchRoutesInit.match),

    mergeMap(({ payload }) =>
      http.getJSON<GeoUnitRoute[]>(api.geography.routes(payload)).pipe(
        mergeMap(routes => of(actions.fetchRoutesSuccess(mapRoutesWithKeyField(routes)))),
        catchError(err => of(actions.fetchRoutesFailed(getResponseError(err))))
      )
    )
  );

const fetchFaultsByUnitTypeEpic = (action$: ActionsObservable<AnyAction>) =>
  action$.pipe(
    filter(actions.fetchFaultsByUnitTypeInit.match),

    mergeMap(({ payload }) =>
      http.getJSON<GeoFaultsByUnitType>(api.geography.faults.byUnitType(payload)).pipe(
        mergeMap(faults => of(actions.fetchFaultsByUnitTypeSuccess(faults))),
        catchError(err => of(actions.fetchFaultsByUnitTypeFailed(getResponseError(err))))
      )
    )
  );

const fetchFaultsByCodeEpic = (action$: ActionsObservable<AnyAction>) =>
  action$.pipe(
    filter(actions.fetchFaultsByCodeInit.match),

    mergeMap(({ payload }) =>
      http.getJSON<GeoFaultsByCode>(api.geography.faults.byUnitCode(payload)).pipe(
        mergeMap(faults => of(actions.fetchFaultsByCodeSuccess(faults))),
        catchError(err => of(actions.fetchFaultsByCodeFailed(getResponseError(err))))
      )
    )
  );

const fetchLinkLayersEpic = (action$: ActionsObservable<AnyAction>) =>
  action$.pipe(
    filter(actions.fetchLinkLayersInit.match),

    mergeMap(({ payload }) =>
      http.getJSON<GeoLinkLayers>(api.geography.linkLayers(payload)).pipe(
        mergeMap(linkLayers => of(actions.fetchLinkLayersSuccess(linkLayers))),
        catchError(err => of(actions.fetchLinkLayersFailed(getResponseError(err))))
      )
    )
  );

const fetchDependencyEpic = (action$: ActionsObservable<AnyAction>) =>
  action$.pipe(
    filter(actions.fetchDependencyInit.match),
    mergeMap(({ payload }) =>
      http.getJSON<GeoDependency>(api.geography.dependency(payload)).pipe(
        mergeMap(dependency => of(actions.fetchDependencySuccess(dependency))),
        catchError(err => of(actions.fetchDependencyFailed(getResponseError(err))))
      )
    )
  );

const fetchGeoCenterPerBUEpic = (action$: ActionsObservable<AnyAction>) =>
  action$.pipe(
    filter(action => actions.fetchGeoCenterPerBUInit.match(action)),
    mergeMap(({ payload }) =>
      http
        .getJSON<BusinessUnitGeoCenter>(
          api.geography.geoCenter(payload)
        )
        .pipe(
          mergeMap(res => of(actions.fetchGeoCenterPerBUSuccess(res))),
          catchError(err => of(actions.fetchGeoCenterPerBUFailed(getResponseError(err))))
        )
    )
  );

const fetchInvalidAddressesCountEpic = (action$: ActionsObservable<AnyAction>) =>
  action$.pipe(
    filter(action => actions.fetchInvalidAddressesCountInit.match(action)),
    mergeMap(({ payload }) =>
      http.getJSON<number>(api.geography.errorsCount(payload))
        .pipe(
          mergeMap(res => of(actions.fetchInvalidAddressesCountSuccess(res))),
          catchError(err => of(actions.fetchInvalidAddressesCountFailed(getResponseError(err))))
        )
    )
  );

const exportInvalidAddressesListEpic = (action$: ActionsObservable<AnyAction>) =>
  action$.pipe(
    filter(actions.exportInvalidAddressesListInit.match),
    mergeMap(({ payload: { businessUnitId } }) =>
      http
        .call({
          method: 'GET',
          url: api.geography.exportInvalidAddresses(businessUnitId),
          responseType: 'blob' as 'json',
        })
        .pipe(
          mergeMap(res => {
            const fileName = 'Failed_GeoCoding_Addresses.csv';
            saveFile(res.response, fileName);
            return of(
              actions.exportInvalidAddressesListSuccess(),
              notificationsActions.enqueue({
                message: `${fileName} has been exported`,
                options: { variant: 'success' },
              })
            );
          }),
          catchError(err => of(actions.exportInvalidAddressesListFailed(getResponseError(err))))
        )
    )
  );

const fetchAddressPerCoordinatesEpic = (action$: ActionsObservable<AnyAction>) =>
  action$.pipe(
    filter(action => actions.fetchAddressPerCoordinatesInit.match(action)),
    mergeMap(({ payload: { longitude, latitude } }) => {
      const params = querystring.stringify({ lat: latitude, lon: longitude });

      return http.call({
        method: 'GET',
        url: api.geography.address(params),
        responseType: 'text/plain',
      })
        .pipe(
          mergeMap(res => of(actions.fetchAddressPerCoordinatesSuccess(res.response))),
          catchError(err => of(actions.fetchAddressPerCoordinatesFailed(getResponseError(err))))
        );
    })
  );

export const epics = combineEpics(
  fetchUnitsEpic,
  fetchRoutesEpic,
  fetchFaultsByUnitTypeEpic,
  fetchFaultsByCodeEpic,
  fetchLinkLayersEpic,
  fetchGeoCenterPerBUEpic,
  fetchDependencyEpic,
  fetchInvalidAddressesCountEpic,
  exportInvalidAddressesListEpic,
  fetchAddressPerCoordinatesEpic,
);
export const allEpics = {
  fetchUnitsEpic,
  fetchRoutesEpic,
  fetchFaultsByUnitTypeEpic,
  fetchFaultsByCodeEpic,
  fetchLinkLayersEpic,
  fetchGeoCenterPerBUEpic,
  fetchDependencyEpic,
  fetchInvalidAddressesCountEpic,
  exportInvalidAddressesListEpic,
  fetchAddressPerCoordinatesEpic,
};

declare global {
  namespace AES {
    export interface Actions {
      geography: typeof actions;
    }

    export interface Selectors {
      geography: typeof selectors;
    }

    export interface RootState {
      geography: GeographyState;
    }
  }
}
