import { Button } from '@material-ui/core';
import { PropsWithChildren } from 'react';

import { Dialog, DialogActions, DialogContent, DialogTitle } from '~ui-kit';

type TurnOnOffSettingsDialogProps = PropsWithChildren<{
  open: boolean;
  title: string;
  onClose?: () => void;
  onSwitch: (value: boolean) => void;
}>;

export const TurnOnOffSettingsDialog = ({
  open = false,
  title,
  onClose,
  onSwitch,
  children,
}: TurnOnOffSettingsDialogProps) => (
  <Dialog open={open} maxWidth="sm">
    <DialogTitle title={title} onClose={onClose} />
    <DialogContent>{children}</DialogContent>

    <DialogActions>
      <Button variant="outlined" color="primary" onClick={() => onSwitch(false)}>
        Turn Off
      </Button>

      <Button variant="contained" color="primary" onClick={() => onSwitch(true)}>
        Turn On
      </Button>
    </DialogActions>
  </Dialog>
);
