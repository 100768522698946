import { Theme } from '@material-ui/core';
import { createStyles } from '@material-ui/styles';

export const styles = ({ spacing, palette }: Theme) =>
  createStyles({
    wrapper: {
      display: 'flex',
      gap: spacing(1),
    },

    input: {
      width: spacing(4),
      height: spacing(4),
      backgroundColor: palette.blueColor.main,
      borderRadius: spacing(0.5),
      border: `1px solid ${palette.primary.main}`,
      textAlign: 'center',
      fontSize: '18px',
      color: palette.greyColor.light,
    }
  });
