import { Paper, Grid, WithStyles, withStyles, Typography, Button } from '@material-ui/core';
import { equals } from 'ramda';
import { useState, useCallback, useEffect } from 'react';
import { useSelector } from 'react-redux';

import { LoginLogo } from '../Login';
import { PinInput } from '../PinInput';

import { styles } from './MFAConfiguration.styles';

import { boundActions, selectors } from '~store';

const initialValues = Array(6).fill('');

export const MFAConfirmation = withStyles(styles)(({ classes }: WithStyles<typeof styles>) => {
  const [pin, setPin] = useState(initialValues);
  const mfa = useSelector(selectors.auth.getMFA, equals);

  const onClick = useCallback(() => {
    boundActions.auth.validateMFAInit({
      mfaToken: mfa?.mfaToken as string,
      username: mfa?.username as string,
      totp: pin.join(''),
    });
  }, [pin]);

  useEffect(() => {
    if (mfa?.error) {
      setPin(initialValues);
    }
  }, [mfa?.error]);

  return <Paper className={classes.paper}>
    <LoginLogo />

    <Typography className={classes.title} variant="h6" color="primary">
      Confirm Sign In
    </Typography>

    <Grid className={classes.content}>Please input 6-digits code</Grid>

    <PinInput
      length={6}
      values={pin}
      setValues={setPin}
    />

    <Button
      type="submit"
      variant="contained"
      color="primary"
      className={classes.confirmBtn}
      disabled={!pin.every(val => val !== '')}
      onClick={onClick}
    >
      Confirm
    </Button>
  </Paper>;
});

