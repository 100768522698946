import { Button, Grid, withStyles, WithStyles, Link } from '@material-ui/core';
import { Fragment } from 'react';

import androidMFACode from '~assets/images/mfa/android-mfa-code.svg';
import appStore from '~assets/images/mfa/app-store.svg';
import googlePlay from '~assets/images/mfa/google-play.svg';
import macMFACode from '~assets/images/mfa/mac-mfa-code.svg';
import { DialogContent, DialogActions } from '~ui-kit/Dialog';

import { styles } from './InstallMFAApp.styles';

type InstallMFAAppProps = WithStyles<typeof styles> & {
  onClick: () => void;
}

export const InstallMFAApp = withStyles(styles)(({ onClick, classes }: InstallMFAAppProps) => (
  <Fragment>
    <DialogContent>
      <Grid>
        Please install the Google Authenticator app on your device, then you can set up INCC access to use the Google
        Authenticator for multifactor authentication.
      </Grid>

      <Grid className={classes.item}>
        <img src={macMFACode} />
        <img src={androidMFACode} />
      </Grid>

      <Grid className={classes.item}>
        <Link
          href="https://apps.apple.com/us/app/google-authenticator/id388497605"
          underline="none"
          target="_blank"
        >
          <img src={appStore} />
        </Link>

        <Link
          href="https://play.google.com/store/search?q=google%20authenticator&c=apps"
          underline="none"
          target="_blank"
        >
          <img src={googlePlay} />
        </Link>
      </Grid>
    </DialogContent>

    <DialogActions>
      <Button
        type="submit"
        variant="contained"
        color="primary"
        onClick={onClick}
      >
          Next
      </Button>
    </DialogActions>
  </Fragment>
));
