import { Paper } from '@material-ui/core';
import { equals } from 'ramda';
import { Fragment } from 'react';
import { useSelector } from 'react-redux';

import { Routing } from './Routing';

import { BusinessUnit, UnitRoute } from '~models';
import { selectors } from '~store';
import { Placeholder, Card, CardSummary, ContentField, Divider } from '~ui-kit';
import { formatDate } from '~utils';

interface RoutingCardListProps {
  routes: UnitRoute[];
  businessUnitId: BusinessUnit['id'];
  timeFormat: string;
  backTo?: string;
}

export const RoutingList = ({ routes, businessUnitId, timeFormat, backTo }: RoutingCardListProps) => {
  if (!routes || !routes.length) {
    return (
      <Paper>
        <Placeholder />
      </Paper>
    );
  }

  const routingCardsCount = Math.max(...routes?.map(route => route?.hops?.length));
  const timeZone = useSelector(selectors.profile.getUserTimeZone, equals);

  return (
    <Fragment>
      {routes.map((route, i) => (
        <Card key={i}>
          <CardSummary>
            <Routing route={route} count={routingCardsCount} businessUnitId={businessUnitId} backTo={backTo} />

            <Divider flexItem orientation="vertical" spacing="both" />

            <ContentField title="Use count">{route.useCount}</ContentField>

            <Divider flexItem orientation="vertical" spacing="right" />

            <ContentField title="Date & Time" width={240}>
              {formatDate(route.timestamp, true, timeFormat, timeZone)}
            </ContentField>
          </CardSummary>
        </Card>
      ))}
    </Fragment>
  );
};
