import { Button, Grid, WithStyles, withStyles } from '@material-ui/core';
import { difference, equals, uniq } from 'ramda';
import { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { hexToInt, intToHex } from '~utils/hex';

import { styles } from './MultipleAssignUnitsDialog.styles';
import { MultipleRangeInput } from './MultipleRangeInput';

import { PaginatedRoutingLinkList } from '~components';
import { BusinessUnit } from '~models';
import { selectors, boundActions } from '~store';
import { Dialog, DialogTitle, DialogActions, Chip, DialogContent } from '~ui-kit';

type MultipleAssignUnitsDialogProps = WithStyles<typeof styles> & {
  open: boolean;
  businessUnitId: BusinessUnit['id'];
  selected?: number[];
  title?: string;
  path?: string;
  controlRelaySettings?: boolean;
  onConfirm: (values) => void;
  onCancel?: () => void;
};

export const MultipleAssignUnitsDialog = withStyles(styles)(
  ({
    open,
    businessUnitId,
    selected = [],
    path = 'units',
    title = 'Select Units',
    controlRelaySettings,
    classes,
    onConfirm,
    onCancel,
  }: MultipleAssignUnitsDialogProps) => {
    const units = useSelector(selectors.businessUnits.getBusinessUnitFoundUnits, equals) as {
      id: number;
      unitType: string;
    }[];
    const { findUnits: isLoading } = useSelector(selectors.businessUnits.getBusinessUnitsLoaders);
    const pagination = useSelector(selectors.page.getPagePagination, equals);
    const showRange = units?.length && !isLoading;
    const [currentSelected, setCurrentSelected] = useState(selected);
    const [searchValues, setSearchValues] = useState({ from: '', to: '' });
    const pageSelected = units?.every(({ id }) => currentSelected.includes(id));

    const onPaginationChange = useCallback(
      pagination => {
        boundActions.businessUnits.fetchUnitsByRangeInit({
          pagination,
          businessUnitId,
          path,
          from: hexToInt(searchValues.from),
          to: hexToInt(searchValues.to),
        });
      },
      [businessUnitId, searchValues]
    );

    const onOptionSelect = (e, value, isSelected: boolean) => {
      e.stopPropagation();

      const updated = isSelected ? currentSelected.filter(v => v !== value) : currentSelected.concat(value);
      setCurrentSelected(updated);
    };

    useEffect(() => {
      if (open) {
        boundActions.businessUnits.fetchUnitsByRangeInit({
          pagination: { ...pagination, size: 40, number: -1 },
          businessUnitId,
          path,
        });
        setCurrentSelected(selected);
      } else {
        boundActions.page.resetPageState();
        setSearchValues({ from: '', to: '' });
        setCurrentSelected([]);
      }
    }, [open]);

    const onSelectAllUnits = (select: boolean) => {
      const unitsIds = units.map(({ id }) => id);
      if (select) {
        setCurrentSelected(uniq(currentSelected.concat(unitsIds)));
      } else {
        setCurrentSelected(difference(currentSelected, unitsIds));
      }
    };

    return (
      <Dialog open={open} maxWidth="sm" PaperProps={{ style: { width: '100%' } }}>
        <DialogTitle title={title} />

        <DialogContent className={classes.content}>
          {showRange ? (
            <Grid className={classes.range}>
              <MultipleRangeInput
                title={'Range'}
                fromValue={searchValues.from}
                toValue={searchValues.to}
                onSubmit={({ valueFrom, valueTo }) => {
                  boundActions.businessUnits.fetchUnitsByRangeInit({
                    businessUnitId,
                    path,
                    to: hexToInt(valueTo),
                    from: hexToInt(valueFrom),
                    pagination: { ...pagination, size: 40, number: -1 },
                  });
                  setSearchValues({ from: valueFrom, to: valueTo });
                }}
              />

              <Button
                type="submit"
                size="small"
                variant="contained"
                color="primary"
                className={classes.button}
                onClick={() => onSelectAllUnits(!pageSelected)}
              >
                {pageSelected ? 'Deselect All Page' : 'Select All Page'}
              </Button>
            </Grid>
          ) : null}

          <PaginatedRoutingLinkList
            data={units}
            onPaginationChange={onPaginationChange}
            loading={isLoading}
            renderItem={(res, i: number) => (
              <Chip
                key={i}
                label={intToHex(res?.id)}
                value={res?.id}
                isSelected={currentSelected.find(value => value === res?.id)}
                onOptionSelect={onOptionSelect}
                userForm={true}
              />
            )}
          />
        </DialogContent>

        <DialogActions>
          <Button type="button" variant="outlined" color="primary" onClick={onCancel}>
            Cancel
          </Button>
          <Button type="submit" variant="contained" color="primary" onClick={() => onConfirm(currentSelected)}>
            {controlRelaySettings ? 'Copy' : 'Assign'}
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
);
