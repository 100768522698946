import { AlarmAutomationSocket } from '~models';

export const helpers = {
  getPrimarySocket: (sockets: AlarmAutomationSocket[]) => {
    if (!sockets?.length) {
      return null;
    }

    const primary = sockets.find((socket: AlarmAutomationSocket) => socket.number === 0);

    return primary ?? sockets[0];
  },
};

export const getAAHeartbeatOptions = () => {
  const options = ['OFF', 'ON'];

  return options.map(option => ({ value: option === 'ON' ? true : false, label: option }));
};
