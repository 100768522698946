import { pickAll } from 'ramda';
import * as Yup from 'yup';

import { BusinessUnit, MobileCarrier, RecipientTrigger } from '~models';
import { trailingSpacesValidator, phoneNumber10DigitsValidator } from '~utils';

export const createRecipientInitialValue: Partial<RecipientTrigger> = {
  description: '',
  email: '',
  firstName: '',
  isEnabled: false,
  lastName: '',
  mobileCarrierId: (null as unknown) as number,
  phoneNumber: '',
};

export const getRecipientInitialValues = (businessUnit?: BusinessUnit) =>
  pickAll(['recipientTrigger'], businessUnit) as Partial<BusinessUnit>;

export const getMobileCarriersOption = (mobileCarriers?: MobileCarrier[]) => {
  if (!mobileCarriers) {
    return [];
  }

  const carriers: { label: string; value: number }[] = [];

  mobileCarriers.map(item => carriers.push({ label: item.name, value: item.id }));

  return carriers;
};

export const prepareRecipientPayload = (values: Partial<RecipientTrigger>) => {
  if (!values) {
    return values;
  }

  // BE reqires mobileCarrierId & phoneNumber be null if no phone number been entered
  return {
    ...values,
    mobileCarrierId: values.phoneNumber ? values.mobileCarrierId : null,
    phoneNumber: values.phoneNumber ? values.phoneNumber : null,
  };
};

export const recipientValidationSchema = Yup.object().shape({
  firstName: trailingSpacesValidator()
    .required('Required field')
    .typeError('Incorrect format')
    .nullable(true),
  lastName: trailingSpacesValidator()
    .required('Required field')
    .typeError('Incorrect format')
    .nullable(true),
  email: Yup.string()
    .email(() => 'Invalid email address')
    .required('Required field'),
  description: trailingSpacesValidator()
    .typeError('Incorrect format')
    .max(200)
    .nullable(true),
  phoneNumber: phoneNumber10DigitsValidator().nullable(true),
});

export const isInActiveSubscribersTrigger = (notifyWhen): boolean => Boolean(notifyWhen === 'Subscriber has not sent Check-Ins');

export const isInActiveSubscribersEvent = (eventType): boolean => Boolean(eventType === 'INACTIVE_SUBSCRIBERS');

export const triggerValidationSchema = Yup.object().shape({
  threshold: Yup.number()
    .min(0)
    .max(100, 'Threshold Value should be less than or equal to 100')
    .when('eventType.notifyWhen', {
      is: isInActiveSubscribersTrigger,
      then: Yup.number().min(0)
        .max(6, 'Subscriber Check-in Days should not exceed 6'),
      otherwise: schema => schema.optional(),
    })
    .when('eventType', {
      is: isInActiveSubscribersEvent,
      then: Yup.number().min(0)
        .max(6, 'Subscriber Check-in Days should not exceed 6'),
      otherwise: schema => schema.optional(),
    }),
});
