import { Grid, WithStyles, withStyles } from '@material-ui/core';
import { useEffect, useRef, KeyboardEvent, ChangeEvent } from 'react';

import { styles } from './PinInput.styles';

interface PinInputProps extends WithStyles<typeof styles> {
  length: number;
  values: string[];
  setValues: (values) => void;
}

export const PinInput = withStyles(styles)(({ length, values, setValues, classes }: PinInputProps) => {
  const inputsRef = useRef<HTMLInputElement[]>([]);

  const onChange = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, i) => {
    const { value } = e.target;

    if (!/^\d*$/.test(value)) {
      return;
    }

    const newValues = [...values];
    newValues[i] = value.slice(-1);
    setValues(newValues);

    if (value && i < length - 1) {
      inputsRef.current[i + 1]?.focus();
    }
  };

  const onKeyDown = (e: KeyboardEvent<HTMLDivElement>, i) => {
    if (e.key === 'Backspace' && !values[i] && i > 0) {
      inputsRef.current[i - 1]?.focus();
    }
  };

  useEffect(() => {
    if (values.every(val => val === '')) {
      inputsRef?.current[0]?.focus();
    }
  }, [values, inputsRef]);

  return <Grid className={classes.wrapper}>
    {values.map((val, i) => (
      <input
        key={i}
        name="pin"
        type="text"
        maxLength={1}
        value={val}
        className={classes.input}
        onChange={e => onChange(e, i)}
        onKeyDown={e => onKeyDown(e, i)}
        ref={el => {
          inputsRef.current[i] = el as HTMLInputElement;
        }}
      />
    ))}
  </Grid>;
});
