import { Button, ButtonProps } from '@material-ui/core';
import { PropsWithChildren, useCallback } from 'react';
import { useHistory } from 'react-router';

export type LinkButtonProps = ButtonProps &
  PropsWithChildren<{
    linkTo: string;
  }>;

export const LinkButton = ({ linkTo, onClick, children, ...props }: LinkButtonProps) => {
  const history = useHistory();

  const onButtonClick = useCallback(
    e => {
      if (onClick) {
        onClick(e);
      }

      history.push(linkTo);
    },
    [linkTo, onClick]
  );

  return (
    <Button {...props} onClick={onButtonClick}>
      {children}
    </Button>
  );
};
