import { Divider, Drawer, MenuList, useTheme } from '@material-ui/core';
import clsx from 'clsx';
import { equals } from 'ramda';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom';

import { CustomLogo } from '~ui-kit/Logo/CustomLogo';
import { Logo } from '~ui-kit/Logo/Logo';
import { SelfMonitoringLogo } from '~ui-kit/SelfMonitoringLogo/SelfMonitoringLogo';
import { ULSticker } from '~ui-kit/UL/ULSticker';

import { ProtectedContent } from '../ProtectedContent/ProtectedContent';

import { NavItem } from './NavItem/NavItem';
import { useSideNavStyles } from './SideNav.styles';

import { appRoutes } from '~constants';
import { IconType } from '~icons';
import { PermissionName } from '~models';
import { boundActions, selectors } from '~store';

const links = [
  {
    icon: 'dashboard',
    label: 'Dashboard',
    to: appRoutes.dashboard,
  },
  {
    icon: 'info',
    label: 'Kiosk mode',
    to: appRoutes.kioskMode,
  },
  {
    icon: 'business-unit',
    label: 'Business Units',
    to: appRoutes.businessUnits,
    permissions: [PermissionName.BU_VIEW] && ([PermissionName.SUBSCRIBERS_VIEW] || [PermissionName.IP_LINK_VIEW]),
  },
  {
    icon: 'ip-link-outlined',
    label: 'IP Links',
    to: appRoutes.ipLinks,
    permissions: [PermissionName.IP_LINK_VIEW],
  },
  {
    icon: 'subscribers',
    label: 'Subscribers',
    to: appRoutes.subscribers,
    permissions: [PermissionName.SUBSCRIBERS_VIEW],
  },
  {
    icon: 'hybrid-outlined',
    label: 'Hybrids',
    to: appRoutes.hybrids,
    permissions: [PermissionName.SUBSCRIBERS_VIEW],
  },
  {
    icon: 'user',
    label: 'Users',
    to: appRoutes.users,
    permissions: [PermissionName.USER_VIEW],
  },
  {
    icon: 'dealer',
    label: 'Dealers',
    to: appRoutes.dealers,
    permissions: [PermissionName.DEALER_VIEW],
  },
  {
    icon: 'system-settings',
    label: 'Settings',
    to: appRoutes.settings,
    permissions: [PermissionName.SETTINGS_VIEW],
  },
  {
    icon: 'live-traffic',
    label: 'Live Traffic',
    to: appRoutes.liveTraffic,
    permissions: [PermissionName.LIVE_TRAFFIC_VIEW],
  },
  {
    icon: 'geography',
    label: 'Geography',
    to: appRoutes.geography,
    permissions: [PermissionName.GEO_VIEW],
  },
  /* INCC-3576-Hide-features-for-R2 */
  {
    icon: 'relay-control',
    label: 'Relay Control',
    to: appRoutes.controlRelays,
    permissions: [PermissionName.CONTROL_RELAY_VIEW],
  },
  {
    icon: 'help',
    label: 'Help',
    to: appRoutes.help,
  },
] as { icon: IconType; label: string; to: string; permissions?: PermissionName[] }[];

export const SideNav = () => {
  const isExpanded = useSelector(selectors.layout.isExpanded);
  const theme = useTheme();
  const classes = useSideNavStyles();
  const currentUser = useSelector(selectors.auth.getCurrentUser, equals);
  const userRole = currentUser?.role === 'KIOSK' || currentUser?.role === 'ROLE_TIER_THREE';
  const businessUnitId = currentUser?.businessUnits[0]?.id as number;
  const buLogo = useSelector(selectors.businessUnits.getBusinessUnitLogo, equals);
  const isSelfMonitoring = useSelector(selectors.app.isSelfMonitoring, equals);

  useEffect(() => {
    if (userRole) {
      boundActions.businessUnits.fetchBusinessUnitLogoInit({ buId: businessUnitId });
    }
  }, []);

  return (
    <Drawer
      variant="permanent"
      className={clsx(classes.SideNav, {
        [classes.SideNavExpanded]: isExpanded,
        [classes.SideNavCollapsed]: !isExpanded,
      })}
      classes={{
        paper: clsx(classes.paper, {
          [classes.SideNavExpanded]: isExpanded,
          [classes.SideNavCollapsed]: !isExpanded,
        }),
      }}
    >
      <NavLink to="/">
        {userRole && buLogo ? (
          <CustomLogo
            logo={buLogo as string}
            classes={{ logo: clsx({ [classes.logoExpanded]: isExpanded, [classes.logoCollapsed]: !isExpanded }) }}
          />
        ) : (
          <Logo
            classes={{ logo: clsx({ [classes.logoExpanded]: isExpanded, [classes.logoCollapsed]: !isExpanded }) }}
          />
        )}
      </NavLink>

      <MenuList>
        {links.map(({ permissions, ...props }) => (
          <ProtectedContent key={props.to} permissions={permissions}>
            <NavItem {...props} />
          </ProtectedContent>
        ))}

        <Divider />

        <NavItem
          onClick={() => boundActions.theme.toggleTheme()}
          icon={theme.palette.type === 'dark' ? 'light' : 'dark'}
          label={theme.palette.type === 'dark' ? 'Light mode' : 'Dark mode'}
          attr-at="mode-switch"
        />
        <NavItem
          onClick={() => {
            boundActions.layout.toggle();
            setTimeout(() => {
              window.resizeTo(window.innerWidth, window.innerHeight);
            }, 1000);
          }}
          icon={isExpanded ? 'collapse' : 'expand'}
          label={isExpanded ? 'Hide menu' : 'Expand menu'}
          attr-at="hide-show-menu"
        />

        <Divider />

        {isSelfMonitoring ? (
          <SelfMonitoringLogo />
        ) : (
          <ULSticker />
        )}
      </MenuList>
    </Drawer>
  );
};
