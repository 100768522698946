import { Fragment, FC } from 'react';

import { PageDetailsNavigation, PageDetailsPanel } from '~components';
import { PageProps } from '~models';

export const SettingsPanel: FC<PageProps> = ({ children, ...props }) => (
  <Fragment>
    <PageDetailsPanel
      id={props.id}
      title={props.title}
      getPanelTitle={({ id, title }) => `${title} Settings`}
      routes={props.routes}
    >
      {children}
    </PageDetailsPanel>

    <PageDetailsNavigation routes={props.routes} activeTab={`${props.id}`} />
  </Fragment>
);
