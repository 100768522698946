import { Button } from '@material-ui/core';

import { Dialog, DialogContent, DialogActions, DialogProps } from '~ui-kit';

type NHAReportDialogProps = Partial<DialogProps> & {
  onConfirm: () => void;
  onCancel: () => void;
};

export const NHAReportDialog = ({ open = false, onConfirm, onCancel, ...props }: NHAReportDialogProps) => (
  <Dialog open={open} {...props}>
    <DialogContent>
      Monthly NHA report generation requires huge CPU load and can significantly affect performance for a long time
    </DialogContent>
    <DialogActions>
      <Button variant="outlined" color="primary" onClick={onCancel}>
        Cancel
      </Button>
      <Button variant="contained" color="primary" onClick={onConfirm}>
        I understand
      </Button>
    </DialogActions>
  </Dialog>
);
