import { Theme } from '@material-ui/core';
import { createStyles } from '@material-ui/styles';

export const styles = (theme: Theme) =>
  createStyles({
    noteTitle: {
      color: theme.palette.greyColor.main,
      lineHeight: 1.5,
    },

    noteTitleSpace: {
      marginTop: theme.spacing(2),
      color: theme.palette.greyColor.main,
      lineHeight: 1.5,
    },
  });
