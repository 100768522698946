import { UnitType } from '~models/unit';
import { intToHex } from '~utils/hex';

export type NoteUnit = {
    unitType: UnitType;
    unitId: number;
    businessUnitId: number;
};

export const getTitle = (unit: NoteUnit) => {
  const subTitle = 'Custom Notes';
  const id = intToHex(unit.unitId);

  switch (unit.unitType) {
    case 'SUBSCRIBER':
      return `Subscriber ${id} ${subTitle}`;

    case 'HYBRID':
      return `Hybrid ${id} ${subTitle}`;

    default:
      return `IP Link ${id} ${subTitle}`;
  }
};

export const getUnitTypePath = (unitType: UnitType) => {
  if (unitType === 'IP_LINK') {
    return 'ip-links';
  }

  if (unitType === 'SUBSCRIBER') {
    return 'subscribers';
  }

  if (unitType === 'HYBRID') {
    return 'hybrids';
  }

  return '';
};
