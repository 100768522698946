import {
  Stepper,
  Step,
  StepLabel,
  Grid,
  WithStyles,
  withStyles,
  StepConnector as MiStepConnector,
} from '@material-ui/core';
import { useState } from 'react';

import { ActivateMFAApp } from './Activate';
import { InstallMFAApp } from './Install';
import { styles } from './MFARegistration.styles';

import { Dialog, DialogTitle, Divider } from '~ui-kit';

export const steps = ['Install Application', 'Activate Application'];

type StepConnectorProps = WithStyles<typeof styles>;
const StepConnector = withStyles(styles)(({ classes }: StepConnectorProps) => (
  <MiStepConnector classes={{ line: classes.line }} />
));

type FirstLoginStepperProps = WithStyles<typeof styles>;

export const MFARegistration = withStyles(styles)(({ classes }: FirstLoginStepperProps) => {
  const [activeStep, setActiveStep] = useState(0);

  return (
    <Dialog open={true} fullWidth maxWidth="sm">
      <DialogTitle title="Setup 2-Factor Authentication" />

      <Stepper
        classes={{ horizontal: classes.horizontal }}
        activeStep={activeStep}
        alternativeLabel
        connector={<StepConnector />}
      >
        {steps.map(step => (
          <Step key={step} classes={{ completed: classes.completedStep }}>
            <StepLabel classes={{ iconContainer: classes.icon, alternativeLabel: classes.label }}>{step}</StepLabel>
          </Step>
        ))}
      </Stepper>

      <Divider classes={{ root: classes.divider }} />

      <Grid>
        {activeStep === 0
          ? <InstallMFAApp
            onClick={() => setActiveStep(1)}
          />
          : <ActivateMFAApp
            onBack={() => setActiveStep(0)}
          />}
      </Grid>
    </Dialog>
  );
});
