import { AnyAction, createDraftSafeSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ActionsObservable, combineEpics } from 'redux-observable';
import { of } from 'rxjs';
import { catchError, filter, mergeMap } from 'rxjs/operators';

import { actions as authActions } from '../auth/authSlice';
import { actions as notificationsActions } from '../notifications/notificationsSlice';
import { actions as sharedActions } from '../sharedSlice';

import { api } from '~constants';
import { Profile, RequestStatus, ResponseError } from '~models';
import { http } from '~services';
import { getResponseError } from '~utils';

export interface ProfileState {
  status: RequestStatus;
  error: ResponseError | null;
  account?: Profile;
  loader: boolean;
}

export const initialState: ProfileState = {
  error: null,
  status: RequestStatus.Idle,
  loader: false,
};

export const { actions, reducer, name } = createSlice({
  name: 'profile',
  initialState,
  reducers: {
    save: (state, action: PayloadAction<Profile>) => {
      state.status = RequestStatus.Pending;
      state.loader = true;
    },
    saveSuccess: (state, action: PayloadAction<Profile>) => {
      state.status = RequestStatus.Success;
      state.error = null;
      state.account = action.payload;
      state.loader = false;
    },
    saveFailure: (state, action) => {
      state.error = action.payload;
      state.status = RequestStatus.Failure;
      state.loader = false;
    },
    updatePassword: (
      state,
      action: PayloadAction<{
        currentPassword: string;
        newPassword: string;
      }>
    ) => {
      state.status = RequestStatus.Pending;
    },
    updatePasswordSuccess: state => {
      state.status = RequestStatus.Success;
      state.error = null;
    },
    updatePasswordFailure: (state, action: PayloadAction<ResponseError | null>) => {
      state.error = action.payload;
      state.status = RequestStatus.Failure;
    },
    get: state => {
      state.status = RequestStatus.Pending;
      state.loader = true;
    },
    getSuccess: (state, action: PayloadAction<Profile>) => {
      state.status = RequestStatus.Success;
      state.error = null;
      state.account = action.payload;
      state.loader = false;
    },
    getFailure: (state, action) => {
      state.error = action.payload;
      state.status = RequestStatus.Failure;
      state.loader = false;
    },
    idle: state => {
      state.status = RequestStatus.Idle;
    },
    updateWeeklyReportSettings: (state, { payload }: PayloadAction<{ dateTimeForNextWeeklyReport: number }>) => {
      state.account!.dateTimeForNextWeeklyReport = payload.dateTimeForNextWeeklyReport;
    },
  },
  extraReducers: {
    [sharedActions.reset.toString()]: state => Object.assign(state, initialState),
  },
});

const getProfileState = (state: AES.RootState) => state.profile;

export const selectors = {
  getProfileState,

  getStatus: createDraftSafeSelector(getProfileState, state => state.status),
  getAccount: createDraftSafeSelector(getProfileState, state => state.account),
  getUserTimeZone: createDraftSafeSelector(getProfileState, state => state.account?.userTimezone),
  getError: createDraftSafeSelector(getProfileState, state => state.error),
  getLoader: createDraftSafeSelector(getProfileState, state => state.loader),
};

const updatePasswordEpic = (action$: ActionsObservable<AnyAction>) =>
  action$.pipe(
    filter(actions.updatePassword.match),
    mergeMap(({ payload }) =>
      http.put(api.users.updatePassword, payload).pipe(
        mergeMap(() =>
          of(
            actions.updatePasswordSuccess(),
            authActions.updateRequiredPassword(false),
            notificationsActions.enqueue({
              message: 'Password has been successfully changed!',
              options: { variant: 'success' },
            })
          )
        ),
        catchError(err => {
          const error = getResponseError(err);
          const hasMultipleMessages = error.message?.match('.,');
          const message = hasMultipleMessages ? error.message?.split('.,').join('\n') : (error.message as string);
          const notificationMessage = hasMultipleMessages ? 'Update password failed' : (error.message as string);

          return of(
            actions.updatePasswordFailure({ ...error, message }),
            notificationsActions.enqueue({ message: notificationMessage, options: { variant: 'error' } })
          );
        }),
        mergeMap(action => of(action, actions.idle()))
      )
    )
  );

// TODO: implement helper
const getProfileEpic = (action$: ActionsObservable<AnyAction>) =>
  action$.pipe(
    filter(actions.get.match),
    mergeMap(() =>
      http.getJSON(api.users.profile).pipe(
        mergeMap(account => of(actions.getSuccess(account as Profile))),
        catchError(err => {
          const error = getResponseError(err);

          return of(actions.getFailure(error));
        })
      )
    ),
    mergeMap(action => of(action, actions.idle()))
  );

const saveProfileEpic = (action$: ActionsObservable<AnyAction>) =>
  action$.pipe(
    filter(actions.save.match),
    mergeMap(({ payload }) =>
      http.put(api.users.profile, payload).pipe(
        mergeMap(() =>
          of(
            actions.saveSuccess(payload),
            notificationsActions.enqueue({
              message: 'User Profile updated',
              options: { variant: 'success' },
            })
          )
        ),
        catchError(err => {
          const error = getResponseError(err);

          return of(actions.saveFailure(error));
        }),
        mergeMap(action => of(action, actions.idle()))
      )
    )
  );

export const epics = combineEpics(getProfileEpic, saveProfileEpic, updatePasswordEpic);
export const allEpics = {
  getProfileEpic,
  saveProfileEpic,
  updatePasswordEpic,
};

declare global {
  namespace AES {
    export interface Actions {
      profile: typeof actions;
    }
    export interface RootState {
      profile: ProfileState;
    }

    export interface Selectors {
      profile: typeof selectors;
    }
  }
}
