import { createStyles, Theme } from '@material-ui/core';

import { statusColors, highContrastColors } from '~styles/colors';

import { matchStyles } from '~utils';

export const styles = (theme: Theme) => {
  const {
    palette: { type, blueColor, background },
    spacing,
  } = theme;
  const getStyles = matchStyles(type);

  return createStyles({
    root: {
      backgroundColor: getStyles(blueColor.dark, background.default),
      padding: spacing(1, 3, 1, 2),
      minHeight: 56,
      alignItems: 'center',
      borderLeftWidth: spacing(1),
      borderLeftColor: 'transparent',
      borderLeftStyle: 'solid',
      ...highContrastColors(theme),
      ...statusColors(theme, 'borderLeftColor'),
    },
  });
};
