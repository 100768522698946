import { Button } from '@material-ui/core';
import { PropsWithChildren } from 'react';

import { Dialog, DialogContent, DialogActions, DialogProps } from '~ui-kit';

type ToggleStatusDialogProps = Partial<DialogProps> &
  PropsWithChildren<{
    actionStatus: string;
    onConfirm: () => void;
    onCancel: () => void;
  }>;

export const ToggleStatusDialog = ({
  open = false,
  actionStatus,
  onConfirm,
  onCancel,
  children,
  ...props
}: ToggleStatusDialogProps) => (
  <Dialog open={open} {...props}>
    <DialogContent>{children}</DialogContent>

    <DialogActions>
      <Button variant="outlined" color="primary" onClick={onCancel}>
        Cancel
      </Button>
      <Button variant="contained" color="primary" onClick={onConfirm}>
        {actionStatus}
      </Button>
    </DialogActions>
  </Dialog>
);
