import { AnyAction, createSlice, PayloadAction, createDraftSafeSelector } from '@reduxjs/toolkit';
import { head, last, length } from 'ramda';
import { ActionsObservable, combineEpics } from 'redux-observable';
import { of } from 'rxjs';
import { catchError, filter, mergeMap } from 'rxjs/operators';

import { actions as notificationsActions } from '../notifications/notificationsSlice';
import { actions as pageActions } from '../page/pageSlice';
import { extractPaginationValues, getPaginationQueryParams } from '../page/pageUtils';
import { actions as sharedActions } from '../sharedSlice';

import {
  filterRestoredAlarms,
  filterRestoredFaults,
  generateAlarmGroupId,
  generateAlarmsGroups,
  generateMetaAlarmId,
  getGroupedAlarms,
  getAlarmsCount,
  getUpdatesCount,
  mapAlarmsWithMeta,
  sortAlarms,
  updateAlarm,
} from './utils';

import { api } from '~constants';
import {
  Alarm,
  AlarmMeta,
  AlarmType,
  ConnectivityAlarm,
  CustomNote,
  PaginationRequestPayload,
  PaginationResponse,
  ResponseError,
  Subscriber,
} from '~models';
import { http } from '~services';
import { getResponseError, getResponsePayload, updateArrayWithCallback } from '~utils';

export type AlarmTab = 'unacknowledged' | 'acknowledged' | 'alerts' | 'connectivity' | 'test';
export type AlarmLoading = AlarmTab | 'customNote' | 'inactive';

export interface AlarmsState {
  data: {
    unacknowledged: { [key in AlarmType]: { [key: string]: Alarm[] } };
    acknowledged: Alarm[];
    alerts: Alarm[];
    connectivity: ConnectivityAlarm[];
    test: { [key in AlarmType]: { [key: string]: Alarm[] } };
    inactive: Subscriber[];
  };
  meta: { [key: string]: AlarmMeta };
  loading: { [key in AlarmLoading]?: boolean };
  count: { [key in AlarmTab]: number };
  updates: { [key in AlarmTab]?: number };
  expandedAll: boolean;
  customNotes: CustomNote[];
}

export const initialState: AlarmsState = {
  data: {
    unacknowledged: {
      FIRE: {},
      MEDICAL: {},
      PANIC: {},
      BURGLARY: {},
      SUPERVISORY: {},
      OTHER: {},
      UNKNOWN: {},
    },
    acknowledged: [],
    alerts: [],
    connectivity: [],
    test: {
      FIRE: {},
      MEDICAL: {},
      PANIC: {},
      BURGLARY: {},
      SUPERVISORY: {},
      OTHER: {},
      UNKNOWN: {},
    },
    inactive: [],
  },
  meta: {},
  count: {
    unacknowledged: 0,
    acknowledged: 0,
    alerts: 0,
    connectivity: 0,
    test: 0,
  },
  updates: {
    unacknowledged: 0,
  },
  loading: {
    acknowledged: false,
    customNote: false,
    inactive: false,
  },
  expandedAll: false,
  customNotes: [],
};

export const { name, reducer, actions } = createSlice({
  name: 'alarms',
  initialState,
  reducers: {
    // Unacknowledged or test alarms
    fetchAlarms: (state, { payload }: PayloadAction<{ alarms: Alarm[], type: 'unacknowledged' | 'test' }>) => {
      const sortGroups: { [key in AlarmType]?: Map<string, Alarm[]> } = {};
      let counter = payload.alarms.length;

      payload.alarms.forEach(value => {
        const alarm = updateAlarm(value);
        const group = generateAlarmGroupId(alarm);

        if (sortGroups[alarm.alarmType]) {
          const values = sortGroups[alarm.alarmType]?.get(group);

          if (values?.length) {
            values.push(alarm);
          } else {
            sortGroups[alarm.alarmType]?.set(group, [alarm]);
          }
        } else {
          sortGroups[alarm.alarmType] = new Map();

          sortGroups[alarm.alarmType]?.set(group, [alarm]);
        }
      });

      Object.keys(sortGroups).forEach(alarmType => {
        sortGroups[alarmType].forEach((values, group) => {
          const ids = values.map(alarm => alarm.id);
          const existing = state.data[payload.type][alarmType][group]?.length
            ? state.data[payload.type][alarmType][group].filter(alarm => ids.includes(alarm.id))
            : [];

          counter = counter - existing.length;

          state.data[payload.type][alarmType][group] = sortAlarms(
            updateArrayWithCallback<Alarm>(
              state.data[payload.type][alarmType][group] || [],
              values,
              (e, u) => e.id === u.id && e.businessUnitId === u.businessUnitId
            )
          );
        });
      });

      state.count[payload.type] = getAlarmsCount(state.data[payload.type]);
      if (payload.type === 'unacknowledged') {
        state.updates.unacknowledged = state.count.unacknowledged as number;
      }
    },
    updateAlarms: (state, { payload }: PayloadAction<{ alarm: Alarm, type: 'unacknowledged' | 'test' }>) => {
      const group = generateAlarmGroupId(updateAlarm(payload.alarm));
      const ackAlarms = state.data[payload.type][payload.alarm.alarmType][group];
      const alarmsCount = length(ackAlarms);
      const filtered = (ackAlarms || []).filter(
        alarm => alarm.id !== payload.alarm.id
      );

      if (filtered.length) {
        state.data[payload.type][payload.alarm.alarmType][group] = filtered;
      } else {
        // eslint-disable-next-line @typescript-eslint/no-dynamic-delete
        delete state.data[payload.type][payload.alarm.alarmType][group];
      }

      state.data.acknowledged = updateArrayWithCallback(
        state.data.acknowledged,
        [payload.alarm],
        (e, u) => e.id === u.id && e.businessUnitId === u.businessUnitId
      );

      state.count[payload.type] = getAlarmsCount(state.data[payload.type]);

      if (payload.type === 'unacknowledged') {
        state.updates.unacknowledged = getUpdatesCount(state.updates.unacknowledged, alarmsCount - filtered.length);
      }
    },
    removeAlarms: (state, { payload }: PayloadAction<{ alarms: Alarm[], type: 'unacknowledged' | 'test' }>) => {
      payload.alarms.forEach(alarm => {
        const group = generateAlarmGroupId(updateAlarm(alarm));
        const alarmsCount = length(state.data[payload.type][alarm.alarmType][group] || []);

        if (alarmsCount < 1) {
          // eslint-disable-next-line @typescript-eslint/no-dynamic-delete
          delete state.data[payload.type][alarm.alarmType][group];

          return;
        }

        state.data[payload.type][alarm.alarmType][group] = state.data[payload.type][alarm.alarmType][group]?.filter(
          a => a.id !== alarm.id
        );

        if (payload.type === 'unacknowledged') {
          state.updates.unacknowledged = state.count.unacknowledged;
        }
      });

      state.count[payload.type] = getAlarmsCount(state.data[payload.type]);
    },
    setAlarmsCount: (state, { payload: { tab, count } }: PayloadAction<{ count: number; tab: AlarmTab }>) => {
      state.count[tab] = count;
    },
    resetUnacknowledgedAlarmsUpdatesCounter: state => {
      state.updates.unacknowledged = 0;
    },

    // Acknowledged alarms
    fetchAcknowledgedAlarmsInit: (state, action: PayloadAction<PaginationRequestPayload>) => {
      state.loading!.acknowledged = true;
    },
    fetchAcknowledgedAlarmsSuccess: (
      state,
      { payload: { content, number, totalElements } }: PayloadAction<PaginationResponse<Alarm>>
    ) => {
      state.loading!.acknowledged = false;
      const alarms = content.filter(alarm => !alarm.isTest);

      if (number === 0) {
        state.data.acknowledged = alarms;
      } else {
        state.data.acknowledged = updateArrayWithCallback(
          state.data.acknowledged,
          alarms,
          (e, u) => e.id === u.id && e.businessUnitId === u.businessUnitId
        );
      }

      state.count.acknowledged = totalElements;
    },
    fetchAcknowledgedAlarmsFail: (state, payload) => {
      state.loading!.acknowledged = false;
    },

    resolveAckAlarmInit: (state, { payload }: PayloadAction<PaginationRequestPayload & { businessUnitId: Alarm['businessUnitId'], id: Alarm['id'] }>) => {
      state.loading!.acknowledged = true;
    },
    resolveAckAlarmFail: (state, payload) => {
      state.loading!.acknowledged = false;
    },
    resetAcknowledgedAlarms: state => {
      state.data.acknowledged = [];
    },

    removeAckAlarm: (state, { payload }: PayloadAction<Alarm>) => {
      state.data.acknowledged = state.data.acknowledged.filter(alarm => alarm.id !== payload.id);
    },

    // Ack alarm
    ackAlarmInit: (state, { payload }: PayloadAction<Alarm>) => {
      state.meta[generateMetaAlarmId(payload)] = {
        ...(state.meta[generateMetaAlarmId(payload)] || {}),
        isLoading: true,
      };
    },
    ackAlarmSuccess: (state, { payload }: PayloadAction<Alarm>) => {
      state.meta[generateMetaAlarmId(payload)] = {
        ...(state.meta[generateMetaAlarmId(payload)] || {}),
        isLoading: false,
      };
    },
    ackAlarmFail: (state, { payload }: PayloadAction<Alarm>) => {
      state.meta[generateMetaAlarmId(payload)] = {
        ...(state.meta[generateMetaAlarmId(payload)] || {}),
        isLoading: false,
      };
    },

    // Ack alarm all
    ackAlarmAllInit: (state, { payload }: PayloadAction<{
      alarm: Alarm;
      buId: Alarm['businessUnitId'];
      cidCode: Alarm['cid'];
      unitId: number;
      unitType: Alarm['unitType'];
    }>) => {
      state.meta[generateMetaAlarmId(payload.alarm)] = {
        ...(state.meta[generateMetaAlarmId(payload.alarm)] || {}),
        isLoading: true,
      };
    },

    ackAlarmAllSuccess: (state, { payload }: PayloadAction<{
      alarm: Alarm;
      buId: Alarm['businessUnitId'];
      cidCode: Alarm['cid'];
      unitId: number }>) => {
      state.meta[generateMetaAlarmId(payload.alarm)] = {
        ...(state.meta[generateMetaAlarmId(payload.alarm)] || {}),
        isLoading: false,
      };
    },

    ackAlarmAllFail: (state, { payload }: PayloadAction<{
      alarm: Alarm;
      buId: Alarm['businessUnitId'];
      cidCode: Alarm['cid'];
      unitId: number }>) => {
      state.meta[generateMetaAlarmId(payload.alarm)] = {
        ...(state.meta[generateMetaAlarmId(payload.alarm)] || {}),
        isLoading: true,
      };
    },

    // Handle expandable cards
    onAlarmExpand: (state, { payload: { alarm, expanded } }: PayloadAction<{ alarm: Alarm; expanded: boolean }>) => {
      state.meta[generateMetaAlarmId(alarm)] = {
        ...(state.meta[generateMetaAlarmId(alarm)] || {}),
        isExpanded: expanded,
      };
    },
    resetMeta: state => {
      state.meta = {};
    },

    onExpandAll: (state, { payload }: PayloadAction<boolean>) => {
      state.expandedAll = payload;
    },

    // Connectivity alarms
    fetchConnectivityAlarmsInit: state => {},
    fetchConnectivityAlarmsSuccess: (state, { payload }: PayloadAction<ConnectivityAlarm[]>) => {
      state.data.connectivity = payload;
      state.count.connectivity = state.data.connectivity.length;
    },
    fetchConnectivityAlarmsFailed: (state, action: PayloadAction<ResponseError>) => {},

    // Alerts
    fetchAlerts: (state, { payload }: PayloadAction<Alarm[]>) => {
      const updated = updateArrayWithCallback(
        state.data.alerts,
        payload,
        (e, u) => e.unitId === u.unitId && e.businessUnitId === u.businessUnitId && e.code === u.code
      ).filter(alarm => !alarm.isTest);
      state.data.alerts = sortAlarms(updated);
      state.count.alerts = state.data.alerts.length;
    },
    restoreAlerts: (state, { payload }: PayloadAction<Alarm[]>) => {
      state.data.alerts = filterRestoredAlarms(state.data.alerts, payload);
      state.count.alerts = state.data.alerts.length;
    },
    restoreFaults: (
      state,
      {
        payload,
      }: PayloadAction<{
        buId: Alarm['businessUnitId'];
        cid: Alarm['cid'];
        code: Alarm['code'];
        id: Alarm['id'];
        zone: Alarm['zone'];
      }>
    ) => {
      state.data.alerts = filterRestoredFaults(
        state.data.alerts,
        payload.buId,
        payload.cid,
        payload.code,
        payload.id,
        payload.zone
      );
      state.count.alerts = state.data.alerts.length;
    },

    // Mute alarm
    muteAlarmInit: (state, { payload }: PayloadAction<Alarm>) => {
      state.meta[generateMetaAlarmId(payload)] = {
        ...(state.meta[generateMetaAlarmId(payload)] || {}),
        isLoading: true,
      };
    },
    muteAlarmSuccess: (state, { payload }: PayloadAction<Alarm>) => {
      const group = generateAlarmGroupId(updateAlarm(payload));

      state.meta[generateMetaAlarmId(payload)] = {
        ...(state.meta[generateMetaAlarmId(payload)] || {}),
        isLoading: false,
      };
      state.data.unacknowledged[payload.alarmType][group] = updateArrayWithCallback(
        state.data.unacknowledged[payload.alarmType][group],
        payload,
        (e, u) => e.id === payload.id && u.businessUnitId === payload.businessUnitId
      );
    },
    muteAlarmFail: (state, { payload }: PayloadAction<Alarm>) => {
      state.meta[generateMetaAlarmId(payload)] = {
        ...(state.meta[generateMetaAlarmId(payload)] || {}),
        isLoading: false,
      };
    },

    fetchCustomNotesInit: (state, { payload: { businessUnitId, id, unitType } }: PayloadAction<{
      businessUnitId: number;
      id: number;
      unitType: string;
    }>) => {
      state.loading.customNote = true;
    },
    fetchCustomNotesSuccess: (state, { payload }: PayloadAction<CustomNote[]>) => {
      state.loading.customNote = false;
      state.customNotes = payload;
    },
    fetchCustomNotesFailed: (state, { payload }: PayloadAction<ResponseError>) => {
      state.loading.customNote = false;
    },

    fetchInactiveUnitsInit: (
      state,
      payload: PayloadAction<
        PaginationRequestPayload
      >
    ) => {
      state.loading.inactive = true;
      state.data.inactive = [];
    },
    fetchInactiveUnitsSuccess: (
      state,
      { payload: { content } }: PayloadAction<PaginationResponse<Subscriber>>
    ) => {
      state.loading.inactive = false;
      state.data.inactive = content;
    },
    fetchInactiveUnitsFailed: (state, action: PayloadAction<ResponseError>) => {
      state.loading.inactive = false;
    },

    resetInactiveUnits: state => {
      state.data.inactive = initialState.data.inactive;
    },
  },

  extraReducers: {
    [sharedActions.reset.toString()]: state => Object.assign(state, initialState),
  },
});

const getAlarmsState = (state: AES.RootState) => state.alarms;

export const selectors = {
  getAlarmsState,
  getAlarmsCount: createDraftSafeSelector(getAlarmsState, state => state.count),
  getAcknowledgedLoader: createDraftSafeSelector(getAlarmsState, state => state.loading.acknowledged),
  getAlarmsLoaders: createDraftSafeSelector(getAlarmsState, state => state.loading),
  getUnacknowledgedAlarmsUpdatesCounter: createDraftSafeSelector(getAlarmsState, state => state.updates.unacknowledged),
  getAcknowledgedAlarms: createDraftSafeSelector(getAlarmsState, mapAlarmsWithMeta('acknowledged')),
  getAlerts: createDraftSafeSelector(getAlarmsState, mapAlarmsWithMeta('alerts')),
  getConnectivityAlarms: createDraftSafeSelector(getAlarmsState, mapAlarmsWithMeta('connectivity')),
  getFirstReceivedAlarm: createDraftSafeSelector(getAlarmsState, state => {
    const values = generateAlarmsGroups(state.data.unacknowledged)
      .map(({ children }) => last(children))
      .filter(v => Boolean(v));
    const alarm = head(values);

    if (alarm) {
      return { ...alarm, meta: state.meta[generateMetaAlarmId(alarm)] || {} } as Alarm;
    }

    return null;
  }),
  getGroupedAlarms: (type: 'unacknowledged' | 'test') => createDraftSafeSelector(getAlarmsState, state => {
    const groups = generateAlarmsGroups(state.data[type]);

    return getGroupedAlarms(groups, state.meta);
  }),
  getCustomNotes: createDraftSafeSelector(getAlarmsState, state => state.customNotes),
  isExpandedAll: createDraftSafeSelector(getAlarmsState, state => state.expandedAll),
  getInactiveUnits: createDraftSafeSelector(getAlarmsState, state => state.data.inactive),

};

const ackAlarm = (action$: ActionsObservable<AnyAction>) =>
  action$.pipe(
    filter(actions.ackAlarmInit.match),
    mergeMap(({ payload }) =>
      http.put(api.alarm.acknowledge, payload).pipe(
        mergeMap(() =>
          of(
            actions.ackAlarmSuccess(payload),
            notificationsActions.enqueue({
              message: 'Alarm has been acknowledged',
              options: { variant: 'success' },
            })
          )
        ),
        catchError(() => of(actions.ackAlarmFail(payload)))
      )
    )
  );

const ackAlarmAll = (action$: ActionsObservable<AnyAction>) =>
  action$.pipe(
    filter(actions.ackAlarmAllInit.match),
    mergeMap(({ payload }) =>
      http.put(api.alarm.acknowledgeAll, payload).pipe(
        mergeMap(() =>
          of(
            actions.ackAlarmAllSuccess(payload),
            notificationsActions.enqueue({
              message: 'All alarm has been acknowledged',
              options: { variant: 'success' },
            }),
            window.location.reload()
          )
        ),
        catchError(() => of(actions.ackAlarmAllFail(payload)))
      )
    )
  );

const muteAlarm = (action$: ActionsObservable<AnyAction>) =>
  action$.pipe(
    filter(actions.muteAlarmInit.match),
    mergeMap(({ payload }) =>
      http.put(api.alarm.mute, { ...payload, mute: true }).pipe(
        mergeMap(res =>
          of(
            actions.muteAlarmSuccess(getResponsePayload(res)),
            notificationsActions.enqueue({
              message: 'Alarm has been muted',
              options: { variant: 'success' },
            })
          )
        ),
        catchError(() => of(actions.muteAlarmFail(payload)))
      )
    )
  );

const fetchAcknowledgedAlarms = (action$: ActionsObservable<AnyAction>) =>
  action$.pipe(
    filter(actions.fetchAcknowledgedAlarmsInit.match),
    mergeMap(({ payload }) => {
      const query = getPaginationQueryParams(payload, {
        sort: ['alarmTypePriority', 'acknowledgedAt', 'desc'].join(','),
      });

      return http.getJSON<PaginationResponse<Alarm>>(api.alarm.acknowledgedAlarms(query)).pipe(
        mergeMap(res =>
          of(actions.fetchAcknowledgedAlarmsSuccess(res), pageActions.setPagePagination(extractPaginationValues(res)))
        ),
        catchError(err => of(actions.fetchAcknowledgedAlarmsFail(err)))
      );
    })
  );

const fetchConnectivityAlarmsEpic = (action$: ActionsObservable<AnyAction>) =>
  action$.pipe(
    filter(actions.fetchConnectivityAlarmsInit.match),
    mergeMap(() =>
      http.getJSON<ConnectivityAlarm[]>(api.alarmAutomation.connectivity).pipe(
        mergeMap(payload => of(actions.fetchConnectivityAlarmsSuccess(payload))),
        catchError(err => of(actions.fetchConnectivityAlarmsFailed(getResponseError(err))))
      )
    )
  );

const fetchCustomNotesEpic = (action$: ActionsObservable<AnyAction>) =>
  action$.pipe(
    filter(actions.fetchCustomNotesInit.match),
    mergeMap(({ payload: { businessUnitId, id, unitType } }) =>
      http.getJSON<CustomNote[]>(api.alarm.customNotes(businessUnitId, id, unitType)).pipe(
        mergeMap(payload => of(actions.fetchCustomNotesSuccess(payload))),
        catchError(err => of(actions.fetchCustomNotesFailed(getResponseError(err))))
      )
    )
  );

const resolveAckAlarmEpic = (action$: ActionsObservable<AnyAction>) =>
  action$.pipe(
    filter(actions.resolveAckAlarmInit.match),
    mergeMap(({ payload: { businessUnitId, id, pagination } }) =>
      http.put(api.alarm.resolve, { businessUnitId, id }).pipe(
        mergeMap(() =>
          of(
            actions.fetchAcknowledgedAlarmsInit({ pagination }),
            notificationsActions.enqueue({
              message: 'Alarm has been resolved',
              options: { variant: 'success' },
            })
          )
        ),
        catchError(res => of(actions.resolveAckAlarmFail(res)))
      )
    )
  );

const fetchInactiveUnitsEpic = (action$: ActionsObservable<AnyAction>) =>
  action$.pipe(
    filter(action => actions.fetchInactiveUnitsInit.match(action)),
    mergeMap(({ payload: { pagination, initial } }) => {
      const searchParams = getPaginationQueryParams({ pagination, initial });

      return http.getJSON<PaginationResponse<Subscriber>>(api.alarm.inactiveUnits(searchParams)).pipe(
        mergeMap(res =>
          of(actions.fetchInactiveUnitsSuccess(res), pageActions.setPagePagination(extractPaginationValues(res)))
        ),
        catchError(err => {
          const error = getResponseError(err);

          return of(actions.fetchInactiveUnitsFailed(error));
        })
      );
    })
  );

export const epics = combineEpics(
  ackAlarm,
  ackAlarmAll,
  fetchAcknowledgedAlarms,
  muteAlarm,
  fetchConnectivityAlarmsEpic,
  fetchCustomNotesEpic,
  resolveAckAlarmEpic,
  fetchInactiveUnitsEpic);

export const allEpics = {
  ackAlarm,
  ackAlarmAll,
  fetchAcknowledgedAlarms,
  muteAlarm,
  fetchConnectivityAlarmsEpic,
  fetchCustomNotesEpic,
  resolveAckAlarmEpic,
  fetchInactiveUnitsEpic
};

declare global {
  namespace AES {
    export interface RootState {
      alarms: AlarmsState;
    }
    export interface Actions {
      alarms: typeof actions;
    }

    export interface Selectors {
      alarms: typeof selectors;
    }
  }
}
