import { Chart as DevXChart, Legend, PieSeries } from '@devexpress/dx-react-chart-material-ui';
import { WithStyles, withStyles, Grid } from '@material-ui/core';
import clsx from 'clsx';
import { Fragment } from 'react';

import { styles } from './PieChart.styles';

export interface PieData {
  device: string;
  signals: number;
}

export interface PieChartProps extends WithStyles<typeof styles> {
  title?: string;
  data: PieData[];
}

type ChartRootProps = WithStyles<typeof styles> & DevXChart.RootProps;
const ChartRootComponent = withStyles(styles)(({ classes, children, ...restProps }: ChartRootProps) => (
  <Grid {...restProps} className={classes.chart}>
    {children}
  </Grid>
));

type LegendRootProps = WithStyles<typeof styles> & Legend.RootProps;
const LegendRoot = withStyles(styles)(({ classes, ...restProps }: LegendRootProps) => (
  <Legend.Root className={classes.legend} {...restProps} />
));

type LegendItemProps = WithStyles<typeof styles> & Legend.ItemProps;
const LegendComponent = withStyles(styles)(({ classes, ...props }: LegendItemProps) => (
  <Legend.Item {...props} className={classes.legend} />
));

type PieSeriesPointProps = WithStyles<typeof styles> & PieSeries.PointProps;
const PieSeriesPoint = withStyles(styles)(({ classes, ...restProps }: PieSeriesPointProps) => {
  const lineSize = 115;
  const { startAngle, endAngle, val, value, arg, maxRadius: r, innerRadius } = restProps;
  const angle = startAngle + (endAngle - startAngle) / 2 - Math.PI / 2;
  const rMid = r - (r * (1 - innerRadius)) / 2;
  const x = Math.cos(angle) * rMid;
  const y = Math.sin(angle) * rMid;
  const rtl = x > 0;
  const dx = (rtl && lineSize) || -115;
  return (
    <Fragment>
      <PieSeries.Point {...restProps} />
      {value && <g transform={`translate(${arg} ${val})`}>
        <line x1={x} y1={y} x2={x + dx} y2={y} className={classes.pieLine} />
        <text
          x={x + dx}
          y={y - 5}
          className={clsx(classes.pieText, {
            [classes.rtl]: rtl,
          })}
        >
          {Math.round(value)}%
        </text>
      </g>}
    </Fragment>
  );
});

export const PieChart = withStyles(styles)(({ data: inputData }: PieChartProps) => {
  // eslint-disable-next-line
  const dataSource = inputData?.filter(item => {
    if (item.signals !== 0) {
      return item;
    }
  });

  // Commented out for future-api-purpose
  // const data = (total =>
  //   dataSource.map(({ device, signals }) => ({
  //     device,
  //     signals: signals / total,
  //   })))(sum(dataSource.map(item => item.signals)));

  return (
    <DevXChart data={dataSource} rootComponent={ChartRootComponent}>
      <Legend position="bottom" rootComponent={LegendRoot} itemComponent={LegendComponent} />
      <PieSeries innerRadius={0.85} pointComponent={PieSeriesPoint} valueField={'signals'} argumentField="device" />
    </DevXChart>
  );
});
