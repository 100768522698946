import { Grid, WithStyles, withStyles } from '@material-ui/core';
import { equals } from 'ramda';
import { useSelector } from 'react-redux';
import { Redirect } from 'react-router';

import { styles } from './Auth.styles';
import { Login } from './Login';
import { MFAConfirmation } from './MFAConfirmation';
import { MFARegistration } from './MFARegistration';

import { PageLoader } from '~components';
import { appRoutes } from '~constants';
import { selectors } from '~store';

const Auth = withStyles(styles)(({ classes }: WithStyles<typeof styles>) => {
  const currentUser = useSelector(selectors.auth.getCurrentUser, equals);
  const mfa = useSelector(selectors.auth.getMFA, equals);
  const shouldMFARegistration = !mfa?.mfaRegister && Boolean(mfa?.mfaToken?.length);
  const shouldMFAConfirmation = mfa?.mfaRegister && Boolean(mfa?.mfaToken?.length);
  const authToken = useSelector(selectors.auth.getAuthToken, equals);

  const renderAuthOptions = () => {
    switch (true) {
      case shouldMFARegistration: {
        return <MFARegistration />;
      }

      case shouldMFAConfirmation: {
        return <MFAConfirmation />;
      }

      default:
        return <Login />;
    }
  };

  if (currentUser) {
    return <Redirect to={appRoutes.root} />;
  }

  if (authToken?.length) {
    return <PageLoader fullscreen />;
  }

  return (
    <Grid container alignContent="center" alignItems="center" className={classes.root}>
      <Grid item className={classes.container} xs={4}>
        {renderAuthOptions()}
      </Grid>
    </Grid>
  );
});

export default Auth;
