import { Button, Grid } from '@material-ui/core';
import { withStyles, WithStyles } from '@material-ui/styles';
import { Formik, Form } from 'formik';
import { equals } from 'ramda';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';

import { getAAHeartbeatOptions } from '~pages/Settings/AlarmAutomation';
import { FormCheckbox } from '~ui-kit/Forms';

import { initialValues, validationSchema } from './DefaultBusinessUnit.config';
import { styles } from './DefaultBusinessUnit.styles';

import { PageLoader } from '~components';
import { boundActions, selectors } from '~store';
import { Divider, DialogContent, DialogActions, FormTextField, Required, FormSelect, SelectOption } from '~ui-kit';
import { getFormErrors } from '~utils';

export const DefaultBusinessUnit = withStyles(styles)(({ classes }: WithStyles<typeof styles>) => {
  const unitCreated = useSelector(selectors.businessUnits.isBusinessUnitCreated, equals);
  const portRanges = useSelector(selectors.app.getAllowedPortRanges, equals);
  const error = useSelector(selectors.businessUnits.getBusinessUnitError, equals);
  const businessUnitLoaders = useSelector(selectors.businessUnits.getBusinessUnitsLoaders, equals);
  const isLoading = businessUnitLoaders.create || unitCreated;
  const isDatabaseMigrationInProgress = useSelector(selectors.app.isDatabaseMigrationInProgress);

  useEffect(
    () => () => {
      boundActions.businessUnits.resetUnitStatus();
    },
    []
  );

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={({ name, ipAddress, portNumber, isHeartbeat, isDatabaseMigration }) => {
        if (isDatabaseMigration) {
          boundActions.app.setAppDialog({
            dialog: 'uploadMigratedDB',
            value: true,
          });

          return;
        }

        boundActions.businessUnits.createDefaultBusinessUnitInit({
          alarmAutomation: {
            isServer: true,
            sockets: [
              {
                ipAddress,
                portNumber,
                isHeartbeatEnabled: isHeartbeat,
                number: 0,
              },
            ],
          },
          name,
          automationFormat: 1,
          oldAlarms: 1,
          receiverNumber: 1,
          isDefault: true,
        });
      }}
    >
      {({ values, setFieldValue, setErrors }) => {
        const { isDatabaseMigration } = values;

        useEffect(() => {
          setErrors(
            getFormErrors(error, {
              alarmAutomation: 'tcpServer',
            })
          );
        }, [error]);

        useEffect(() => {
          if (isDatabaseMigration) {
            setFieldValue('name', initialValues.name);
            setFieldValue('portNumber', initialValues.portNumber);
            setFieldValue('ipAddress', initialValues.ipAddress);
            setFieldValue('isHeartbeat', initialValues.isHeartbeat);
          }
        }, [isDatabaseMigration]);

        return (
          <Form>
            {isLoading && <PageLoader fullscreen />}

            <DialogContent>
              <FormTextField
                cleanable
                name="name"
                label={<Required>BU Name</Required>}
                disabled={isDatabaseMigration}
              />

              <FormTextField
                cleanable
                name="portNumber"
                className={classes.field}
                label={<Required>Alarm automation port number</Required>}
                type="number"
                helperText={portRanges.alarmAutomation}
                disabled={isDatabaseMigration}
              />

              <Grid container spacing={3}>
                <Grid item xs={6}>
                  <FormTextField
                    cleanable
                    name="ipAddress"
                    label={<Required>Alarm automation IP address</Required>}
                    disabled={isDatabaseMigration}
                  />
                </Grid>

                <Grid item xs={6}>
                  <FormSelect
                    name="isHeartbeat"
                    label="Primary Heartbeat"
                    options={getAAHeartbeatOptions() as unknown as SelectOption[]}
                    disabled={isDatabaseMigration}
                  />
                </Grid>
              </Grid>

              <FormCheckbox
                className={classes.checkbox}
                name="isDatabaseMigration"
                label="I will import BU from existing MNR"
                disabled={isDatabaseMigrationInProgress}
              />
            </DialogContent>

            <Divider />

            <DialogActions>
              <Button type="submit" variant="contained" color="primary">
                Next
              </Button>
            </DialogActions>
          </Form>
        );
      }}
    </Formik>
  );
});
