import * as yup from 'yup';

import { alarmAutomationValidators, businessUnitsValidators } from '~utils';

export const initialValues = {
  name: '',
  portNumber: 0,
  ipAddress: '',
  isHeartbeat: false,
  isDatabaseMigration: false,
};

export const validationSchema = yup.object().shape({
  name: yup.string().when('isDatabaseMigration', {
    is: false,
    then: businessUnitsValidators.name,
    otherwise: yup.string().notRequired(),
  }),
  portNumber: yup.number().when('isDatabaseMigration', {
    is: false,
    then: alarmAutomationValidators.portNumber,
    otherwise: yup.number().notRequired(),
  }),
  ipAddress: yup.string().when('isDatabaseMigration', {
    is: false,
    then: alarmAutomationValidators.ipAddress,
    otherwise: yup.string().notRequired(),
  }),
  isDatabaseMigration: yup.boolean(),
});
