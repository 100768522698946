export enum LicenseType {
  UP_TO_5000_UNITS = '1',
  UNLIMITED = '2',
  SELF_MONITORING = '3',
  BACNET = '4',
}

export type LicenseTier = {
  label: string;
  value: LicenseType;
};

export type LicenseDetails = {
  productKey: string;
  requestKey: string;
  licenseKey: string;
  availableLicenses: LicenseTier[];
  requestedLicenseType: LicenseTier;
  currentLicenseType: LicenseTier;
  requestedTimestamp: number;
  totalUnitsCount: number;
};

export type AddOnLicenseType = 'self-monitoring' | 'bacnet' | 'instance-3';

export type AddOnLicenseDetails = {
  lastUpdated?: number,
  licenseKey: string,
  type: LicenseType | null,
  productKey: string,
  requestKey: string,
  requestedType: LicenseTier,
  requestedTimestamp: number,
};
